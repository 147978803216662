import {
  createContext,
  type PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { API_ENDPOINTS } from '@packages/constants'

import { useWalletContext } from 'src/wallet'
import { useApi } from 'src/api'
import { replaceUrlParams } from 'src/shared/utils'
import type { RewardEventBalance } from 'src/rewards/interfaces'

interface MysticPointsContextType {
  mysticPoints: bigint
  isLoadingMysticPoints: boolean
  mysticPointsError: string | null | undefined

  refetchMysticPointsBalance: () => void
}

const MysticPointsContext = createContext<MysticPointsContextType | undefined>(undefined)

export const useMysticPointsContext = () => {
  const context = useContext(MysticPointsContext)

  if (!context) {
    throw new Error('useMysticPointsContext must be used within Mystic Points')
  }

  return context
}

export const MysticPointsProvider = ({ children }: PropsWithChildren) => {
  const { isConnected, runesAddress, paymentAddress } = useWalletContext()
  const [refetchKey, setRefetchKey] = useState<string | number | null>(null)

  const {
    data: runesBalance,
    error: runesBalanceError,
    loading: isRunesLoading,
  } = useApi<RewardEventBalance>({
    endpoint: replaceUrlParams(API_ENDPOINTS.GET.runes.balances.rewardBalanceByAccount, {
      address: runesAddress?.addrString || '',
    }),
    disable: !isConnected || !runesAddress || Boolean(refetchKey),
  })

  const {
    data: paymentBalance,
    error: paymentBalanceError,
    loading: isPaymentLoading,
  } = useApi<RewardEventBalance>({
    endpoint: replaceUrlParams(API_ENDPOINTS.GET.runes.balances.rewardBalanceByAccount, {
      address: paymentAddress?.addrString || '',
    }),
    disable:
      !isConnected ||
      !paymentAddress ||
      Boolean(refetchKey) ||
      Boolean(
        runesAddress?.addrString &&
          paymentAddress?.addrString &&
          runesAddress.addrString === paymentAddress.addrString
      ),
  })

  const handleRefetch = useCallback(() => {
    setRefetchKey(new Date().getTime())
  }, [])

  useEffect(
    function resetRefetchKey() {
      if (refetchKey) {
        setRefetchKey(null)
      }
    },
    [refetchKey]
  )

  const providerValue = useMemo<MysticPointsContextType>(() => {
    const totalPoints = (runesBalance?.points ?? 0n) + (paymentBalance?.points ?? 0n)
    const isLoading = isRunesLoading || isPaymentLoading
    const error = runesBalanceError || paymentBalanceError

    return {
      mysticPointsError: error,
      isLoadingMysticPoints: isLoading,
      mysticPoints: totalPoints,
      refetchMysticPointsBalance: handleRefetch,
    }
  }, [
    handleRefetch,
    isRunesLoading,
    isPaymentLoading,
    runesBalance?.points,
    paymentBalance?.points,
    runesBalanceError,
    paymentBalanceError,
  ])

  return (
    <MysticPointsContext.Provider value={providerValue}>{children}</MysticPointsContext.Provider>
  )
}

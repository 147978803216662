import { PropsWithChildren } from 'react'
import { styled } from 'styled-components'

import { BREAKPOINTS, COLORS } from 'src/shared/constants'
import { HeaderText } from 'src/shared/components'

export type RuneToolsTabPanelWrapperProps = PropsWithChildren<{
  title: string
}>

export function RuneToolsTabPanel({ title, children }: RuneToolsTabPanelWrapperProps) {
  return (
    <Container>
      <HeaderText>{title}</HeaderText>
      <div>{children}</div>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  border: 1px ${COLORS.border} solid;
  border-radius: 16px;

  @media (max-width: ${BREAKPOINTS.medium}) {
    padding: 15px;
  }
`

import { createGlobalStyle, styled } from 'styled-components'
import { BrowserRouter } from 'react-router-dom'
import { WalletStandardProvider } from '@wallet-standard/react'
import { Bounce, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { AppBar } from '@mui/material'

import { WalletProvider, EscrowWalletProvider } from './wallet'
import {
  PageRoutes,
  NavBar,
  NAVBAR_HEIGHT,
  Footer,
  CONTENT_PADDING,
  MOBILE_CONTENT_PADDING,
} from './pages'
import { BREAKPOINTS, COLORS, FONTS } from './shared/constants'
import { BANNER_MESSAGE } from './settings'
import { MarketOrderProvider, LimitOrderProvider } from './orders'
import { MysticPointsProvider } from './rewards'
import { TransferProvider } from './runes/TransferContext'
import { EscrowTransferProvider } from './runes/EscrowTransferContext'
import { GoogleAnalyticsProvider } from './analytics'
import { FeatureFlagProvider } from './featureFlags'

const TOAST_AUTOCLOSE_MS = 30 * 1000

const ToastStyles = createGlobalStyle`
    .Toastify__toast-container{
        display: flex;
        justify-content: end;
    }
    .Toastify__toast-body {
        padding: 0;
    }
    .Toastify__toast {
        border: 1px solid white;
        border-radius: 5px;
        padding: 3px;
        background: ${COLORS.background.primary};
        width: fit-content;
        max-width: 250px;
        @media (max-width: ${BREAKPOINTS.medium}) {
            bottom: 10px !important;
            right: 10px;
        }
    }
    .Toastify__close-button {
        padding: 5px;
        svg {
            min-height: 18px;
            min-width: 16px;
        }
    }
`

export function App() {
  return (
    <GoogleAnalyticsProvider window={window}>
      <FeatureFlagProvider>
        <WalletStandardProvider>
          <ToastStyles />
          <WalletProvider>
            <EscrowWalletProvider>
              <BrowserRouter>
                <MysticPointsProvider>
                  <MarketOrderProvider>
                    <LimitOrderProvider>
                      <TransferProvider>
                        <EscrowTransferProvider>
                          {BANNER_MESSAGE && <InfoBanner position='sticky'>{BANNER_MESSAGE}</InfoBanner>}
                          <NavBar />
                          <Container>
                            <PageRoutes />
                          </Container>
                          <Footer />
                          <ToastContainer
                            position='bottom-right'
                            stacked
                            newestOnTop
                            limit={5}
                            theme='dark'
                            transition={Bounce}
                            style={{
                              minWidth: '250px',
                              padding: '0',
                              margin: '0',
                              minHeight: '50px',
                            }}
                            progressStyle={{ background: COLORS.hover }}
                            autoClose={TOAST_AUTOCLOSE_MS}
                          />
                        </EscrowTransferProvider>
                      </TransferProvider>
                    </LimitOrderProvider>
                  </MarketOrderProvider>
                </MysticPointsProvider>
              </BrowserRouter>
            </EscrowWalletProvider>
          </WalletProvider>
        </WalletStandardProvider>
      </FeatureFlagProvider>
    </GoogleAnalyticsProvider>
  )
}

const InfoBanner = styled(AppBar)`
  height: 50px;
  text-align: center;
  padding: 10px;
  font-family: ${FONTS.text};
  font-weight: 500;
  font-size: 18px;
  background-color: ${COLORS.warning};
`

const Container = styled.div`
  padding-top: ${NAVBAR_HEIGHT};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: ${CONTENT_PADDING};
  @media (max-width: ${BREAKPOINTS.medium}) {
    padding: ${MOBILE_CONTENT_PADDING};
  }
`

import { styled } from 'styled-components'

import mysticPointsIcon from 'src/pages/assets/mysticPoints.svg'

export function MysticPointsLogo() {
  return <Logo src={mysticPointsIcon} alt={''} />
}

const Logo = styled.img`
  height: 1em;
`

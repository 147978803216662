import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { styled } from 'styled-components'

import { RuneBalance } from '@packages/interfaces'

import {
  Accordion,
  Drawer,
  DrawerBoldColumn,
  DrawerButtons,
  DrawerCancelButton,
  DrawerCard,
  DrawerConfirmButton,
  DrawerRow,
  DrawerTitle,
  ErrorMessage,
  VirtualizedTable,
} from 'src/shared/components'
import { NetworkFeeSelector, SuccessfulTransactionToast } from 'src/web3'
import { getRuneTransactionInputs, RuneUtxoOutpoint, useWalletContext } from 'src/wallet'
import { DISABLE_WRITES } from 'src/settings'
import { useGoogleAnalyticsContext } from 'src/analytics'
import { useIsMobile } from 'src/shared/hooks'
import { BREAKPOINTS } from 'src/shared/constants'

import { useTransferContext } from '../TransferContext'
import { RuneDetailsLink } from './RuneDetailsLink'
import { formatRunesAmount } from '../utils'
import { RuneOutpointExternalLink } from './RuneOutpointExternalLink.tsx'

interface Props {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  selectedRuneBalance: RuneBalance
  transferAmount: bigint
  recipientAddress: string
}

export function ConfirmTransferRunesDrawer({ 
  isOpen,
  onClose,
  onConfirm,
  selectedRuneBalance,
  transferAmount,
  recipientAddress,
}: Props) {
  const isMobile = useIsMobile()
  const { transferRunes, runesAddress } = useWalletContext()
  const [loadingText, setLoadingText] = useState<string>()
  const [error, setError] = useState<string>()
  const [outpoints, setOutpoints] = useState<RuneUtxoOutpoint[]>([])

  const { confirmEvent, executeEvent, abandonEvent } = useGoogleAnalyticsContext()

  useEffect(() => {
    (async () => {
      setOutpoints([])

      if (!runesAddress || !isOpen) {
        return
      }

      setOutpoints(await getRuneTransactionInputs(runesAddress, selectedRuneBalance.runeId, transferAmount))
    })()
  }, [
    isOpen,
    runesAddress?.addrString,
    selectedRuneBalance.runesAmount,
    selectedRuneBalance.runeId,
    transferAmount
  ])

  function handleOnClose() {
    abandonEvent(true, 'transfer', {
      token_name: selectedRuneBalance.runeName,
      token_type: 'rune',
      transfer_amount: transferAmount,
      fee,
    })

    setError(undefined)
    onClose()
  }

  function handleOnCancel() {
    abandonEvent(false, 'transfer', {
      token_name: selectedRuneBalance.runeName,
      token_type: 'rune',
      transfer_amount: transferAmount,
      fee,
    })

    setLoadingText(undefined)
  }

  const {
    fee,
    setFee,
  } = useTransferContext()

  async function handleConfirm() {
    confirmEvent('transfer', {
      token_name: selectedRuneBalance.runeName,
      token_type: 'rune',
      transfer_amount: transferAmount,
      fee,
    })

    try {
      const signTxResult = await transferRunes({
        recipientAddress,
        transferAmount,
        fee: BigInt(fee),
        rune: selectedRuneBalance,
        onStatusChange: (status) => {
          switch (status) {
            case 'build':
              setLoadingText('Building tx')
              break
            case 'wallet-prompt':
              setLoadingText('See Wallet')
              break
          }
        },
      })
  
      if (signTxResult.success) {
        toast(
          <SuccessfulTransactionToast
            message='Transfer successful!'
            transactionId={signTxResult.txId ?? ''}
          />,
          { toastId: 'transfer-successful-' + signTxResult.txId }
        )
        executeEvent('transfer', {
          token_name: selectedRuneBalance.runeName,
          token_type: 'rune',
          transfer_amount: transferAmount,
          fee,
        })
        
        onConfirm()
        onClose()
      } else {
        setError(signTxResult.error)
      }
    } catch (error: any) {
      console.error(error)

      abandonEvent(false, 'transfer', {
        token_name: selectedRuneBalance.runeName,
        token_type: 'rune',
        transfer_amount: transferAmount,
        fee,
      })
  
      setError(error.message)
    } finally {
      setLoadingText(undefined)
    }
  }

  return (
    <Drawer isOpen={isOpen} onClose={handleOnClose}>
      <DrawerCard>
        <ConfirmTitle>
          CONFIRM
        </ConfirmTitle>
      </DrawerCard>

      <DrawerCard>
        <DrawerTitle>TRANSFER</DrawerTitle>

        <DrawerRow>
          <DrawerRuneDetailsLink rune={selectedRuneBalance} openInNewTab />
        </DrawerRow>
      </DrawerCard>

      <DrawerCard>
        <DrawerQuantityRaw>
          <DrawerBoldColumn>Quantity</DrawerBoldColumn>
          <div>
            {formatRunesAmount({
              rune: selectedRuneBalance,
              runesAmount: transferAmount
            })}
          </div>
        </DrawerQuantityRaw>
      </DrawerCard>

      <DrawerCard>
        <DrawerRow>
          <DrawerBoldColumn>Recipient Address</DrawerBoldColumn>
        </DrawerRow>
        <DrawerRow>
          <DrawerRecipientAddressColumn>{recipientAddress}</DrawerRecipientAddressColumn>
        </DrawerRow>
      </DrawerCard>

      <DrawerCard>
        <Accordion linkText='View Rune Outpoints'>
          <VirtualizedTableWrapper
            columns={[
              {
                dataKey: 'runesAmount',
                label: isMobile ? 'Qty' : 'Quantity',
                formatter: ({ data: runeOutpoint }) =>
                  formatRunesAmount({
                    rune: selectedRuneBalance,
                    runesAmount: runeOutpoint.amount,
                  }),
              },
              {
                dataKey: 'outpointId',
                label: 'ID',
                formatter: ({ data: runeOutpoint }) => (
                  <RuneOutpointExternalLink runeOutpoint={{
                    ...runeOutpoint.utxo,
                    txId: runeOutpoint.utxo.txid,
                    amount: runeOutpoint.amount,
                    outpointId: `${runeOutpoint.utxo.txid}:${runeOutpoint.utxo.vout}`,
                    vout: BigInt(runeOutpoint.utxo.vout),
                    value: BigInt(runeOutpoint.utxo.value),
                  }} />
                ),
              },
            ]}
            paginatedData={[outpoints]}
            fetchPage={async () => {}}
            hasNextPage={false}
            viewableRows={outpoints.length}
            rowHeight={30}
          />
        </Accordion>
      </DrawerCard>

      <DrawerCard>
        <DrawerRow>
          <NetworkFeeSelector
            selectedFee={fee}
            onChange={(fee) => {
              setFee(fee)
            }}
          />
        </DrawerRow>
      </DrawerCard>

      {error && <ErrorMessage message={error} />}
      <DrawerButtons>
        {loadingText && (
          <DrawerCancelButton onClick={() => handleOnCancel()}>Cancel Tx</DrawerCancelButton>
        )}
        <ConfirmButton
          onClick={handleConfirm}
          disabled={DISABLE_WRITES}
          loading={!!loadingText}
        >
          {loadingText ?? 'Confirm Transfer'}
        </ConfirmButton>
      </DrawerButtons>
    </Drawer>
  )
}

const ConfirmTitle = styled.h2`
  font-weight: 500;
`

const DrawerRuneDetailsLink = styled(RuneDetailsLink)`
  font-size: 14px;
  font-weight: 600;
`
const DrawerQuantityRaw = styled(DrawerRow)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const DrawerRecipientAddressColumn = styled(DrawerBoldColumn)`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
`

const VirtualizedTableWrapper = styled(VirtualizedTable<RuneUtxoOutpoint>)`
  tfoot {
    display: none;
  }
  thead {
    position: relative !important;
  }
`

const ConfirmButton = styled(DrawerConfirmButton)`
  padding: 9px 16px !important;

  @media (max-width: ${BREAKPOINTS.medium}) {
    width: 100%;
  }
`

import { useMemo } from 'react'

import { API_ENDPOINTS } from '@packages/constants'

import { useApi } from 'src/api'
import { replaceUrlParams } from 'src/shared/utils'
import type { RewardEventProjection, RewardEventType } from 'src/rewards/interfaces'
import { useDebouncedValue } from 'src/shared/hooks'

export type UseGetRewardSizeParams = {
  runeId: string | undefined
  eventType: RewardEventType
  fee: number | bigint
  disabled?: boolean
  recalculateRewardSizeTime?: number
}

export function useGetRewardSize({
  runeId,
  eventType,
  fee,
  disabled = false,
  recalculateRewardSizeTime = 150,
}: UseGetRewardSizeParams) {
  // Fee value can be changed quickly in UI
  // Set debounce here can prevent unnecessary requests
  const [debouncedFee] = useDebouncedValue(fee, recalculateRewardSizeTime)

  const apiParams = useMemo(() => {
    return {
      endpoint: replaceUrlParams(API_ENDPOINTS.GET.runes.projections.get, {
        runeId: runeId || '',
      }),
      queryParams: {
        eventType: eventType,
        fee: debouncedFee,
      },
      disable: !runeId || disabled,
    }
  }, [disabled, eventType, debouncedFee, runeId])

  const { data, loading, error } = useApi<RewardEventProjection>(apiParams)

  const points = data?.points ?? 0n

  return { data: points, loading, error }
}

import { styled } from 'styled-components'

import { DrawerCard, DrawerColumn, DrawerRow, DrawerTitle } from 'src/shared/components'
import { BREAKPOINTS } from 'src/shared/constants'

import { MysticPointsLink } from './MysticPointsLink'

type Props = {
  points: bigint
  place: string
}

export function MysticPointsDrawerCard({ points, place }: Props) {
  return (
    <DrawerCard>
      <MysticPointsDrawerRow>
        <DrawerTitleWrapped>Once this {place} executes, you will receive</DrawerTitleWrapped>
        <DrawerColumn>
          <DrawerMysticPointsLink mysticPoints={points} />
        </DrawerColumn>
      </MysticPointsDrawerRow>
    </DrawerCard>
  )
}

const DrawerMysticPointsLink = styled(MysticPointsLink)`
  font-size: 16px;
`

const DrawerTitleWrapped = styled(DrawerTitle)`
  font-size: 1em;
`

const MysticPointsDrawerRow = styled(DrawerRow)`
  @media (max-width: ${BREAKPOINTS.medium}) {
    flex-direction: row;
  }
`

import { useState } from 'react'
import styled from 'styled-components'

import { BREAKPOINTS } from 'src/shared/constants'
import { RUNE_INSCRIPTION_IMAGE_URL_BASE } from 'src/settings'

import { RuneSymbolDisplay } from './RuneSymbolDisplay'
import { RUNE_IMAGE_OVERRIDES } from '../constants'

const IMAGE_NAME_POSTFIX = 'i0'

type IMAGE_SIZE = 'small' | 'medium'

interface PartialRuneDetails {
  runeName: string
  runeSymbolChar?: string
  runeNameSpacerIndices?: bigint[]
  etchedTransactionId?: string
}

function getRuneInscriptionImageUrl(runeDetails: PartialRuneDetails) {
  const override = runeDetails.etchedTransactionId
    ? RUNE_IMAGE_OVERRIDES[runeDetails.etchedTransactionId]
    : undefined

  return `${RUNE_INSCRIPTION_IMAGE_URL_BASE}${
    override ?? runeDetails.etchedTransactionId
  }${IMAGE_NAME_POSTFIX}`
}

interface Props {
  runeDetails: PartialRuneDetails
  className?: string
  size?: IMAGE_SIZE
}

export function RuneImage({ runeDetails, className, size = 'small' }: Props) {
  const [imageLoaded, setImageLoaded] = useState(false)

  function onImageLoaded() {
    setImageLoaded(true)
  }

  const src = getRuneInscriptionImageUrl(runeDetails)

  return (
    <PlaceholderImage className={className} loaded={imageLoaded}>
      {!imageLoaded && (runeDetails.runeSymbolChar || runeDetails.runeName.length > 0) && (
        <RuneSymbolDisplayWrapper
          runeSymbol={runeDetails.runeSymbolChar ?? runeDetails.runeName[0]}
          size={size}
        />
      )}
      <ImageLoader src={src} onLoad={onImageLoaded} />

      {imageLoaded && <Image className={className} src={src} onLoad={onImageLoaded} size={size} />}
    </PlaceholderImage>
  )
}

const RuneSymbolDisplayWrapper = styled(RuneSymbolDisplay)<{ size?: IMAGE_SIZE }>`
  text-align: center;
  width: ${({ size }) => (size === 'small' ? '25px' : '50px')};
  @media (max-width: ${BREAKPOINTS.medium}) {
    width: ${({ size }) => (size === 'small' ? '20px' : '40px')};
  }
`

const PlaceholderImage = styled.div<{ loaded?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.5vw;
  transition: 250ms ease-in;
`

const ImageLoader = styled.img`
  display: none;
`

const Image = styled.img<{ size: IMAGE_SIZE }>`
  border-radius: 5px;
  width: ${({ size }) => (size === 'small' ? '25px' : '50px')};
  max-width: ${({ size }) => (size === 'small' ? '25px' : '50px')};
  @media (max-width: ${BREAKPOINTS.medium}) {
    max-width: ${({ size }) => (size === 'small' ? '20px' : '40px')};
  }
`

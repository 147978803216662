import { useMemo } from 'react'

import { AggregateFilledOrder, FilledBidOrder, OrderStatus, Rune } from '@packages/interfaces'

import { OrderStatusQueryParams, useOrdersByStatusApi } from './useOrdersByStatusApi'

const QUERY_PARAMS: OrderStatusQueryParams = {
  status: OrderStatus.Filled,
  sortOrder: 'desc',
  sortOrderBy: 'timestamp',
  limit: 40,
  provider: 'Mystic',
}

export function useOrderHistoryForTable({
  rune,
  type,
  otherQueryParams,
}: {
  rune: Rune
  type: 'sell' | 'bid'
  otherQueryParams?: { [key: string]: any }
}) {
  const showBidOrders = type === 'bid'

  const queryParams = useMemo(() => ({ ...QUERY_PARAMS, ...otherQueryParams }), [otherQueryParams])

  const {
    paginatedData: filledSellOrders,
    fetchPage: fetchSellOrdersPage,
    newPageLoading: newSellOrdersPageLoading,
    hasNextPage: hasNextSellOrdersPage,
  } = useOrdersByStatusApi<AggregateFilledOrder>({
    runeName: rune.runeName,
    queryParams,
    type: 'sell',
  })

  const {
    paginatedData: filledBidOrders,
    fetchPage: fetchBidOrdersPage,
    newPageLoading: newBidOrdersPageLoading,
    hasNextPage: hasNextBidOrdersPage,
  } = useOrdersByStatusApi<FilledBidOrder>({
    runeName: rune.runeName,
    queryParams,
    type: 'bid',
  })

  const filledOrders = showBidOrders ? filledBidOrders : filledSellOrders
  const fetchPage = showBidOrders ? fetchBidOrdersPage : fetchSellOrdersPage
  const newPageLoading = showBidOrders ? newBidOrdersPageLoading : newSellOrdersPageLoading
  const hasNextPage = showBidOrders ? hasNextBidOrdersPage : hasNextSellOrdersPage

  return useMemo(() => {
    return {
      filledOrders,
      fetchPage,
      newPageLoading,
      hasNextPage,
    }
  }, [filledOrders, fetchPage, newPageLoading, hasNextPage])
}

import { styled } from 'styled-components'

import { DrawerCard, DrawerRow, TextInput } from 'src/shared/components'
import { COLORS } from 'src/shared/constants'

import { Header } from './styles'

type MintRunesDrawerRecipientAddressProps = {
  className?: string
  isDisabled?: boolean
  onRecipientAddressChange: (address: string) => void
  recipientAddress: string
  mode?: 'read' | 'write'
}

export function MintRunesDrawerRecipientAddress({
  className,
  isDisabled,
  recipientAddress,
  onRecipientAddressChange,
  mode = 'write',
}: MintRunesDrawerRecipientAddressProps) {
  return (
    <DrawerCard className={className}>
      <DrawerRow>
        <Header>Recipient Address</Header>
      </DrawerRow>
      <DrawerRow>
        {mode === 'write' && (
          <RecipientAddressTextInput
            value={recipientAddress}
            onChange={onRecipientAddressChange}
            error={!recipientAddress}
            helperText={!recipientAddress ? 'Required' : undefined}
            disabled={isDisabled}
          />
        )}
        {mode === 'read' && <span>{recipientAddress}</span>}
      </DrawerRow>
    </DrawerCard>
  )
}

const RecipientAddressTextInput = styled(TextInput)<{ disabled?: boolean }>`
  .MuiOutlinedInput-root {
    border: 1px solid ${COLORS.border};
    border-radius: 17px;

    &:hover {
      border-radius: 17px;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${COLORS.border};
    border-radius: 17px;
  }

  .Mui-disabled {
    -webkit-text-fill-color: ${(props) => (props.disabled ? COLORS.labels.disabled : undefined)};
    background-color: ${(props) => (props.disabled ? COLORS.buttons.disabled : undefined)};
  }

  .MuiInputBase-root.MuiOutlinedInput-root {
    &:hover {
      border-color: ${(props) => (props.disabled ? COLORS.buttons.disabled : undefined)};
    }
    border-color: ${(props) => (props.disabled ? COLORS.buttons.disabled : undefined)};

    .MuiOutlinedInput-notchedOutline {
      border-color: ${(props) => (props.disabled ? COLORS.buttons.disabled : undefined)};
    }

    .MuiOutlinedInput-notchedOutline:hover {
      border-color: ${(props) => (props.disabled ? COLORS.buttons.disabled : undefined)};
    }
  }
`

import type { RuneDetails } from '@packages/interfaces'
import { API_ENDPOINTS } from '@packages/constants'

import { useApi } from 'src/api'
import { replaceUrlParams } from 'src/shared/utils'

export function useRuneDetails(runeName: string, skip: boolean) {
  const result = useApi<RuneDetails>({
    endpoint: replaceUrlParams(API_ENDPOINTS.GET.runes.details, {
      runeName: runeName?.toUpperCase() ?? '',
    }),
    disable: skip,
  })

  return result
}

import { type ReactNode, useMemo } from 'react'
import { styled } from 'styled-components'

import type { RuneDetails } from '@packages/interfaces'
import { bigIntToInteger, formatNumber } from '@packages/utils'

import { useIsMobile } from 'src/shared/hooks'
import type { MintDetails } from 'src/minting/interfaces'
import { Section } from 'src/shared/components'
import { hexToRgb, unixTimestampToDateString } from 'src/shared/utils'
import { COLORS } from 'src/shared/constants'
import { TransactionExternalLink } from 'src/web3'

import { formatRunesAmount } from '../utils'

type RuneOverviewSectionProps = {
  runeDetails: RuneDetails | undefined
  mintDetails: MintDetails | undefined
}

const BIG_INT_ZERO = 0n
const MAX_ROWS_DESKTOP = 3
const MAX_COLUMNS_MOBILE = 2

export function RuneOverviewSection({ runeDetails, mintDetails }: RuneOverviewSectionProps) {
  const isMobile = useIsMobile()

  const overviewItems = useMemo(() => {
    if (!runeDetails || !mintDetails) return []

    const allItems: ReactNode[] = [
      <DetailDataItem key={'ID'}>
        <DetailHeader>ID</DetailHeader>
        {runeDetails.runeId.toString()}
      </DetailDataItem>,

      <DetailDataItem key={'Decimals'}>
        <DetailHeader>Decimals</DetailHeader>
        {runeDetails.runeDecimals.toString()}
      </DetailDataItem>,

      <DetailDataItem key={'Turbo'}>
        <DetailHeader>Turbo</DetailHeader>
        {runeDetails.isTurbo ? 'True' : 'False'}
      </DetailDataItem>,

      <DetailDataItem key={'Etched At'}>
        <DetailHeader>Etched At</DetailHeader>
        <TransactionExternalLink transactionId={runeDetails.etchedTransactionId}>
          {unixTimestampToDateString(runeDetails.etchedAtTimestamp, true)}
        </TransactionExternalLink>
      </DetailDataItem>,

      <DetailDataItem key={'Holders'}>
        <DetailHeader>Holders</DetailHeader>
        {runeDetails.holderCount?.toLocaleString()}
      </DetailDataItem>,

      <DetailDataItem key={'Current Supply'}>
        <DetailHeader>Current Supply</DetailHeader>
        {formatRunesAmount({
          rune: runeDetails,
          runesAmount: runeDetails.currentSupply,
          displayShortFormat: isMobile,
        })}
      </DetailDataItem>,

      <DetailDataItem key={'Maximum Supply'}>
        <DetailHeader>Maximum Supply</DetailHeader>
        {formatRunesAmount({
          rune: runeDetails,
          runesAmount: runeDetails.maximumSupply,
          displayShortFormat: isMobile,
        })}
      </DetailDataItem>,

      <DetailDataItem key={'Premined Amount'}>
        <DetailHeader>Premined Amount</DetailHeader>
        {formatRunesAmount({
          rune: runeDetails,
          runesAmount: runeDetails.amountPremined,
          displayShortFormat: isMobile,
        })}
      </DetailDataItem>,

      runeDetails.amountBurned > 0n && (
        <DetailDataItem key={'Burned Amount'}>
          <DetailHeader>Burned Amount</DetailHeader>
          {formatRunesAmount({
            rune: runeDetails,
            runesAmount: runeDetails.amountBurned,
            displayShortFormat: isMobile,
          })}
        </DetailDataItem>
      ),
      runeDetails.runeSymbolChar && (
        <DetailDataItem key={'Symbol'}>
          <DetailHeader>Symbol</DetailHeader>
          {runeDetails.runeSymbolChar}
        </DetailDataItem>
      ),
      ...(runeDetails.isMintable
        ? [
            <DetailDataItem key={'Minted Amount'}>
              <DetailHeader>Minted Amount</DetailHeader>
              {formatRunesAmount({
                rune: runeDetails,
                runesAmount:
                  runeDetails.numberOfMints * (runeDetails.runesAmountPerMint ?? BIG_INT_ZERO),
                displayShortFormat: isMobile,
              })}
            </DetailDataItem>,

            <DetailDataItem key={'Runes Per Mint'}>
              <DetailHeader>Runes Per Mint</DetailHeader>
              {formatRunesAmount({
                rune: runeDetails,
                runesAmount: runeDetails.runesAmountPerMint ?? BIG_INT_ZERO,
              })}
            </DetailDataItem>,

            runeDetails.maxMints ? (
              <DetailDataItem key={'Max Mints'}>
                <DetailHeader>Max Mints</DetailHeader>
                {formatNumber({
                  numStr: `${bigIntToInteger(runeDetails.maxMints)}`,
                  useCompactNotation: isMobile,
                })}
              </DetailDataItem>
            ) : null,

            runeDetails.mintOpenBlockHeight ? (
              <DetailDataItem key={'Mint Starts At Block'}>
                <DetailHeader>Mint Starts At Block</DetailHeader>
                {runeDetails.mintOpenBlockHeight.toString()}
              </DetailDataItem>
            ) : null,

            runeDetails.mintCloseBlockHeight ? (
              <DetailDataItem key={'Mint State'}>
                <DetailHeader>
                  {mintDetails.mintState === 'Ended' ? 'Mint Ended At Block' : 'Mint Ends At Block'}
                </DetailHeader>
                {runeDetails.mintCloseBlockHeight.toString()}
              </DetailDataItem>
            ) : null,
          ]
        : [
            <DetailDataItem key={'Mintable'}>
              <DetailHeader>Mintable</DetailHeader>
              False
            </DetailDataItem>,
          ]),
    ].filter(Boolean)

    const itemsConfig = isMobile
      ? { maxColumns: MAX_COLUMNS_MOBILE }
      : { maxRows: MAX_ROWS_DESKTOP }
    const columns = getItemsByColumns(allItems, itemsConfig)

    return columns
  }, [isMobile, mintDetails, runeDetails])

  return (
    <OverviewSection title='OVERVIEW'>
      {runeDetails && mintDetails && <Details>{overviewItems}</Details>}
    </OverviewSection>
  )
}

function getItemsByColumns(
  items: ReactNode[],
  {
    maxRows: _maxRows,
    maxColumns,
  }:
    | {
        maxRows: number
        maxColumns?: never
      }
    | { maxColumns: number; maxRows?: never }
) {
  const maxRows = _maxRows ?? Math.ceil(items.length / maxColumns)
  const totalColumns = maxColumns ? maxColumns : Math.ceil(items.length / maxRows)

  const columns = Array.from({ length: totalColumns }).map((_, colIdx) => {
    const colItems = []

    for (let itemIdx = colIdx * maxRows; itemIdx < colIdx * maxRows + maxRows; itemIdx++) {
      colItems.push(items[itemIdx])
    }

    return <Column key={colIdx}>{colItems}</Column>
  })

  return columns
}

const OverviewSection = styled(Section)`
  display: flex;
  flex: 2;
`

const Details = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  padding: 20px 0px;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 15px;
  gap: 20px;
  flex: 1;
`

const DetailDataItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`

const DetailHeader = styled.h3`
  font-weight: 300;
  font-size: 15px;
  color: ${hexToRgb(COLORS.white, 0.7)};
`

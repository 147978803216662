import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded'
import { styled } from 'styled-components'

import { formatRuneName } from '@packages/utils'
import type { Rune } from '@packages/interfaces'

import { RuneSymbolDisplay } from 'src/runes'
import { BREAKPOINTS, COLORS, SELECT_BUTTON_HEIGHT } from 'src/shared/constants'
import { hexToRgb } from 'src/shared/utils'
import { Button } from 'src/shared/components'

type RuneSelectButtonProps = {
  selectedRune: Rune | undefined
  selectedRuneName: string | undefined
  isLoadingNewRune: boolean
  onClick: () => void
}

export function RuneSelectButton({
  selectedRune,
  selectedRuneName,
  onClick,
  isLoadingNewRune,
}: RuneSelectButtonProps) {
  const runeName = selectedRune && !isLoadingNewRune ? formatRuneName(selectedRune) : ''

  return (
    <RuneButton onClick={onClick} runeName={runeName}>
      {selectedRuneName ? (
        <>
          <RuneSymbolDisplay runeSymbol={selectedRune?.runeSymbolChar} color={COLORS.black} />{' '}
          {selectedRune ? formatRuneName(selectedRune) : selectedRuneName}
        </>
      ) : (
        'Select a Rune'
      )}
      <ArrowDropDownRoundedIcon />
    </RuneButton>
  )
}

const RuneButton = styled(Button)<{ runeName: string }>`
  height: ${SELECT_BUTTON_HEIGHT}px;
  color: ${COLORS.black};
  font-weight: 700;
  padding: 5px 15px;
  padding-right: 6px;
  padding-left: 12px;
  border-radius: 5px;
  background-color: ${COLORS.buttons.primary};
  white-space: nowrap;

  @media (max-width: ${BREAKPOINTS.medium}) {
    padding-left: 15px;
    padding-right: 5px;
  }
  &:hover {
    background-color: ${hexToRgb(COLORS.white, 0.5)};
  }
  @media (max-width: ${BREAKPOINTS.medium}) {
    font-size: ${({ runeName }) =>
      runeName.length > 20 ? '10' : runeName.length > 15 ? '12' : '13'}px;
  }
`

import { 
  ReactNode,
  createContext,
  useContext,
  useState,
} from 'react'

import { ConfirmEscrowTransferDrawer } from './components/ConfirmEscrowTransferDrawer'

export type Action = 'deposit' | 'withdraw'

interface EscrowTransferContextType {
  action: Action
  setAction: (action: Action) => void

  quantity: bigint
  setQuantity: (quantity: bigint) => void

  fee: number
  setFee: (fee: number) => void

  openConfirmDrawer: () => void
  isConfirmDrawerOpen: boolean
}

const EscrowTransferContext = createContext<EscrowTransferContextType | undefined>(undefined)

export const useEscrowTransferContext = () => {
  const context = useContext(EscrowTransferContext)
  if (context === undefined) {
    throw new Error('useEscrowTransferContext must be used within a EscrowTransferProvider')
  }
  return context
}

export const EscrowTransferProvider = ({ children }: { children: ReactNode }) => {
  const [quantity, setQuantity] = useState(0n)
  const [action, setAction] = useState<Action>('deposit')
  const [fee, setFee] = useState(0)

  const [isConfirmDrawerOpen, setIsConfirmDrawerOpen] = useState(false)

  const openConfirmDrawer = () => {
    setIsConfirmDrawerOpen(true)
  }

  const closeConfirmDrawer = () => {
    setIsConfirmDrawerOpen(false)
  }

  const handleConfirm = () => {
    setAction('deposit')
    setQuantity(0n)
  }

  return (
    <EscrowTransferContext.Provider
      value={{
        quantity,
        setQuantity,

        action,
        setAction: (action) => {
          setQuantity(0n)
          setAction(action)
        },

        fee,
        setFee,

        openConfirmDrawer,
        isConfirmDrawerOpen,
      }}
    >
      {children}
      {!!quantity && (
        <ConfirmEscrowTransferDrawer
          isOpen={isConfirmDrawerOpen}
          onClose={closeConfirmDrawer}
          onConfirm={handleConfirm}
          quantity={quantity}
          action={action}
        />
      )}
    </EscrowTransferContext.Provider>
  )
}

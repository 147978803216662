import { styled } from 'styled-components'
import { useEffect, useState } from 'react'
import Decimal from 'decimal.js-light'
import { useAutoAnimate } from '@formkit/auto-animate/react'
import { toast } from 'react-toastify'

import { Order, BTC_PRICE_SYMBOL, Rune } from '@packages/interfaces'
import {
  formatBtc,
  formatUsd,
  satsToBtc,
  formatRuneName,
  formatNumber,
  formatUsdPrice,
  ManualError,
} from '@packages/utils'

import {
  AccordianHeader,
  Accordion,
  AccordionContent,
  Divider,
  Drawer,
  DrawerBoldColumn,
  DrawerButtons,
  DrawerCancelButton,
  DrawerCard,
  DrawerColumn,
  DrawerConfirmButton,
  DrawerRow,
  DrawerTieredContent,
  DrawerTitle,
  ErrorMessage,
  TieredTableCell,
  VirtualizedTable,
  WarningMessage,
} from 'src/shared/components'
import { COLORS } from 'src/shared/constants'
import { NetworkFeeSelector, SuccessfulTransactionToast } from 'src/web3'
import { useWalletContext } from 'src/wallet'
import { RuneSymbolDisplay, RunesAmountDisplay, formatRunesAmount } from 'src/runes'
import { useBtcBalance } from 'src/wallet/hooks'
import { DISABLE_WRITES, TRX_VIRTUAL_SIZE_BYTES } from 'src/settings'
import { useDebounce, useIsMobile } from 'src/shared/hooks'
import { hexToRgb } from 'src/shared/utils'
import { useGoogleAnalyticsContext } from 'src/analytics'
import { MysticPointsDrawerCard, useGetRewardSize, useMysticPointsContext } from 'src/rewards'

import { calculateWeightedAveragePrice, formatPriceSymbol } from '../utils'
import { PriceSatsDisplay } from './PriceSatsDisplay'
import { PriceUsdDisplay } from './PriceUsdDisplay'
import { RunesTotalColumn } from './styles'

interface Props {
  isOpen: boolean
  onClose: ({
    filledOrders,
    validOrders,
  }: {
    filledOrders?: Order[]
    validOrders?: Order[]
  }) => void
  rune: Rune
  orders: Order[]
}

const RECALCULATE_TOTALS_TIME = 250

export function BuyRunesDrawer({ isOpen, onClose, orders, rune }: Props) {
  const isMobile = useIsMobile()
  const [animateRefParent] = useAutoAnimate()

  const [selectedNetworkFee, setSelectedNetworkFee] = useState(0)
  const [loadingText, setLoadingText] = useState<string | undefined>(undefined)
  const [error, setError] = useState<string>()
  const [validOrders, setValidOrders] = useState<Array<Order>>(orders)
  const [isOrderDetailsExpanded, setIsOrderDetailsExpanded] = useState(false)
  const [runesAmountTotal, setRunesAmountTotal] = useState<bigint>(0n)
  const [isTotalsLoading, setIsTotalsLoading] = useState(false)
  const [ordersTotals, setOrdersTotals] = useState<{
    ordersSatsTotal: bigint
    ordersBtcTotal: number
    ordersUsdTotal: number
    btcTotal: number
    usdTotal: number
    marketplaceFeeAmountBtcTotal: number
    marketplaceFeeAmountUsdTotal: number
    networkFeeBtcTotal: number
    networkFeeUsdTotal: number
  }>({
    ordersSatsTotal: 0n,
    ordersBtcTotal: 0,
    ordersUsdTotal: 0,
    btcTotal: 0,
    usdTotal: 0,
    marketplaceFeeAmountBtcTotal: 0,
    marketplaceFeeAmountUsdTotal: 0,
    networkFeeBtcTotal: 0,
    networkFeeUsdTotal: 0,
  })

  const { settings, btcPrice, requestBuyRunes, submitBuyRunes } = useWalletContext()
  const { btcBalance } = useBtcBalance()
  const { initiateEvent, confirmEvent, executeEvent, abandonEvent } = useGoogleAnalyticsContext()

  const { refetchMysticPointsBalance } = useMysticPointsContext()

  const marketplaceFee = orders.reduce((prev, curr) => {
    let serviceFee = 0n
    switch (curr.provider) {
      case 'MagicEden':
        serviceFee = BigInt(settings.serviceFees.feeBps.orderMagicEden)
        break
      case 'Okx':
        serviceFee = BigInt(settings.serviceFees.feeBps.orderOkx)
        break
      case 'Unisat':
        // Unisat requires a minimum amount for the service fee to be applied
        return curr.satsAmount >= settings.serviceFees.orderMinimumForFee.satAmountUnisat
          ? prev + (BigInt(curr.satsAmount) * serviceFee) / BigInt(10000)
          : prev
      case 'Mystic':
        serviceFee = BigInt(settings.serviceFees.feeBps.order)
        break
    }
    return prev + (BigInt(curr.satsAmount) * serviceFee) / BigInt(10000)
  }, 0n)

  // Mystic points calculated based on total service fee, including aggregate orders
  const { data: mysticPoints } = useGetRewardSize({
    runeId: rune?.runeId,
    eventType: 'buy',
    fee: marketplaceFee,
    disabled: !isOpen,
    recalculateRewardSizeTime: RECALCULATE_TOTALS_TIME,
  })

  useEffect(() => {
    const total = orders.reduce((acc, curr) => {
      return acc + curr.runesAmount
    }, 0n)
    setValidOrders(orders)
    setRunesAmountTotal(total)
  }, [orders])

  // Estimates the vsize needed to complete all selected orders.
  const estimateNetworkFeeSizePerMarketplace = (): { [marketplace: string]: number } => {
    const networkFees: { [marketplace: string]: number } = {}

    const marketplaces = [...new Set(orders.map((order) => order.provider))]

    // Each aggregate market uses its own transaction, which requires separate calculations.
    marketplaces.forEach((marketplace) => {
      const marketplaceOrders = orders.filter((order) => order.provider === marketplace)
      const numOrders = marketplaceOrders.length
      let networkFee
      // Unisat does not support completing multiple orders in a single transaction.
      if (marketplace == 'Unisat') {
        networkFee =
          numOrders *
          selectedNetworkFee *
          (TRX_VIRTUAL_SIZE_BYTES.buy.each * 1 +
            TRX_VIRTUAL_SIZE_BYTES.buy.initial +
            TRX_VIRTUAL_SIZE_BYTES.sell)
      } else {
        networkFee =
          selectedNetworkFee *
          (TRX_VIRTUAL_SIZE_BYTES.buy.each * numOrders +
            TRX_VIRTUAL_SIZE_BYTES.buy.initial +
            TRX_VIRTUAL_SIZE_BYTES.sell)
      }

      networkFees[marketplace] = networkFee
    })

    // console.log(networkFees)

    return networkFees
  }

  useDebounce(
    async () => {
      if (orders.length === 0 || !btcPrice || !rune || !selectedNetworkFee) {
        return
      }
      setIsTotalsLoading(true)
      try {
        const btcPriceUsd = btcPrice?.btcPriceUsd ?? 0
        const satPriceUsd = btcPrice?.satPriceUsd ?? 0
        const totals = orders
          .map((order) => {
            return {
              satsTotal: order.satsAmount,
              runesAmountTotal: order.runesAmount,
              provider: order.provider,
            }
          })
          .reduce(
            (acc, curr) => {
              return {
                satsTotal: acc.satsTotal + curr.satsTotal,
                satsMysticTotal:
                  curr.provider === 'Mystic'
                    ? acc.satsMysticTotal + curr.satsTotal
                    : acc.satsMysticTotal,
                runesAmountTotal: acc.runesAmountTotal + curr.runesAmountTotal,
              }
            },
            {
              satsTotal: 0n,
              satsMysticTotal: 0n,
              runesAmountTotal: 0n,
            }
          )
        const networkFees = estimateNetworkFeeSizePerMarketplace()
        const networkFeeSatsTotal = Object.values(networkFees).reduce(
          (total, fee) => total + fee,
          0
        )

        const ordersBtcTotal = satsToBtc(totals.satsTotal)
        const btcTotal = satsToBtc(
          totals.satsTotal + BigInt(networkFeeSatsTotal) + BigInt(marketplaceFee)
        )

        setOrdersTotals({
          ordersSatsTotal: totals.satsTotal,
          ordersBtcTotal,
          ordersUsdTotal: new Decimal(ordersBtcTotal).mul(btcPriceUsd).toNumber(),
          btcTotal,
          usdTotal: new Decimal(btcTotal).mul(btcPriceUsd).toNumber(),
          marketplaceFeeAmountBtcTotal: satsToBtc(marketplaceFee),
          marketplaceFeeAmountUsdTotal: new Decimal(`${marketplaceFee}`)
            .mul(satPriceUsd)
            .toNumber(),
          networkFeeBtcTotal: satsToBtc(networkFeeSatsTotal),
          networkFeeUsdTotal: new Decimal(networkFeeSatsTotal).mul(satPriceUsd).toNumber(),
        })
      } catch (error: any) {
        console.error(error)
      } finally {
        setIsTotalsLoading(false)
      }
    },
    [selectedNetworkFee, btcPrice, orders],
    RECALCULATE_TOTALS_TIME
  )

  useEffect(() => {
    if (isOpen) {
      initiateEvent('swap', {
        token_name: rune.runeName,
        token_type: 'rune',
        order_amount: runesAmountTotal,
        order_action: 'buy',
        price: ordersTotals.ordersSatsTotal,
      })
    }
  }, [isOpen])

  const filteredOrders = orders.filter((order) =>
    validOrders.some((validOrder) => validOrder.id === order.id)
  )

  function resetForm() {
    setError(undefined)
    setSelectedNetworkFee(0)
  }

  async function onConfirmClick() {
    try {
      setLoadingText('Generating tx')
      setError(undefined)
      confirmEvent('swap', {
        token_name: rune.runeName,
        token_type: 'rune',
        order_amount: runesAmountTotal,
        order_action: 'buy',
        fee: selectedNetworkFee,
        price: ordersTotals.ordersSatsTotal,
      })

      const orderToBuy = [...filteredOrders]

      if (orderToBuy.length === 0) {
        abandonEvent(true, 'swap', {
          token_name: rune.runeName,
          token_type: 'rune',
          order_amount: runesAmountTotal,
          order_action: 'buy',
          fee: selectedNetworkFee,
          price: ordersTotals.ordersSatsTotal,
        })
      }

      if (orderToBuy.length > 0) {
        const requestBuyResults = await requestBuyRunes({
          rune,
          fee: selectedNetworkFee,
          orders,
        })

        const requestBuyValidOrders: Order[] = []
        // Format resulting object into order list
        requestBuyResults.forEach((result) => requestBuyValidOrders.push(...result.validOrders))
        if (requestBuyValidOrders.length < orders.length) {
          setValidOrders((prevState) =>
            prevState.filter((order) => requestBuyValidOrders.some((x) => x.id === order.id))
          )
          if (requestBuyValidOrders.length > 0) {
            setError(
              'Some of your selected orders are no longer available. See updated orders above.'
            )
          } else {
            // Default error handler will show error in this case
            setError(undefined)
          }
          setLoadingText(undefined)
          return
        } else {
          const buyResult = await submitBuyRunes({
            rune,
            fee: BigInt(selectedNetworkFee),
            validOrders: requestBuyResults,
            onStatusChange: (status) => {
              switch (status) {
                case 'build':
                  setLoadingText('Building tx')
                  break
                case 'wallet-prompt':
                  setLoadingText('See wallet')
                  break
                case 'api-submit':
                  setLoadingText('Submitting order')
                  break
              }
            },
          })
          // If 'runeId' exists, then backend found out, that some orders are invalid
          if ('runeId' in buyResult) {
            setValidOrders((prevState) =>
              prevState.filter((order) => buyResult.validOrders.some((x) => x.id === order.id))
            )
            setError(
              'Some of your selected orders are no longer available. See updated orders above.'
            )
            abandonEvent(true, 'swap', {
              token_name: rune.runeName,
              token_type: 'rune',
              order_amount: runesAmountTotal,
              order_action: 'buy',
              fee: selectedNetworkFee,
              price: ordersTotals.ordersSatsTotal,
            })
          } else if (
            Array.isArray(buyResult) &&
            buyResult.length > 0 &&
            'success' in buyResult[0] &&
            buyResult[0].success
          ) {
            // Shows result for each provider used in buy that succeded
            buyResult.forEach(
              (result) =>
                result.success &&
                result.txId &&
                toast(
                  <SuccessfulTransactionToast
                    message='Buy Order Broadcasted'
                    transactionId={result.txId}
                  />,
                  {
                    toastId: Date.now(),
                  }
                )
            )
          } else {
            setError('Some of your selected orders did not execute')
            return
          }
        }
      }

      refetchMysticPointsBalance()
      executeEvent('swap', {
        token_name: rune.runeName,
        token_type: 'rune',
        order_amount: runesAmountTotal,
        order_action: 'buy',
        fee: selectedNetworkFee,
        price: ordersTotals.ordersSatsTotal,
      })

      onClose({ filledOrders: [...filteredOrders] })
      resetForm()
    } catch (error: any) {
      console.error(error)
      if (error instanceof ManualError) {
        setError((error as ManualError).message)
      } else {
        switch (error) {
          // placeholder
          case '':
            break
          default:
            setError('Something unexpected has gone wrong, please contact support on our Discord')
            break
        }
      }
    } finally {
      setLoadingText(undefined)
    }
  }

  function handleOnClose() {
    abandonEvent(true, 'swap', {
      token_name: rune.runeName,
      token_type: 'rune',
      order_amount: runesAmountTotal,
      order_action: 'buy',
      fee: selectedNetworkFee,
      price: ordersTotals.ordersSatsTotal,
    })
    setError(undefined)
    onClose({ validOrders })
  }

  const priceSats = calculateWeightedAveragePrice({ orders: filteredOrders })

  const buttonText = loadingText ?? 'Confirm Buy'
  const notEnoughBalance = btcBalance < ordersTotals.btcTotal
  const noValidOrders = validOrders.length === 0
  const someOrdersNotAvailable = validOrders.length < orders.length

  return (
    <Drawer isOpen={isOpen} onClose={handleOnClose} canClose={!loadingText}>
      <DrawerCard>
        <DrawerTitle>Buy {formatRuneName(rune)}</DrawerTitle>
        <DrawerRow>
          <DrawerBoldColumn>Average Price</DrawerBoldColumn>
          <DrawerColumn>
            <DrawerTieredContent
              content={
                <>
                  &nbsp;
                  <PriceSatsDisplay priceSats={priceSats} runeSymbol={rune.runeSymbolChar} />
                </>
              }
              subContent={<PriceUsdDisplay priceSats={priceSats} />}
              loading={isTotalsLoading}
            />
          </DrawerColumn>
        </DrawerRow>
        <DrawerRow>
          <DrawerBoldColumn>{formatRuneName(rune)}</DrawerBoldColumn>
          <RunesTotalColumn $colorVariant='positive'>
            +
            <RunesAmountDisplay
              rune={rune}
              runesAmount={runesAmountTotal}
              showPriceSymbol={false}
            />
          </RunesTotalColumn>
        </DrawerRow>

        <Divider />
        <DrawerRow>
          <DrawerBoldColumn>
            <AccordianHeader
              linkText='Total BTC'
              expanded={isOrderDetailsExpanded}
              onExpand={() => setIsOrderDetailsExpanded(!isOrderDetailsExpanded)}
            />
          </DrawerBoldColumn>
          <DrawerColumn>
            <DrawerTieredContent
              content={<BtcTotal>-{ordersTotals.btcTotal}</BtcTotal>}
              subContent={<UsdTotal>&nbsp;{formatUsd({ usd: ordersTotals.usdTotal })}</UsdTotal>}
              loading={isTotalsLoading}
            />
          </DrawerColumn>
        </DrawerRow>

        <div ref={animateRefParent}>
          <AccordionContentWrapper expanded={isOrderDetailsExpanded}>
            <DrawerRow>
              <DrawerBoldColumn>Order Cost</DrawerBoldColumn>
              <DrawerColumn>
                <DrawerTieredContent
                  content={
                    <BtcTotal>
                      -{`${ordersTotals.ordersBtcTotal}`}{' '}
                      <RuneSymbolDisplay runeSymbol={BTC_PRICE_SYMBOL} />
                    </BtcTotal>
                  }
                  subContent={
                    <UsdTotal>&nbsp;{formatUsd({ usd: ordersTotals.ordersUsdTotal })}</UsdTotal>
                  }
                  loading={isTotalsLoading}
                />
              </DrawerColumn>
            </DrawerRow>
            {ordersTotals && ordersTotals?.marketplaceFeeAmountBtcTotal > 0 && (
              <DrawerRow>
                <DrawerBoldColumn>Service Fee</DrawerBoldColumn>
                <DrawerColumn>
                  <DrawerTieredContent
                    content={
                      <BtcTotal>
                        -
                        {formatNumber({
                          numStr: `${ordersTotals?.marketplaceFeeAmountBtcTotal}`,
                          showAllDecimals: true,
                        })}{' '}
                        <RuneSymbolDisplay runeSymbol={BTC_PRICE_SYMBOL} />
                      </BtcTotal>
                    }
                    subContent={
                      <UsdTotal>
                        &nbsp;
                        {formatUsdPrice({ price: ordersTotals.marketplaceFeeAmountUsdTotal })}
                      </UsdTotal>
                    }
                    loading={isTotalsLoading}
                  />
                </DrawerColumn>
              </DrawerRow>
            )}
            <DrawerRow>
              <DrawerBoldColumn>Estimated Network Fee</DrawerBoldColumn>
              <DrawerColumn>
                <DrawerTieredContent
                  content={
                    <BtcTotal>
                      -{`${ordersTotals.networkFeeBtcTotal}`}{' '}
                      <RuneSymbolDisplay runeSymbol={BTC_PRICE_SYMBOL} />
                    </BtcTotal>
                  }
                  subContent={
                    <UsdTotal>
                      &nbsp;{formatUsdPrice({ price: ordersTotals.networkFeeUsdTotal })}
                    </UsdTotal>
                  }
                  loading={isTotalsLoading}
                />
              </DrawerColumn>
            </DrawerRow>
          </AccordionContentWrapper>
        </div>
      </DrawerCard>
      {/* <DrawerCard>
          <DrawerRow>
            <DrawerBoldColumn>Available BTC</DrawerBoldColumn>
            <DrawerColumn>
              <DrawerColumn>
                {btcBalance} <RuneSymbolDisplay runeSymbol={BTC_PRICE_SYMBOL} />
              </DrawerColumn>
              <UsdTotal>{formatUsd(btcBalanceUsd)}</UsdTotal>
            </DrawerColumn>
          </DrawerRow>
        </DrawerCard> */}
      {!noValidOrders && (
        <DrawerCard>
          <Accordion linkText='View Orders'>
            <VirtualizedTableWrapper
              columns={[
                {
                  dataKey: 'runesAmount',
                  label: isMobile ? 'Qty' : 'Quantity',
                  formatter: ({ data: order }) =>
                    formatRunesAmount({
                      rune,
                      runesAmount: order.runesAmount,
                    }),
                },
                {
                  dataKey: 'priceSats',
                  label: isMobile
                    ? 'Price'
                    : `Price (${formatPriceSymbol({ runeSymbol: rune.runeSymbolChar })})`,
                  formatter: ({ data: order }) => (
                    <TieredTableCell header={order.priceSats} subHeader={order.priceUsd} />
                  ),
                },
                {
                  dataKey: 'satsAmount',
                  label: `Total (${BTC_PRICE_SYMBOL})`,
                  formatter: ({ data: order }) => (
                    <TieredTableCell
                      header={`${formatBtc(order.satsAmount)}`}
                      subHeader={formatUsd({ usd: order.valueUsd })}
                    />
                  ),
                },
              ]}
              defaultSortBy='desc'
              defaultSort='satsAmount'
              paginatedData={[filteredOrders]}
              fetchPage={async () => {}}
              hasNextPage={false}
              viewableRows={filteredOrders.length}
              rowHeight={30}
            />
          </Accordion>
          {someOrdersNotAvailable && (
            <WarningMessage message={'Some of your selected orders are no longer available'} />
          )}
        </DrawerCard>
      )}

      <DrawerCard>
        <DrawerRow>
          <NetworkFeeSelector
            selectedFee={selectedNetworkFee}
            onChange={(fee) => setSelectedNetworkFee(fee)}
          />
        </DrawerRow>
      </DrawerCard>

      {mysticPoints > 0 && <MysticPointsDrawerCard points={mysticPoints} place='trade' />}

      {notEnoughBalance && (
        <ErrorMessage
          message={`Not enough BTC balance. Available: 
              ${formatNumber({ numStr: `${btcBalance}` })}
              ${BTC_PRICE_SYMBOL}`}
        />
      )}
      {noValidOrders && <ErrorMessage message={'None of your selected orders are available'} />}
      {error && <ErrorMessage message={error} />}
      <DrawerButtons>
        {loadingText ? (
          <DrawerCancelButton onClick={() => setLoadingText(undefined)}>
            Cancel Tx
          </DrawerCancelButton>
        ) : (
          <DrawerCancelButton onClick={handleOnClose}>Exit</DrawerCancelButton>
        )}
        <DrawerConfirmButton
          disabled={notEnoughBalance || noValidOrders || isTotalsLoading || DISABLE_WRITES}
          loading={!!loadingText}
          onClick={onConfirmClick}
        >
          {buttonText}
        </DrawerConfirmButton>
      </DrawerButtons>
    </Drawer>
  )
}

const BtcTotal = styled(DrawerColumn)`
  display: flex;
  color: ${COLORS.negative};
  font-weight: 700;
`

const UsdTotal = styled.div`
  color: ${hexToRgb(COLORS.white, 0.7)};
  font-size: 14px;
`

const VirtualizedTableWrapper = styled(VirtualizedTable<Order>)`
  tfoot {
    display: none;
  }
  thead {
    position: relative !important;
  }
`

const AccordionContentWrapper = styled(AccordionContent)`
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  gap: 5px;
`

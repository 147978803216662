import { useCallback, useEffect, useMemo } from 'react'
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'

import { formatRuneName } from '@packages/utils'

import { useOrderContext } from 'src/orders'
import {
  buildAccountDetailsUrl,
  buildPageTabSearchParam,
  buildRuneToolsTabSearchParam,
  ROUTES,
} from 'src/pages/constants'
import { concatSearchParams } from 'src/shared/utils'
import { useWalletContext } from 'src/wallet'

import { DEFAULT_TAB, RUNE_TOOLS_TABS } from './constants'
import { isOnMarketRoutes, isOnMintRoutes, isOnTransferRouters } from './utils'
import { useRuneToolsState } from './useRuneToolsState'

import type { RuneToolsTab } from './types'

export function useRuneToolsTabs() {
  const { runesAddress } = useWalletContext()

  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation()
  const { runeName: runeNameParam } = useParams<{ runeName: string | undefined }>()
  const { selectedRune } = useOrderContext()
  const runeNameFallback: string | undefined = selectedRune
    ? formatRuneName(selectedRune)
    : undefined
  const navigate = useNavigate()

  const { currentRuneTool: currentTab, fallbackRuneTool } = useRuneToolsState()

  const selectedTabObject = useMemo(() => {
    return RUNE_TOOLS_TABS[currentTab] ?? RUNE_TOOLS_TABS[DEFAULT_TAB]
  }, [currentTab])

  const handleChangeTab = useCallback(
    (tab: RuneToolsTab) => {
      setSearchParams((prev) => {
        const newSearchParams = concatSearchParams(prev, buildRuneToolsTabSearchParam(tab))

        return newSearchParams
      })
    },
    [setSearchParams]
  )

  useEffect(
    function redirectOnTabOrLocationChange() {
      if (currentTab === 'swap') {
        if (isOnMarketRoutes(location.pathname)) {
          return
        }

        const pathname =
          runeNameParam || runeNameFallback
            ? generatePath(ROUTES.marketDetails, { runeName: runeNameParam || runeNameFallback })
            : ROUTES.markets
        navigate({
          pathname: pathname,
          search: concatSearchParams(searchParams, buildPageTabSearchParam('Orderbook')).toString(),
        })
      }

      if (currentTab === 'mint') {
        if (isOnMintRoutes(location.pathname)) {
          return
        }

        const pathname =
          runeNameParam || runeNameFallback
            ? generatePath(ROUTES.marketDetails, { runeName: runeNameParam || runeNameFallback })
            : ROUTES.mint
        navigate({
          pathname: pathname,
          search: concatSearchParams(searchParams, buildPageTabSearchParam('Overview')).toString(),
        })
      }

      if (currentTab === 'transfer') {
        if (isOnTransferRouters(location.pathname)) {
          return
        }

        if (runesAddress) {
          navigate({
            pathname: buildAccountDetailsUrl(runesAddress.addrString),
            search: buildRuneToolsTabSearchParam('transfer').toString(),
          })
        }
      }
    },
    [currentTab, location.pathname, runesAddress, searchParams]
  )

  return {
    selectedTab: currentTab ?? fallbackRuneTool,
    selectedTabObject,
    changeTab: handleChangeTab,
  }
}

import { useWalletContext } from 'src/wallet'
import { useMysticPointsContext } from 'src/rewards'

import { MysticPointsLink } from './MysticPointsLink'

export function MysticPointsBalanceLink() {
  const { isConnected } = useWalletContext()
  const { mysticPoints } = useMysticPointsContext()

  if (!isConnected) {
    return null
  }

  return <MysticPointsLink mysticPoints={mysticPoints} />
}

import { styled } from 'styled-components'

import { RuneDetails } from '@packages/interfaces'

import { MintDetails } from 'src/minting'
import { MintProgressBar } from 'src/minting/components'
import { RuneNameHeader } from 'src/runes/components'

type Props = {
  runeDetails: RuneDetails | undefined
  mintDetails: MintDetails | undefined
}

export function MarketRuneOverview({ runeDetails, mintDetails }: Props) {
  return (
    <Container>
      <RuneNameHeader runeDetails={runeDetails} />
      {mintDetails && runeDetails && (
        <MintProgressBar mintDetails={mintDetails} runeDetails={runeDetails} />
      )}
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
`

import { styled } from 'styled-components'

import { MysticPointsPageLink } from './MysticPointsPageLink'
import { MysticPointsLogo } from './MysticPointsLogo'

type MysticPointsLinkProps = {
  mysticPoints?: bigint
}

export function MysticPointsLink({ mysticPoints = 0n }: MysticPointsLinkProps) {
  return (
    <Container>
      <MysticPointsLogo />
      <MysticPointsNumber>{mysticPoints.toString()}</MysticPointsNumber>
      <MysticPointsPageLink />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 1em;
  font-weight: 700;
`

const MysticPointsNumber = styled.span``

import { styled } from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { useCallback, useEffect, useMemo, useState } from 'react'

import type { Rune, RuneDetails } from '@packages/interfaces'

import { DrawerButtons, DrawerConfirmButton, SubheaderText } from 'src/shared/components'
import { useWalletContext, WalletPickerMenu } from 'src/wallet'
import { buildMarketDetailsUrl, buildRuneToolsTabSearchParam, ROUTES } from 'src/pages'
import { useMintButtonState } from 'src/minting/hooks'
import type { MintDetails } from 'src/minting/interfaces'

import { MintRunesDrawerTitle } from './MintRunesShortFormTitle'
import { MintRunesDrawerRepeatMints } from './MintRunesDrawerRepeatMints'
import { MintRunesDrawerRecipientAddress } from './MintRunesDrawerRecipientAddress'

export type MintRunesShortFormState = {
  recipientAddress: string
  numberOfMints: number
  numberOfMintsError: string | undefined
}

type MintRunesFormShortProps = {
  runeDetails: RuneDetails | undefined
  mintDetails: MintDetails | undefined
  onConfirm: () => void
  onFormStateChange: (formState: MintRunesShortFormState) => void
}

export function MintRunesShortForm({
  runeDetails,
  mintDetails,
  onConfirm,
  onFormStateChange,
}: MintRunesFormShortProps) {
  const navigate = useNavigate()

  const [formState, setFormState] = useState<MintRunesShortFormState>({
    recipientAddress: '',
    numberOfMints: 1,
    numberOfMintsError: undefined,
  })

  const [isWalletModalOpen, setIsWalletModalOpen] = useState(false)
  const { isConnected, runesAddress } = useWalletContext()

  const { disabled, disabledReason, text } = useMintButtonState(mintDetails)

  const handleRuneSelectCompleted = useCallback(
    (rune: Rune | undefined) => {
      if (rune) {
        navigate({
          pathname: buildMarketDetailsUrl(rune.runeName),
          search: buildRuneToolsTabSearchParam('mint').toString(),
        })
      } else {
        navigate({
          pathname: ROUTES.mint,
          search: buildRuneToolsTabSearchParam('mint').toString(),
        })
      }
    },
    [navigate]
  )

  const handleRecipientAddressChange = useCallback((address: string) => {
    setFormState((prev) => {
      return {
        ...prev,
        recipientAddress: address,
      }
    })
  }, [])

  const handleNumberOfMintsChange = useCallback(
    (mints: number) => {
      let error: string | undefined = undefined
      if (runeDetails) {
        if (mints + Number(runeDetails.numberOfMints ?? 0n) > Number(runeDetails.maxMints ?? 0n)) {
          error = 'You are trying to mint more times than the remaining mints.'
        }
      }

      setFormState((prev) => {
        return {
          ...prev,
          numberOfMints: mints,
          numberOfMintsError: error,
        }
      })
    },
    [runeDetails]
  )

  const handleConfirmClick = useCallback(() => {
    if (!isConnected) {
      setIsWalletModalOpen(true)
      return
    }

    onConfirm()
  }, [isConnected, onConfirm])

  useEffect(
    function updateRecipientAddress() {
      if (runesAddress?.addrString) {
        setFormState((prev) => {
          return {
            ...prev,
            recipientAddress: runesAddress.addrString,
          }
        })
      }
    },
    // Only isConnected listed here to avoid resetting value once fees has been fetched
    [isConnected]
  )

  useEffect(() => {
    onFormStateChange(formState)
  }, [formState, onFormStateChange])

  const isConfirmDisabled = !isConnected || disabled

  const totalMints = useMemo(() => {
    return (runeDetails?.runesAmountPerMint ?? 0n) * BigInt(formState.numberOfMints)
  }, [runeDetails?.runesAmountPerMint, formState.numberOfMints])

  const formSubheader =
    disabledReason === 'mint-over' || disabledReason === 'minted-out'
      ? 'Use this tool to mint runes on Mystic. Looks like you were a bit late — the rune has already finished minting. Check out other popular runes that are available to mint now!'
      : 'Use this tool to mint runes on Mystic. We support bulk minting, so you can execute multiple mints at once and receive a single set of runes.'

  return (
    <Container>
      <SubheaderText>{formSubheader}</SubheaderText>
      <MintRunesDrawerTitle
        runeDetails={runeDetails}
        onRuneSelectCompleted={handleRuneSelectCompleted}
      />
      {!isConfirmDisabled && (
        <MintRunesDrawerRepeatMints
          runeDetails={runeDetails}
          numberOfMints={formState.numberOfMints}
          onNumberOfMintsChange={handleNumberOfMintsChange}
          numberOfMintsError={formState.numberOfMintsError}
          totalMints={totalMints}
          isDisabled={false}
        />
      )}

      {!isConfirmDisabled && (
        <MintRunesDrawerRecipientAddress
          recipientAddress={formState.recipientAddress}
          onRecipientAddressChange={handleRecipientAddressChange}
          isDisabled={false}
        />
      )}

      <DrawerButtons>
        {isConnected && (
          <DrawerConfirmButton disabled={isConfirmDisabled} onClick={handleConfirmClick}>
            {text}
          </DrawerConfirmButton>
        )}
        {!isConnected && (
          <DrawerConfirmButton
            onClick={() => {
              setIsWalletModalOpen(true)
            }}
          >
            Connect to mint
          </DrawerConfirmButton>
        )}
      </DrawerButtons>

      <WalletPickerMenu isOpen={isWalletModalOpen} onClose={() => setIsWalletModalOpen(false)} />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

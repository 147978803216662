import { styled } from 'styled-components'

import { SwapTabPanel } from '../SwapTabPanel'
import { MintTabPanel } from '../MintTabPanel'
import { RuneToolsTabs } from './RuneToolsTabs'
import { RuneToolsTabPanel } from './RuneToolsTabPanel'
import { TransferTabPanel } from './TransferTabPanel'
import { EscrowTransferTabPanel } from './EscrowTransferTabPanel'
import { useRuneToolsTabs } from './useRuneToolsTabs'

interface RuneToolsProps {
  isEscrowWallet?: boolean;
}

export function RuneTools({ isEscrowWallet = false }: RuneToolsProps) {
  const { selectedTab, selectedTabObject, changeTab } = useRuneToolsTabs()

  return (
    <Container>
      <RuneToolsTabs selectedTab={selectedTab} onTabChange={changeTab} />
      <TabPanelContainer>
        <RuneToolsTabPanel title={selectedTabObject.label}>
          <SwapTabPanel visible={selectedTab === 'swap'} />
          <MintTabPanel visible={selectedTab === 'mint'} />
          <TransferTabPanel visible={selectedTab === 'transfer' && !isEscrowWallet} />
          <EscrowTransferTabPanel visible={selectedTab === 'transfer' && isEscrowWallet} />
        </RuneToolsTabPanel>
      </TabPanelContainer>
    </Container>
  )
}

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 25px;
`

export const TabPanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

import Grid from '@mui/material/Grid'
import { styled } from 'styled-components'

import { PageContainer } from 'src/pages/pages/PageContainer'
import { MintSearch } from 'src/minting'
import { RuneTools } from 'src/runes'
import { useIsMobile } from 'src/shared/hooks'
import { BREAKPOINTS } from 'src/shared/constants'
import { MysticPointsPageContent } from 'src/rewards'

export function MysticPointsPage() {
  const isMobile = useIsMobile()

  return (
    <PageContainer>
      <MintSearchContainer>
        <MintSearch linkType='marketDetail' />
      </MintSearchContainer>
      {/* <Grid container spacing={2}>
        <Grid order={isMobile ? 1 : 0} item md={7} xs={12}> */}
      <MysticPointsPageContent />
      {/* </Grid>
        <Grid order={isMobile ? 0 : 1} item md={5} xs={12}>
          <RuneTools />
        </Grid>
      </Grid> */}
    </PageContainer>
  )
}

const MintSearchContainer = styled.div`
  padding-bottom: 20px;

  @media (max-width: ${BREAKPOINTS.medium}) {
    padding-bottom: 0;
  }
`

import { styled } from 'styled-components'
import { useCallback, useEffect, useState } from 'react'

import { BidOrder, EBidOrderStatus, Order, OrderStatus } from '@packages/interfaces'
import { formatUsd } from '@packages/utils'
import { BID_ORDERS_SORT_COLUMN, ORDERS_SORT_ORDER, SORT_ORDER } from '@packages/constants'

import { MarketDetailsLink } from 'src/markets'
import { RunesAmountDisplay } from 'src/runes'
import {
  Badge,
  Button,
  HelpTooltip,
  TieredTableCell,
  ToggleSwitch,
  VirtualizedTable,
} from 'src/shared/components'
import { BREAKPOINTS, COLORS, FONTS } from 'src/shared/constants'
import { useIsMobile } from 'src/shared/hooks'
import { unixTimestampToDateString } from 'src/shared/utils'
import { TransactionExternalLink } from 'src/web3'

import { useOpenOrdersByAddressForTable } from '../hooks'
import { useOrderContext } from '../OrderContext'
import { isBidOrder } from '../utils'
import { BtcAmountDisplay } from './BtcAmountDisplay'
import { TableHeaderContainerWrapper, TableSwitchesContainer } from './styles'
import { BidOrderStatusBadge } from './BidOrderStatusBadge'
import { useGetOrderState } from '../hooks/useGetOrderState'

interface Props {
  address: string
  runeId?: string
  ordersType?: 'bid' | 'sell'
  showOrdersTypeSwitch?: boolean
  status?: OrderStatus | 'Any'
}

export function OpenOrdersByAddress({
  address,
  runeId,
  ordersType: initialOrdersType,
  status,
  showOrdersTypeSwitch = true,
}: Props) {
  const {
    isStaleOrderDataTimestamp,
    setSelectedCancelOrder,
    openCancelDrawer,
    recentlyCancelledOrders,
  } = useOrderContext()
  const isMobile = useIsMobile()
  const [filteredOrders, setFilteredOrders] = useState<Order[][]>([])

  const { isBidOrderState, toggleOrderState } = useGetOrderState()
  const handleToggleBidOrders = useCallback(
    (isBidOrdersEnabled: boolean) => {
      if (initialOrdersType === undefined) {
        toggleOrderState(isBidOrdersEnabled ? 'bid' : 'sell')
      }
    },
    [initialOrdersType, toggleOrderState]
  )
  const showBidOrders =
    initialOrdersType === undefined ? isBidOrderState : initialOrdersType === 'bid'

  const [queryParams, setQueryParams] = useState({})

  const {
    orders: openOrders,
    fetchPage,
    hasNextPage,
    forceRefresh,
    loading,
  } = useOpenOrdersByAddressForTable({
    address,
    runeId,
    type: showBidOrders ? 'bid' : 'sell',
    status: status ?? OrderStatus.Placed,
    otherQueryParams: queryParams,
  })

  function onSort(orderBy: ORDERS_SORT_ORDER | BID_ORDERS_SORT_COLUMN, order: SORT_ORDER) {
    setQueryParams({ sortOrderBy: orderBy, sortOrder: order })
  }

  useEffect(() => {
    if (isStaleOrderDataTimestamp) {
      forceRefresh()
    }
  }, [isStaleOrderDataTimestamp])

  useEffect(() => {
    let orders = openOrders

    orders = orders.map((nestedOrders) => {
      return nestedOrders.filter((order) => {
        // Check if order was recently cancelled
        if (recentlyCancelledOrders.some((cancelledOrder) => cancelledOrder.id === order.id)) {
          return false
        }

        return true
      })
    })

    setFilteredOrders(orders)
  }, [openOrders, recentlyCancelledOrders])

  const hideRuneName = !!runeId // do not need to show runeName if on the market details page

  return (
    <Container>
      <VirtualizedTableWrapper
        columns={[
          // {
          //   dataKey: 'id',
          //   label: 'ID',
          //   width: 5,
          //   hideOnMobile: true,
          // },
          ...(hideRuneName
            ? []
            : [
                {
                  dataKey: 'runeName',
                  label: 'Name',
                  width: 25,
                  formatter: ({ data: order }: { data: Order | BidOrder }) => (
                    <MarketDetailsLink rune={order} />
                  ),
                },
              ]),
          {
            dataKey: 'priceSats',
            width: isMobile ? 30 : 20,
            label: 'Price (sats)',
            hideOnMobile: !hideRuneName,
            onSort: (sortOrder: SORT_ORDER) => onSort('priceSats', sortOrder),
            formatter: ({ data: order }) => (
              <TieredTableCell header={order.priceSats} subHeader={order.priceUsd} />
            ),
          },
          {
            dataKey: 'runesAmount',
            width: isMobile ? 30 : 20,
            label: isMobile ? 'Qty' : 'Quantity',
            formatter: ({ data: order }) => (
              <RunesAmountDisplay
                rune={order}
                runesAmount={order.runesAmount}
                shortFormat={isMobile}
              />
            ),
          },
          {
            dataKey: 'satsAmount',
            label: 'Total',
            width: isMobile ? 25 : 20,
            onSort: (sortOrder: SORT_ORDER) => onSort('total', sortOrder),
            formatter: ({ data: order }) => (
              <TieredTableCell
                header={<BtcAmountDisplay btcAmount={order.satsAmount} showPriceSymbol />}
                subHeader={formatUsd({ usd: order.valueUsd, shortFormat: isMobile })}
              />
            ),
          },
          ...(!showBidOrders
            ? [
                {
                  dataKey: 'placedAtTimestamp',
                  label: 'Placed',
                  width: 15,
                  hideOnMobile: true,
                  content: (
                    <ThContainer>
                      Placed{' '}
                      <HelpTooltip
                        content={
                          'If applicable, this column links to the tx used to split or combine your runes in preparation for your sell order.'
                        }
                        icon='info'
                      />
                    </ThContainer>
                  ),
                  formatter: ({ data: order }: { data: BidOrder | Order }) => {
                    if ('boxTransactionId' in order && order.boxTransactionId) {
                      return (
                        <TransactionExternalLink transactionId={order.boxTransactionId}>
                          {unixTimestampToDateString(order.placedAtTimestamp, true)}
                        </TransactionExternalLink>
                      )
                    } else {
                      return unixTimestampToDateString(order.placedAtTimestamp, true)
                    }
                  },
                },
              ]
            : []),
          ...(showBidOrders
            ? [
                {
                  dataKey: 'expiresAtTimestamp',
                  width: 20,
                  label: 'Expires',
                  formatter: ({ data: bidOrder }: { data: BidOrder | Order }) => {
                    if (isBidOrder(bidOrder)) {
                      return unixTimestampToDateString(bidOrder.expiresAtTimestamp, true)
                    }
                    return ''
                  },
                },
              ]
            : []),
          {
            dataKey: 'isActive',
            label: 'Status',
            width: 15,
            hideOnMobile: true,
            formatter: ({ data: order }) => {
              if (isBidOrder(order)) {
                return <BidOrderStatusBadge status={order.status} />
              }
              if (order.isActive) {
                return <BadgeWrapper color={COLORS.positive}>Active</BadgeWrapper>
              } else if ('boxTransactionId' in order && order.boxTransactionId) {
                return <BadgeWrapper color={COLORS.warning}>PENDING</BadgeWrapper>
              }
            },
          },
          {
            dataKey: 'cancel',
            label: '',
            width: isMobile ? 15 : 20,
            disableSort: true,
            formatter: ({ data: order }) => {
              function onClick() {
                setSelectedCancelOrder(order)
                openCancelDrawer()
              }
              const isBidOrderCancellable =
                isBidOrder(order) && order.status === EBidOrderStatus.Placed

              if (order.isActive || isBidOrderCancellable) {
                if (isMobile) {
                  return <CancelButton onClick={onClick}>X</CancelButton>
                }
                return <CancelButton onClick={onClick}>Cancel</CancelButton>
              } else {
                return null
              }
            },
          },
        ]}
        paginatedData={filteredOrders}
        fetchPage={fetchPage}
        loading={loading}
        hasNextPage={hasNextPage}
        rowHeight={45}
        viewableRows={25}
        defaultSortBy='desc'
        defaultSort={!showBidOrders ? 'placedAtTimestamp' : 'expiresAtTimestamp'}
        emptyDataMessage='No open orders'
        useWindowScroll={false}
        customHeader={
          showOrdersTypeSwitch ? (
            <TableHeaderContainerWrapper>
              <TableSwitchesContainer>
                <ToggleSwitch
                  value={showBidOrders}
                  onChange={handleToggleBidOrders}
                  options={[false, true]}
                  labels={['Sell Orders', 'Bid Orders']}
                />
              </TableSwitchesContainer>
            </TableHeaderContainerWrapper>
          ) : undefined
        }
      />
    </Container>
  )
}

const Container = styled.div``

const CancelButton = styled(Button)`
  background-color: ${COLORS.negative};
  color: ${COLORS.white};
  @media (max-width: ${BREAKPOINTS.medium}) {
    height: 25px;
    width: 25px;
    font-weight: 700;
    font-size: 11px;
  }
`

const BadgeWrapper = styled(Badge)<{ color: string }>`
  background-color: ${(props) => props.color} !important;
  border-radius: 5px !important;
  text-transform: uppercase;
  font-family: ${FONTS.text};
`

const ThContainer = styled.div`
  display: flex;
  align-items: center;
  svg {
    height: 15px;
  }
`

const VirtualizedTableWrapper = styled(VirtualizedTable<BidOrder | Order>)`
  height: 400px;
  min-height: 50vh;

  @media (max-width: ${BREAKPOINTS.medium}) {
    min-height: unset;
    height: 330px;
  }
`

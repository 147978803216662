import { ROUTES } from 'src/pages'
import { InternalLink } from 'src/shared/components'

export function MysticPointsPageLink() {
  return (
    <InternalLink
      text={'Mystic Points'}
      target='_blank'
      to={{
        pathname: ROUTES.mysticPoints,
      }}
    />
  )
}

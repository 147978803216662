import { useMemo } from 'react'

import { Order, OrderStatus } from '@packages/interfaces'
import { API_ENDPOINTS } from '@packages/constants'

import { useUpdateOnBlockPaginationApi } from 'src/api'
import { replaceUrlParams } from 'src/shared/utils'
import { AddressDetails } from 'src/wallet'

export function useOrdersHistoryByRuneForAccountApi<T extends Order>({
  address,
  runeId,
  type,
  disabled,
  runesAddress,
  otherQueryParams,
}: {
  runeId: string | undefined
  address: string
  runesAddress: AddressDetails | undefined
  disabled?: boolean
  type: 'sell' | 'bid'
  otherQueryParams?: { [key: string]: any }
}) {
  const requestOption = useMemo(() => {
    let endpoint: string = `${
      runeId
        ? replaceUrlParams(API_ENDPOINTS.GET.runes.orders.byRuneForAccount, {
            address,
            runeId,
          })
        : replaceUrlParams(API_ENDPOINTS.GET.runes.orders.byAccount, {
            address,
          })
    }?status=${OrderStatus.Any}${runesAddress ? `&runesAddress=${runesAddress?.addrString}` : ''}`

    if (type === 'bid') {
      endpoint = `${
        runeId
          ? replaceUrlParams(API_ENDPOINTS.GET.runes.bidOrders.byRuneForAccount, {
              address,
              runeId,
            })
          : replaceUrlParams(API_ENDPOINTS.GET.runes.bidOrders.byAccount, {
              address,
            })
      }?status=${OrderStatus.Any}${runesAddress ? `&runesAddress=${runesAddress?.addrString}` : ''}`
    }

    return {
      endpoint: endpoint,
      disabled,
      otherQueryParams,
    }
  }, [address, disabled, runeId, runesAddress, type, otherQueryParams])

  const res = useUpdateOnBlockPaginationApi<T>(requestOption)

  return res
}

import Alert from '@mui/material/Alert'
import { styled } from 'styled-components'
import { type ReactNode } from 'react'

import { COLORS, FONTS } from '../constants'

interface Props {
  message: string
  linkElement?: ReactNode
  className?: string
  hideIcon?: boolean
}
export function ErrorMessage({ hideIcon = false, message, className, linkElement }: Props) {
  return (
    <AlertWrapper icon={hideIcon ? false : undefined} severity='error' className={className}>
      {message}
      {linkElement}
    </AlertWrapper>
  )
}

const AlertWrapper = styled(Alert)`
  background-color: ${COLORS.negative};
  font-weight: 500;
  font-family: ${FONTS.text};
  svg {
    color: ${COLORS.white};
  }
`

import { styled } from 'styled-components'
import { useEffect, useState } from 'react'

import { PopularMint, SortOrderParameters } from '@packages/interfaces'
import {
  API_ENDPOINTS,
  POPULAR_MINTS_SORT_ORDER,
  SORT_ORDER,
  TIMEFRAMES,
} from '@packages/constants'

import {
  VirtualizedTable,
  Switch,
  SmallButtonGroup,
  HeaderText,
  HeaderContainer,
  SubheaderContainer,
  SubheaderText,
} from 'src/shared/components'
import { useUpdateOnBlockPaginationApi } from 'src/api'
import { RuneDetailsLink } from 'src/runes'
import { useIsMobile } from 'src/shared/hooks'
import { BREAKPOINTS, TIMEFRAME_LABELS } from 'src/shared/constants'
import { IS_TESTNET_DEPLOYMENT } from 'src/settings'

import { MintedPercentBadge } from './MintedPercentBadge'

const getPopularMintsTableSubheader = (selectedTimeframe: TIMEFRAMES) => {
  let timeframeString: 'hour' | 'day' | 'week' = 'day'

  switch (selectedTimeframe) {
    case '1h': {
      timeframeString = 'hour'
      break
    }
    case '24h': {
      timeframeString = 'day'
      break
    }
    case '7d': {
      timeframeString = 'week'
      break
    }
    default: {
      timeframeString = 'day'
    }
  }

  return `Bitcoin Runes are a fungible token standard on the Bitcoin network. Discover the hottest minted runes over the last ${timeframeString}. Act fast—these popular runes tend to sell out quickly!`
}

const DEFAULT_TIMEFRAME = IS_TESTNET_DEPLOYMENT ? TIMEFRAME_LABELS['1h'] : TIMEFRAME_LABELS['24h']

const selectedTimeframeOptions = Object.values(TIMEFRAME_LABELS).map((timeframe) => ({
  value: `${timeframe.valueHours}`,
  label: timeframe.label,
}))

interface Props {
  className?: string
}

export function PopularMintsTable({ className }: Props) {
  const isMobile = useIsMobile()
  const [selectedTimeframe, setSelectedTimeframe] = useState(DEFAULT_TIMEFRAME)
  const [updatedQueryParams, setUpdatedQueryParams] = useState(false)
  const [showOnlyLiveMints, setShowOnlyLiveMints] = useState(true)
  const [queryParams, setQueryParams] = useState<
    SortOrderParameters<POPULAR_MINTS_SORT_ORDER> & {
      timeframe: TIMEFRAMES
      onlyLiveMints?: boolean
    }
  >({
    timeframe: '24h',
    onlyLiveMints: true,
  })
  const {
    paginatedData: popularMints,
    fetchPage,
    newPageLoading,
    loading,
    hasNextPage,
  } = useUpdateOnBlockPaginationApi<PopularMint>({
    endpoint: `${API_ENDPOINTS.GET.runes.mints.popular}`,
    limit: isMobile ? 20 : 40,
    otherQueryParams: queryParams,
  })

  useEffect(() => {
    setQueryParams({
      ...queryParams,
      timeframe: selectedTimeframe.timeframe,
      onlyLiveMints: showOnlyLiveMints,
    })
  }, [showOnlyLiveMints])

  useEffect(() => {
    if (updatedQueryParams && !loading) {
      setUpdatedQueryParams(false)
    }
  }, [loading, popularMints])

  function onSort(sortOrderBy: POPULAR_MINTS_SORT_ORDER, order: SORT_ORDER) {
    setQueryParams({
      ...queryParams,
      sortOrderBy,
      sortOrder: order,
    })
    setUpdatedQueryParams(true)
  }

  function onTimeframeChange(value: string) {
    const selectedTimeframe = Object.values(TIMEFRAME_LABELS).find(
      (timeframe) => timeframe.valueHours === parseInt(value)
    )
    if (selectedTimeframe) {
      setSelectedTimeframe(selectedTimeframe)
      setQueryParams({
        ...queryParams,
        timeframe: selectedTimeframe.timeframe,
      })
      setUpdatedQueryParams(true)
    }
  }

  return (
    <Container className={className}>
      <>
        <HeaderContainerWrapper $direction={isMobile ? 'column' : 'row'}>
          <HeaderText $wordBreak='break-word'>Popular Mints</HeaderText>
          {isMobile && (
            <SubheaderContainer>
              <SubheaderText>
                {getPopularMintsTableSubheader(selectedTimeframe?.timeframe)}
              </SubheaderText>
            </SubheaderContainer>
          )}
          <FilteringOptions>
            <LiveMintsSwitch
              checked={showOnlyLiveMints}
              onChange={setShowOnlyLiveMints}
              label={'Only Live Mints'}
            />
            <SmallButtonGroup
              options={selectedTimeframeOptions}
              value={selectedTimeframe.valueHours.toString()}
              onChange={onTimeframeChange}
              exclusive
            />
          </FilteringOptions>
        </HeaderContainerWrapper>
        {!isMobile && (
          <SubheaderContainer>
            <SubheaderText>
              {getPopularMintsTableSubheader(selectedTimeframe?.timeframe)}
            </SubheaderText>
          </SubheaderContainer>
        )}
      </>
      <VirtualizedTableWrapper
        columns={[
          {
            dataKey: 'rank',
            label: 'Rank',
            width: isMobile ? 13 : 5,
            disableSort: true,
            formatter: ({ index }) => <Rank>#{index + 1}</Rank>,
          },
          {
            dataKey: 'runeName',
            label: 'Token',
            disableSort: true,
            width: isMobile ? 60 : 50,
            formatter: ({ data: mint }) => <RuneDetailsLink rune={mint} swapToolTab={'mint'} />,
          },
          {
            dataKey: 'mintCount',
            label: `Mints (${selectedTimeframe.label})`,
            hideOnMobile: true,
            width: 20,
            formatter: ({ data: mint }) => mint.mintCount.toLocaleString(),
            onSort: (sortOrder: SORT_ORDER) => onSort('mintCount', sortOrder),
          },
          {
            dataKey: 'mintedPercent',
            label: 'Percent Minted',
            width: 25,
            formatter: ({ data: mint }) => <MintedPercentBadge runeDetails={mint} />,
            onSort: (sortOrder: SORT_ORDER) => onSort('mintedPercent', sortOrder),
          },
          {
            dataKey: 'holderCount',
            label: 'Holder Count',
            hideOnMobile: true,
            width: 15,
            formatter: ({ data: mint }) => mint.holderCount?.toLocaleString(),
            onSort: (sortOrder: SORT_ORDER) => onSort('holderCount', sortOrder),
          },
          // {
          //   dataKey: 'isMintable',
          //   label: 'Mint State',
          //   formatter: ({ data: rune }) => <MintStateBadge runeDetails={rune} />,
          // },
        ]}
        paginatedData={updatedQueryParams ? [] : popularMints}
        fetchPage={fetchPage}
        loading={newPageLoading || loading}
        hasNextPage={hasNextPage}
        rowHeight={45}
        viewableRows={10}
        emptyDataMessage='No popular mints'
        useWindowScroll={false}
      />
    </Container>
  )
}

const Container = styled.div``

const Rank = styled.div`
  font-weight: 700;
`

const FilteringOptions = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;

  @media (max-width: ${BREAKPOINTS.medium}) {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    align-self: flex-end;
    padding-bottom: 10px;
    gap: 0px;
  }
`

const LiveMintsSwitch = styled(Switch)`
  label {
    margin-right: 0px;
  }
`

const HeaderContainerWrapper = styled(HeaderContainer)`
  padding-top: 0px;
`

const VirtualizedTableWrapper = styled(VirtualizedTable<PopularMint>)`
  height: 600px;
  min-height: 70vh;

  @media (max-width: ${BREAKPOINTS.medium}) {
    min-height: unset;
    height: 330px;
  }
`

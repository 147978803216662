import { styled } from 'styled-components'

import { BREAKPOINTS, COLORS, FONTS } from 'src/shared/constants'
import { hexToRgb } from 'src/shared/utils'

export function EscrowWalletPageContent() {
  return (
    <Container>
      <Title>Welcome to Bidding Wallet:</Title>

      <TextContent>
        At <b>Mystic</b>, we use custodial bidding wallets to facilitate automated bidding
        transactions, ensuring that they happen seamlessly as soon as your desired order price
        becomes available. To ensure the highest level of security, we’ve partnered with a
        specialised partner for our wallet infrastructure, so your funds are always protected.
      </TextContent>

      <Subtitle>How does the bidding wallet work?</Subtitle>
      <TextContent>
        Each Mystic user is assigned an bidding wallet{' '}
        <b>based on the wallet they use to connect to the platform</b>. These wallets hold the
        necessary Bitcoin for your bidding transactions.
      </TextContent>

      <List>
        <li>
          <ListItemHeader>Adding or Withdrawing Funds</ListItemHeader>
          <ListItemContent>
            You can deposit or withdraw funds from the bidding wallet directly via the Mystic
            platform.
          </ListItemContent>
        </li>
        <li>
          <ListItemHeader>Best Practices</ListItemHeader>
          <ListItemContent>
            Since this is only used for Bid orders, we recommend only keeping the Bitcoin needed to
            complete these transactions in the wallet.
          </ListItemContent>
        </li>
      </List>

      <TextContent>
        Once your funding transaction is confirmed on the blockchain, you’re ready to create{' '}
        <b>Bid Orders</b> and engage in the bidding process on the platform.
      </TextContent>

      <Subtitle>How do Bid Orders work?</Subtitle>

      <TextContent>
        With <b>Bid Orders</b>, you can specify the price and quantity of Bitcoin Runes you want to
        purchase, even if those specific runes are not listed in the platform’s orderbook. Here’s
        how it works:
      </TextContent>

      <List>
        <li>
          <ListItemHeader>Creating a Bid</ListItemHeader>
          <ListItemContent>
            You can set your desired price and quantity, as well as an <b>expiry date</b> to limit
            the bid’s validity.
          </ListItemContent>
        </li>
        <li>
          <ListItemHeader>Expiry Date</ListItemHeader>
          <ListItemContent>
            When your bid expires, it will no longer be automatically filled. The bitcoin will
            remain in the bidding wallet, ready to be reused or to be withdrawn.
          </ListItemContent>
        </li>
      </List>

      <TextContent>
        Once a seller lists their runes at the same price and quantity, Mystic will use the funds in
        your <b>bidding wallet</b> to automatically complete the trade. The Bitcoin will be sent to
        the seller, and the runes will be transferred directly to your <b>non-custodial wallet</b>.
      </TextContent>

      <Subtitle>Why are you using bidding wallets?</Subtitle>

      <TextContent>
        At <b>Mystic</b>, our goal is to be a <b>fully decentralized swap platform</b>. However, due
        to current Bitcoin network limitations, we cannot offer bidding functionality without using
        bidding wallets. Without bidding, we would either compromise user experience or rely on
        unproven, complex technologies.
        <br />
        <br />
        We continue to explore emerging solutions and remain committed to transitioning towards a
        more decentralized model in the future—one that aligns with both our ethos and the wider
        community’s vision for decentralization.
      </TextContent>

      <Subtitle>Is the bidding wallet safe?</Subtitle>

      <TextContent>
        Yes, the bidding wallet is designed with top-tier security measures:
      </TextContent>

      <List>
        <li>
          <ListItemHeader>MPC (Multi-Party Computation) Technology</ListItemHeader>
          <ListItemContent>
            Powered by our MPC wallet provider, the wallet uses a <b>multisignature scheme</b>, to
            access the funds. These signatures are split between multiple secured environment.
          </ListItemContent>
        </li>
        <li>
          <ListItemHeader>Protection Against Attacks</ListItemHeader>
          <ListItemContent>
            In order to access your funds, attackers would need to compromise both Mystic’s and our
            partner's systems, adding an extra layer of security.
          </ListItemContent>
        </li>
        <li>
          <ListItemHeader>Recovery Key</ListItemHeader>
          <ListItemContent>
            In the unlikely event that Mystic or our partner ceases to exist, <b>recovery keys</b>{' '}
            are securely stored off-site to ensure your funds can still be accessed.
          </ListItemContent>
        </li>
        <li>
          <ListItemHeader>Insurance</ListItemHeader>
          <ListItemContent>
            Your funds are further protected by a comprehensive insurance policy underwritten by one
            of the largest and most trusted reinsurers in the world. This policy covers you in case
            of a breach or security incident.
          </ListItemContent>
        </li>
      </List>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  border: 1px solid ${hexToRgb(COLORS.labels.primary, 0.25)};
  border-radius: 16px;

  @media (max-width: ${BREAKPOINTS.medium}) {
    padding: 15px;
  }
`

const Title = styled.h2`
  font-size: 36px;
  line-height: 36px;
  font-weight: 500;
  text-transform: uppercase;
`

const Subtitle = styled.h3`
  font-size: 20px;
  font-family: ${FONTS.headers};
  font-weight: 700;
`

const TextContent = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: ${hexToRgb(COLORS.labels.primary, 0.75)};
`

const List = styled.ul`
  display: flex;
  flex-direction: column;

  gap: 20px;

  padding-inline-start: 24px;
`

const ListItemHeader = styled.h4`
  font-size: 16px;
  font-weight: 500;
  text-align: left;
`

const ListItemContent = styled(TextContent)`
  padding-top: 10px;
`

import { useMemo } from 'react'

import { API_ENDPOINTS } from '@packages/constants'
import { BidOrder, OrderStatus } from '@packages/interfaces'

import { useUpdateOnBlockPaginationApi } from 'src/api'
import { replaceUrlParams } from 'src/shared/utils'

export function useAccountOpenBidOrders({
  runeId,
  address,
  otherQueryParams,
} : {
  runeId?: string;
  address: string;
  otherQueryParams?: { [key: string]: any };
}) {
  const endpoint = useMemo(() => {
    if (runeId) {
      return `${replaceUrlParams(API_ENDPOINTS.GET.runes.bidOrders.byRuneForAccount, {
        address,
        runeId,
      })}?status=${OrderStatus.Placed}`
    } else {
      return `${replaceUrlParams(API_ENDPOINTS.GET.runes.bidOrders.byAccount, {
        address,
      })}?status=${OrderStatus.Placed}`
    }
  }, [address, runeId])

  const res = useUpdateOnBlockPaginationApi<BidOrder>({
    endpoint,
    otherQueryParams,
  })

  return res
}

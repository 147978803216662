import { useCallback, useRef, useState } from 'react'

import { RuneDetails } from '@packages/interfaces'

import { Drawer } from 'src/shared/components'

import { MintFormRef, MintRunesDrawerContent } from './MintRunesDrawerContent'

interface Props {
  isOpen: boolean
  onClose: () => void
  runeDetails: RuneDetails
  recipientAddress?: string | undefined
  numberOfMints?: number | undefined
}

export function MintRunesDrawer({
  isOpen,
  onClose,
  runeDetails,
  numberOfMints,
  recipientAddress,
}: Props) {
  const mintFormRef = useRef<MintFormRef>(null)

  const [loadingText, setLoadingText] = useState<string>()

  const handleOnClose = useCallback(() => {
    mintFormRef.current?.resetForm?.()
    onClose?.()
  }, [onClose])

  const handleLoadingTextChange = useCallback((loadingText: string | undefined) => {
    setLoadingText(loadingText)
  }, [])

  return (
    <Drawer isOpen={isOpen} onClose={handleOnClose} canClose={!loadingText}>
      <MintRunesDrawerContent
        ref={mintFormRef}
        runeDetails={runeDetails}
        onClose={onClose}
        isOpen={isOpen}
        onLoadingTextChange={handleLoadingTextChange}
        loadingText={loadingText}
        recipientAddress={recipientAddress}
        numberOfMints={numberOfMints}
      />
    </Drawer>
  )
}

import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'
import { CircularProgress } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { css, styled } from 'styled-components'

import { BidOrder, EBidOrderStatus, FilledBidOrder, Order, OrderStatus } from '@packages/interfaces'
import { BID_ORDERS_SORT_COLUMN, ORDERS_SORT_ORDER, SORT_ORDER } from '@packages/constants'
import { formatNumber, formatUsd } from '@packages/utils'

import { useGoogleAnalyticsContext } from 'src/analytics'
import { useGetOrderState } from 'src/orders/hooks/useGetOrderState'
import { RunesAmountDisplay } from 'src/runes'
import {
  Badge,
  BUTTON_HOVER_STYLES,
  RefreshButton,
  Switch,
  TieredTableCell,
  ToggleSwitch,
  VirtualizedTable,
} from 'src/shared/components'
import { BREAKPOINTS, COLORS, FONTS } from 'src/shared/constants'
import { useDebounce, useIsMobile } from 'src/shared/hooks'
import { hexToRgb, unixTimestampToDateString } from 'src/shared/utils'
import { useWalletContext } from 'src/wallet'
import { useFeatureFlagContext } from 'src/featureFlags'

import { useOpenOrdersForTable } from '../hooks'
import { useOrderContext } from '../OrderContext'
import {
  formatPriceSymbol,
  isAggregateOrder,
  isBidOrder,
  isOwnMagicEdenOrder,
  isOwnOrder,
} from '../utils'
import { BtcAmountDisplay } from './BtcAmountDisplay'
import { TableHeaderContainerWrapper, TableSwitchesContainer } from './styles'

const FILLED_PENDING_ORDER_STATUS: OrderStatus = OrderStatus.FilledPending

function isOrderSelected(order: Order | BidOrder, selectedOrders: Order[]): boolean {
  const selectedOrder = selectedOrders.find((selected) => {
    if (isBidOrder(selected)) {
      return (selected as BidOrder).id === order.id
    }

    if (isAggregateOrder(selected)) {
      return selected.id === order.id
    }

    return false
  })

  return !!selectedOrder
}

export function OpenSellOrdersTable() {
  const {
    selectedSellOrders,
    setSelectedSellOrders,
    isStaleOrderDataTimestamp,
    selectedRune,
    recentlyFilledOrders,
    recentlyCancelledOrders,
  } = useOrderContext('market')
  const { isBiddingEnabled } = useFeatureFlagContext()

  const { runesAddress } = useWalletContext()
  const isMobile = useIsMobile()
  const [showPendingOrders, setShowPendingOrders] = useState(false)
  const [hideMyOrders, setHideMyOrders] = useState(false)
  const [filteredOrders, setFilteredOrders] = useState<(Order | FilledBidOrder)[][]>([])
  //const { customEvent, abandonEvent } = useGoogleAnalyticsContext()
  const [queryParams, setQueryParams] = useState({})

  const { isBidOrderState, isLimitMode, toggleOrderState } = useGetOrderState()
  const handleToggleBidOrders = useCallback(
    (isBidOrdersEnabled: boolean) => {
      toggleOrderState(isBidOrdersEnabled ? 'bid' : 'sell')
    },
    [toggleOrderState]
  )

  const showBidOrders = isBidOrderState && isBiddingEnabled

  const {
    openOrders,
    fetchPage,
    newPageLoading,
    hasNextPage,
    forceRefresh,
    filledPendingOrders,
    filledForceRefresh,
  } = useOpenOrdersForTable({
    selectedRune,
    type: showBidOrders ? 'bid' : 'sell',
    otherQueryParams: queryParams,
  })

  async function refreshOrders() {
    await forceRefresh()
    await filledForceRefresh()
  }

  useEffect(() => {
    if (isStaleOrderDataTimestamp) {
      refreshOrders()
    }
  }, [isStaleOrderDataTimestamp])

  useEffect(
    function filterOrdersToShow() {
      let orders: (Order | FilledBidOrder)[][] = openOrders

      orders = orders.map((nestedOrders) => {
        return nestedOrders.filter((order) => {
          // Always hide own order that are placed on MagicEden.
          if (runesAddress && isOwnMagicEdenOrder(order as Order, runesAddress)) {
            return false
          }

          if (hideMyOrders && runesAddress && isOwnOrder(order, runesAddress)) {
            return false
          }

          // Check if order was recently filled
          if (recentlyFilledOrders.some((filledOrder) => filledOrder.id === order.id)) {
            return false
          }
          // Check if order was recently cancelled
          if (recentlyCancelledOrders.some((cancelledOrder) => cancelledOrder.id === order.id)) {
            return false
          }

          return true
        })
      })

      if (showPendingOrders && filledPendingOrders.length > 0) {
        orders = [...filledPendingOrders, ...orders]
      }
      setFilteredOrders(orders)
    },
    [
      showBidOrders,
      runesAddress,
      openOrders,
      showPendingOrders,
      hideMyOrders,
      runesAddress?.addrString,
      filledPendingOrders,
      recentlyFilledOrders,
      recentlyCancelledOrders,
    ]
  )

  useDebounce(
    async () => {
      if (selectedSellOrders.length === 0) {
        refreshOrders()
      }
    },
    [selectedSellOrders.length],
    1000
  )

  // function removeOrder(order: Order) {
  //   customEvent('remove', false, 'swap', {
  //     token_name: order.runeName,
  //     token_type: 'rune',
  //     order_amount: order.runesAmount,
  //     price: order.satsAmount,
  //   })
  //   if (selectedSellOrders.length == 1) {
  //     abandonEvent(true, 'swap', {
  //       token_name: order.runeName,
  //       token_type: 'rune',
  //       order_action: 'buy',
  //       order_amount: order.runesAmount,
  //       price: order.satsAmount,
  //     })
  //   }

  //   setSelectedSellOrders((prev) => prev.filter((selected) => selected.id !== order.id))
  // }

  // function addOrder(order: Order) {
  //   customEvent('add', false, 'swap', {
  //     token_name: order.runeName,
  //     token_type: 'rune',
  //     order_action: 'buy',
  //     order_amount: order.runesAmount,
  //     price: order.satsAmount,
  //   })

  //   setSelectedSellOrders((prev) => [...prev, order])
  // }

  function onRefreshButtonClick() {
    refreshOrders()
  }

  function onSort(orderBy: ORDERS_SORT_ORDER | BID_ORDERS_SORT_COLUMN, order: SORT_ORDER) {
    setQueryParams({ sortOrderBy: orderBy, sortOrder: order })
  }

  return (
    <Container>
      <VirtualizedTableWrapper
        columns={[
          {
            dataKey: 'priceSats',
            width: isMobile ? 25 : 30,
            label:
              'Price' +
              (!isMobile
                ? ` (${formatPriceSymbol({ runeSymbol: selectedRune?.runeSymbolChar })})`
                : ''),
            onSort: (sortOrder: SORT_ORDER) => onSort('priceSats', sortOrder),
            formatter: ({ data: order }) => {
              return (
                <TdContainer
                  $orderStatus={order.status}
                  $isOwnOrder={isOwnOrder(order, runesAddress)}
                >
                  <TieredTableCell
                    header={formatNumber({ numStr: order.priceSats, useCompactNotation: isMobile })}
                    subHeader={order.priceUsd}
                  />
                </TdContainer>
              )
            },
          },
          {
            dataKey: 'runesAmount',
            width: 25,
            label: isMobile ? 'Qty' : 'Quantity',
            formatter: ({ data: order }) => (
              <RunesAmountDisplay
                rune={selectedRune}
                runesAmount={order.runesAmount}
                shortFormat={isMobile}
                color={
                  order.status === FILLED_PENDING_ORDER_STATUS || isOwnOrder(order, runesAddress)
                    ? hexToRgb(COLORS.white, 0.6)
                    : COLORS.white
                }
              />
            ),
          },
          {
            dataKey: 'satsAmount',
            width: 30,
            label: 'Total',
            onSort: (sortOrder: SORT_ORDER) => onSort('total', sortOrder),
            formatter: ({ data: order }) => (
              <TdContainer
                $orderStatus={order.status}
                $isOwnOrder={isOwnOrder(order, runesAddress)}
              >
                <TieredTableCell
                  header={<BtcAmountDisplay btcAmount={order.satsAmount} showPriceSymbol />}
                  subHeader={formatUsd({ usd: order.valueUsd, shortFormat: isMobile })}
                />
              </TdContainer>
            ),
          },
          ...(showBidOrders
            ? [
                {
                  dataKey: 'expiresIn',
                  width: 20,
                  label: 'Expires',
                  disableSort: true,
                  formatter: ({ data: bidOrder }: { data: Order | FilledBidOrder }) => {
                    if (isBidOrder(bidOrder)) {
                      return unixTimestampToDateString(
                        (bidOrder as BidOrder).expiresAtTimestamp,
                        true
                      )
                    }

                    return ''
                  },
                },
              ]
            : []),
          // {
          //   dataKey: 'buyButton',
          //   label: 'Buy',
          //   width: 5,
          //   disableSort: true,
          //   formatter: ({ data: order }) => {
          //     if (order.status === FILLED_PENDING_ORDER_STATUS) {
          //       return (
          //         <PendingBuyButton>
          //           <LoadingIcon />
          //         </PendingBuyButton>
          //       )
          //     }

          //     if (isOwnOrder(order, runesAddress)) {
          //       return <YourOrderBadge>Yours</YourOrderBadge>
          //     }
          //     return (
          //       <BuyButton $disabled={isLimitMode}>
          //         {isOrderSelected(order, selectedSellOrders) ? (
          //           <AddCircleRoundedIconWrapper
          //             onClick={() => {
          //               if (isLimitMode) {
          //                 return
          //               }

          //               removeOrder(order)
          //             }}
          //           />
          //         ) : showBidOrders ? (
          //           <AddCircleOutlineRoundedIcon
          //             onClick={() => {
          //               if (isLimitMode) {
          //                 return
          //               }

          //               addOrder(order)
          //             }}
          //           />
          //         ) : (
          //           <></>
          //         )}
          //       </BuyButton>
          //     )
          //   },
          // },
        ]}
        defaultSortBy='asc'
        defaultSort='priceSats'
        paginatedData={filteredOrders}
        fetchPage={fetchPage}
        loading={newPageLoading}
        hasNextPage={hasNextPage}
        rowHeight={45}
        viewableRows={25}
        headerShown={false}
        fixedHeader={false}
        emptyDataMessage='No open orders'
        useWindowScroll={false}
        customHeader={
          <TableHeaderContainerWrapper>
            <TableSwitchesContainer>
              {isBiddingEnabled && (
                <ToggleSwitch
                  value={showBidOrders}
                  onChange={handleToggleBidOrders}
                  options={[false, true]}
                  labels={['Sell Orders', 'Bid Orders']}
                />
              )}

              {runesAddress && (filteredOrders[0]?.length > 0 || hideMyOrders) && (
                <Switch
                  checked={hideMyOrders}
                  onChange={setHideMyOrders}
                  label={'Hide My Orders'}
                />
              )}
              {filledPendingOrders.length > 0 && filledPendingOrders[0].length > 0 && (
                <Switch
                  checked={showPendingOrders}
                  onChange={setShowPendingOrders}
                  label={'Show Pending Orders'}
                />
              )}
            </TableSwitchesContainer>
            <RefreshButtonWrapper onClick={onRefreshButtonClick} />
          </TableHeaderContainerWrapper>
        }
      />
    </Container>
  )
}

const Container = styled.div``

const BuyButton = styled.div<{ $disabled?: boolean }>`
  ${BUTTON_HOVER_STYLES}
  width: fit-content;
  height: fit-content;
  ${(props) =>
    props.$disabled &&
    css`
      color: ${COLORS.labels.disabled} !important;
      pointer-events: none;

      svg {
        color: ${COLORS.labels.disabled};
      }
    `}
  svg {
    height: 28px;
    width: 28px;
  }
`

const PendingBuyButton = styled.div`
  padding-left: 5px;
`

const LoadingIcon = styled(CircularProgress)`
  color: ${COLORS.white};
  width: 20px !important;
  height: 20px !important;
  animation-duration: 2.8s;

  svg {
    circle {
      animation-duration: 2.8s;
    }
  }
`

const TdContainer = styled.div<{
  $orderStatus: OrderStatus | EBidOrderStatus
  $isOwnOrder: boolean
}>`
  width: 100%;
  display: flex;

  color: ${({ $orderStatus, $isOwnOrder }) =>
    $orderStatus === FILLED_PENDING_ORDER_STATUS || $isOwnOrder
      ? hexToRgb(COLORS.white, 0.6)
      : COLORS.white} !important;

  div {
    div:first-child {
      color: ${({ $orderStatus, $isOwnOrder }) =>
        $orderStatus === FILLED_PENDING_ORDER_STATUS || $isOwnOrder
          ? hexToRgb(COLORS.white, 0.6)
          : COLORS.white} !important;
    }
  }
`

const YourOrderBadge = styled(Badge)`
  border: 1px solid ${COLORS.hover};
  font-family: ${FONTS.text};
  text-transform: uppercase;
  font-size: 12px !important;
  border-radius: 5px !important;
  @media (max-width: ${BREAKPOINTS.medium}) {
    font-size: 9px !important;
  }
`

const AddCircleRoundedIconWrapper = styled(AddCircleRoundedIcon)<{ $disabled?: boolean }>`
  color: ${COLORS.hover};
`

const RefreshButtonWrapper = styled(RefreshButton)`
  background-color: transparent;
  padding: 0px;
`

const VirtualizedTableWrapper = styled(VirtualizedTable<Order | FilledBidOrder>)`
  height: 400px;
  min-height: 50vh;

  @media (max-width: ${BREAKPOINTS.medium}) {
    min-height: unset;
    height: 330px;
  }
`

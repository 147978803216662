import { styled } from 'styled-components'
// import CheckCircleIcon from '@mui/icons-material/CheckCircle'

import { RunesHistoryRecord } from '@packages/interfaces'
import { API_ENDPOINTS } from '@packages/constants'

import { VirtualizedTable } from 'src/shared/components'
import { replaceUrlParams, unixTimestampToDateString } from 'src/shared/utils'
import { RuneDetailsLink, formatRunesAmount } from 'src/runes'
import { COLORS, BREAKPOINTS } from 'src/shared/constants'
import { usePaginationApi } from 'src/api'
import { useIsMobile } from 'src/shared/hooks'
import { TransactionExternalLink } from 'src/web3'

interface Props {
  address: string
}

export function AccountHistoryTable({ address }: Props) {
  const isMobile = useIsMobile()
  const {
    paginatedData: accountHistory,
    fetchPage,
    newPageLoading,
    hasNextPage,
  } = usePaginationApi<RunesHistoryRecord>({
    endpoint: replaceUrlParams(API_ENDPOINTS.GET.accounts.history, { address }),
    limit: isMobile ? 20 : 40,
  })

  return (
    <Container>
      <VirtualizedTableWrapper
        columns={[
          {
            dataKey: 'runeName',
            label: 'Token',
            width: 45,
            formatter: ({ data: historyRecord }) => <RuneDetailsLink rune={historyRecord} />,
          },
          {
            dataKey: 'recordType',
            label: 'Type',
            width: 12,
            formatter: ({ data: historyRecord }) => {
              if (historyRecord.recordType !== 'RunesTransferred') return <>{'Transfer'}</>
              else if (historyRecord.isMint) return <>{'Mint'}</>
              else if (historyRecord.isAirdrop) return <>{'Airdrop'}</>
              else return <>{'Received'}</>
            },
          },
          // {
          //   dataKey: 'recordType',
          //   label: 'Transfer',
          //   hideOnMobile: true,
          //   disableSort: true,
          //   width: 8,
          //   formatter: ({ data: historyRecord }) => {
          //     const isTransferOut = historyRecord.recordType !== 'RunesTransferred'
          //     return isTransferOut ? <IconWrapped /> : null
          //   },
          // },
          // {
          //   dataKey: 'isMint',
          //   label: 'Mint',
          //   hideOnMobile: true,
          //   disableSort: true,
          //   width: 7,
          //   formatter: ({ data: historyRecord }) => (historyRecord.isMint ? <IconWrapped /> : null),
          // },
          // {
          //   dataKey: 'isAirdrop',
          //   label: 'Airdrop',
          //   hideOnMobile: true,
          //   disableSort: true,
          //   width: 7,
          //   formatter: ({ data: historyRecord }) =>
          //     historyRecord.isAirdrop ? <IconWrapped /> : null,
          // },
          {
            dataKey: 'runesAmount',
            label: 'Amount',
            width: 25,
            formatter: ({ data: historyRecord }) => {
              const isPositive = historyRecord.recordType === 'RunesTransferred'
              return (
                <RecordType $positive={isPositive}>
                  <Amount>
                    {isPositive ? '+' : '-'}
                    {formatRunesAmount({
                      rune: historyRecord,
                      runesAmount: historyRecord.runesAmount,
                      displayShortFormat: isMobile,
                    })}
                  </Amount>
                </RecordType>
              )
            },
          },
          {
            dataKey: 'timestamp',
            label: 'Date',
            width: 30,
            formatter: ({ data: historyRecord }) => {
              if (historyRecord.transactionId) {
                return (
                  <TransactionExternalLink
                    transactionId={historyRecord.transactionId}
                    displayIcon={!isMobile}
                  >
                    {unixTimestampToDateString(historyRecord.timestamp, true)}
                  </TransactionExternalLink>
                )
              } else {
                return unixTimestampToDateString(historyRecord.timestamp, true)
              }
            },
          },
        ]}
        defaultSortBy='desc'
        defaultSort='timestamp'
        paginatedData={accountHistory ?? []}
        fetchPage={fetchPage}
        loading={newPageLoading}
        hasNextPage={hasNextPage}
        rowHeight={35}
        viewableRows={20}
        useWindowScroll={false}
      />
    </Container>
  )
}

const Container = styled.div``

const RecordType = styled.div<{ $positive: boolean }>`
  padding: 5px;
  border-radius: 4px;
  width: fit-content;
  font-weight: 700;
  div {
    color: ${({ $positive }) => COLORS[$positive ? 'positive' : 'negative']} !important;
  }
`

const Amount = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`

// const IconWrapped = styled(CheckCircleIcon)`
//   color: ${COLORS.link} !important;
//   line-height: 15px;
// `

const VirtualizedTableWrapper = styled(VirtualizedTable<RunesHistoryRecord>)`
  height: 400px;
  min-height: 50vh;

  @media (max-width: ${BREAKPOINTS.medium}) {
    min-height: unset;
    height: 330px;
  }
`

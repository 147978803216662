import { useMemo } from 'react'
import { styled } from 'styled-components'

import { EBidOrderStatus } from '@packages/interfaces'

import { COLORS, FONTS } from 'src/shared/constants'
import { Badge } from 'src/shared/components'

export const BidOrderStatusBadge = ({ status }: { status: EBidOrderStatus }) => {
	const color = useMemo(() => {
		if ([
			EBidOrderStatus.PlacedActive,
			EBidOrderStatus.Placed,
			EBidOrderStatus.Filled
		].includes(status)) {
			return COLORS.positive
		} else {
			return COLORS.warning
		}
	}, [status])

	const statusText = useMemo(() => {
		if ([EBidOrderStatus.PlacedPending, EBidOrderStatus.FilledPending].includes(status)) {
			return 'Pending'
		} else if (EBidOrderStatus.PlacedActive === status) {
			return 'Placed'
		} else if (EBidOrderStatus.Any === status) {
			return 'Unknown'
		}

		return status
	}, [status])

	return <BadgeWrapper color={color}>{statusText}</BadgeWrapper>
}

const BadgeWrapper = styled(Badge)<{ color: string }>`
  background-color: ${(props) => props.color} !important;
  border-radius: 5px !important;
  text-transform: uppercase;
  font-family: ${FONTS.text};
`

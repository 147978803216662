import { useCallback, useEffect, useState } from 'react'
import { styled } from 'styled-components'
import { useAutoAnimate } from '@formkit/auto-animate/react'
import { useSearchParams } from 'react-router-dom'

import { SubheaderText, TabPanelContentContainer, type TabPanelProps } from 'src/shared/components'
import { buildSwapTypeSearchParam, RUNE_TOOLS_SWAP_TYPE_SEARCH_PARAM_NAME } from 'src/pages'
import { useOrderContext } from 'src/orders'
import { concatSearchParams } from 'src/shared/utils'

import { SwapTool } from './SwapTool'
import { SwapToolModeSelector } from './SwapToolModeSelector'
import { SwapToolMode } from './types'

export function SwapTabPanel({ visible }: TabPanelProps) {
  const [animateRefParent] = useAutoAnimate()

  const { isBTConTop } = useOrderContext()

  const [swapToolMode, setSwapToolMode] = useState<SwapToolMode>('market')
  const [searchParams, setSearchParams] = useSearchParams()

  const handleSwapToolModeChange = useCallback(
    (mode: SwapToolMode) => {
      setSearchParams((prev) => {
        const newSearchParams = concatSearchParams(prev, buildSwapTypeSearchParam(mode))

        return newSearchParams
      })
    },
    [setSearchParams]
  )

  useEffect(() => {
    const swapToolModeParam = searchParams.get(
      RUNE_TOOLS_SWAP_TYPE_SEARCH_PARAM_NAME
    ) as SwapToolMode

    setSwapToolMode(swapToolModeParam || 'market')
  }, [searchParams])

  const title = isBTConTop
    ? swapToolMode === 'market'
      ? // buy market
        'Use this tool to buy runes on Mystic. Enter your Bitcoin budget, and we’ll automatically find the best orders for you and execute the swap instantly'
      : // bid order copy
        'Use this tool to create bid orders on Mystic. Enter the Bitcoin you want to spend and the number of runes you wish to purchase, and we’ll create a bid order for you.'
    : swapToolMode === 'market'
      ? // sell market
        'Use this tool to sell runes from your account on Mystic. Simply enter the amount of runes or select outpoints from the My Runes tab. We’ll automatically find the best orders for you and execute the swap instantly!'
      : // limit sell
        'Use this tool to list runes from your account on Mystic. Simply enter the amount of runes or select outpoints from the My Runes tab, then set your price.'

  return (
    <Container ref={animateRefParent} $visible={visible}>
      <Title>{title}</Title>

      <SwapToolModeSelector selectedMode={swapToolMode} onChange={handleSwapToolModeChange} />

      <SwapToolContainer $visible={swapToolMode === 'market'}>
        <SwapTool mode={'market'} />
      </SwapToolContainer>

      <SwapToolContainer $visible={swapToolMode === 'limit'}>
        <SwapTool mode={'limit'} />
      </SwapToolContainer>
    </Container>
  )
}

const Container = styled(TabPanelContentContainer)`
  gap: 20px;
`

const Title = styled(SubheaderText)``

const SwapToolContainer = styled.div<{ $visible: boolean }>`
  display: ${(props) => (props.$visible ? 'flex' : 'none')};
`

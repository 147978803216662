import { css, styled } from 'styled-components'
import { type PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import SwapInputIcon from '@mui/icons-material/ArrowDropDown'
import SwapCallsIcon from '@mui/icons-material/SwapCalls'
import { generatePath, useNavigate } from 'react-router-dom'
import JSONbig from '@cardanosolutions/json-bigint'

import {
  AggregateCursor,
  AggregatePaginationResponse,
  BTC_PRICE_SYMBOL,
  Order,
  OrderStatus,
  PaginationResponse,
  Provider as AggregateProviders,
  Rune,
  RuneOutpointDetails,
} from '@packages/interfaces'
import { API_ENDPOINTS, BTC_DECIMALS, SATOSHI_DUST_THRESHOLD } from '@packages/constants'
import { formatBtc, formatRuneName, ManualError, satsToBtc } from '@packages/utils'

import {
  BUTTON_HOVER_STYLES,
  BigIntInput,
  Button,
  ErrorMessage,
  NumberInputWithSuffix,
  Select,
  WarningMessage,
  InternalLink,
  Checkbox,
} from 'src/shared/components'
import { BREAKPOINTS, COLORS, FONTS, SELECT_BUTTON_HEIGHT, Z_INDEX } from 'src/shared/constants'
import { RuneSymbolDisplay, formatRunesAmount } from 'src/runes'
import { concatSearchParams, hexToRgb, replaceUrlParams } from 'src/shared/utils'
import { apiFetch } from 'src/api'
import { WalletPickerMenu, useEscrowWalletContext, useWalletContext } from 'src/wallet'
import { useDebouncedValue, useIsMobile } from 'src/shared/hooks'
import {
  buildAccountDetailsWalletTabSearchParam,
  buildMarketDetailsUrl,
  buildRuneToolsTabSearchParam,
  buildSwapToolSideSearchParam,
  ROUTES,
} from 'src/pages/constants'
import { DEFAULT_CSS_TRANSITION } from 'src/shared/styles'
import { BID_ORDER_MINIMUM_TOTAL_PRICE } from 'src/settings'
import { SWITCH_ACCOUNTS_TABS } from 'src/account/constants'

import { RuneSelectButton } from './RuneSelectButton'
import { SelectRuneModal } from './SelectRuneModal'
import {
  calculatePrice,
  calculatePriceDifference,
  calculateTotalSatsFromDesiredPrice,
  calculateTotalSatsFromDesiredTotal,
  formatPriceSymbol,
  parsePriceStr,
} from '../utils'
import { PriceSatsDisplay } from './PriceSatsDisplay'
import { PriceUsdDisplay } from './PriceUsdDisplay'
import { OrderStateType, useOrderContext } from '../OrderContext'
const RUNES_INPUT_DEBOUNCE_DELAY_MS = 500
const BTC_INPUT_DEBOUNCE_DELAY_MS = 1500
const BID_ORDER_MINIMUM_TOTAL_PRICE_BTC = satsToBtc(BID_ORDER_MINIMUM_TOTAL_PRICE)

const jsonBig = JSONbig({ useNativeBigInt: true, alwaysParseAsBig: true })

const OPEN_ORDER_STATUS: OrderStatus = OrderStatus.PlacedActive

function getSellOrdersEndpoint(
  runeName: string,
  cursor?: AggregateCursor | undefined,
  limit?: number
) {
  return (
    `${replaceUrlParams(API_ENDPOINTS.GET.runes.orders.byStatus, {
      runeName: runeName.toUpperCase(),
    })}?status=${OPEN_ORDER_STATUS}&sortOrderBy=price&sortOrder=asc` +
    (cursor ? `&cursor=${jsonBig.stringify(cursor)}` : '') +
    (limit ? `&limit=${limit}` : '')
  )
}

function getBidOrdersEndpoint(runeName: string, cursor?: string) {
  return (
    `${replaceUrlParams(API_ENDPOINTS.GET.runes.bidOrders.byStatus, {
      runeName: runeName.toUpperCase(),
    })}?status=${OPEN_ORDER_STATUS}&sortOrderBy=price&sortOrder=asc` +
    (cursor ? `&cursor=${cursor}` : '')
  )
}

type RuneSwapperAggregateTotal = {
  provider: AggregateProviders
  selectedOrders: Order[]
  total: bigint
}

export type RuneSwapperProps = PropsWithChildren<{
  stateType?: OrderStateType
  expiresIn?: number
  disabled?: boolean
}>

export function RuneSwapper({
  children,
  stateType = 'market',
  expiresIn,
  disabled,
}: RuneSwapperProps) {
  const {
    isLoadingNewRune,
    isBTConTop,
    setIsBTConTop,
    selectedRune,
    selectedRuneName,
    selectedRuneMarket,
    selectedRuneAvailableBalance,
    setSelectedRune,
    orderSummary,
    selectedRuneOutpoints,
    setSelectedRuneOutpoints,
    selectedSellOrders,
    setSelectedSellOrders,
    desiredBtcSellAmount,
    setDesiredBtcSellAmount,
    desiredRunesSellAmount,
    setDesiredRunesSellAmount,
    openPlaceBidDrawer,
    openBuyBidOrderDrawer,
    openBuyDrawer,
    openSellDrawer,
    isSellDrawerOpen,
  } = useOrderContext(stateType)

  const {
    runesAddress,
    paymentAddress,
    getRunesOutpointsForSale,
    btcBalances,
    recommendedNetworkFees,
  } = useWalletContext()

  const { btcBalance: escrowBtcBalance, lockedBtcBalance: escrowLockedBtcBalance } =
    useEscrowWalletContext()
  const availableEscrowWalletBalance: bigint =
    (escrowBtcBalance ?? 0n) - (escrowLockedBtcBalance ?? 0n)

  const isBidOrderCreation = stateType === 'limit' && isBTConTop
  const isBidOrderBuying = stateType === 'market' && !isBTConTop

  const useEscrowWallet = isBidOrderCreation
  const notEnoughBtcOnEscrowWallet =
    isBidOrderCreation && availableEscrowWalletBalance < (desiredBtcSellAmount || 0n)

  const isMobile = useIsMobile()
  const navigate = useNavigate()

  const btcInputRef = useRef<HTMLInputElement>(null)
  const runeInputRef = useRef<HTMLInputElement>(null)

  const [isSelectRuneModalOpen, setIsSelectRuneModalOpen] = useState(false)

  const [previouslySelectedSellOrders, setPreviouslySelectedSellOrders] = useState<Order[]>([])
  const [userEditedRunesAmount, setUserEditedRunesAmount] = useState(false)
  const [userEditedBtcAmount, setUserEditedBtcAmount] = useState(false)

  const [debouncedRunesAmount, runesCounter] = useDebouncedValue(
    desiredRunesSellAmount,
    RUNES_INPUT_DEBOUNCE_DELAY_MS
  )

  const [disableRunesAmount, setDisableRunesAmount] = useState<boolean>(false)

  const [debouncedBtcAmount, btcCounter] = useDebouncedValue(
    desiredBtcSellAmount,
    BTC_INPUT_DEBOUNCE_DELAY_MS
  )

  const [disableBtcAmount, setDisableBtcAmount] = useState<boolean>(false)

  const [desiredSatsPrice, setDesiredSatsPrice] = useState<number>()
  const [isUsingBtcTotal, setIsUsingBtcTotal] = useState<boolean>(true)

  const [isWalletMenuOpen, setIsWalletMenuOpen] = useState(false)
  const [isSwapButtonHovered, setIsSwapButtonHovered] = useState(false)

  const [loadingText, setLoadingText] = useState<string>()
  const [errorMessage, setErrorMessage] = useState<string>()
  const [buyWarningMessage, setBuyWarningMessage] = useState<string>()

  const [isUsingFloorPrice, setIsUsingFloorPrice] = useState<boolean>(false)
  const [isUsingFloorPriceForBidOrders, setIsUsingFloorPriceForBidOrders] = useState<boolean>(false)
  const [isFloorPriceWarningChecked, setIsFloorPriceWarningChecked] = useState<boolean>(false)

  const [runesAmountIsNotOutpointAmount, setRunesAmountIsNotOutpointAmount] =
    useState<boolean>(false)

  useEffect(() => {
    if (!selectedRuneMarket || !selectedRune) return

    if (isUsingFloorPrice && !isBTConTop && !isSellDrawerOpen) {
      if (isUsingBtcTotal) {
        updateBtcAmountFromSatsPrice({
          desiredPriceSats: selectedRuneMarket.floorPriceSats,
          desiredRunesAmount: debouncedRunesAmount,
        })
      } else {
        onSatsAmountChange(parseFloat(selectedRuneMarket.floorPriceSats))
      }
    }
  }, [
    selectedRuneMarket,
    selectedRune,
    isSellDrawerOpen,
    isUsingFloorPrice,
    debouncedRunesAmount,
    isBTConTop,
    isUsingBtcTotal,
    selectedRuneMarket?.floorPriceSats,
  ])

  // useEffect(() => {
  //   console.log(selectedRuneOutpoints)
  //   if (selectedRuneOutpoints.length === 0 && !!desiredSatsPrice) {
  //     setDesiredSatsPrice(undefined)
  //   }
  // }, [desiredSatsPrice, selectedRuneOutpoints, selectedRuneOutpoints.length, userEditedBtcAmount])

  //Disables warning message on manual add/removing of selected order. Which is disabled currently
  useEffect(() => {
    if (selectedSellOrders.length === previouslySelectedSellOrders.length) {
      return
    }

    setPreviouslySelectedSellOrders(selectedSellOrders)
  }, [selectedSellOrders, previouslySelectedSellOrders.length])

  // Main swap function. Calculates the optimal @desiredAmount for @desiredAmountField for the selected @orderType.
  // Returns an optimal list of orders if found.
  async function onBuyOrderChange({
    desiredAmount = 0n,
    desiredAmountField,
    callCounter,
    orderType = 'sell',
  }: {
    desiredAmount?: bigint
    desiredAmountField: 'runesAmount' | 'satsAmount'
    callCounter: number
    orderType?: 'bid' | 'sell'
  }) {
    if (!selectedRune || !runesAddress || stateType != 'market') return
    if (desiredAmount === 0n) return
    // Sanity conversion. Sometime it can be string on first init
    desiredAmount = BigInt(desiredAmount)
    function isCurrentCounter() {
      return desiredAmountField === 'satsAmount'
        ? callCounter === btcCounter.current
        : callCounter === runesCounter.current
    }

    if (!isCurrentCounter()) {
      return // Ignore outdated calls
    }

    reset(true)
    setLoadingText('Loading orders')

    let selectedOrders: Order[] = []
    let iteration = 0
    try {
      // Calculations using Bid orders from Mystic Database
      if (orderType === 'bid') {
        let total = 0n
        let nextCursor: string | bigint | undefined = undefined
        let response: PaginationResponse<Order> | undefined

        // This do...while loop iterates until it finds an array of orders that fulfills the desired parameters.
        do {
          // By default, orders returned from this function are sorted in ascending order by price.
          response = await apiFetch<PaginationResponse<Order>>(
            getBidOrdersEndpoint(
              selectedRune.runeName,
              nextCursor ? nextCursor.toString() : undefined
            )
          )
          nextCursor = response.nextCursor
          for (const item of response.data) {
            // Ignores orders from the current user.
            if (item.placedByAddress === runesAddress.addrString) {
              continue
            }
            // Exits the loop when the desired goal is reached.
            if (total >= desiredAmount) {
              break
            }
            // Checks that adding the current order does not exceed @desiredAmount,
            // allowing for a 3% margin of error.
            if (total + item[desiredAmountField] > (desiredAmount * 103n) / 100n) {
              if (desiredAmountField === 'satsAmount') {
                break
              } else {
                continue
              }
            }
            total += item[desiredAmountField]
            selectedOrders.push(item)
          }
          iteration++
        } while (
          total < desiredAmount &&
          nextCursor &&
          response.data.length > 0 &&
          ((total * 103n) / 100n < desiredAmount || iteration < 7)
          // Exits the loop if the accumulated amount is close to @desiredAmount after enough iterations.
          // Specifically, this applies when @total is too near to @desiredAmount for any order to breach the gap.
        )
      } else {
        const totalList: RuneSwapperAggregateTotal[] = [
          { provider: 'MagicEden', selectedOrders: [], total: 0n },
          { provider: 'Okx', selectedOrders: [], total: 0n },
          { provider: 'Mystic', selectedOrders: [], total: 0n },
        ]
        const reverseAmountField =
          desiredAmountField === 'runesAmount' ? 'satsAmount' : 'runesAmount'
        let nextCursor: AggregateCursor | undefined = undefined
        let response: AggregatePaginationResponse<Order> | undefined
        const unisatOrders: Order[] = []
        const mysticOrders: Order[] = []
        const possibleSolutions: {
          selectedOrders: Order[]
          total: bigint
          price: number
          totalOposite: bigint
        }[] = []
        do {
          response = await apiFetch<AggregatePaginationResponse<Order>>(
            getSellOrdersEndpoint(selectedRune.runeName, nextCursor, 200)
          )
          // Adds any order that fulfills @desiredAmount on its own to the possible solution list.
          possibleSolutions.push(
            ...response.data
              .filter(
                (t) =>
                  t[desiredAmountField] === desiredAmount &&
                  t.placedByAddress !== runesAddress.addrString
              )
              .map((item) => {
                return {
                  price: Number(item.priceSats),
                  selectedOrders: [item],
                  total: item[desiredAmountField],
                  totalOposite: item[reverseAmountField],
                }
              })
          )
          nextCursor = response.nextCursor
          // Separately saves Unisat and Mystic orders for future use.
          unisatOrders.push(
            ...response.data.filter(
              (t) => t.provider === 'Unisat' && t[desiredAmountField] < desiredAmount
            )
          )
          mysticOrders.push(
            ...response.data.filter(
              (t) => t.provider === 'Mystic' && t[desiredAmountField] < desiredAmount
            )
          )
          for (const item of response.data) {
            // Removes Unisat orders from the iteration since they follow separate logic.
            for (const provider of totalList.filter((t) => t.provider !== 'Unisat')) {
              // Skips orders from aggregate markets other than Mystic Orders,
              // because Mystic Orders can be used in conjunction with any aggregate market.
              if (item.provider !== 'Mystic' && item.provider !== provider.provider) {
                continue
              }

              if (item.placedByAddress === runesAddress.addrString) {
                continue
              }
              if (provider.total >= desiredAmount) {
                break
              }
              // Checks that adding the current order does not exceed @desiredAmount,
              // allowing for a 3% margin of error
              // In case @desiredAmountField equals 'satsAmount', ensures the resulting amount does not exceed the user's BTC balance.
              if (
                provider.total + item[desiredAmountField] > (desiredAmount * 103n) / 100n ||
                (btcBalances &&
                  desiredAmountField === 'satsAmount' &&
                  desiredAmount <= btcBalances.chainBalanceSats &&
                  provider.total + item[desiredAmountField] > btcBalances.chainBalanceSats)
              ) {
                if (desiredAmountField === 'satsAmount') {
                  break
                } else {
                  continue
                }
              }
              provider.total += item[desiredAmountField]
              provider.selectedOrders.push(item)
            }
          }
          iteration++
        } while (
          !totalList.some((t) => t.total >= desiredAmount) &&
          nextCursor &&
          response.data.length > 0 &&
          // (totalList.some((t) => t.total > 0) || iteration < 4) &&
          (!totalList.some((t) => (t.total * 103n) / 100n >= desiredAmount) || iteration < 7)
          // Exits the loop if the accumulated amount is close to @desiredAmount after enough iterations.
          // Specifically, this applies when @total is too near to @desiredAmount for any order to breach the gap.
        )

        // Parse @totalList and add filtered ones to possible solutions
        possibleSolutions.push(
          ...totalList
            .filter((t) => t.selectedOrders.length > 0)
            .map((item) => {
              const runeSum = item.selectedOrders.reduce((sum, t) => sum + t.runesAmount, 0n)
              const satSum = item.selectedOrders.reduce((sum, t) => sum + t.satsAmount, 0n)
              const totalOposite = item.selectedOrders.reduce(
                (sum, t) => sum + t[reverseAmountField],
                0n
              )
              return {
                price: Number(satSum / runeSum) + Number(satSum % runeSum) / Number(runeSum),
                selectedOrders: item.selectedOrders,
                total: item.total,
                totalOposite: totalOposite,
              }
            })
        )

        // Adds a basic sanity check by filtering out orders that cannot be used.
        const desireCheck = mysticOrders.reduce((sum, t) => sum + t[desiredAmountField], 0n)
        // A Unisat order can only be used individually, with the option to add Mystic orders. This leads to this loop.
        for (const unisatOrder of unisatOrders.filter(
          (t) => t[desiredAmountField] + desireCheck >= desiredAmount
        )) {
          let total: bigint = unisatOrder[desiredAmountField]
          const uniSelectOrders: Order[] = [unisatOrder]
          // For each unisat order, we trying to find list of mystic orders that fullfils @desiredAmount
          for (const mysticOrder of mysticOrders) {
            if (total >= desiredAmount) {
              break
            }
            // Checks that adding the current order does not exceed @desiredAmount,
            // allowing for a 3% margin of error
            // In case @desiredAmountField equals 'satsAmount', ensures the resulting amount does not exceed the user's BTC balance.
            if (
              total + mysticOrder[desiredAmountField] > (desiredAmount * 103n) / 100n ||
              (btcBalances &&
                desiredAmountField === 'satsAmount' &&
                desiredAmount <= btcBalances.chainBalanceSats &&
                total + mysticOrder[desiredAmountField] > btcBalances.chainBalanceSats)
            ) {
              if (desiredAmountField === 'satsAmount') {
                break
              } else {
                continue
              }
            }
            total += mysticOrder[desiredAmountField]
            uniSelectOrders.push(mysticOrder)
          }
          if (total >= desiredAmount) {
            const runeSum = uniSelectOrders.reduce((sum, t) => sum + t.runesAmount, 0n)
            const satSum = uniSelectOrders.reduce((sum, t) => sum + t.satsAmount, 0n)
            const totalOposite = uniSelectOrders.reduce((sum, t) => sum + t[reverseAmountField], 0n)
            possibleSolutions.push({
              price: Number(satSum / runeSum) + Number(satSum % runeSum) / Number(runeSum),
              selectedOrders: uniSelectOrders,
              total: total,
              totalOposite: totalOposite,
            })
          }
        }

        // Select best orders based on the best price amongst found solutions
        selectedOrders =
          possibleSolutions.length === 0
            ? []
            : possibleSolutions
                .sort((a, b) => {
                  if (a.totalOposite === b.totalOposite) {
                    return a.price > b.price ? 1 : a.price < b.price ? -1 : 0
                  } else if (a.totalOposite > b.totalOposite) {
                    return reverseAmountField === 'satsAmount' ? 1 : -1
                  } else {
                    return reverseAmountField === 'runesAmount' ? 1 : -1
                  }
                })
                .map((t) => t.selectedOrders)[0]
      }
    } catch (error) {
      console.error('Error getting sell orders', error)
      if (isCurrentCounter()) {
        if (error instanceof ManualError) {
          setErrorMessage(error.message)
        } else {
          switch (error) {
            // placeholder
            case '':
              break
            default:
              setErrorMessage(
                'Something unexpected has gone wrong, please contact support on our Discord'
              )
              break
          }
        }
      }
      return
    } finally {
      setLoadingText(undefined)
    }

    if (!isCurrentCounter()) {
      return
    }

    const newOrderSatsAmount = selectedOrders.reduce((acc, item) => acc + item.satsAmount, 0n)
    const newOrderRunesAmount = selectedOrders.reduce((acc, item) => acc + item.runesAmount, 0n)
    // Change satsAmount and RunesAmount if @selectedOrders is not empty
    if (newOrderSatsAmount !== 0n) {
      setDesiredBtcSellAmount(newOrderSatsAmount)
      setDesiredRunesSellAmount(newOrderRunesAmount)
    } else {
      // if no orders found, set to desired amount
      if (desiredAmountField === 'satsAmount') {
        setDesiredBtcSellAmount(desiredAmount)
      } else if (desiredAmountField === 'runesAmount') {
        setDesiredRunesSellAmount(desiredAmount)
      }
      const warningMessage =
        desiredAmountField === 'satsAmount'
          ? 'No orders found for this BTC amount'
          : 'No orders found for this rune amount'
      setBuyWarningMessage(warningMessage)
    }

    setSelectedSellOrders(selectedOrders)
    setErrorMessage(undefined)
    setLoadingText(undefined)
  }

  async function onSellOrderChange({
    runesAmount = 0n,
    callCounter,
  }: {
    runesAmount?: bigint
    callCounter: number
  }) {
    if (!selectedRune || !runesAddress) return
    if (runesAmount === 0n) return

    function isCurrentCounter() {
      return callCounter === runesCounter.current
    }

    if (!isCurrentCounter()) {
      return // Ignore outdated calls
    }

    reset()
    setLoadingText('Loading rune balance')

    let selectedOutpoints: RuneOutpointDetails[] = []
    try {
      // Fetching available rune outpoints of selected rune, that are not used in other orders
      selectedOutpoints = await getRunesOutpointsForSale({
        runeId: selectedRune.runeId,
        sellAmount: runesAmount,
      })
    } catch (error) {
      console.error('Error getting runes outpoints for sale', error)
      if (isCurrentCounter()) {
        if (error instanceof ManualError) {
          if (!(error.message === 'Insufficient rune funds' && insufficientFundsError)) {
            setErrorMessage(error.message)
          }
        } else {
          switch (error) {
            // placeholder
            case '':
              break
            default:
              setErrorMessage(
                'Something unexpected has gone wrong, please contact support on our Discord'
              )
              break
          }
        }
      }
      return
    } finally {
      setLoadingText(undefined)
    }

    if (!isCurrentCounter()) {
      return
    }

    setErrorMessage(undefined)

    if (desiredSatsPrice && desiredSatsPrice !== 0) {
      updateBtcAmountFromSatsPrice({
        desiredPriceSats: desiredSatsPrice,
        desiredRunesAmount: runesAmount,
      })
    }

    setSelectedRuneOutpoints(selectedOutpoints)
    setErrorMessage(undefined)
    setLoadingText(undefined)
  }

  // on runes amount or selected rune change
  useEffect(() => {
    async function update() {
      try {
        if (!selectedRune || !userEditedRunesAmount) return

        setUserEditedRunesAmount(false)
        if (stateType === 'market') {
          if (isBTConTop) {
            await onBuyOrderChange({
              desiredAmount: debouncedRunesAmount,
              desiredAmountField: 'runesAmount',
              callCounter: runesCounter.current,
              orderType: 'sell',
            })
          } else {
            await onBuyOrderChange({
              desiredAmount: debouncedRunesAmount,
              desiredAmountField: 'runesAmount',
              callCounter: runesCounter.current,
              orderType: 'bid',
            })
          }
        } else if (!isBTConTop) {
          await onSellOrderChange({
            runesAmount: debouncedRunesAmount,
            callCounter: runesCounter.current,
          })
        }
      } catch (error) {
        console.error('Error updating runes amount', error)
      } finally {
        // if disabled will override their desired runes amount if less than what is in a utxo
        setDisableBtcAmount(false)
        setLoadingText(undefined)
      }
    }

    update()
  }, [debouncedRunesAmount, selectedRune])

  // on btc amount change
  useEffect(() => {
    async function update() {
      try {
        if (!selectedRune || !userEditedBtcAmount) return

        setUserEditedBtcAmount(false)

        if (stateType === 'market') {
          if (isBTConTop) {
            await onBuyOrderChange({
              desiredAmount: debouncedBtcAmount,
              desiredAmountField: 'satsAmount',
              callCounter: btcCounter.current,
              orderType: 'sell',
            })
          } else {
            await onBuyOrderChange({
              desiredAmount: debouncedBtcAmount,
              desiredAmountField: 'satsAmount',
              callCounter: btcCounter.current,
              orderType: 'bid',
            })
          }
        }
      } catch (error) {
        console.error('Error updating BTC amount', error)
      } finally {
        setDisableRunesAmount(false)
        setLoadingText(undefined)
      }
    }

    update()
  }, [debouncedBtcAmount, btcCounter, selectedRune])

  function reset(resetMessages: boolean = true) {
    if (resetMessages) {
      setBuyWarningMessage(undefined)
      setErrorMessage(undefined)
    }

    setLoadingText(undefined)
  }
  useEffect(() => {
    if (
      desiredBtcSellAmount &&
      desiredRunesSellAmount &&
      selectedRune &&
      desiredBtcSellAmount > 0 &&
      desiredRunesSellAmount > 0
    ) {
      setDesiredSatsPrice(
        Number(
          calculatePrice({
            satsAmount: desiredBtcSellAmount ?? 0n,
            runesAmount: desiredRunesSellAmount ?? 0n,
            rune: selectedRune,
            format: false,
          })
        )
      )
    }
  }, [desiredBtcSellAmount, desiredRunesSellAmount, selectedRune])

  function onBtcAmountChange(amount: bigint) {
    setLoadingText(undefined)
    setDesiredBtcSellAmount(amount)
    if (isBTConTop && amount === 0n) {
      setBuyWarningMessage(undefined)
    }

    if (!selectedRune || amount === 0n || amount === desiredBtcSellAmount) return

    setUserEditedBtcAmount(true)
    if (isBTConTop) {
      // will not auto update the runes amount if selling, but buys are restricted to the amount of runes on orders
      setDisableRunesAmount(true)
      setLoadingText('Loading')
    }
  }
  // NOTE: This version doesn't do any search for orders due to it has been called only for bid creation
  function onBtcAmountChangeForBidOrder(amount: bigint) {
    setLoadingText(undefined)
    setDesiredBtcSellAmount(amount)
  }

  function onSatsAmountChange(amount?: number) {
    setLoadingText(undefined)
    setDesiredSatsPrice(amount)

    if (!selectedRune || amount === 0 || amount === desiredSatsPrice) return

    setDisableRunesAmount(true)
    setLoadingText('Loading')

    updateBtcAmountFromSatsPrice({
      desiredPriceSats: amount,
      desiredRunesAmount: desiredRunesSellAmount,
    })
  }

  function updateBtcAmountFromSatsPrice({
    desiredPriceSats,
    desiredRunesAmount,
  }: {
    desiredPriceSats?: number | string
    desiredRunesAmount?: bigint
  }) {
    try {
      if (!selectedRune) return

      if (!desiredPriceSats || !desiredRunesAmount) {
        setDesiredBtcSellAmount(undefined)
        return
      }

      const totalBtc: bigint = calculateTotalSatsFromDesiredPrice({
        desiredPriceSats,
        desiredRunesAmount,
        rune: selectedRune,
      })
      setDesiredBtcSellAmount(totalBtc)
    } catch (error) {
      console.error('Error updating BTC amount from sats price', error)
    } finally {
      setDisableRunesAmount(false)
      setLoadingText(undefined)
    }
  }

  function onRunesAmountChange(amount: bigint) {
    setLoadingText(undefined)
    setDesiredRunesSellAmount(amount)

    if (!isBTConTop && amount === 0n) {
      setSelectedRuneOutpoints([])
      setBuyWarningMessage(undefined)
    }

    if (!selectedRune || amount === 0n || amount === desiredRunesSellAmount) return

    setUserEditedRunesAmount(true)
    if (!isBTConTop) {
      setDisableBtcAmount(true)
      setLoadingText('Loading')
    }
  }

  // NOTE: This version doesn't do any search for orders due to it has been called only for bid creation
  function onRunesAmountChangeForBidOrder(amount: bigint) {
    setLoadingText(undefined)
    setDesiredRunesSellAmount(amount)
  }

  const handleSwap = useCallback(
    function handleSwap() {
      if (!selectedRune) {
        return
      }

      if (isBTConTop) {
        openBuyDrawer()
      } else {
        openSellDrawer()
      }
    },
    [isBTConTop, openBuyDrawer, openSellDrawer, selectedRune]
  )

  const handlePlaceBidOrder = useCallback(
    function handlePlaceBidOrder() {
      if (!selectedRune) {
        return
      }
      setDesiredBtcSellAmount(desiredBtcSellAmount)
      setDesiredRunesSellAmount(desiredRunesSellAmount)

      if (isBTConTop) {
        openPlaceBidDrawer()
      }
    },
    [
      desiredBtcSellAmount,
      desiredRunesSellAmount,
      isBTConTop,
      openPlaceBidDrawer,
      selectedRune,
      setDesiredBtcSellAmount,
      setDesiredRunesSellAmount,
    ]
  )

  const handleBidOrderSwap = useCallback(
    function handleBidOrderSwap() {
      if (!selectedRune) {
        return
      }
      setDesiredBtcSellAmount(desiredBtcSellAmount)
      setDesiredRunesSellAmount(desiredRunesSellAmount)

      if (isBidOrderBuying) {
        openBuyBidOrderDrawer()
      }
    },
    [
      desiredBtcSellAmount,
      desiredRunesSellAmount,
      isBidOrderBuying,
      openBuyBidOrderDrawer,
      selectedRune,
      setDesiredBtcSellAmount,
      setDesiredRunesSellAmount,
    ]
  )

  async function onBuySellSwitch() {
    reset()
    setIsBTConTop(!isBTConTop)

    if (stateType === 'market') {
      // If one of the desiredAmounts were not empty, we will try to recalculate selected order list
      // based on bid/sell orders priorities (satsAmount for Sell orders and runesAmount for Bid orders)
      if (!isBTConTop) {
        await onBuyOrderChange({
          desiredAmount:
            desiredBtcSellAmount && desiredBtcSellAmount > 0
              ? desiredBtcSellAmount
              : desiredRunesSellAmount,
          desiredAmountField:
            desiredBtcSellAmount && desiredBtcSellAmount > 0 ? 'satsAmount' : 'runesAmount',
          callCounter:
            desiredBtcSellAmount && desiredBtcSellAmount > 0
              ? btcCounter.current
              : runesCounter.current,
          orderType: 'sell',
        })
      } else {
        await onBuyOrderChange({
          desiredAmount:
            desiredRunesSellAmount && desiredRunesSellAmount > 0
              ? desiredRunesSellAmount
              : desiredBtcSellAmount,
          desiredAmountField:
            desiredRunesSellAmount && desiredRunesSellAmount > 0 ? 'runesAmount' : 'satsAmount',

          callCounter:
            desiredRunesSellAmount && desiredRunesSellAmount > 0
              ? runesCounter.current
              : btcCounter.current,
          orderType: 'bid',
        })
      }
    } else if (isBTConTop) {
      setUserEditedRunesAmount(true)
      await onSellOrderChange({
        runesAmount: desiredRunesSellAmount,
        callCounter: runesCounter.current,
      })
    }
  }

  async function onRuneMaxButtonClick() {
    if (selectedRuneAvailableBalance) {
      if (desiredRunesSellAmount !== selectedRuneAvailableBalance) {
        onRunesAmountChange(selectedRuneAvailableBalance)
      }
    }
  }

  async function onBtcMaxButtonClick() {
    if (btcBalances) {
      if (isBidOrderCreation) {
        // Use escrow wallet balance
        onBtcAmountChangeForBidOrder(availableEscrowWalletBalance)
      } else {
        onBtcAmountChange(btcBalances.chainBalanceSats)
      }
    }
  }

  async function onUseFloorPriceClick() {
    setIsUsingFloorPrice(!isUsingFloorPrice)
  }

  async function onUseFloorPriceClickForBidOrders() {
    setIsUsingFloorPriceForBidOrders(!isUsingFloorPriceForBidOrders)
  }

  useEffect(() => {
    if (!selectedRune || !selectedRuneMarket) return

    if (!desiredBtcSellAmount || desiredBtcSellAmount == 0n) return
    if (isUsingFloorPriceForBidOrders) {
      const satsAmount = calculateTotalSatsFromDesiredTotal({
        desiredPriceSats: selectedRuneMarket.floorPriceSats,
        desiredTotalSats: desiredBtcSellAmount,
        rune: selectedRune,
      })

      setDesiredRunesSellAmount(satsAmount)
    }
  }, [isUsingFloorPriceForBidOrders, desiredBtcSellAmount, selectedRuneMarket?.floorPriceSats])

  function handleOnSelectedRuneChange(rune: Rune) {
    setSelectedRune(rune)
    setIsUsingFloorPrice(false)
    setDesiredSatsPrice(undefined)

    setDesiredRunesSellAmount(undefined)
    setDesiredBtcSellAmount(undefined)

    setBuyWarningMessage(undefined)
    setErrorMessage(undefined)
  }

  function onSelectRuneModalClose(rune: Rune | undefined) {
    setIsSelectRuneModalOpen(false)
    if (rune) {
      const runeName = isMobile
        ? rune.runeName
        : formatRuneName({
            runeName: rune.runeName,
            runeNameSpacerIndices: rune.runeNameSpacerIndices,
          })
      navigate({
        pathname: buildMarketDetailsUrl(runeName),
        search: concatSearchParams(
          buildSwapToolSideSearchParam(isBTConTop ? 'buy' : 'sell'),
          buildRuneToolsTabSearchParam('swap')
        ).toString(),
      })
      setDesiredRunesSellAmount(undefined)
      setDesiredBtcSellAmount(undefined)
    }
  }

  const submitButtonText = loadingText ?? isBTConTop ? 'Swap' : 'Place Order'
  const sellAmountIsBelowDustThreshold = !!(
    desiredBtcSellAmount && desiredBtcSellAmount < SATOSHI_DUST_THRESHOLD
  )
  const sellAmountIsBelowBidOrderThreshold =
    stateType === 'limit' &&
    isBTConTop &&
    !!(desiredBtcSellAmount && desiredBtcSellAmount < BID_ORDER_MINIMUM_TOTAL_PRICE)
  const insufficientFundsError: string | undefined =
    desiredRunesSellAmount && desiredRunesSellAmount > selectedRuneAvailableBalance
      ? 'Insufficient rune funds'
      : undefined

  const btcTotalIsZero = stateType === 'limit' && desiredBtcSellAmount === 0n
  const isSubmitLoading = disableBtcAmount || disableRunesAmount || !!loadingText
  const notEnoughBalance =
    btcBalances && desiredBtcSellAmount && isBTConTop && stateType === 'market'
      ? btcBalances.chainBalanceSats < desiredBtcSellAmount
      : false

  const isSubmitDisabled =
    sellAmountIsBelowDustThreshold ||
    runesAmountIsNotOutpointAmount ||
    notEnoughBalance ||
    !runesAddress ||
    !paymentAddress ||
    !!errorMessage ||
    !selectedRune ||
    btcTotalIsZero ||
    !desiredBtcSellAmount ||
    desiredRunesSellAmount === 0n ||
    !desiredRunesSellAmount ||
    (isBTConTop && selectedSellOrders.length === 0) ||
    (!isBTConTop && selectedRuneOutpoints.length === 0) ||
    disabled

  const isPlaceBidOrderDisabled =
    sellAmountIsBelowDustThreshold ||
    sellAmountIsBelowBidOrderThreshold ||
    !runesAddress ||
    !paymentAddress ||
    !!errorMessage ||
    !selectedRune ||
    !desiredBtcSellAmount ||
    desiredRunesSellAmount === 0n ||
    !desiredRunesSellAmount ||
    notEnoughBtcOnEscrowWallet ||
    !expiresIn ||
    disabled

  const isBidOrderSwapDisabled =
    sellAmountIsBelowDustThreshold ||
    !runesAddress ||
    !paymentAddress ||
    !!errorMessage ||
    !selectedRune ||
    btcTotalIsZero ||
    !desiredBtcSellAmount ||
    desiredRunesSellAmount === 0n ||
    !desiredRunesSellAmount ||
    selectedSellOrders.length === 0 ||
    desiredRunesSellAmount > selectedRuneAvailableBalance ||
    disabled

  // Is the user input resulting in an order where the floor price is 0
  const isPriceTooLow =
    !!desiredBtcSellAmount &&
    desiredBtcSellAmount > 0n &&
    !!desiredRunesSellAmount &&
    desiredRunesSellAmount > 0n &&
    orderSummary?.priceSats == '0'

  // Is the user input resulting in an order where the price is 50% higher than floor or vice versa.
  const isFloorPriceMismatched =
    !isPriceTooLow &&
    !!desiredBtcSellAmount &&
    desiredBtcSellAmount > 0n &&
    !!desiredRunesSellAmount &&
    desiredRunesSellAmount > 0n &&
    (parsePriceStr(orderSummary?.priceSats) >
      parsePriceStr(selectedRuneMarket?.floorPriceSats) * 1.5 ||
      parsePriceStr(orderSummary?.priceSats) * 1.5 <
        parsePriceStr(selectedRuneMarket?.floorPriceSats))

  const disableInputs = disabled || !runesAddress || !paymentAddress
  const showBtcTotal = isUsingBtcTotal

  useEffect(() => {
    if (stateType === 'market' && orderSummary && isBTConTop && !desiredBtcSellAmount) {
      setDesiredSatsPrice(parsePriceStr(orderSummary?.priceSats))
    }
  }, [orderSummary, isBTConTop, desiredBtcSellAmount])

  useEffect(
    function showRuneAmountIsNotOutpointAmount() {
      if (!selectedRune) {
        setRunesAmountIsNotOutpointAmount(false)
        return
      }

      if (!debouncedRunesAmount) {
        setRunesAmountIsNotOutpointAmount(false)
        return
      }

      if (isBidOrderCreation) {
        setRunesAmountIsNotOutpointAmount(false)
        return
      }

      if (isBidOrderBuying) {
        setRunesAmountIsNotOutpointAmount(false)
        return
      }

      if (isBTConTop) {
        setRunesAmountIsNotOutpointAmount(false)
        return
      }

      // Rune amount is not outpoint amount error should be shown only when the user is selling runes
      const runeAmountIsNotOutpointAmount: boolean =
        debouncedRunesAmount !== selectedRuneOutpoints.reduce((acc, val) => acc + val.amount, 0n)

      setRunesAmountIsNotOutpointAmount(runeAmountIsNotOutpointAmount)
    },
    [
      isBTConTop,
      debouncedRunesAmount,
      isSubmitDisabled,
      isSubmitLoading,
      selectedRune,
      selectedRuneOutpoints,
      selectedSellOrders.length,
      isBidOrderCreation,
      isBidOrderBuying,
    ]
  )

  const handleFloorPriceWarningCheckboxChange = () => {
    setIsFloorPriceWarningChecked(!isFloorPriceWarningChecked)
  }

  const submitButton = useMemo(() => {
    if (isBidOrderCreation) {
      return (
        <SubmitButton
          onClick={handlePlaceBidOrder}
          disabled={
            isPlaceBidOrderDisabled || (isFloorPriceMismatched && !isFloorPriceWarningChecked)
          }
          loading={isSubmitLoading}
        >
          {loadingText ?? 'Place Order'}
        </SubmitButton>
      )
    }

    if (isBidOrderBuying) {
      return (
        <SubmitButton
          onClick={handleBidOrderSwap}
          disabled={
            isBidOrderSwapDisabled || (isFloorPriceMismatched && !isFloorPriceWarningChecked)
          }
          loading={isSubmitLoading}
        >
          {loadingText ?? 'Swap'}
        </SubmitButton>
      )
    }

    return (
      <SubmitButton
        onClick={handleSwap}
        disabled={isSubmitDisabled || (isFloorPriceMismatched && !isFloorPriceWarningChecked)}
        loading={isSubmitLoading}
      >
        {submitButtonText}
      </SubmitButton>
    )
  }, [
    handleBidOrderSwap,
    handlePlaceBidOrder,
    handleSwap,
    isBidOrderBuying,
    isBidOrderCreation,
    isBidOrderSwapDisabled,
    isPlaceBidOrderDisabled,
    isSubmitDisabled,
    isSubmitLoading,
    loadingText,
    submitButtonText,
    isFloorPriceWarningChecked,
    isFloorPriceMismatched,
  ])

  return (
    <Container>
      <Inputs $isBTConTop={isBTConTop}>
        <Input
          onClick={() => {
            btcInputRef?.current?.focus()
          }}
        >
          <InputContainer>
            <InputLabel>
              {isBTConTop
                ? isUsingBtcTotal
                  ? 'Sell'
                  : 'Price'
                : isUsingBtcTotal
                  ? 'Buy'
                  : 'Price'}
            </InputLabel>
            {showBtcTotal ? (
              <BtcInput
                value={desiredBtcSellAmount}
                disabled={disableBtcAmount || disableInputs || (!isBTConTop && isUsingFloorPrice)}
                onChange={isBidOrderCreation ? onBtcAmountChangeForBidOrder : onBtcAmountChange}
                decimals={BTC_DECIMALS}
                inputRef={btcInputRef}
              />
            ) : (
              // Price
              <SatsInput
                value={desiredSatsPrice}
                disabled={disableBtcAmount || disableInputs || (!isBTConTop && isUsingFloorPrice)}
                placeholder='0'
                onChange={onSatsAmountChange}
                inputRef={btcInputRef}
                suffix={formatPriceSymbol({ runeSymbol: selectedRune?.runeSymbolChar })}
              />
            )}

            {/* Floor price is not shown when bid order is buying */}
            {!isBTConTop && !isBidOrderBuying && (
              <FloorPriceContainer>
                <FloorPriceButton
                  onClick={onUseFloorPriceClick}
                  selected={isUsingFloorPrice}
                  disabled={
                    parsePriceStr(selectedRuneMarket?.floorPriceSats ?? '0') === 0 ||
                    (desiredRunesSellAmount === 0n && isUsingBtcTotal) ||
                    disableInputs ||
                    disableBtcAmount ||
                    isLoadingNewRune
                  }
                >
                  {isUsingFloorPrice ? 'Using Floor Price' : 'Use Floor Price'}
                </FloorPriceButton>
                {isUsingFloorPrice && (
                  <FloorPriceCloseX onClick={onUseFloorPriceClick}>×</FloorPriceCloseX>
                )}
              </FloorPriceContainer>
            )}
          </InputContainer>
          <BtcDetailsContainer>
            {stateType === 'market' ? (
              <BtcButton>BTC</BtcButton>
            ) : (
              <SelectBtcButton
                label={''}
                options={[
                  { value: 'btc', label: 'Total BTC' },
                  {
                    value: 'sats',
                    label: 'Price',
                  },
                ]}
                value={showBtcTotal ? 'btc' : 'sats'}
                onChange={function (value: string): void {
                  setIsUsingBtcTotal(value === 'btc')
                }}
              />
            )}

            {!useEscrowWallet && btcBalances && (
              <DetailsInfo>
                <DetailsInfoLabel>{'Available: '}</DetailsInfoLabel>{' '}
                {btcBalances && formatBtc(btcBalances.chainBalanceSats)}
                {isBTConTop && (
                  <DetailsButton
                    onClick={onBtcMaxButtonClick}
                    disabled={btcBalances.chainBalanceSats === 0n || isLoadingNewRune}
                  >
                    Max
                  </DetailsButton>
                )}
              </DetailsInfo>
            )}
            {useEscrowWallet && (
              <DetailsInfo>
                <DetailsInfoLabel>Available on Bidding: </DetailsInfoLabel>
                {formatBtc(availableEscrowWalletBalance)}
                {isBTConTop && (
                  <DetailsButton
                    onClick={onBtcMaxButtonClick}
                    disabled={availableEscrowWalletBalance === 0n || isLoadingNewRune}
                  >
                    Max
                  </DetailsButton>
                )}
              </DetailsInfo>
            )}
          </BtcDetailsContainer>
        </Input>
        <ArrowIconContainer
          onClick={onBuySellSwitch}
          onMouseEnter={() => setIsSwapButtonHovered(true)}
          onMouseLeave={() => setIsSwapButtonHovered(false)}
        >
          {isSwapButtonHovered ? <SwapIcon /> : <ArrowIcon />}
        </ArrowIconContainer>
        <Input onClick={() => runeInputRef?.current?.focus()}>
          <InputContainer>
            <InputLabel>{isBTConTop ? 'Buy' : 'Sell'}</InputLabel>
            <RuneInput
              value={desiredRunesSellAmount}
              disabled={disableRunesAmount || disableInputs || isUsingFloorPriceForBidOrders}
              onChange={isBidOrderCreation ? onRunesAmountChangeForBidOrder : onRunesAmountChange}
              decimals={selectedRune?.runeDecimals ?? 0}
              inputRef={runeInputRef}
            />
            {isBidOrderCreation && (
              <FloorPriceContainer>
                <FloorPriceButton
                  onClick={onUseFloorPriceClickForBidOrders}
                  selected={isUsingFloorPriceForBidOrders}
                  disabled={
                    !desiredBtcSellAmount ||
                    desiredBtcSellAmount == 0n ||
                    parsePriceStr(selectedRuneMarket?.floorPriceSats ?? '0') === 0
                  }
                >
                  {isUsingFloorPriceForBidOrders ? 'Using Floor Price' : 'Use Floor Price'}
                </FloorPriceButton>
              </FloorPriceContainer>
            )}
          </InputContainer>
          <DetailsContainer>
            <RuneSelectButton
              onClick={() => setIsSelectRuneModalOpen(true)}
              selectedRune={selectedRune}
              selectedRuneName={selectedRuneName}
              isLoadingNewRune={isLoadingNewRune}
            />

            {!isBidOrderCreation && (
              <DetailsInfo>
                <DetailsInfoLabel>Available: </DetailsInfoLabel>
                {selectedRune && !isLoadingNewRune
                  ? formatRunesAmount({
                      rune: selectedRune,
                      runesAmount: selectedRuneAvailableBalance ?? 0n,
                    })
                  : 0}
                {!isBTConTop && (
                  <DetailsButton
                    onClick={onRuneMaxButtonClick}
                    disabled={
                      !selectedRuneAvailableBalance ||
                      selectedRuneAvailableBalance === 0n ||
                      isLoadingNewRune
                    }
                  >
                    Max
                  </DetailsButton>
                )}
              </DetailsInfo>
            )}
          </DetailsContainer>
        </Input>
      </Inputs>

      <PriceDetails>
        {showBtcTotal ? (
          <PriceDetailsText>
            <PriceSatsDisplay
              priceSats={orderSummary?.priceSats}
              runeSymbol={selectedRune?.runeSymbolChar}
            />{' '}
            <PriceDetailsLabel>
              (<PriceUsdDisplay priceSats={orderSummary?.priceSats} />)
            </PriceDetailsLabel>
          </PriceDetailsText>
        ) : (
          <PriceDetailsText>
            <RuneSymbolDisplay runeSymbol={BTC_PRICE_SYMBOL} />{' '}
            {formatBtc(orderSummary?.totalSats ?? 0n)} <PriceDetailsLabel>Total</PriceDetailsLabel>
          </PriceDetailsText>
        )}
        <FeeRate>
          {recommendedNetworkFees?.fastestFee} sat/vB{' '}
          <PriceDetailsLabel>Fee Rate</PriceDetailsLabel>
        </FeeRate>
      </PriceDetails>

      {!isBidOrderBuying && runesAmountIsNotOutpointAmount && (
        <ErrorMessage message='Unable to partially list runes' />
      )}

      {isBidOrderBuying && insufficientFundsError && (
        <ErrorMessage message={insufficientFundsError} />
      )}
      {notEnoughBalance && btcBalances && (
        <ErrorMessage
          message={`Not enough BTC balance. Available: 
                    ${formatBtc(btcBalances.chainBalanceSats)}
                    ${BTC_PRICE_SYMBOL}`}
        />
      )}
      {sellAmountIsBelowDustThreshold && (
        <ErrorMessage message='Sell amount is below dust threshold' />
      )}
      {sellAmountIsBelowBidOrderThreshold && (
        <ErrorMessage
          message={`Sell amount must be at least ${BID_ORDER_MINIMUM_TOTAL_PRICE_BTC} BTC for bid orders`}
        />
      )}

      {isPriceTooLow && <WarningMessage message='Order price is 0' />}

      {orderSummary && selectedRuneMarket && isFloorPriceMismatched && (
        <>
          <WarningMessage
            message={`Price of ${stateType === 'market' ? 'swap' : 'order'} is ${
              parsePriceStr(orderSummary?.priceSats) >
              parsePriceStr(selectedRuneMarket?.floorPriceSats)
                ? `${calculatePriceDifference(
                    orderSummary?.priceSats,
                    selectedRuneMarket?.floorPriceSats,
                    4
                  )} higher`
                : `${calculatePriceDifference(
                    selectedRuneMarket?.floorPriceSats,
                    orderSummary?.priceSats,
                    4
                  )} lower`
            } than the floor price`}
          />
          <AdvancedOptions>
            I understand, proceed with trading.
            <Checkbox
              checked={isFloorPriceWarningChecked}
              onChange={handleFloorPriceWarningCheckboxChange}
            ></Checkbox>
          </AdvancedOptions>
        </>
      )}
      {notEnoughBtcOnEscrowWallet && (
        <NotEnoughBtcError
          hideIcon={true}
          message={'Not enough BTC.'}
          linkElement={
            <InternalLink
              text={'Top up Bidding wallet'}
              to={{
                pathname: generatePath(ROUTES.accountDetails, {
                  address: runesAddress?.addrString ?? '',
                }),
                search: concatSearchParams(
                  buildRuneToolsTabSearchParam('transfer'),
                  buildAccountDetailsWalletTabSearchParam(SWITCH_ACCOUNTS_TABS.escrow)
                ).toString(),
              }}
            />
          }
        />
      )}

      {btcTotalIsZero && !isBTConTop && <ErrorMessage message={'BTC sell total is zero'} />}
      {errorMessage && <ErrorMessage message={errorMessage} />}
      {stateType === 'market' && buyWarningMessage && (
        <WarningMessage message={buyWarningMessage} />
      )}

      {/* Extra info to insert into RuneSwapper */}
      {children}

      {/* {infoAlert && <InfoMessage message={infoAlert} />} */}
      {runesAddress && paymentAddress ? (
        submitButton
      ) : (
        <ConnectButton onClick={() => setIsWalletMenuOpen(true)}>Connect Wallet</ConnectButton>
      )}

      <SelectRuneModal
        isOpen={isSelectRuneModalOpen}
        onSelectRune={handleOnSelectedRuneChange}
        onClose={onSelectRuneModalClose}
      />
      <WalletPickerMenu isOpen={isWalletMenuOpen} onClose={() => setIsWalletMenuOpen(false)} />
    </Container>
  )
}

const AdvancedOptions = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  span {
    padding: 0px;
    width: 35px;
  }
`

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`

const MAX_WIDTH_INPUTS = 600

const Inputs = styled.div<{ $isBTConTop: boolean }>`
  font-weight: 700;
  width: 100%;
  max-width: ${MAX_WIDTH_INPUTS}px;
  border-radius: 15px;
  gap: 5px;
  display: flex;
  flex-direction: ${(props) => (props.$isBTConTop ? 'column' : 'column-reverse')};
`

const Input = styled.div`
  cursor: text;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: 1px solid ${COLORS.white};
  border-radius: 15px;
  padding: 20px;
  background-color: ${COLORS.background.container} !important;

  @media (max-width: ${BREAKPOINTS.medium}) {
    padding: 15px;
  }
`

const INPUT_WIDTH = 500

const InputContainer = styled.div`
  background-color: transparent;
  width: ${INPUT_WIDTH}px;
  text-transform: uppercase;

  @media (max-width: ${BREAKPOINTS.medium}) {
    display: flex;
    flex-direction: column;
    gap: 7px;
  }
`

const InputLabel = styled.div`
  font-weight: 700;
  padding-left: 10px;
  color: ${COLORS.labels.primary};
  @media (max-width: ${BREAKPOINTS.medium}) {
    padding-left: 0px;
  }
`

const InputStyles = css`
  color: ${COLORS.white};

  &:focus {
    border: none;
  }
  input {
    width: 100%;
    &:focus {
      outline: none !important;
      border: none;
    }
    border: none !important;
    background-color: transparent !important;
    color: ${COLORS.white};
    font-weight: 500;
    font-family: ${FONTS.text};
    font-size: 32px;
    padding: 0 10px 10px 10px;

    @media (max-width: ${BREAKPOINTS.medium}) {
      padding-left: 0;
    }
  }

  div {
    p {
      color: ${hexToRgb(COLORS.white, 0.6)};
    }
  }
`

const BigIntInputWrapper = styled(BigIntInput)`
  ${InputStyles}
`

const BtcInput = styled(BigIntInputWrapper)`
  span {
    margin-left: 10px;
    height: 100%;
    min-height: 50px;
  }
`

const SatsInput = styled(NumberInputWithSuffix)`
  ${InputStyles}
  span {
    padding-top: 0px;
    padding-bottom: 15px;
    @media (max-width: ${BREAKPOINTS.medium}) {
      padding-left: 0px;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
  fieldset {
    border: none;
  }
`

const RuneInput = styled(BigIntInputWrapper)`
  @media (max-width: ${BREAKPOINTS.medium}) {
    padding-bottom: 10px;
  }
`

const ArrowIconContainer = styled.div`
  border-radius: 100px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  background-color: ${COLORS.buttons.primary};
  outline: solid 2px ${COLORS.background.overlay};
  position: relative;
  left: 48%;
  top: -16px;
  margin-bottom: -37px;
  z-index: ${Z_INDEX.priorityContent};
  cursor: pointer;

  &:hover {
    background-color: ${COLORS.link};
  }

  @media (max-width: ${BREAKPOINTS.small}) {
    left: 46%;
  }
`

const ArrowIcon = styled(SwapInputIcon)`
  font-size: 2rem;
  color: ${COLORS.black};
`

const SwapIcon = styled(SwapCallsIcon)`
  margin-top: 0.25rem;
  font-size: 1.5rem;
  color: ${COLORS.black};
`

const BtcButton = styled.div`
  padding: 5px 10px;
  border-radius: 5px;
  color: ${COLORS.white};
  background-color: ${hexToRgb(COLORS.white, 0.3)};
  width: fit-content;
  font-size: 14px;
`

const SelectBtcButton = styled(Select)`
  height: ${SELECT_BUTTON_HEIGHT}px;
  div {
    @media (max-width: ${BREAKPOINTS.medium}) {
      font-size: 13px;
    }
  }
`

const DetailsContainer = styled.div`
  min-width: 200px;
  max-width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: end;
  margin: auto;
  width: 100%;
  height: 100%;
  justify-content: start;
  text-transform: uppercase;

  @media (max-width: ${BREAKPOINTS.medium}) {
    gap: 25px;
  }
`

const BtcDetailsContainer = styled(DetailsContainer)`
  min-width: auto;
`

const DetailsInfo = styled.div`
  color: ${hexToRgb(COLORS.white, 0.7)};
  font-weight: 500;
  font-size: 14px;
  display: flex;
  gap: 5px;
  justify-content: end;
  padding-top: 10px;
  width: 100%;
  white-space: nowrap;
`

const DetailsInfoLabel = styled.span`
  color: ${COLORS.labels.secondary};
`

const FloorPriceCloseX = styled.div`
  position: absolute;
  background-color: ${COLORS.negative};
  border-radius: 100px;
  margin-left: -17px;
  color: ${COLORS.white};
  font-size: 20px;
  line-height: 1;
  width: 20px;
  text-align: center;
  opacity: 0;
  ${DEFAULT_CSS_TRANSITION}
`

const FloorPriceContainer = styled(DetailsInfo)`
  margin-top: -20px;
  justify-content: flex-start;
  padding-left: 10px;
  @media (max-width: ${BREAKPOINTS.medium}) {
    padding-left: 5px;
  }
`

const DetailsButton = styled.div<{ disabled?: boolean }>`
  ${BUTTON_HOVER_STYLES}
  color: ${COLORS.link};
  font-weight: 700;
  width: fit-content;
  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      cursor: none;
      color: ${hexToRgb(COLORS.white, 0.7)};
      &:hover {
        opacity: 1;
      }
    `}
`

const FloorPriceButton = styled(DetailsButton)<{ selected: boolean }>`
  ${DEFAULT_CSS_TRANSITION}
  border-radius: 3px;
  color: ${({ selected }) => (selected ? COLORS.labels.primary : COLORS.link)};
  ${(props) =>
    props.selected &&
    css`
      background-color: ${COLORS.hover};
      color: ${COLORS.labels.primary};
      padding: 0px 5px;
    `}
  ${(props) =>
    props.disabled &&
    css`
      color: ${hexToRgb(COLORS.white, 0.7)};
    `}

    &:hover {
    & + ${FloorPriceCloseX} {
      cursor: pointer;
      opacity: 1;
    }
  }
`

const SubmitButton = styled(Button)`
  width: 100%;
  max-width: 250px;
  padding: 8px 12px;
`

const ConnectButton = styled(Button)`
  width: 100%;
  max-width: 250px;
`

const PriceDetails = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  max-width: ${MAX_WIDTH_INPUTS}px;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  justify-content: space-between;
  @media (max-width: ${BREAKPOINTS.medium}) {
    justify-content: center;
  }
`

const PriceDetailsText = styled.div`
  display: flex;
  gap: 5px;
`

const FeeRate = styled.div`
  display: flex;
  gap: 5px;
  @media (max-width: ${BREAKPOINTS.medium}) {
    display: none;
  }
`

const PriceDetailsLabel = styled.div`
  color: ${COLORS.labels.secondary};
`

const NotEnoughBtcError = styled(ErrorMessage)`
  width: 100%;
  padding: 10px 15px;
  // NOTE: Commented now to match the current design
  //border-radius: 14px;

  & .MuiAlert-message {
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`

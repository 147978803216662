import { useMemo, useState } from 'react'
import { styled } from 'styled-components'

import { formatUsdPrice, satsToBtc } from '@packages/utils'
import { BTC_DECIMALS } from '@packages/constants'

import { SubheaderText, TabPanelContentContainer, type TabPanelProps } from 'src/shared/components'
import {
  BUTTON_HOVER_STYLES,
  BigIntInput,
  DrawerButtons,
  DrawerCard,
  DrawerColumn,
  DrawerConfirmButton,
  DrawerRow,
  LargeButtonGroup,
} from 'src/shared/components'
import { BREAKPOINTS, COLORS } from 'src/shared/constants'
import { useEscrowWalletContext, useWalletContext } from 'src/wallet'
import { Action, useEscrowTransferContext } from 'src/runes/EscrowTransferContext'

const ACTIONS = {
  deposit: 'deposit',
  withdraw: 'withdraw',
}

const actionTabs = [
  {
    label: ACTIONS.deposit.toUpperCase(),
    value: ACTIONS.deposit,
  },
  {
    label: ACTIONS.withdraw.toUpperCase(),
    value: ACTIONS.withdraw,
  },
]

export function EscrowTransferTabPanel({ visible }: TabPanelProps) {
  const { action, setAction, quantity, setQuantity, openConfirmDrawer } = useEscrowTransferContext()
  const { btcBalance, lockedBtcBalance } = useEscrowWalletContext()

  const { btcPrice, btcBalances } = useWalletContext()
  const [quantityError, setQuantityError] = useState<string>()

  const availableBalance = useMemo(() => {
    return action === ACTIONS.deposit
      ? btcBalances?.chainBalanceSats ?? 0
      : (btcBalance ?? 0n) - (lockedBtcBalance ?? 0n)
  }, [btcBalance, lockedBtcBalance, btcBalances, action])

  function onMaxButtonClick() {
    setQuantity(BigInt(availableBalance))
  }

  function onQuantityChange(value: bigint) {
    setQuantity(value)

    if (value > availableBalance) {
      setQuantityError('Insufficient balance')
    } else if (value < 0n) {
      setQuantityError('Should not be lower than 0')
    } else if (value === 0n) {
      setQuantityError('Required')
    } else {
      setQuantityError(undefined)
    }
  }

  const disabled = useMemo(() => {
    return !!quantityError || !quantity
  }, [quantity, quantityError])

  return (
    <TabPanelContentContainer $visible={visible}>
      <SubheaderText>
        Top up or withdraw from your bidding account using your connected wallet. These funds will be
        available to use for bid orders once the transaction has been confirmed on-chain!
      </SubheaderText>
      <CardsContainer>
        <DrawerCard>
          <LargeButtonGroupWrapper
            options={actionTabs}
            value={action}
            onChange={(val) => {
              setAction(val as Action)
              setQuantityError(undefined)
            }}
            exclusive
            updateSearchParams={false}
          />
        </DrawerCard>

        {action === ACTIONS.withdraw ? (
          <DrawerCard>
            <BalanceRow>
              <DrawerColumn>Total Bidding Balance</DrawerColumn>
              <BalanceColumn>
                <div>
                  <BalanceValue>{satsToBtc(btcBalance ?? 0n)}</BalanceValue> <span>BTC</span>
                </div>
                <div>
                  {formatUsdPrice({
                    price:
                      btcPrice && btcBalance ? btcPrice.btcPriceUsd * satsToBtc(btcBalance) : '0',
                  })}
                </div>
              </BalanceColumn>
            </BalanceRow>

            <BalanceRow>
              <DrawerColumn>Available Bidding Balance</DrawerColumn>
              <BalanceColumn>
                <div>
                  <BalanceValue>{satsToBtc(availableBalance)}</BalanceValue> <span>BTC</span>
                </div>
                <div>
                  {formatUsdPrice({
                    price: btcPrice ? btcPrice.btcPriceUsd * satsToBtc(availableBalance) : '0',
                  })}
                </div>
                <MaxButton onClick={onMaxButtonClick}>MAX</MaxButton>
              </BalanceColumn>
            </BalanceRow>
          </DrawerCard>
        ) : (
          <DrawerCard>
            <BalanceRow>
              <DrawerColumn>Total Balance</DrawerColumn>
              <BalanceColumn>
                <div>
                  <BalanceValue>{btcBalances?.chainBalanceBtc ?? 0}</BalanceValue> <span>BTC</span>
                </div>
                <div>
                  {formatUsdPrice({
                    price:
                      btcPrice && btcBalances?.chainBalanceBtc
                        ? btcPrice.btcPriceUsd * btcBalances?.chainBalanceBtc
                        : '0',
                  })}
                </div>
              </BalanceColumn>
            </BalanceRow>
          </DrawerCard>
        )}

        <DrawerCard>
          <DrawerRow>Quantity</DrawerRow>
          <DrawerRow>
            <BigIntInput
              value={quantity}
              onChange={onQuantityChange}
              decimals={BTC_DECIMALS}
              helperText={quantityError ?? undefined}
            />
          </DrawerRow>
        </DrawerCard>

        <DrawerButtons>
          <TransferButton disabled={disabled} onClick={openConfirmDrawer}>
            Confirm {action}
          </TransferButton>
        </DrawerButtons>
      </CardsContainer>
    </TabPanelContentContainer>
  )
}

const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 20px;
`

const TransferButton = styled(DrawerConfirmButton)`
  padding: 9px 31px !important;

  @media (max-width: ${BREAKPOINTS.medium}) {
    width: 100%;
  }
`

const BalanceValue = styled.span``

const MaxButton = styled.div<{ disabled?: boolean }>`
  ${BUTTON_HOVER_STYLES}
  font-weight: 700;
`

const BalanceRow = styled(DrawerRow)`
  gap: 15px;

  div,
  span {
    font-size: 14px;
    font-weight: 500;
    color: ${COLORS.labels.secondary};
  }

  ${BalanceValue} {
    color: ${COLORS.labels.primary};
  }

  ${MaxButton} {
    color: ${COLORS.labels.active};
  }

  > div {
    justify-content: flex-start;
  }

  @media (max-width: ${BREAKPOINTS.medium}) {
    flex-direction: row;
  }
`

const BalanceColumn = styled(DrawerColumn)`
  flex-direction: column;
  gap: unset;
`

const LargeButtonGroupWrapper = styled(LargeButtonGroup)`
  gap: 10px;
  button {
    font-size: 14px;
    flex: 1;
    height: 30px;
    border-radius: 12px;

    &:not([disabled]) {
      background: unset;
      border: 1px solid ${COLORS.buttons.primary};
      color: ${COLORS.labels.primary};
    }
  }
`

import { useState, useEffect } from 'react'
import JSONbig from '@cardanosolutions/json-bigint'

import {
  AggregateCursor,
  AggregatePaginationResponse,
  AggregatePaginationParameters,
} from '@packages/interfaces'

import { apiFetch, apiFetchWithCache } from '../utils'

const jsonBig = JSONbig({ useNativeBigInt: true, alwaysParseAsBig: true })

export function useAggregatePaginationApi<T>(
  {
    endpoint,
    limit,
    otherQueryParams,
    disabled = false,
  }: {
    endpoint: string
    limit?: number
    otherQueryParams?: { [key: string]: any }
    disabled?: boolean
  },
  useCache?: boolean
) {
  const [paginatedData, setPaginatedData] = useState<T[][]>([])
  const [initialDataLoaded, setInitialDataLoaded] = useState<boolean>(false)
  const [cursor, setCursor] = useState<string | AggregateCursor | undefined>()
  const [hasNextPage, setHasNextPage] = useState<boolean>(true)
  const [loading, setLoading] = useState(false) // anytime data is fetched this is true
  const [newPageLoading, setNewPageLoading] = useState(!disabled) // only true when new page is being fetched
  const [error, setError] = useState(null)
  const [paginationOptions, setPaginationOptions] = useState<AggregatePaginationParameters>({
    limit,
  })

  async function fetchData(newPaginationOptions: AggregatePaginationParameters) {
    setLoading(true)
    try {
      // console.log('fetchData cursor', newPaginationOptions?.cursor)
      const queryParams = {
        ...(newPaginationOptions?.cursor &&
          ((newPaginationOptions.cursor as AggregateCursor).MagicEden != undefined ||
          (newPaginationOptions.cursor as AggregateCursor).Mystic != undefined ||
          (newPaginationOptions.cursor as AggregateCursor).Okx != undefined ||
          (newPaginationOptions.cursor as AggregateCursor).Unisat != undefined
            ? { cursor: jsonBig.stringify(newPaginationOptions.cursor) }
            : { cursor: newPaginationOptions.cursor as string })),
        ...(newPaginationOptions?.limit && { limit: newPaginationOptions.limit }),
        ...otherQueryParams,
      }
      // console.log('fetchData otherQueryParams', otherQueryParams)
      // console.log('fetchData queryParams', queryParams)
      let response: AggregatePaginationResponse<T>
      if (useCache) {
        response = await apiFetchWithCache<AggregatePaginationResponse<T>>(
          endpoint,
          queryParams,
          undefined,
          30 * 1000
        )
      } else {
        response = await apiFetch<AggregatePaginationResponse<T>>(endpoint, queryParams)
      }

      return response
    } catch (error: any) {
      console.error(error)
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  async function updateExistingData() {
    if (disabled) {
      resetData()
      return
    }
    if (loading || newPageLoading) return
    const newDataResponse = await fetchData({ limit: paginationOptions.limit })
    if (!newDataResponse) return

    setPaginatedData([newDataResponse?.data])
    setCursor(newDataResponse.nextCursor)
    setHasNextPage(newDataResponse.data.length > 0)
  }

  async function fetchNextPage() {
    if (!newPageLoading || disabled) return

    if (!initialDataLoaded) {
      setNewPageLoading(true)
    }
    setInitialDataLoaded(true)

    if (hasNextPage) {
      const response = await fetchData(paginationOptions)
      setNewPageLoading(false)
      if (!response) return

      setPaginatedData([...paginatedData, response.data])
      setCursor(response.nextCursor)
      setHasNextPage(response.data.length > 0)
    }

    setNewPageLoading(false)
  }

  useEffect(() => {
    fetchNextPage()
  }, [paginationOptions])

  useEffect(() => {
    updateExistingData()
  }, [disabled, endpoint, otherQueryParams])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const fetchPage = async (page: number, forceFetch: boolean = false) => {
    if ((paginatedData?.length ?? 0) < page + 1 && !newPageLoading && (!error || forceFetch)) {
      setNewPageLoading(true)
      const nextPageParams = {
        ...paginationOptions,
        cursor: cursor,
      }
      setPaginationOptions(nextPageParams)
    }
  }

  const resetData = () => {
    setPaginatedData([])
    setCursor(undefined)
    setHasNextPage(true)
  }

  return {
    paginatedData,
    loading,
    newPageLoading,
    error,
    fetchPage,
    hasNextPage,
    forceRefresh: updateExistingData,
    initialDataLoaded,
    resetData,
  }
}

// Usage example:
// const endpoint = 'https://api.example.com/data';
// const { data, loading, error, fetchPage } = usePaginationApi(endpoint);
// Call fetchPage to load the next page of data

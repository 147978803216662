import { styled } from 'styled-components'

import { RuneBalance } from '@packages/interfaces'

import { Button } from 'src/shared/components'
import { COLORS } from 'src/shared/constants'

import { useTransferContext } from '../TransferContext'

interface Props {
  balance?: RuneBalance
  className?: string
}

export function TransferRunesButton({ balance, className }: Props) {
  const { setSelectedRuneId } = useTransferContext()

  const selectBalance = () => {
    if (balance) {
      setSelectedRuneId(balance.runeId)
    }
  }

  return (
    <TransferButton onClick={selectBalance} className={className}>
      Transfer
    </TransferButton>
  )
}

const TransferButton = styled(Button)`
  background-color: ${COLORS.black};
  color: ${COLORS.white};
  border: 1px solid ${COLORS.white};
`

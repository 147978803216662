import { 
  ReactNode,
  createContext,
  useContext,
  useState,
  useEffect,
} from 'react'

import { RuneBalance } from '@packages/interfaces'

import { useGoogleAnalyticsContext } from 'src/analytics'

import { ConfirmTransferRunesDrawer } from './components/ConfirmTransferRunesDrawer'
import { useRuneBalanceById } from './hooks/useRuneBalanceById'

interface TransferContextType {
  selectedRuneBalance?: RuneBalance

  selectedRuneId?: string
  setSelectedRuneId: (runeId?: string) => void

  transferAmount: bigint
  setTransferAmount: (amount: bigint) => void

  recipientAddress: string
  setRecipientAddress: (address: string) => void

  fee: number
  setFee: (fee: number) => void

  openConfirmDrawer: () => void
  isConfirmDrawerOpen: boolean
}

const TransferContext = createContext<TransferContextType | undefined>(undefined)

export const useTransferContext = () => {
  const context = useContext(TransferContext)
  if (context === undefined) {
    throw new Error('useTransferContext must be used within a TransferProvider')
  }
  return context
}

export const TransferProvider = ({ children }: { children: ReactNode }) => {
  const { initiateEvent } = useGoogleAnalyticsContext()

  const [selectedRuneBalance, setSelectedRuneBalance] = useState<RuneBalance | undefined>()
  const [selectedRuneId, setSelectedRuneId] = useState<string | undefined>()

  const [transferAmount, setTransferAmount] = useState(0n)
  const [recipientAddress, setRecipientAddress] = useState('')
  const [fee, setFee] = useState(0)

  const { 
    runeBalance: fetchedSelectedRuneBalance,
    forceRefresh: refetchSelectedRuneBalance
  } = useRuneBalanceById({ runeId: selectedRuneId, hideOrderBalances: true })

  const changeSelectedRuneId = (runeId?: string) => {
    setTransferAmount(0n)
    setRecipientAddress('')
    setFee(0)

    if (runeId) {
      setSelectedRuneId(runeId)
    } else {
      setSelectedRuneBalance(undefined)
      setSelectedRuneId(undefined)
    }
  }

  const [isConfirmDrawerOpen, setIsConfirmDrawerOpen] = useState(false)
  const openConfirmDrawer = () => {
    if (selectedRuneBalance) {
      initiateEvent('transfer', { 
        token_name: selectedRuneBalance.runeName,
        token_type: 'rune'
      })
      setIsConfirmDrawerOpen(true)
    }
  }
  const closeConfirmDrawer = () => {
    setIsConfirmDrawerOpen(false)
  }

  useEffect(() => {
    if (fetchedSelectedRuneBalance) {
      setSelectedRuneBalance({ 
        ...fetchedSelectedRuneBalance,
        runeId: fetchedSelectedRuneBalance.runeId ?? selectedRuneId
      })
    } else {
      setSelectedRuneBalance(undefined)
    }
  }, [fetchedSelectedRuneBalance])

  const handleConfirm = () => {
    setFee(0)
    setRecipientAddress('')
    setTransferAmount(0n)

    refetchSelectedRuneBalance()
  }

  return (
    <TransferContext.Provider
      value={{
        selectedRuneBalance,

        selectedRuneId,
        setSelectedRuneId: changeSelectedRuneId,
      
        transferAmount,
        setTransferAmount,
      
        recipientAddress,
        setRecipientAddress,

        fee,
        setFee,

        openConfirmDrawer,
        isConfirmDrawerOpen,
      }}
    >
      {children}
      {selectedRuneBalance && !!transferAmount && recipientAddress && (
        <ConfirmTransferRunesDrawer
          isOpen={isConfirmDrawerOpen}
          onClose={closeConfirmDrawer}
          onConfirm={handleConfirm}
          selectedRuneBalance={selectedRuneBalance}
          transferAmount={transferAmount}
          recipientAddress={recipientAddress}
        />
      )}
    </TransferContext.Provider>
  )
}

// Class for errors that contain human(user) readable descriptions
export class ManualError extends Error {
  readonly isUserError = true
  constructor(message: string | undefined) {
    message =
      message ?? 'Something unexpected has gone wrong, please contact support on our Discord'
    super(message)
    this.name = 'ManualError'
    Object.setPrototypeOf(this, ManualError.prototype)
  }
}

import { Decimal } from 'decimal.js-light'
import { formatUnits, parseUnits } from 'ethers'

import { BTC_DECIMALS, NUM_SATS_IN_BTC } from '@packages/constants'
import { parseToFloat } from './float'

Decimal.set({ toExpNeg: -12, toExpPos: 12 })

export function btcToSats(btcAmount: bigint | string | number) {
  const btc = new Decimal(`${btcAmount}`)
  return BigInt(btc.mul(NUM_SATS_IN_BTC).toFixed(0))
}

export function satsToBtc(satsAmount: bigint | string | number) {
  const sats = new Decimal(`${satsAmount}`)
  return sats.div(NUM_SATS_IN_BTC).toNumber()
}

export function floatBtcToBigInt(btcAmount: number) {
  return BigInt(parseUnits(btcAmount.toString(), BTC_DECIMALS))
}

export function formatBtc(btc: bigint) {
  return formatUnits(btc, BTC_DECIMALS)
}

const minimumBtc = '0.00001'

const SMALLEST_BTC_SHOWN = new Decimal(minimumBtc)

const SHORT_DECIMALS = 5

export function shortFormatBtc(btcNum: number | string | bigint) {
  const btc = new Decimal(typeof btcNum === 'string' ? parseToFloat(btcNum) : `${btcNum}`)

  if (btc > SMALLEST_BTC_SHOWN) {
    const rounded = Number(btc.toFixed(SHORT_DECIMALS))
    if (rounded >= 1000) {
      return (rounded / 1000).toFixed(rounded % 1000 === 0 ? 0 : 2) + 'K'
    }

    return rounded.toString()
  } else if (btc.greaterThan(0)) {
    return `<${minimumBtc}`
  } else {
    return '0'
  }
}

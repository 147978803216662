import { styled } from 'styled-components'

import type { Rune, RuneDetails } from '@packages/interfaces'

import { DrawerCard, DrawerTitle } from 'src/shared/components'
import { RuneSelector } from 'src/runes/components'

type MintRunesDrawerTitleProps = {
  runeDetails: RuneDetails | undefined
  onRuneSelectCompleted?: (rune: Rune | undefined) => void
}

export function MintRunesDrawerTitle({
  runeDetails,
  onRuneSelectCompleted,
}: MintRunesDrawerTitleProps) {
  return (
    <DrawerCard>
      <DrawerTitle>
        <TitleLayout>
          <span>Minting</span>
          <div>
            <RuneSelector runeDetails={runeDetails} onRuneSelectCompleted={onRuneSelectCompleted} />
          </div>
        </TitleLayout>
      </DrawerTitle>
    </DrawerCard>
  )
}

const TitleLayout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

import { useEffect, useMemo, useState } from 'react'
import { styled } from 'styled-components'

import { Rune } from '@packages/interfaces'

import { SubheaderText, TabPanelContentContainer, type TabPanelProps } from 'src/shared/components'
import {
  BUTTON_HOVER_STYLES,
  BigIntInput,
  DrawerBoldColumn,
  DrawerButtons,
  DrawerCard,
  DrawerColumn,
  DrawerConfirmButton,
  DrawerRow,
  DrawerTitle,
} from 'src/shared/components'
import { RunesAmountDisplay } from 'src/runes'
import { useTransferContext } from 'src/runes/TransferContext'
import { RuneSelectButton } from 'src/orders/components/RuneSelectButton'
import { SelectRuneModal } from 'src/orders/components/SelectRuneModal'
import { BREAKPOINTS, COLORS } from 'src/shared/constants'
import { AddressInput } from 'src/web3'
import { useWalletContext } from 'src/wallet'
import { useOrderContext } from 'src/orders'

export function TransferTabPanel({ visible }: TabPanelProps) {
  const { runesAddress, paymentAddress } = useWalletContext()
  const [isSelectRuneModalOpen, setIsSelectRuneModalOpen] = useState(false)
  const [runesAmountError, setRunesAmountError] = useState<string>()
  const [isValidRecipientAddress, setIsValidRecipientAddress] = useState(true)
  const {
    selectedRuneBalance,
    setSelectedRuneId,
    transferAmount: runesAmount,
    recipientAddress,
    setRecipientAddress,
    setTransferAmount: setRunesAmount,
    openConfirmDrawer,
  } = useTransferContext()
  const { setSelectedRune, selectedRune } = useOrderContext()

  const handleSelectRune = (rune: Rune) => {
    setSelectedRune(rune)
  }

  useEffect(() => {
    setSelectedRuneId(selectedRune?.runeId)
  }, [selectedRune])

  const availableBalance = useMemo(
    () => selectedRuneBalance?.runesAmount ?? 0n,
    [selectedRuneBalance]
  )

  function onMaxButtonClick() {
    setRunesAmount(availableBalance)
  }

  function onRunesAmountChange(value: bigint) {
    setRunesAmount(value)
    if (value > availableBalance) {
      setRunesAmountError('Insufficient balance')
    } else if (value < 0n) {
      setRunesAmountError('Should not be lower than 0')
    } else {
      setRunesAmountError(undefined)
    }
  }

  const disabled = useMemo(() => {
    return (
      !!runesAmountError ||
      !runesAmount ||
      !isValidRecipientAddress ||
      !recipientAddress ||
      !paymentAddress ||
      !runesAddress
    )
  }, [
    runesAmountError,
    runesAmount,
    isValidRecipientAddress,
    recipientAddress,
    paymentAddress,
    runesAddress,
  ])

  return (
    <TabPanelContentContainer $visible={visible}>
      <SubheaderText>
        Easily transfer runes between Bitcoin addresses. Just make sure you’re sending to a Taproot
        address (starting with bc1p) to avoid losing your assets.
      </SubheaderText>
      <CardsContainer>
        <DrawerCard>
          <DrawerTitle>Transfer</DrawerTitle>
          <>
            <RuneSelectButton
              selectedRune={selectedRuneBalance}
              selectedRuneName={selectedRuneBalance?.runeName}
              onClick={() => {
                setIsSelectRuneModalOpen(true)
              }}
              isLoadingNewRune={false}
            />
            <SelectRuneModal
              isOpen={isSelectRuneModalOpen}
              onSelectRune={handleSelectRune}
              onClose={() => setIsSelectRuneModalOpen(false)}
            />
          </>

          {selectedRuneBalance && (
            <>
              <BalanceRow>
                <DrawerBoldColumn>Total Balance</DrawerBoldColumn>
                <DrawerColumn>
                  <RunesAmountDisplay
                    rune={selectedRuneBalance}
                    runesAmount={selectedRuneBalance?.runesAmount}
                  />
                </DrawerColumn>
              </BalanceRow>
              <BalanceRow>
                <DrawerBoldColumn>Available Balance</DrawerBoldColumn>
                <DrawerColumn>
                  <RunesAmountDisplay
                    rune={selectedRuneBalance}
                    runesAmount={availableBalance ?? selectedRuneBalance?.runesAmount}
                  />
                  <MaxButton onClick={onMaxButtonClick}>Max</MaxButton>
                </DrawerColumn>
              </BalanceRow>
            </>
          )}
        </DrawerCard>

        {selectedRuneBalance && (
          <>
            <DrawerCard>
              <DrawerRow>
                <DrawerBoldColumn>Quantity</DrawerBoldColumn>
              </DrawerRow>
              <DrawerRow>
                <BigIntInput
                  value={runesAmount}
                  onChange={onRunesAmountChange}
                  decimals={selectedRuneBalance.runeDecimals}
                  helperText={runesAmount === 0n ? 'Required' : runesAmountError ?? undefined}
                />
              </DrawerRow>
            </DrawerCard>
            <DrawerCard>
              <DrawerRow>
                <DrawerBoldColumn>Recipient Address</DrawerBoldColumn>
              </DrawerRow>
              <DrawerRow>
                <AddressInput
                  value={recipientAddress}
                  onChange={setRecipientAddress}
                  helperText={!recipientAddress ? 'Required' : undefined}
                  onIsValidChange={setIsValidRecipientAddress}
                  isValid={isValidRecipientAddress}
                  placeholder='Type here'
                />
              </DrawerRow>
            </DrawerCard>
          </>
        )}

        <DrawerButtons>
          <TransferButton disabled={disabled} onClick={() => openConfirmDrawer()}>
            Transfer
          </TransferButton>
        </DrawerButtons>
      </CardsContainer>
    </TabPanelContentContainer>
  )
}

const CardsContainer = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const TransferButton = styled(DrawerConfirmButton)`
  padding: 9px 31px !important;

  @media (max-width: ${BREAKPOINTS.medium}) {
    width: 100%;
  }
`

const BalanceRow = styled(DrawerRow)`
  svg {
    height: 17px;
  }
`

const MaxButton = styled.div<{ disabled?: boolean }>`
  ${BUTTON_HOVER_STYLES}
  color: ${COLORS.hover};
  font-weight: 700;
`

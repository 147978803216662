import { useMemo } from 'react'

import {
  AggregateFilledOrder,
  FilledBidOrder,
  Order,
  OrderStatus,
  Rune,
} from '@packages/interfaces'

import { OrderStatusQueryParams, useOrdersByStatusApi } from './useOrdersByStatusApi'

const OPEN_ORDER_STATUS: OrderStatus = OrderStatus.PlacedActive
const FILLED_PENDING_ORDER_STATUS: OrderStatus = OrderStatus.FilledPending

const OPEN_ORDERS_QUERY_PARAMS: OrderStatusQueryParams = {
  status: OPEN_ORDER_STATUS,
  sortOrder: 'asc',
  sortOrderBy: 'price',
  limit: 100,
}

const FILLED_PENDING_QUERY_PARAMS: OrderStatusQueryParams = {
  status: FILLED_PENDING_ORDER_STATUS,
  provider: 'Mystic',
  sortOrder: 'desc',
  sortOrderBy: 'price',
  limit: 25,
}

export function useOpenOrdersForTable({
  type,
  selectedRune,
  otherQueryParams,
}: {
  type: 'sell' | 'bid'
  selectedRune: Rune | undefined
  otherQueryParams?: { [key: string]: any }
}) {
  const showBidOrders = type === 'bid'

  const openOrdersQueryParams = useMemo(
    () => ({
      ...OPEN_ORDERS_QUERY_PARAMS,
      ...otherQueryParams,
    }),
    [otherQueryParams]
  )

  const filledPendingOrdersQueryParams = useMemo(
    () => ({
      ...FILLED_PENDING_QUERY_PARAMS,
      ...otherQueryParams,
    }),
    [otherQueryParams]
  )

  const {
    paginatedData: openSellOrders,
    fetchPage: fetchSellOrdersPage,
    newPageLoading: newSellOrdersPageLoading,
    hasNextPage: hasNextSellOrdersPage,
    forceRefresh: forceRefreshSellOrders,
  } = useOrdersByStatusApi<Order>({
    runeName: selectedRune?.runeName ?? '',
    queryParams: openOrdersQueryParams,
    type: 'sell',
    disabled: !selectedRune,
  })

  const {
    paginatedData: openBidOrders,
    fetchPage: fetchBidOrdersPage,
    newPageLoading: newBidOrdersPageLoading,
    hasNextPage: hasNextBidOrdersPage,
    forceRefresh: forceRefreshBidOrders,
  } = useOrdersByStatusApi<Order>({
    runeName: selectedRune?.runeName ?? '',
    queryParams: openOrdersQueryParams,
    type: 'bid',
    disabled: !selectedRune,
  })

  const openOrders = showBidOrders ? openBidOrders : openSellOrders
  const fetchPage = showBidOrders ? fetchBidOrdersPage : fetchSellOrdersPage
  const newPageLoading = showBidOrders ? newBidOrdersPageLoading : newSellOrdersPageLoading
  const hasNextPage = showBidOrders ? hasNextBidOrdersPage : hasNextSellOrdersPage
  const forceRefresh = showBidOrders ? forceRefreshBidOrders : forceRefreshSellOrders

  const { paginatedData: filledPendingSellOrders, forceRefresh: filledSellOrdersForceRefresh } =
    useOrdersByStatusApi<AggregateFilledOrder>({
      runeName: selectedRune?.runeName ?? '',
      queryParams: filledPendingOrdersQueryParams,
      type: 'sell',
      disabled: !selectedRune,
    })

  const { paginatedData: filledPendingBidOrders, forceRefresh: filledBidOrdersForceRefresh } =
    useOrdersByStatusApi<FilledBidOrder>({
      runeName: selectedRune?.runeName ?? '',
      queryParams: filledPendingOrdersQueryParams,
      type: 'bid',
      disabled: !selectedRune,
    })

  const filledPendingOrders = showBidOrders ? filledPendingBidOrders : filledPendingSellOrders
  const filledForceRefresh = showBidOrders
    ? filledBidOrdersForceRefresh
    : filledSellOrdersForceRefresh

  return useMemo(() => {
    return {
      openOrders,
      fetchPage,
      newPageLoading,
      hasNextPage,
      forceRefresh,
      filledPendingOrders,
      filledForceRefresh,
    }
  }, [
    openOrders,
    fetchPage,
    newPageLoading,
    hasNextPage,
    forceRefresh,
    filledPendingOrders,
    filledForceRefresh,
  ])
}

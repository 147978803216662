import { Link } from 'react-router-dom'
import { styled } from 'styled-components'

import { formatRuneName } from '@packages/utils'

import {
  buildMarketDetailsUrl,
  buildPageTabSearchParam,
  buildRuneToolsTabSearchParam,
} from 'src/pages/constants'
import { BUTTON_HOVER_STYLES } from 'src/shared/components'
import { COLORS } from 'src/shared/constants'
import { useIsMobile } from 'src/shared/hooks'
import { concatSearchParams } from 'src/shared/utils'

import { RuneImage } from './RuneImage'
import { RuneNameDisplay } from './RuneNameDisplay'
import { RuneToolsTab } from './RuneTools'

interface Props {
  rune: { runeName: string; runeSymbolChar?: string; runeNameSpacerIndices?: bigint[] }
  displayName?: string
  className?: string
  openInNewTab?: boolean
  swapToolTab?: RuneToolsTab

}

export function RuneDetailsLink({ rune, displayName, className, openInNewTab = false, swapToolTab = 'swap', }: Props) {
  const isMobile = useIsMobile()
  const runeName = formatRuneName(rune)

  return (
    <LinkWrapper
      to={{
        pathname: buildMarketDetailsUrl(isMobile ? rune.runeName : runeName),
        search: concatSearchParams(
          buildRuneToolsTabSearchParam(swapToolTab),
          buildPageTabSearchParam('Overview')
        ).toString(),
      }}
      className={className}
      target={openInNewTab ? '_blank' : undefined}
    >
      <RuneName>
        {displayName ?? (
          <>
            <RuneImage runeDetails={rune} />
            <RuneNameDisplay rune={rune} />
          </>
        )}
      </RuneName>
    </LinkWrapper>
  )
}

const LinkWrapper = styled(Link)`
  ${BUTTON_HOVER_STYLES}
`

const RuneName = styled.div`
  color: ${COLORS.link} !important;
  font-weight: 700;
  word-break: break-word;
  gap: 5px;
  display: flex;
  align-items: center;
`

import Stack from '@mui/material/Stack'
import _Slider from '@mui/material/Slider'
import { styled } from 'styled-components'

import { COLORS } from 'src/shared/constants'

export interface SliderProps {
  spacing: number
  min: number
  max: number
  value: number
  onChange: (value: number) => void
  disabled?: boolean
  className?: string
}

export function Slider({ spacing, value, min, max, onChange, disabled, className }: SliderProps) {
  const handleChange = (_event: Event, newValue: number | number[]) => {
    onChange(newValue as number)
  }

  return (
    <StackWrapper
      spacing={spacing}
      direction='row'
      alignItems='center'
      className={className}
      $disabled={disabled}
    >
      <_Slider
        disabled={disabled}
        value={value}
        onChange={handleChange}
        valueLabelDisplay='auto'
        min={min}
        max={max}
        marks
      />
    </StackWrapper>
  )
}

const StackWrapper = styled(Stack)<{ $disabled?: boolean }>`
  width: 100%;
  color: ${(props) => (props.$disabled ? undefined : COLORS.link)};
  .MuiSlider-root {
    color: ${(props) => (props.$disabled ? undefined : COLORS.link)};
  }
  padding: 0px 10px;
`

import { styled } from 'styled-components'
import { useCallback } from 'react'

import { LargeButtonGroup } from 'src/shared/components'
import { BREAKPOINTS } from 'src/shared/constants'
import { RUNE_TOOLS_TAB_SEARCH_PARAM_NAME } from 'src/pages/constants'

import { type RuneToolsTab } from './types'
import { RUNE_TOOLS_TABS_OPTIONS } from './constants'

export type RuneToolsTabsProps = {
  onTabChange: (tabId: RuneToolsTab) => void
  selectedTab: RuneToolsTab
}

export function RuneToolsTabs({ onTabChange, selectedTab }: RuneToolsTabsProps) {
  const handleTabChange = useCallback(
    (tab: string) => {
      onTabChange(tab as RuneToolsTab)
    },
    [onTabChange]
  )

  return (
    <TabsGroup
      value={selectedTab}
      onChange={handleTabChange}
      options={RUNE_TOOLS_TABS_OPTIONS}
      exclusive={true}
      updateSearchParams={false}
      searchParamName={RUNE_TOOLS_TAB_SEARCH_PARAM_NAME}
    />
  )
}

const TabsGroup = styled(LargeButtonGroup)`
  justify-content: space-between;

  button {
    flex: 1 1 auto;
    padding: 8px 12px;
    text-transform: uppercase;
  }

  @media (max-width: ${BREAKPOINTS.medium}) {
    justify-content: center;

    button {
      min-width: auto;
    }
  }
`

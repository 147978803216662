import { useMemo } from 'react'

import { AddressDetails } from 'src/wallet'

import { useOrdersHistoryByRuneForAccountApi } from './useOrdersHistoryByRuneForAccountApi'

export function useOrderHistoryByAddressForTable({
  address,
  runeId,
  runesAddress,
  type,
  otherQueryParams
}: {
  address: string
  runeId?: string
  runesAddress: AddressDetails | undefined
  type: 'sell' | 'bid'
  otherQueryParams?: { [key: string]: any }
}) {
  const showBidOrders = type === 'bid'

  const {
    paginatedData: sellOrdersHistory,
    loading: sellOrdersLoading,
    fetchPage: fetchSellOrdersPage,
    hasNextPage: hasNextSellOrdersPage,
    forceRefresh: forceRefreshSellOrders,
    newPageLoading: newSellOrdersPageLoading,
  } = useOrdersHistoryByRuneForAccountApi({
    address,
    runeId,
    runesAddress,
    type: 'sell',
    otherQueryParams
  })

  const {
    paginatedData: bidOrdersHistory,
    loading: bidOrdersLoading,
    fetchPage: fetchBidOrdersPage,
    hasNextPage: hasNextBidOrdersPage,
    forceRefresh: forceRefreshBidOrders,
    newPageLoading: newBidOrdersPageLoading,
  } = useOrdersHistoryByRuneForAccountApi({
    address,
    runeId,
    runesAddress,
    type: 'bid',
    otherQueryParams
  })

  const orderHistory = showBidOrders ? bidOrdersHistory : sellOrdersHistory
  const fetchPage = showBidOrders ? fetchBidOrdersPage : fetchSellOrdersPage
  const newPageLoading = showBidOrders ? newBidOrdersPageLoading : newSellOrdersPageLoading
  const hasNextPage = showBidOrders ? hasNextBidOrdersPage : hasNextSellOrdersPage
  const forceRefresh = showBidOrders ? forceRefreshBidOrders : forceRefreshSellOrders
  const loading = showBidOrders ? bidOrdersLoading : sellOrdersLoading

  return useMemo(() => {
    return {
      orderHistory,
      fetchPage,
      newPageLoading,
      hasNextPage,
      forceRefresh,
      loading,
    }
  }, [orderHistory, fetchPage, newPageLoading, hasNextPage, forceRefresh, loading])
}

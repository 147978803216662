import type { RuneDetails } from '@packages/interfaces'

import { TabPanelContentContainer, type TabPanelProps } from 'src/shared/components'
import { OrderHistoryTable } from 'src/orders'

export type RuneTradeHistoryTabPanelProps = {
  runeDetails: RuneDetails | undefined
} & TabPanelProps

export function RuneTradeHistoryTabPanel({ runeDetails, visible }: RuneTradeHistoryTabPanelProps) {
  if (!runeDetails) {
    return null
  }

  return (
    <TabPanelContentContainer $visible={visible}>
      <OrderHistoryTable rune={runeDetails} />
    </TabPanelContentContainer>
  )
}

import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { useWalletContext } from 'src/wallet'
import { useIsMobile, useScrollPosition } from 'src/shared/hooks'
import { RefreshPageToast } from 'src/shared/components'

import { useAggregatePaginationApi } from './useAggregatePaginationApi'

const SCROLL_HEIGHT_DISABLED = 400
const SCROLL_HEIGHT_MOBILE_DISABLED = 200

export function useUpdateOnBlockAggregatePaginationApi<T>(
  params: {
    disabled?: boolean
    endpoint: string
    limit?: number
    otherQueryParams?: { [key: string]: any }
  },
  useCache?: boolean
) {
  const { blockTip } = useWalletContext()
  const isMobile = useIsMobile()
  const scrollPosition = useScrollPosition()
  const [isDisabled, setIsDisabled] = useState(false)
  const { forceRefresh, initialDataLoaded, ...data } = useAggregatePaginationApi<T>(
    params,
    useCache
  )

  useEffect(() => {
    if (isMobile) {
      if (scrollPosition > SCROLL_HEIGHT_MOBILE_DISABLED) {
        setIsDisabled(true)
      } else {
        setIsDisabled(false)
      }
    } else {
      if (scrollPosition > SCROLL_HEIGHT_DISABLED) {
        setIsDisabled(true)
      } else {
        setIsDisabled(false)
      }
    }
  }, [scrollPosition])

  useEffect(() => {
    if (initialDataLoaded && !isDisabled) {
      console.debug('block updated, refreshing data', blockTip, initialDataLoaded)
      forceRefresh()
    } else if (isDisabled) {
      console.debug('block updated, but disabled', blockTip, initialDataLoaded)
      toast(
        <RefreshPageToast
          onClick={() => {
            forceRefresh()
            window.scrollTo(0, 0)
          }}
          message='Viewing outdated data'
          buttonMessage='Update to latest'
        />,
        { toastId: 'refresh-page-block-update' }
      )
    }
  }, [blockTip])

  return { ...data, forceRefresh, initialDataLoaded }
}

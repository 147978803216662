import { useMemo } from 'react'
import { styled } from 'styled-components'
import { useParams } from 'react-router-dom'

import { formatUsdPrice, satsToBtc } from '@packages/utils'

import { BREAKPOINTS, COLORS } from 'src/shared/constants'
import {
  Accordion,
  Badge,
  CopyText,
  HeaderContainer,
  HeaderText,
  LargeButtonGroup,
  SubheaderText,
  InternalLink,
} from 'src/shared/components'
import {
  formatMediumAddress,
  formatShortAddress,
  useEscrowWalletContext,
  useWalletContext,
} from 'src/wallet'
import { useIsMobile } from 'src/shared/hooks'
import { MysticPointsPageLink, useMysticPointsContext } from 'src/rewards'
import { SWITCH_ACCOUNTS_TABS } from 'src/account/constants'
import { ACCOUNT_DETAILS_WALLET_TAB_SEARCH_PARAM_NAME } from 'src/pages/constants/routes'
import { ROUTES } from 'src/pages'
import { useFeatureFlagContext } from 'src/featureFlags'

import { EscrowTransactionsTable } from './EscrowTransactionsTable'

interface AccountDetailsPageContentProps {
  selectedWallet: string
  onWalletChange: (val: string) => void
}

export function AccountDetailsPageContent({
  selectedWallet,
  onWalletChange,
}: AccountDetailsPageContentProps) {
  const {
    escrowWallet: escrowAddress,
    btcBalance: escrowBtcBalance,
    lockedBtcBalance: escrowLockedBtcBalance,
  } = useEscrowWalletContext()
  const { isBiddingEnabled } = useFeatureFlagContext()

  const isMobile = useIsMobile()
  const { address } = useParams() // this should be their ordinals / runes address
  const { runesAddress, paymentAddress, btcBalances, btcPrice } = useWalletContext()
  const { mysticPoints } = useMysticPointsContext()
  const isConnectedAddress = runesAddress?.addrString === address

  const singleAddress = paymentAddress?.addrString === address

  const switchAccountsTabOptions = useMemo(
    () => [
      { label: SWITCH_ACCOUNTS_TABS.myWallet, value: SWITCH_ACCOUNTS_TABS.myWallet },
      {
        label: SWITCH_ACCOUNTS_TABS.escrow,
        value: SWITCH_ACCOUNTS_TABS.escrow,
        disabled: !isBiddingEnabled,
      },
    ],
    [isBiddingEnabled]
  )

  const isOwnAccount = useMemo(() => {
    return address === paymentAddress?.addrString || address === runesAddress?.addrString
  }, [address, paymentAddress, runesAddress])

  return (
    <Container>
      <HeaderContainerWrapper>
        <HeaderText>Account Details</HeaderText>{' '}
        {isConnectedAddress && <ConnectedLabel>Connected</ConnectedLabel>}
      </HeaderContainerWrapper>

      <AccountDetailsContainer>
        {isOwnAccount && (
          <SwitchWalletsButtons
            options={switchAccountsTabOptions}
            value={selectedWallet}
            onChange={onWalletChange}
            updateSearchParams={false}
            exclusive
            searchParamName={ACCOUNT_DETAILS_WALLET_TAB_SEARCH_PARAM_NAME}
          />
        )}

        {selectedWallet === SWITCH_ACCOUNTS_TABS.myWallet ? (
          <MyWalletSection>
            {isConnectedAddress && (
              <MyWalletItem>
                <MyWalletItemTitle>Available Balance</MyWalletItemTitle>
                <Balance>
                  {btcBalances?.chainBalanceBtc} <BtcBalanceSymbol>BTC</BtcBalanceSymbol>
                </Balance>
              </MyWalletItem>
            )}

            {isConnectedAddress && (
              <MyWalletItem>
                <MyWalletItemTitle>
                  <MysticPointsPageLink />
                </MyWalletItemTitle>
                <Balance>{mysticPoints.toString()}</Balance>
              </MyWalletItem>
            )}

            <MyWalletItem>
              {!singleAddress && (
                <>
                  <AddressPurpose>Runes Address</AddressPurpose>{' '}
                  <CopyTextWrapper
                    iconPlacement={'start'}
                    textToCopy={address ?? ''}
                    tooltipPlacement={'bottom-end'}
                  >
                    {isMobile ? formatShortAddress(address ?? '') : formatMediumAddress(address ?? '')}{' '}
                  </CopyTextWrapper>
                </>
              )}

              {isConnectedAddress && (
                <PaymentAddress>
                  <AddressPurpose>
                    {singleAddress ? 'Payment and Runes Address' : 'Payment Address'}
                  </AddressPurpose>{' '}
                  <CopyTextWrapper
                    textToCopy={paymentAddress?.addrString ?? ''}
                    iconPlacement={'start'}
                    tooltipPlacement={'bottom-end'}
                  >
                    {paymentAddress?.addrString &&
                      (isMobile
                        ? formatShortAddress(paymentAddress?.addrString)
                        : formatMediumAddress(paymentAddress?.addrString))}
                  </CopyTextWrapper>
                </PaymentAddress>
              )}
            </MyWalletItem>
          </MyWalletSection>
        ) : (
          <EscrowSection>
            <EscrowText>
              Your{' '}
              <InternalLinkWrapper
                text=' bidding wallet '
                showIcon={false}
                target='_blank'
                to={{
                  pathname: ROUTES.escrowWallet,
                }}
              />{' '}
              protected by Mystic contains Bitcoin to use for creating bid orders. Here you can view
              the balance and history of your bidding account.
            </EscrowText>
            <EscrowContainer>
              <EscrowItem>
                <EscrowItemHeader>Total Bidding Balance</EscrowItemHeader>
                <EscrowItemContent>
                  <EscrowItemValue>{satsToBtc(escrowBtcBalance ?? 0n)}</EscrowItemValue>
                  <EscrowItemCurrency>BTC</EscrowItemCurrency>
                </EscrowItemContent>
                <EscrowItemFooter>
                  {formatUsdPrice({
                    price:
                      escrowBtcBalance && btcPrice
                        ? btcPrice.btcPriceUsd * satsToBtc(escrowBtcBalance)
                        : '0',
                  })}
                </EscrowItemFooter>
              </EscrowItem>

              <EscrowItem>
                <EscrowItemHeader>Locked in Bids</EscrowItemHeader>
                <EscrowItemContent>
                  <EscrowItemValue>{satsToBtc(escrowLockedBtcBalance ?? 0n)}</EscrowItemValue>
                  <EscrowItemCurrency>BTC</EscrowItemCurrency>
                </EscrowItemContent>
                <EscrowItemFooter>
                  {formatUsdPrice({
                    price:
                      escrowLockedBtcBalance && btcPrice
                        ? btcPrice.btcPriceUsd * satsToBtc(escrowLockedBtcBalance)
                        : '0',
                  })}
                </EscrowItemFooter>
              </EscrowItem>

              <EscrowItem>
                <EscrowItemHeader>Bidding Wallet Address</EscrowItemHeader>
                <EscrowItemAddress
                  iconPlacement={'start'}
                  textToCopy={escrowAddress ?? ''}
                  tooltipPlacement={'bottom-end'}
                >
                  {escrowAddress &&
                    (isMobile
                      ? formatShortAddress(escrowAddress)
                      : formatMediumAddress(escrowAddress))}{' '}
                </EscrowItemAddress>
              </EscrowItem>
            </EscrowContainer>

            <EscrowContainer>
              <Accordion linkText='View Transactions History'>
                <EscrowTransactionsTable />
              </Accordion>
            </EscrowContainer>
          </EscrowSection>
        )}
      </AccountDetailsContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: ${BREAKPOINTS.medium}) {
    gap: 10px;
  }
`

const ConnectedLabel = styled(Badge)`
  background-color: ${COLORS.positive};
`

const MyWalletSection = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  gap: 25px;
  align-items: flex-start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;

  @media (max-width: ${BREAKPOINTS.medium}) {
    flex-direction: column;
    gap: 20px;
  }
`

const MyWalletItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1 0 auto;
`

const MyWalletItemTitle = styled.div`
  display: flex;
  flex: 1 1 auto;

  color: ${COLORS.labels.secondary};
  font-size: 14px;
  text-align: left;
  font-weight: 500;
`

const AddressPurpose = styled.div`
  font-size: 15px;
  color: ${COLORS.labels.secondary};
`

const CopyTextWrapper = styled(CopyText)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;

  svg {
    color: ${COLORS.link};
  }
`

const PaymentAddress = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 12px;
`

const Balance = styled.div`
  color: ${COLORS.labels.primary};
  font-size: 24px;
`

const BtcBalanceSymbol = styled.span`
  color: ${COLORS.labels.secondary};
`

const HeaderContainerWrapper = styled(HeaderContainer)`
  padding-top: 0px;
`

const AccountDetailsContainer = styled.div`
  border: 1px ${COLORS.border} solid;
  border-radius: 16px;
  padding: 15px;
`

const SwitchWalletsButtons = styled(LargeButtonGroup)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0;

  button {
    height: 30px;
    flex: 1;
    max-width: unset;

    &:first-child {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }

    &:last-child {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }

    &.Mui-disabled {
      color: ${COLORS.labels.disabled};
    }

    &.Mui-disabled.Mui-selected {
      color: ${COLORS.black};
    }
  }
`

const EscrowSection = styled.div`
  margin-top: 15px;
`

const EscrowText = styled(SubheaderText)`
  font-size: 1rem;
`

const EscrowItem = styled.div`
  display: flex;
  flex-direction: column;
`

const EscrowContainer = styled.div`
  margin-top: 15px;

  background-color: ${COLORS.background.container};
  border-radius: 14px;
  padding: 20px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;

  @media (max-width: ${BREAKPOINTS.medium}) {
    flex-direction: column;

    ${EscrowItem} {
      flex: 0 0 100%;
    }
  }

  ${EscrowItem}:nth-child(1),
  ${EscrowItem}:nth-child(2) {
    flex: 0 0 25%;
  }

  ${EscrowItem}:nth-child(3) {
    flex: 0 0 50%;
  }
`

const EscrowItemHeader = styled(SubheaderText)`
  font-size: 14px;
  font-weight: 500;
`

const EscrowItemContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
`

const EscrowItemValue = styled.div`
  color: ${COLORS.labels.primary};
  font-size: 24px;
  font-weight: 500;
`

const EscrowItemCurrency = styled.div`
  color: ${COLORS.labels.secondary};
  font-size: 24px;
  font-weight: 500;
`

const EscrowItemFooter = styled.div`
  color: ${COLORS.labels.secondary};
  font-size: 14px;
`

const EscrowItemAddress = styled(CopyTextWrapper)`
  margin-top: 7px;
`

const InternalLinkWrapper = styled(InternalLink)`
  display: inline-block;
`

import { styled } from 'styled-components'

import type { RuneDetails } from '@packages/interfaces'

import { TabPanelContentContainer, type TabPanelProps } from 'src/shared/components'
import { RuneOverviewSection } from 'src/runes/components/RuneOverviewSection'
import { RuneHoldersTable } from 'src/runes/components/RuneHoldersTable'
import { type MintDetails } from 'src/minting'

type RuneOverviewTabPanelProps = TabPanelProps & {
  runeDetails: RuneDetails | undefined
  mintDetails: MintDetails | undefined
}

export function RuneOverviewTabPanel({
  visible,
  runeDetails,
  mintDetails,
}: RuneOverviewTabPanelProps) {
  if (!runeDetails || !mintDetails) {
    return null
  }

  return (
    <TabPanelContentContainer $visible={visible}>
      <InfoContainer>
        <RuneOverviewSection runeDetails={runeDetails} mintDetails={mintDetails} />
      </InfoContainer>
      <TabbedContentContainer>
        {runeDetails && <RuneHoldersTable runeDetails={runeDetails} />}
      </TabbedContentContainer>
    </TabPanelContentContainer>
  )
}

const TabbedContentContainer = styled.div`
  padding-top: 20px;
`

const InfoContainer = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
`

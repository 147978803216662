import { useSearchParams } from 'react-router-dom'
import { useCallback, useMemo } from 'react'

import { buildPageTabSearchParam, buildRuneToolsTabSearchParam } from 'src/pages'
import { concatSearchParams } from 'src/shared/utils'

import { DEFAULT_TAB, RUNE_DETAILS_TAB_SEARCH_PARAM, RUNE_DETAILS_TABS } from './constants'

import type { RuneDetailsTabs } from './types'

function shouldSwitchToSwap(tab: RuneDetailsTabs): boolean {
  return (
    tab === RUNE_DETAILS_TABS['Orderbook'].value || tab === RUNE_DETAILS_TABS['Trade History'].value
  )
}

export function useRuneDetailsTabs() {
  const [searchParams, setSearchParams] = useSearchParams()
  const currentTab =
    (searchParams.get(RUNE_DETAILS_TAB_SEARCH_PARAM) as RuneDetailsTabs) || DEFAULT_TAB

  const selectedTabObject = useMemo(() => {
    return RUNE_DETAILS_TABS[currentTab] ?? RUNE_DETAILS_TABS[DEFAULT_TAB]
  }, [currentTab])

  const handleChangeTab = useCallback(
    (tab: RuneDetailsTabs) => {
      setSearchParams((prev) => {
        const newSearchParams = concatSearchParams(
          prev,
          buildPageTabSearchParam(tab),
          buildRuneToolsTabSearchParam(shouldSwitchToSwap(tab) ? 'swap' : 'mint')
        )

        return newSearchParams
      })
    },
    [setSearchParams]
  )

  return {
    selectedTab: currentTab,
    selectedTabObject,
    changeTab: handleChangeTab,
  }
}

import { styled } from 'styled-components'

import { BREAKPOINTS, COLORS } from 'src/shared/constants'
import { DrawerColumn, HeaderContainer } from 'src/shared/components'

export const TableHeaderContainerWrapper = styled(HeaderContainer)`
  padding-top: 0px;
  @media (max-width: ${BREAKPOINTS.medium}) {
    align-items: center;
  }
`

export const TableSwitchesContainer = styled.div`
  display: flex;
  gap: 14px;
  @media (max-width: ${BREAKPOINTS.medium}) {
    flex-direction: column;
    gap: 0;
    .MuiFormControlLabel-root {
      margin-right: 0;
    }
    .MuiFormControlLabel-label {
      font-size: 14px;
    }
    label {
      font-size: 14px;
    }
  }
`

export const RunesTotalColumn = styled(DrawerColumn)<{ $colorVariant: 'positive' | 'negative' }>`
  gap: 0px;
  display: flex;
  color: ${({ $colorVariant }) =>
    $colorVariant === 'positive' ? COLORS.positive : COLORS.negative};
  font-weight: 700;
  @media (max-width: ${BREAKPOINTS.medium}) {
    justify-content: flex-start;
  }
`

import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { styled } from 'styled-components'

import { MARKET_DETAILS_TABS } from 'src/markets/constants'
import { useMintDetails } from 'src/minting'
import {
  OpenOrdersByAddress,
  OpenSellOrdersTable,
  //OrderFooterMenu,
  OrderHistoryByAddress,
  OrderHistoryTable,
  RuneOutpointsTable,
  useOrderContext,
} from 'src/orders'
import { PAGE_TAB_SEARCH_PARAM_NAME } from 'src/pages/constants'
import { RuneOverviewTabPanel } from 'src/runes/components/RuneOverviewTabPanel'
import { ErrorMessage, LargeButtonGroup, LoadingSkeleton } from 'src/shared/components'
import { BREAKPOINTS } from 'src/shared/constants'
import { useWalletContext } from 'src/wallet'
import { useRuneToolsState } from 'src/runes/components/RuneTools/useRuneToolsState'

import { MarketOverviewSection } from './MarketOverviewSection'
import { MarketRuneOverview } from './MarketRuneOverview'

const defaultTabOptions = Object.values(MARKET_DETAILS_TABS).map((value) => ({
  value: value,
  label: value,
}))

interface Props {
  runeName: string
}

export function MarketDetailsPageContent({ runeName }: Props) {
  const {
    selectedRune,
    selectedRuneDetails,
    setSelectedRuneName,
    marketFetchError,
    detailsFetchError,
    isLoadingNewRune,
  } = useOrderContext()

  const { runesAddress, isConnected } = useWalletContext()

  const [searchParams] = useSearchParams()

  const { currentRuneTool: _currentRuneTool } = useRuneToolsState()
  // NOTE: Persist currentRuneTool state to avoid re-rendering when the selected tab changes
  const [currentRuneTool, setCurrentRuneTool] = useState(_currentRuneTool)
  useEffect(
    function syncCurrentRuneTool() {
      setCurrentRuneTool(_currentRuneTool)
    },
    [_currentRuneTool]
  )

  const preselectedTab =
    searchParams.get(PAGE_TAB_SEARCH_PARAM_NAME) || MARKET_DETAILS_TABS.orderbook
  const [selectedTab, setSelectedTab] = useState(preselectedTab)

  useEffect(() => {
    setSelectedRuneName(runeName)
  }, [runeName])

  const mintDetails = useMintDetails({ runeDetails: selectedRuneDetails })

  const availableTabs = useMemo(() => {
    if (!runesAddress) {
      return defaultTabOptions.filter((option) => {
        return (
          option.value !== MARKET_DETAILS_TABS.myOpenOrders &&
          option.value !== MARKET_DETAILS_TABS.myOrderHistory &&
          option.value !== MARKET_DETAILS_TABS.myRuneOutpoints
        )
      })
    }

    return defaultTabOptions
  }, [runesAddress])

  useEffect(() => {
    if (!isConnected) {
      setSelectedTab(MARKET_DETAILS_TABS.orderbook)
    }
  }, [isConnected])

  const handleTabOnChange = useCallback(
    (value: string) => {
      setSelectedTab(value)
    },
    [setSelectedTab]
  )

  if (
    isLoadingNewRune ||
    !selectedRune ||
    !selectedRuneDetails ||
    !!marketFetchError ||
    !!detailsFetchError
  ) {
    return (
      <Container>
        {marketFetchError && (
          <ErrorContainer>
            <ErrorMessage message={marketFetchError} />
          </ErrorContainer>
        )}
        {detailsFetchError && (
          <ErrorContainer>
            <ErrorMessage message={detailsFetchError} />
          </ErrorContainer>
        )}
        {currentRuneTool === 'swap' && <MarketOverviewSection />}
        {currentRuneTool === 'mint' && (
          <MarketRuneOverview runeDetails={selectedRuneDetails} mintDetails={mintDetails} />
        )}
        <LargeButtonGroupWrapper
          options={availableTabs}
          value={selectedTab}
          onChange={handleTabOnChange}
          exclusive
          updateSearchParams={false}
        />
        <TabbedContentContainer>
          {isLoadingNewRune && <LoadingTabbedContentSkeleton height={600} />}
        </TabbedContentContainer>
      </Container>
    )
  }

  let tabbedContent = <OpenSellOrdersTable />

  if (selectedTab === MARKET_DETAILS_TABS.overview) {
    tabbedContent = (
      <RuneOverviewTabPanel
        visible={true}
        runeDetails={selectedRuneDetails}
        mintDetails={mintDetails}
      />
    )
  } else if (selectedTab === MARKET_DETAILS_TABS.orderHistory) {
    tabbedContent = <OrderHistoryTable rune={selectedRuneDetails} />
  } else if (selectedTab === MARKET_DETAILS_TABS.myOpenOrders) {
    tabbedContent = (
      <OpenOrdersByAddress
        address={runesAddress?.addrString as string}
        runeId={selectedRune.runeId}
      />
    )
  } else if (selectedTab === MARKET_DETAILS_TABS.myOrderHistory) {
    tabbedContent = (
      <OrderHistoryByAddress
        address={runesAddress?.addrString as string}
        runeId={selectedRune.runeId}
      />
    )
  } else if (selectedTab === MARKET_DETAILS_TABS.myRuneOutpoints) {
    tabbedContent = <RuneOutpointsTable rune={selectedRune} />
  }

  return (
    <Container>
      {currentRuneTool === 'swap' && <MarketOverviewSection />}
      {currentRuneTool === 'mint' && (
        <MarketRuneOverview runeDetails={selectedRuneDetails} mintDetails={mintDetails} />
      )}
      <LargeButtonGroupWrapper
        options={availableTabs}
        value={selectedTab}
        onChange={handleTabOnChange}
        exclusive
        updateSearchParams={false}
      />
      <TabbedContentContainer>{tabbedContent}</TabbedContentContainer>
      {/* <OrderFooterMenu /> */}
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: ${BREAKPOINTS.medium}) {
    gap: 15px;
  }
`

const LargeButtonGroupWrapper = styled(LargeButtonGroup)`
  button {
    @media (max-width: ${BREAKPOINTS.small}) {
      flex: 45%;
    }
  }
`

const TabbedContentContainer = styled.div``

const LoadingSkeletonWrapper = styled(LoadingSkeleton)`
  transform: none;
`

const LoadingTabbedContentSkeleton = styled(LoadingSkeletonWrapper)``

const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
`

import { useCallback, useEffect, useMemo } from 'react'
import { styled } from 'styled-components'

import { RuneSwapper, useOrderContext, OrderStateType } from 'src/orders'
import { useWalletContext } from 'src/wallet'
import { MintRunesDrawerRecipientAddress } from 'src/minting'
import { useFeatureFlagContext } from 'src/featureFlags'

import { ExpiredTimeDrawerCard } from './ExpiredTimeDrawerCard'
import { SwapToolMode } from './types'

type SwapToolProps = {
  mode: SwapToolMode
}

export function SwapTool({ mode }: SwapToolProps) {
  const { isBiddingEnabled } = useFeatureFlagContext()
  const { isConnected, runesAddress, paymentAddress } = useWalletContext()

  const orderStateType: OrderStateType = mode === 'limit' ? 'limit' : 'market'

  const { selectedRune, setProceedsAddress, proceedsAddress, isBTConTop, expiresAt, setExpiresAt } =
    useOrderContext(orderStateType)

  const isBidOrderCreation = mode === 'limit' && isBTConTop

  // const recipientAddress =
  //   (proceedsAddress !== undefined ? proceedsAddress : runesAddress?.addrString) || ''
  // const handleRecipientAddressChange = useCallback(
  //   (address: string) => {
  //     setProceedsAddress(address)
  //   },
  //   [setProceedsAddress]
  // )

  const handleExpiresAtChange = useCallback(
    (expiresAt: number) => {
      setExpiresAt(expiresAt)
    },
    [setExpiresAt]
  )

  useEffect(
    function prefillAddressWhenOnWalletConnect() {
      if (isConnected) {
        setProceedsAddress(paymentAddress?.addrString || '')
      }
    },
    [isConnected, paymentAddress?.addrString, setProceedsAddress]
  )

  const isSwapDisabled = useMemo(() => {
    return (
      !isBiddingEnabled && ((mode === 'limit' && isBTConTop) || (mode === 'market' && !isBTConTop))
    )
  }, [isBiddingEnabled, mode, isBTConTop])

  return (
    <RuneSwapper
      key={orderStateType}
      stateType={orderStateType}
      expiresIn={isBidOrderCreation ? expiresAt : undefined}
      disabled={isSwapDisabled}
    >
      {isConnected && selectedRune && mode === 'limit' && isBTConTop && (
        <ExpiredTimeRow
          expiredTime={expiresAt}
          onChange={handleExpiresAtChange}
          disabled={isSwapDisabled}
        />
      )}

      {/* {isConnected && selectedRune && (
        <RecipientAddressRow
          onRecipientAddressChange={handleRecipientAddressChange}
          recipientAddress={recipientAddress}
          isDisabled={isSwapDisabled}
        />
      )} */}
    </RuneSwapper>
  )
}

// const RecipientAddressRow = styled(MintRunesDrawerRecipientAddress)`
//   border-radius: 14px;
// `

const ExpiredTimeRow = styled(ExpiredTimeDrawerCard)`
  border-radius: 14px;
`

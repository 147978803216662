import { styled } from 'styled-components'
import _Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormHelperText from '@mui/material/FormHelperText'
import { useEffect, useMemo, useState } from 'react'

import { COLORS, FONTS } from 'src/shared/constants'
import { hexToRgb, unixMsTimestampToDateString } from 'src/shared/utils'

import { EXPIRED_TIME_MAP, EXPIRED_TIME_OPTIONS } from './constants'

type ExpiredTimeSelectProps = {
  onChange: (expireIn: number) => void
  value: number | undefined
  helperText?: string
  error?: boolean
  className?: string
  disabled?: boolean
}

export function ExpiredTimeSelect({
  onChange,
  value,
  helperText,
  error,
  className,
  disabled,
}: ExpiredTimeSelectProps) {
  const [currentTime, setCurrentTime] = useState<number>(Date.now())
  const [isOpen, setIsOpen] = useState(false)
  const [touched, setTouched] = useState(false)

  useEffect(() => {
    if (isOpen) {
      setCurrentTime(Date.now())
    }
  }, [isOpen])

  const selectValue = EXPIRED_TIME_OPTIONS.find((option) => option.ms === value)?.value || ''

  const options = useMemo(() => {
    if (!value) return EXPIRED_TIME_OPTIONS

    const [defaultOption, ...optionsWithValues] = EXPIRED_TIME_OPTIONS

    return optionsWithValues
  }, [value])

  return (
    <Container style={{ height: isOpen ? 'calc(100% + 50%)' : '100%' }}>
      <Select
        onOpen={() => {
          setIsOpen(true)
        }}
        onClose={() => {
          setIsOpen(false)
        }}
        onBlur={() => {
          setTouched(true)
        }}
        variant={'outlined'}
        displayEmpty={true}
        defaultValue={''}
        value={selectValue}
        error={touched && error}
        placeholder={'Choose the Date'}
        disabled={disabled}
        MenuProps={{
          keepMounted: true,
          disablePortal: true,
          disableScrollLock: true,
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
          transformOrigin: { vertical: 'top', horizontal: 'center' },
        }}
        onChange={(event) => {
          const selectValue = event.target.value as string
          const expiresInMs: number = EXPIRED_TIME_MAP[selectValue]?.ms || 0

          onChange(expiresInMs)
        }}
        className={className}
      >
        {options.map((option) => {
          if (option.value === '') {
            return (
              <SelectItem key={option.value} value={option.value}>
                <SelectItemLabel>{option.label}</SelectItemLabel>
              </SelectItem>
            )
          }

          const expiresIn = currentTime + option.ms
          const dateTime = unixMsTimestampToDateString(BigInt(expiresIn), true)

          return (
            <SelectItem key={option.value} value={option.value}>
              <SelectItemLabel>{option.label}</SelectItemLabel>
              <SelectItemTime>{dateTime}</SelectItemTime>
            </SelectItem>
          )
        })}
      </Select>
      {touched && error && helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </Container>
  )
}

const SELECT_COLORS = {
  background: '#090808',
  border: hexToRgb(COLORS.buttons.primary, 0.25),
  textAccent: '#8CDBDB',
  textPrimary: hexToRgb(COLORS.white, 0.45),
  textSecondary: COLORS.labels.primary,
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .MuiFormHelperText-root {
    font-family: ${FONTS.text};
    font-size: 15px;
    color: ${COLORS.negative};
  }
`

const Select = styled(_Select)`
  width: 100%;
  background-color: ${SELECT_COLORS.background};
  color: ${SELECT_COLORS.textAccent};
  border: 1px solid ${SELECT_COLORS.border};
  border-radius: 17px;
  font-family: ${FONTS.text};
  font-weight: 500;

  & .Mui-disabled {
    opacity: 0.4;
    -webkit-text-fill-color: unset;
  }

  & .MuiSelect-nativeInput {
    height: 100%;
  }

  & .MuiInputBase-input {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 32px;
  }

  &.MuiInputBase-root .MuiInputBase-input {
    padding-right: 12px;
  }

  & .MuiInputBase-input > div:first-child {
    color: ${SELECT_COLORS.textAccent};
  }

  & .MuiMenu-paper {
    background-color: ${SELECT_COLORS.background};
    color: ${SELECT_COLORS.textPrimary};
    border: 1px solid ${SELECT_COLORS.border};
    border-radius: 17px;
  }

  & .MuiMenuItem-root {
    font-family: ${FONTS.text};
    font-weight: 500;
    background-color: transparent;
  }

  & .MuiMenuItem-root > div {
    color: ${SELECT_COLORS.textPrimary};
  }

  & .MuiMenuItem-root:hover {
    background-color: transparent;
  }

  & .MuiMenuItem-root.Mui-selected > div {
    background-color: transparent;
    color: ${SELECT_COLORS.textSecondary};
  }

  & .MuiMenuItem-root.Mui-selected > div:first-child {
    color: ${SELECT_COLORS.textAccent};
  }

  & .MuiSelect-icon {
    left: 8px;
    color: ${SELECT_COLORS.textAccent};
  }
`

const SelectItem = styled(MenuItem)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const SelectItemLabel = styled.div`
  text-align: left;
  font-family: ${FONTS.text};
  font-weight: 500;
`

const SelectItemTime = styled.div`
  text-align: right;
  font-family: ${FONTS.headers};
  font-weight: 500;
`

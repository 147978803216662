
URL = globalThis.URL
import __vite__wasmUrl from "/vercel/path0/packages/rune-wasm-frontend/rune_wasm_bg.wasm?url"
import __vite__initWasm from "/__vite-plugin-wasm-helper"

import { __wbindgen_object_drop_ref as __vite__wasmImport_0_0, __wbg_edict_new as __vite__wasmImport_0_1, __wbindgen_string_new as __vite__wasmImport_0_2, __wbindgen_bigint_from_u128 as __vite__wasmImport_0_3, __wbindgen_string_get as __vite__wasmImport_0_4, __wbg_edict_unwrap as __vite__wasmImport_0_5, __wbg_new_abda76e883ba8a5f as __vite__wasmImport_0_6, __wbg_stack_658279fe44541cf6 as __vite__wasmImport_0_7, __wbg_error_f851667af71bcfc6 as __vite__wasmImport_0_8, __wbg_toString_66be3c8e1c6a7c76 as __vite__wasmImport_0_9, __wbg_buffer_12d079cc21e14bdb as __vite__wasmImport_0_10, __wbg_newwithbyteoffsetandlength_aa4a17c33a06e5cb as __vite__wasmImport_0_11, __wbg_new_63b92bc8671ed464 as __vite__wasmImport_0_12, __wbg_set_a47bac70306a19a7 as __vite__wasmImport_0_13, __wbg_length_c20a40f15020d68a as __vite__wasmImport_0_14, __wbindgen_debug_string as __vite__wasmImport_0_15, __wbindgen_throw as __vite__wasmImport_0_16, __wbindgen_memory as __vite__wasmImport_0_17 } from "./rune_wasm_bg.js";
const __vite__wasmModule = await __vite__initWasm({ "./rune_wasm_bg.js": { __wbindgen_object_drop_ref: __vite__wasmImport_0_0, __wbg_edict_new: __vite__wasmImport_0_1, __wbindgen_string_new: __vite__wasmImport_0_2, __wbindgen_bigint_from_u128: __vite__wasmImport_0_3, __wbindgen_string_get: __vite__wasmImport_0_4, __wbg_edict_unwrap: __vite__wasmImport_0_5, __wbg_new_abda76e883ba8a5f: __vite__wasmImport_0_6, __wbg_stack_658279fe44541cf6: __vite__wasmImport_0_7, __wbg_error_f851667af71bcfc6: __vite__wasmImport_0_8, __wbg_toString_66be3c8e1c6a7c76: __vite__wasmImport_0_9, __wbg_buffer_12d079cc21e14bdb: __vite__wasmImport_0_10, __wbg_newwithbyteoffsetandlength_aa4a17c33a06e5cb: __vite__wasmImport_0_11, __wbg_new_63b92bc8671ed464: __vite__wasmImport_0_12, __wbg_set_a47bac70306a19a7: __vite__wasmImport_0_13, __wbg_length_c20a40f15020d68a: __vite__wasmImport_0_14, __wbindgen_debug_string: __vite__wasmImport_0_15, __wbindgen_throw: __vite__wasmImport_0_16, __wbindgen_memory: __vite__wasmImport_0_17 } }, __vite__wasmUrl);
export const memory = __vite__wasmModule.memory;
export const __wbg_terms_free = __vite__wasmModule.__wbg_terms_free;
export const __wbg_get_terms_cap = __vite__wasmModule.__wbg_get_terms_cap;
export const __wbg_set_terms_cap = __vite__wasmModule.__wbg_set_terms_cap;
export const __wbg_get_terms_amount = __vite__wasmModule.__wbg_get_terms_amount;
export const __wbg_set_terms_amount = __vite__wasmModule.__wbg_set_terms_amount;
export const __wbg_get_terms_absolute_open_height = __vite__wasmModule.__wbg_get_terms_absolute_open_height;
export const __wbg_set_terms_absolute_open_height = __vite__wasmModule.__wbg_set_terms_absolute_open_height;
export const __wbg_get_terms_absolute_close_height = __vite__wasmModule.__wbg_get_terms_absolute_close_height;
export const __wbg_set_terms_absolute_close_height = __vite__wasmModule.__wbg_set_terms_absolute_close_height;
export const __wbg_get_terms_relative_open_height = __vite__wasmModule.__wbg_get_terms_relative_open_height;
export const __wbg_set_terms_relative_open_height = __vite__wasmModule.__wbg_set_terms_relative_open_height;
export const __wbg_get_terms_relative_close_height = __vite__wasmModule.__wbg_get_terms_relative_close_height;
export const __wbg_set_terms_relative_close_height = __vite__wasmModule.__wbg_set_terms_relative_close_height;
export const terms_new = __vite__wasmModule.terms_new;
export const __wbg_u128_free = __vite__wasmModule.__wbg_u128_free;
export const u128_to_bigint = __vite__wasmModule.u128_to_bigint;
export const u128_from_bigint = __vite__wasmModule.u128_from_bigint;
export const u128_to_string = __vite__wasmModule.u128_to_string;
export const u128_from_string = __vite__wasmModule.u128_from_string;
export const __wbg_spacedrune_free = __vite__wasmModule.__wbg_spacedrune_free;
export const spacedrune_from_num = __vite__wasmModule.spacedrune_from_num;
export const spacedrune_from_bigint = __vite__wasmModule.spacedrune_from_bigint;
export const spacedrune_to_bigint = __vite__wasmModule.spacedrune_to_bigint;
export const spacedrune_to_string = __vite__wasmModule.spacedrune_to_string;
export const spacedrune_from_string = __vite__wasmModule.spacedrune_from_string;
export const spacedrune_is_reserved = __vite__wasmModule.spacedrune_is_reserved;
export const spacedrune_is_name_valid_at_height = __vite__wasmModule.spacedrune_is_name_valid_at_height;
export const __wbg_artifact_free = __vite__wasmModule.__wbg_artifact_free;
export const artifact_from_transaction = __vite__wasmModule.artifact_from_transaction;
export const artifact_is_cenotaph = __vite__wasmModule.artifact_is_cenotaph;
export const artifact_is_runestone = __vite__wasmModule.artifact_is_runestone;
export const artifact_to_runestone = __vite__wasmModule.artifact_to_runestone;
export const artifact_to_cenotaph = __vite__wasmModule.artifact_to_cenotaph;
export const __wbg_runeid_free = __vite__wasmModule.__wbg_runeid_free;
export const runeid_new = __vite__wasmModule.runeid_new;
export const runeid_block = __vite__wasmModule.runeid_block;
export const runeid_tx = __vite__wasmModule.runeid_tx;
export const runeid_set_block = __vite__wasmModule.runeid_set_block;
export const runeid_set_tx = __vite__wasmModule.runeid_set_tx;
export const runeid_to_string = __vite__wasmModule.runeid_to_string;
export const runeid_from_string = __vite__wasmModule.runeid_from_string;
export const __wbg_cenotaph_free = __vite__wasmModule.__wbg_cenotaph_free;
export const __wbg_get_cenotaph_etching = __vite__wasmModule.__wbg_get_cenotaph_etching;
export const __wbg_set_cenotaph_etching = __vite__wasmModule.__wbg_set_cenotaph_etching;
export const __wbg_get_cenotaph_flaws = __vite__wasmModule.__wbg_get_cenotaph_flaws;
export const __wbg_set_cenotaph_flaws = __vite__wasmModule.__wbg_set_cenotaph_flaws;
export const __wbg_get_cenotaph_mint = __vite__wasmModule.__wbg_get_cenotaph_mint;
export const __wbg_set_cenotaph_mint = __vite__wasmModule.__wbg_set_cenotaph_mint;
export const __wbg_edict_free = __vite__wasmModule.__wbg_edict_free;
export const __wbg_get_edict_id = __vite__wasmModule.__wbg_get_edict_id;
export const __wbg_set_edict_id = __vite__wasmModule.__wbg_set_edict_id;
export const __wbg_get_edict_amount = __vite__wasmModule.__wbg_get_edict_amount;
export const __wbg_set_edict_amount = __vite__wasmModule.__wbg_set_edict_amount;
export const __wbg_get_edict_output = __vite__wasmModule.__wbg_get_edict_output;
export const __wbg_set_edict_output = __vite__wasmModule.__wbg_set_edict_output;
export const init = __vite__wasmModule.init;
export const encode_mint = __vite__wasmModule.encode_mint;
export const __wbg_runestone_free = __vite__wasmModule.__wbg_runestone_free;
export const __wbg_get_runestone_etching = __vite__wasmModule.__wbg_get_runestone_etching;
export const __wbg_set_runestone_etching = __vite__wasmModule.__wbg_set_runestone_etching;
export const __wbg_get_runestone_mint = __vite__wasmModule.__wbg_get_runestone_mint;
export const __wbg_set_runestone_mint = __vite__wasmModule.__wbg_set_runestone_mint;
export const __wbg_get_runestone_pointer = __vite__wasmModule.__wbg_get_runestone_pointer;
export const __wbg_set_runestone_pointer = __vite__wasmModule.__wbg_set_runestone_pointer;
export const runestone_edicts = __vite__wasmModule.runestone_edicts;
export const runestone_set_edicts = __vite__wasmModule.runestone_set_edicts;
export const encode_simple_transfer = __vite__wasmModule.encode_simple_transfer;
export const encode_simple_transfers = __vite__wasmModule.encode_simple_transfers;
export const __wbg_etching_free = __vite__wasmModule.__wbg_etching_free;
export const __wbg_get_etching_divisibility = __vite__wasmModule.__wbg_get_etching_divisibility;
export const __wbg_set_etching_divisibility = __vite__wasmModule.__wbg_set_etching_divisibility;
export const __wbg_get_etching_premine = __vite__wasmModule.__wbg_get_etching_premine;
export const __wbg_set_etching_premine = __vite__wasmModule.__wbg_set_etching_premine;
export const __wbg_get_etching_rune = __vite__wasmModule.__wbg_get_etching_rune;
export const __wbg_set_etching_rune = __vite__wasmModule.__wbg_set_etching_rune;
export const __wbg_get_etching_spacers = __vite__wasmModule.__wbg_get_etching_spacers;
export const __wbg_set_etching_spacers = __vite__wasmModule.__wbg_set_etching_spacers;
export const __wbg_get_etching_symbol = __vite__wasmModule.__wbg_get_etching_symbol;
export const __wbg_set_etching_symbol = __vite__wasmModule.__wbg_set_etching_symbol;
export const __wbg_get_etching_terms = __vite__wasmModule.__wbg_get_etching_terms;
export const __wbg_set_etching_terms = __vite__wasmModule.__wbg_set_etching_terms;
export const __wbg_get_etching_turbo = __vite__wasmModule.__wbg_get_etching_turbo;
export const __wbg_set_etching_turbo = __vite__wasmModule.__wbg_set_etching_turbo;
export const __wbg_get_etchingparams_divisibility = __vite__wasmModule.__wbg_get_etchingparams_divisibility;
export const __wbg_set_etchingparams_divisibility = __vite__wasmModule.__wbg_set_etchingparams_divisibility;
export const __wbg_get_etchingparams_premine = __vite__wasmModule.__wbg_get_etchingparams_premine;
export const __wbg_set_etchingparams_premine = __vite__wasmModule.__wbg_set_etchingparams_premine;
export const __wbg_get_etchingparams_premine_vout = __vite__wasmModule.__wbg_get_etchingparams_premine_vout;
export const __wbg_set_etchingparams_premine_vout = __vite__wasmModule.__wbg_set_etchingparams_premine_vout;
export const __wbg_get_etchingparams_rune = __vite__wasmModule.__wbg_get_etchingparams_rune;
export const __wbg_set_etchingparams_rune = __vite__wasmModule.__wbg_set_etchingparams_rune;
export const __wbg_get_etchingparams_terms = __vite__wasmModule.__wbg_get_etchingparams_terms;
export const __wbg_set_etchingparams_terms = __vite__wasmModule.__wbg_set_etchingparams_terms;
export const __wbg_get_etchingparams_turbo = __vite__wasmModule.__wbg_get_etchingparams_turbo;
export const __wbg_set_etchingparams_turbo = __vite__wasmModule.__wbg_set_etchingparams_turbo;
export const etchingparams_new = __vite__wasmModule.etchingparams_new;
export const encode_commitment = __vite__wasmModule.encode_commitment;
export const encode_etching = __vite__wasmModule.encode_etching;
export const __wbg_get_etchingparams_symbol = __vite__wasmModule.__wbg_get_etchingparams_symbol;
export const __wbg_set_etchingparams_symbol = __vite__wasmModule.__wbg_set_etchingparams_symbol;
export const __wbg_etchingparams_free = __vite__wasmModule.__wbg_etchingparams_free;
export const __wbg_rune_free = __vite__wasmModule.__wbg_rune_free;
export const rune_minimum_at_height = __vite__wasmModule.rune_minimum_at_height;
export const rune_is_name_valid_at_height = __vite__wasmModule.rune_is_name_valid_at_height;
export const rune_first_rune_height = __vite__wasmModule.rune_first_rune_height;
export const rune_from_num = __vite__wasmModule.rune_from_num;
export const rune_from_bigint = __vite__wasmModule.rune_from_bigint;
export const rune_from_string = __vite__wasmModule.rune_from_string;
export const rune_to_num = __vite__wasmModule.rune_to_num;
export const rune_to_bigint = __vite__wasmModule.rune_to_bigint;
export const rune_to_string = __vite__wasmModule.rune_to_string;
export const rune_is_reserved = __vite__wasmModule.rune_is_reserved;
export const rustsecp256k1_v0_8_1_context_create = __vite__wasmModule.rustsecp256k1_v0_8_1_context_create;
export const rustsecp256k1_v0_8_1_context_destroy = __vite__wasmModule.rustsecp256k1_v0_8_1_context_destroy;
export const rustsecp256k1_v0_8_1_default_illegal_callback_fn = __vite__wasmModule.rustsecp256k1_v0_8_1_default_illegal_callback_fn;
export const rustsecp256k1_v0_8_1_default_error_callback_fn = __vite__wasmModule.rustsecp256k1_v0_8_1_default_error_callback_fn;
export const __wbindgen_malloc = __vite__wasmModule.__wbindgen_malloc;
export const __wbindgen_realloc = __vite__wasmModule.__wbindgen_realloc;
export const __wbindgen_add_to_stack_pointer = __vite__wasmModule.__wbindgen_add_to_stack_pointer;
export const __wbindgen_free = __vite__wasmModule.__wbindgen_free;
export const __wbindgen_exn_store = __vite__wasmModule.__wbindgen_exn_store;

import { createGlobalStyle, styled } from 'styled-components'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import Tooltip from '@mui/material/Tooltip'
import { useState } from 'react'

import { BREAKPOINTS, COLORS, FONTS, Z_INDEX } from '../constants'
import { copyText } from '../utils'
import { BUTTON_HOVER_STYLES } from './buttons'

interface Props {
  children: React.ReactNode
  textToCopy: string
  className?: string
  iconPlacement?: 'start' | 'end'
  tooltipPlacement?:
    | 'top'
    | 'bottom'
    | 'left'
    | 'right'
    | 'top-start'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'top-end'
}

export function CopyText({
  children,
  textToCopy,
  className,
  tooltipPlacement = 'top-start',
  iconPlacement = 'end',
}: Props) {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)

  const handleTooltipClose = () => {
    setIsTooltipOpen(false)
  }

  const handleTooltipOpen = () => {
    setIsTooltipOpen(true)
    setTimeout(handleTooltipClose, 1000)
  }

  function onCopyTextClick() {
    handleTooltipOpen()
    copyText(textToCopy)
  }

  return (
    <>
      <TooltipStyles />
      <Tooltip
        title='Copied!'
        PopperProps={{
          disablePortal: true,
        }}
        open={isTooltipOpen}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        placement={tooltipPlacement}
        leaveDelay={200}
        className={className}
      >
        <ContentContainer onClick={onCopyTextClick}>
          {iconPlacement === 'start' && <ContentCopyIcon />}
          {children}
          {iconPlacement === 'end' && <ContentCopyIcon />}
        </ContentContainer>
      </Tooltip>
    </>
  )
}

const ContentContainer = styled.div`
  color: ${COLORS.white};
  ${BUTTON_HOVER_STYLES}
  svg {
    height: 15px;
  }

  @media (max-width: ${BREAKPOINTS.medium}) {
    font-size: 13px;
  }
`

export const TooltipStyles = createGlobalStyle`  
  .MuiTooltip-popper{
    z-index: ${Z_INDEX.content}
  }
  .MuiTooltip-tooltip {
    font-size: 12px;
    text-align: center;
    font-family: ${FONTS.text};
    background-color: ${COLORS.buttons.primary};
    color: ${COLORS.black};
  }
  .MuiTooltip-arrow{
    color: ${COLORS.white};
  }
  .MuiTooltip-tooltipPlacementTop {
    margin-bottom: 0px !important;
  }
`

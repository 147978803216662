import { styled } from 'styled-components'
import { Skeleton } from '@mui/material'
import { useEffect } from 'react'

import { HeaderText, Section } from 'src/shared/components'
import { COLORS } from 'src/shared/constants'
import { MintProgressBar, useMintDetails } from 'src/minting'
import { useIsMobile } from 'src/shared/hooks'
import { useRuneDetails } from 'src/runes/hooks'
import { useOrderContext } from 'src/orders'

import { RuneOverviewTabPanel } from './RuneOverviewTabPanel'
import { OrderBookTabPanel } from './OrderBookTabPanel'
import { RuneTradeHistoryTabPanel } from './RuneTradeHistoryTabPanel'
import { RuneNameHeader } from './RuneNameHeader'
import { RunDetailsTabs, useRuneDetailsTabs } from './RuneDetailsTabs'

interface Props {
  runeName: string
}

export function RuneDetailsPageContent({ runeName }: Props) {
  const isMobile = useIsMobile()

  const { setSelectedRune } = useOrderContext()
  const { data: runeDetails, loading } = useRuneDetails(runeName, !runeName)
  const mintDetails = useMintDetails({ runeDetails })

  useEffect(
    function updateSelectedRuneInState() {
      if (runeDetails) {
        setSelectedRune(runeDetails)
      }
    },
    [runeDetails]
  )

  const { selectedTab, changeTab } = useRuneDetailsTabs()

  if (loading) {
    return (
      <Container>
        <RuneName>
          <SkeletonWrapper variant='rounded' height={67} />
        </RuneName>
        <SkeletonWrapper variant='rounded' height={isMobile ? 103 : 60} />
        <InfoContainer>
          <OverviewSection title='OVERVIEW'>
            <SkeletonWrapper height={isMobile ? 610 : 270} variant='rounded' />
          </OverviewSection>
        </InfoContainer>
      </Container>
    )
  }

  return (
    <Container>
      <RuneNameHeader runeDetails={runeDetails} />
      {mintDetails && runeDetails && (
        <MintProgressBar mintDetails={mintDetails} runeDetails={runeDetails} />
      )}

      <RunDetailsTabs selectedTab={selectedTab} onTabChange={changeTab} />
      <TabPanelContainer>
        <RuneOverviewTabPanel
          visible={selectedTab === 'Overview'}
          runeDetails={runeDetails}
          mintDetails={mintDetails}
        />

        <OrderBookTabPanel visible={selectedTab === 'Orderbook'} runeDetails={runeDetails} />

        <RuneTradeHistoryTabPanel
          visible={selectedTab === 'Trade History'}
          runeDetails={runeDetails}
        />
      </TabPanelContainer>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
`

const SkeletonWrapper = styled(Skeleton)`
  border-radius: 10px;
  background-color: ${COLORS.background.container};
`

const RuneName = styled(HeaderText)``

const InfoContainer = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
`

const OverviewSection = styled(Section)`
  display: flex;
  flex: 2;
`

export const TabPanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

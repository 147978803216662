import { css, styled } from 'styled-components'
import { useEffect, useState } from 'react'
import { base64 } from '@scure/base'
import { toast } from 'react-toastify'

import * as btc from '@packages/scure-btc-signer'
import * as wasm from '@packages/rune-wasm-frontend'
import { API_ENDPOINTS } from '@packages/constants'
import { IsValidRuneNameResponse } from '@packages/interfaces'

import { hexToRgb, readItemFromLocalStorage, writeItemToLocalStorage } from 'src/shared/utils'
import {
  BigIntInput,
  Button,
  Checkbox,
  DrawerCard,
  NumberInput,
  TextInput,
  NumberInputWithButtons,
  RadioButtonGroup,
  Divider,
} from 'src/shared/components'
import { JsEtchingParams, WalletPickerMenu, useWalletContext } from 'src/wallet'
import { BREAKPOINTS, COLORS } from 'src/shared/constants'
import { NetworkFeeSelector, SuccessfulTransactionToast } from 'src/web3'
import { IS_TESTNET_DEPLOYMENT } from 'src/settings'
import { apiFetch } from 'src/api'

import {
  ETCH_COMMITS_TRANSACTION_IDS,
  ETCH_COMMIT_REQUEST_LOCAL_STORAGE_KEY_PREFIX,
  RUNE_NAME_REGEX,
} from '../constants'
import { EtchRunePayload } from '../interfaces'
import { useCommittedEtchings } from '../hooks'

const MAX_DECIMALS = 38

const MAX_SUPPLY_CAP = BigInt('340282366920938463463374607431768211455')

const DEFAULTS = {
  showAdvancedOptions: false,
  useGeneratedRuneName: false,
  runeName: undefined,
  runeSymbol: undefined,
  isTurbo: true,
  hasPremine: false,
  premineAmount: 0n,
  premineAddress: '',
  decimals: 18,
  isOpenMint: true,
  amountPerMint: undefined,
  maxMints: undefined,
  useRelativeBlock: false,
  isImmediateMint: true,
  blockStartRelative: 0,
  blockStartAbsolute: 0,
  isFiniteMintTerm: false,
  blockEndRelative: 0,
  blockEndAbsolute: 0,
}

export function EtchCommitRuneForm() {
  const [isWalletModalOpen, setIsWalletModalOpen] = useState(false)
  const { runesAddress, isConnected, commitEtchRune, blockTip } = useWalletContext()
  const { forceUpdate } = useCommittedEtchings()

  const [showAdvancedOptions, setShowAdvancedOptions] = useState(DEFAULTS.showAdvancedOptions)

  const [runeName, setRuneName] = useState<string | undefined>(DEFAULTS.runeName)
  const [useGeneratedRuneName, setUseGeneratedRuneName] = useState<boolean>(
    DEFAULTS.useGeneratedRuneName
  )
  const [runeNameError, setRuneNameError] = useState<string>()
  const [runeNameEdited, setRuneNameEdited] = useState<boolean>(false)
  const [revealBlockHeight, setRevealBlockHeight] = useState<number | undefined>()

  const [runeSymbol, setRuneSymbol] = useState<string | undefined>(DEFAULTS.runeSymbol)
  const [runeSymbolError, setRuneSymbolError] = useState<string>()

  const [isTurbo, setIsTurbo] = useState(DEFAULTS.isTurbo)

  const [hasPremine, setHasPremine] = useState(DEFAULTS.hasPremine)
  const [premineAmount, setPremineAmount] = useState(DEFAULTS.premineAmount)
  const [premineAmountError, setPremineAmountError] = useState<string>()
  const [premineAddress, setPremineAddress] = useState(DEFAULTS.premineAddress)
  const [premineAddressError, setPremineAddressError] = useState<string>()

  const [decimals, setDecimals] = useState<number>(DEFAULTS.decimals)

  const [isOpenMint, setIsOpenMint] = useState(DEFAULTS.isOpenMint)
  const [amountPerMint, setAmountPerMint] = useState<bigint | undefined>(DEFAULTS.amountPerMint)
  const [amountPerMintError, setAmountPerMintError] = useState<string>()
  const [maxMints, setMaxMints] = useState<bigint | undefined>(DEFAULTS.maxMints)
  const [maxMintsError, setMaxMintsError] = useState<string>()

  const [useRelativeBlock, setUseRelativeBlock] = useState(DEFAULTS.useRelativeBlock)

  const [isImmediateMint, setIsImmediateMint] = useState(DEFAULTS.isImmediateMint)
  const [blockStartRelative, setBlockStartRelative] = useState(DEFAULTS.blockStartRelative)
  const [blockStartAbsolute, setBlockStartAbsolute] = useState(DEFAULTS.blockStartAbsolute)
  const [blockStartError, setBlockStartError] = useState<string>()

  const [isFiniteMintTerm, setIsFiniteMintTerm] = useState(DEFAULTS.isFiniteMintTerm)
  const [blockEndRelative, setBlockEndRelative] = useState(DEFAULTS.blockEndRelative)
  const [blockEndAbsolute, setBlockEndAbsolute] = useState(DEFAULTS.blockEndAbsolute)
  const [blockEndError, setBlockEndError] = useState<string>()

  const [generalError, setGeneralError] = useState<string>()

  const [selectedNetworkFee, setSelectedNetworkFee] = useState(0)

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (runesAddress && premineAddress.length === 0) {
      setPremineAddress(runesAddress.addrString)
    }
  }, [runesAddress])

  useEffect(() => {
    if (
      amountPerMint &&
      maxMints &&
      amountPerMint > 0n &&
      maxMints > 0n &&
      premineAmount + amountPerMint * maxMints > MAX_SUPPLY_CAP
    ) {
      setMaxMintsError('Max supply cap exceeded')
      setAmountPerMintError('Max supply cap exceeded')
      setPremineAmountError('Max supply cap exceeded')
    } else {
      setMaxMintsError(undefined)
      setAmountPerMintError(undefined)
      setPremineAmountError(undefined)
    }
  }, [premineAmount, amountPerMint, maxMints])

  function resetForm() {
    setShowAdvancedOptions(DEFAULTS.showAdvancedOptions)
    setUseGeneratedRuneName(DEFAULTS.useGeneratedRuneName)
    setRuneName(DEFAULTS.runeName)
    setRuneNameEdited(false)
    setRuneSymbol(DEFAULTS.runeSymbol)
    setIsTurbo(DEFAULTS.isTurbo)
    setHasPremine(DEFAULTS.hasPremine)
    setPremineAmount(DEFAULTS.premineAmount)
    setPremineAddress(runesAddress?.addrString ?? DEFAULTS.premineAddress)
    setDecimals(DEFAULTS.decimals)
    setIsOpenMint(DEFAULTS.isOpenMint)
    setAmountPerMint(DEFAULTS.amountPerMint)
    setMaxMints(DEFAULTS.maxMints)
    setUseRelativeBlock(DEFAULTS.useRelativeBlock)
    setIsImmediateMint(DEFAULTS.isImmediateMint)
    setBlockStartRelative(DEFAULTS.blockStartRelative)
    setBlockStartAbsolute(DEFAULTS.blockStartAbsolute)
    setIsFiniteMintTerm(DEFAULTS.isFiniteMintTerm)
    setBlockEndRelative(DEFAULTS.blockEndRelative)
    setBlockEndAbsolute(DEFAULTS.blockEndAbsolute)
    setLoading(false)
  }

  function resetErrors() {
    setRuneNameError(undefined)
    setAmountPerMintError(undefined)
    setMaxMintsError(undefined)
    setBlockStartError(undefined)
    setBlockEndError(undefined)
    setPremineAmountError(undefined)
    setPremineAddressError(undefined)
    setLoading(false)
  }

  async function commitRuneEtching() {
    if (!isConnected || !runesAddress) {
      setIsWalletModalOpen(true)
      return
    }

    resetErrors()

    let hasError = false

    if (showAdvancedOptions) {
      if (!hasPremine && !isOpenMint) {
        setGeneralError('Either Premine or Open Mint must be enabled')
        return
      }

      if (!runeName && !useGeneratedRuneName) {
        setRuneNameError('Required')
        hasError = true
      }

      if (
        !isImmediateMint &&
        ((useRelativeBlock && blockStartRelative === 0) ||
          (!useRelativeBlock && blockStartAbsolute === 0))
      ) {
        setBlockStartError('Required')
        hasError = true
      }

      if (
        isFiniteMintTerm &&
        ((useRelativeBlock && blockEndRelative === 0) ||
          (!useRelativeBlock && blockEndAbsolute === 0))
      ) {
        setBlockEndError('Required')
        hasError = true
      }

      if (hasPremine) {
        if (premineAmount <= 0n) {
          setPremineAmountError('Required')
          hasError = true
        }

        if (!premineAddress) {
          setPremineAddressError('Required')
          hasError = true
        }
      }
    } else {
      if (!runeName) {
        setRuneNameError('Required')
        hasError = true
      }
    }

    if (isOpenMint) {
      if (!amountPerMint) {
        setAmountPerMintError('Required')
        hasError = true
      }

      if (!maxMints) {
        setMaxMintsError('Required')
        hasError = true
      }
    }

    if (hasError) return

    setLoading(true)

    const etchingParams: JsEtchingParams = {
      rune:
        runeName && !useGeneratedRuneName
          ? runeName.replace(RUNE_NAME_REGEX.replaceSpacersWithPeriod, '.')
          : undefined,
      divisibility: decimals,
      premine: hasPremine ? premineAmount : 0n,
      symbol: runeSymbol,
      turbo: isTurbo,
      has_terms: isOpenMint,
      terms_cap: maxMints ?? 0n,
      terms_amount: amountPerMint ?? 0n,
      terms_relative_open_height: isImmediateMint ? undefined : BigInt(blockStartRelative),
      terms_relative_close_height: isFiniteMintTerm ? BigInt(blockEndRelative) : undefined,
      terms_absolute_open_height: isImmediateMint ? undefined : BigInt(blockStartAbsolute),
      terms_absolute_close_height: isFiniteMintTerm ? BigInt(blockEndAbsolute) : undefined,
      premine_vout: undefined,
    }

    try {
      const etchResult = await commitEtchRune({
        fee: BigInt(selectedNetworkFee),
        etchParams: etchingParams,
      })

      if (etchResult.commitTxResult.success && etchResult.commitTxResult.signedPsbtBase64) {
        const tx = btc.Transaction.fromPSBT(
          base64.decode(etchResult.commitTxResult.signedPsbtBase64)
        )

        const etchCommitRequest: EtchRunePayload = {
          etchingParams,
          etchingCommit: {
            commitTxResult: etchResult.commitTxResult,
            committedByAddress: runesAddress.addrString,
            committedAtTimestamp: Date.now(),
            commitTx: tx,
            revealScript: etchResult.revealScript,
            txId: etchResult.txId,
            revealAmount: etchResult.revealAmount,
            revealKey: etchResult.revealKey,
          },
        }

        writeItemToLocalStorage(
          ETCH_COMMIT_REQUEST_LOCAL_STORAGE_KEY_PREFIX + etchCommitRequest.etchingCommit.txId,
          etchCommitRequest
        )
        const previouslyEtchedRunes = readItemFromLocalStorage(
          ETCH_COMMITS_TRANSACTION_IDS,
          []
        ) as string[]
        writeItemToLocalStorage(ETCH_COMMITS_TRANSACTION_IDS, [
          ...previouslyEtchedRunes,
          etchCommitRequest.etchingCommit.txId,
        ])

        toast(
          <SuccessfulTransactionToast
            message='Etch successful!'
            transactionId={etchResult.txId ?? ''}
          />,
          { toastId: etchResult.txId ?? `${Date.now()}` }
        )

        resetForm()
        forceUpdate()
      } else {
        setGeneralError(etchResult.commitTxResult.error)
      }
    } catch (error: any) {
      setGeneralError(error.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (runeNameEdited) runeNameValidation()
  }, [runeName, runeNameEdited])

  function onShowAdvancedOptionsChange(showAdvancedOptions: boolean) {
    setUseGeneratedRuneName(DEFAULTS.useGeneratedRuneName)

    setHasPremine(DEFAULTS.hasPremine)

    setIsOpenMint(DEFAULTS.isOpenMint)

    setIsImmediateMint(DEFAULTS.isImmediateMint)

    setIsFiniteMintTerm(DEFAULTS.isFiniteMintTerm)

    setShowAdvancedOptions(showAdvancedOptions)
  }

  function onRevealBlockHeightChange(newRevealBlockHeight: number) {
    runeNameValidation()
    setRevealBlockHeight(newRevealBlockHeight)
  }

  function onRuneNameChange(newRuneName: string) {
    setRuneNameEdited(true)
    setRuneNameError(undefined)
    const formattedRuneName = newRuneName
      .toUpperCase()
      .replace(RUNE_NAME_REGEX.replaceSpacersWithBullet, '•')

    if (!RUNE_NAME_REGEX.alphaOrSpacer.test(formattedRuneName)) {
      return
    } else if (!RUNE_NAME_REGEX.singleConsecutiveSpacers.test(formattedRuneName)) {
      return
    }

    setRuneName(formattedRuneName)
  }

  function runeNameValidation() {
    if (!runeName) {
      setRuneNameError('Required')
    } else if (!RUNE_NAME_REGEX.spacersNotAtStartOrEnd.test(runeName)) {
      setRuneNameError('Rune name cannot start or end with a spacer')
    } else if (!RUNE_NAME_REGEX.spacersOnlyBetweenAlphas.test(runeName)) {
      setRuneNameError('Rune name can only have spacers between alphabetic characters')
    } else {
      const blockHeight =
        showAdvancedOptions && revealBlockHeight ? revealBlockHeight : blockTip?.height
      const isValidRuneNameAtBlock = wasm.SpacedRune.is_name_valid_at_height(
        runeName,
        IS_TESTNET_DEPLOYMENT ? 'testnet' : 'mainnet',
        (blockHeight ?? 0) + 1
      )
      if (!isValidRuneNameAtBlock) {
        if (blockHeight === blockTip?.height) {
          setRuneNameError('Invalid rune name at current block height')
        } else {
          setRuneNameError(`Invalid rune name at block height ${blockHeight}`)
        }
      }
    }
  }

  async function onRuneNameBlur() {
    try {
      const response = await apiFetch<IsValidRuneNameResponse>(
        API_ENDPOINTS.GET.runes.name.isValidRuneName,
        { runeName: runeName ?? '' }
      )

      if (!response.isValid) {
        setRuneNameError('Rune name is already taken')
      }
    } catch (error) {
      console.error(error)
    }
  }

  function onRuneSymbolChange(newRuneSymbol: string) {
    setRuneSymbolError(undefined)

    if (newRuneSymbol.trim().length === 0) {
      setRuneSymbolError('Required')
    }

    setRuneSymbol(newRuneSymbol.trim())
  }

  function onDecimalsChange(newDecimals: number) {
    if (newDecimals < 0 || newDecimals > MAX_DECIMALS) {
      return
    }

    setDecimals(newDecimals)
  }

  function onUseRelativeBlockChange(type: string) {
    if (type === 'relative') {
      setUseRelativeBlock(true)
    } else {
      setUseRelativeBlock(false)
    }
  }

  function onAmountPerMintChange(newAmountPerMint: bigint) {
    setAmountPerMintError(undefined)
    setAmountPerMint(newAmountPerMint)
  }

  function onMaxMintsChange(newMaxMints: bigint) {
    setMaxMintsError(undefined)
    setMaxMints(newMaxMints)
  }

  function onBlockStartRelativeChange(newBlockStartRelative?: number) {
    setBlockStartError(undefined)
    setBlockStartRelative(newBlockStartRelative ?? DEFAULTS.blockStartRelative)
  }

  function onBlockEndRelativeChange(newBlockEndRelative?: number) {
    setBlockEndError(undefined)
    setBlockEndRelative(newBlockEndRelative ?? DEFAULTS.blockEndRelative)
  }

  function onBlockStartAbsoluteChange(newBlockStartAbsolute?: number) {
    setBlockStartError(undefined)
    setBlockStartAbsolute(newBlockStartAbsolute ?? DEFAULTS.blockStartAbsolute)
  }

  function onBlockEndAbsoluteChange(newBlockEndAbsolute?: number) {
    setBlockEndError(undefined)
    setBlockEndAbsolute(newBlockEndAbsolute ?? DEFAULTS.blockEndAbsolute)
  }

  function onImmediateMintChange(immediateMint: boolean) {
    setBlockStartError(undefined)
    setIsImmediateMint(immediateMint)
  }

  function onFiniteMintTermChange(isFiniteMintTerm: boolean) {
    setBlockEndError(undefined)
    setIsFiniteMintTerm(isFiniteMintTerm)
  }

  function onSelectedNetworkFeeChange(fee: number) {
    setSelectedNetworkFee(fee)
  }

  return (
    <Container>
      <Title>Etch Rune</Title>
      <Form>
        <FormHeader>
          <AdvancedOptions>
            Enable Advanced Options
            <Checkbox checked={showAdvancedOptions} onChange={onShowAdvancedOptionsChange} />
          </AdvancedOptions>
        </FormHeader>
        {showAdvancedOptions && (
          <>
            <LineRow>
              <LabelRow>
                <Header>Auto Generate Rune Name</Header>
                <SubHeader>
                  Use the auto generated name{' '}
                  <GeneratedRuneNameFormat>RESERVED+{'<rune_id>'}</GeneratedRuneNameFormat>
                </SubHeader>
              </LabelRow>
              <InputRow>
                <Checkbox checked={useGeneratedRuneName} onChange={setUseGeneratedRuneName} />
              </InputRow>
            </LineRow>
            <LineRow>
              <Column>
                <LabelRow>
                  <Header>Reveal Block Height</Header>
                  <SubHeader>
                    Used to determine if the rune name will be valid at the time of the reveal
                  </SubHeader>
                </LabelRow>
                <InputRow>
                  <IntegerInput
                    value={revealBlockHeight ?? blockTip?.height ?? 0}
                    onChange={onRevealBlockHeightChange}
                    minValue={blockTip?.height ?? 0}
                  />
                </InputRow>
              </Column>
            </LineRow>
          </>
        )}
        <LineRowExpandOnMobile>
          <Column flex={3}>
            <LabelRow>
              <Header>Rune Name {useGeneratedRuneName ? '' : '*'}</Header>
            </LabelRow>
            <InputRow>
              <TextInputWrapper
                value={runeName && !useGeneratedRuneName ? runeName : ''}
                onChange={onRuneNameChange}
                onBlur={onRuneNameBlur}
                error={!!runeNameError}
                helperText={runeNameError}
                disabled={useGeneratedRuneName}
              />
            </InputRow>
          </Column>
          <SymbolDecimalContainer>
            <Column>
              <LabelRow>
                <Header>Symbol</Header>
              </LabelRow>
              <InputRow>
                <RuneSymbolInput
                  value={runeSymbol ?? ''}
                  onChange={onRuneSymbolChange}
                  error={!!runeSymbolError}
                  helperText={runeSymbolError}
                />
              </InputRow>
            </Column>
            {showAdvancedOptions && (
              <Column>
                <LabelRow>
                  <CenteredHeader>Decimals*</CenteredHeader>
                </LabelRow>
                <InputRow>
                  <IntegerInput
                    value={decimals}
                    onChange={onDecimalsChange}
                    minValue={0}
                    maxValue={MAX_DECIMALS}
                  />
                </InputRow>
              </Column>
            )}
          </SymbolDecimalContainer>
        </LineRowExpandOnMobile>

        <LineRow>
          <LabelRow>
            <Header>Turbo</Header>
            <SubHeader>Opt into new features as they are released</SubHeader>
          </LabelRow>
          <InputRow>
            <Checkbox checked={isTurbo} onChange={setIsTurbo} />
          </InputRow>
        </LineRow>

        <Divider />

        <LineRow>
          <LabelRow>
            <Header>Open Mint</Header>
            <SubHeader>
              {showAdvancedOptions
                ? 'Allow an open minting period'
                : 'Minting will be open to everyone'}
            </SubHeader>
          </LabelRow>
          {showAdvancedOptions && (
            <InputRow>
              <Checkbox checked={isOpenMint} onChange={setIsOpenMint} />
            </InputRow>
          )}
        </LineRow>

        {isOpenMint && (
          <OpenMintContainer>
            <LineRow>
              <Column flex={1}>
                <LabelRow>Amount Per Mint*</LabelRow>
                <InputRow>
                  <BigIntInput
                    value={amountPerMint ?? 0n}
                    onChange={onAmountPerMintChange}
                    decimals={decimals}
                    error={!!amountPerMintError}
                    helperText={amountPerMintError}
                  />
                </InputRow>
              </Column>
              <Column flex={1}>
                <LabelRow>Max Mints*</LabelRow>
                <InputRow>
                  <BigIntInput
                    value={maxMints ?? 0n}
                    onChange={onMaxMintsChange}
                    error={!!maxMintsError}
                    helperText={maxMintsError}
                    decimals={0}
                  />
                </InputRow>
              </Column>
            </LineRow>

            {showAdvancedOptions && (
              <StackedRow>
                <RadioButtonGroup
                  label='Minting Start / End Block'
                  value={useRelativeBlock ? 'relative' : 'absolute'}
                  options={[
                    { value: 'absolute', label: 'Absolute' },
                    { value: 'relative', label: 'Relative' },
                  ]}
                  onChange={onUseRelativeBlockChange}
                  row
                />
                <BlockTerms>
                  {useRelativeBlock
                    ? 'Will start the mint at n blocks after the reveal transaction and end the mint at n blocks after the mint starts'
                    : 'Will start and end the mint at the configured block height'}
                </BlockTerms>
              </StackedRow>
            )}

            <LineRowExpandOnMobile>
              {showAdvancedOptions ? (
                <>
                  <InlineColumn flex={2}>
                    <LabelRow flex={5}>
                      <Header>Start Immediately</Header>
                      <SubHeader>Start minting when the reveal transaction is confirmed</SubHeader>
                    </LabelRow>
                    <InputRow flex={1}>
                      <Checkbox checked={isImmediateMint} onChange={onImmediateMintChange} />
                    </InputRow>
                  </InlineColumn>
                  {!isImmediateMint && (
                    <>
                      {useRelativeBlock ? (
                        <InlineColumn flex={3}>
                          <LabelRow>
                            <Header>Block Start Relative</Header>
                            <SubHeader>
                              Start minting {blockStartRelative} blocks from the reveal transaction
                              block
                            </SubHeader>
                          </LabelRow>
                          <InputRow>
                            <BlockInput
                              value={isImmediateMint ? 0 : blockStartRelative}
                              onChange={onBlockStartRelativeChange}
                              disabled={isImmediateMint}
                              error={!!blockStartError}
                              helperText={blockStartError}
                            />
                          </InputRow>
                        </InlineColumn>
                      ) : (
                        <InlineColumn flex={3}>
                          <LabelRow>
                            <Header>Block Start Absolute</Header>
                            <SubHeader>Start minting at block {blockStartAbsolute}</SubHeader>
                          </LabelRow>
                          <InputRow>
                            <BlockInput
                              value={isImmediateMint ? 0 : blockStartAbsolute}
                              onChange={onBlockStartAbsoluteChange}
                              disabled={isImmediateMint}
                              error={!!blockStartError}
                              helperText={blockStartError}
                            />
                          </InputRow>
                        </InlineColumn>
                      )}
                    </>
                  )}
                </>
              ) : (
                <SubHeader>
                  <MintingTerm>
                    Minting will immediately start once the reveal transaction is confirmed
                  </MintingTerm>
                </SubHeader>
              )}
            </LineRowExpandOnMobile>

            <LineRowExpandOnMobile>
              {showAdvancedOptions ? (
                <>
                  <InlineColumn flex={2}>
                    <LabelRow flex={5}>
                      <Header>Time Limit</Header>
                      <SubHeader>Stop minting at a specific block</SubHeader>
                    </LabelRow>
                    <InputRow flex={1}>
                      <Checkbox checked={isFiniteMintTerm} onChange={onFiniteMintTermChange} />
                    </InputRow>
                  </InlineColumn>
                  {isFiniteMintTerm && (
                    <>
                      {useRelativeBlock ? (
                        <InlineColumn flex={3}>
                          <LabelRow>
                            <Header>Block End Relative</Header>
                            <SubHeader>
                              End minting {blockEndRelative} blocks from the start block
                            </SubHeader>
                          </LabelRow>
                          <InputRow>
                            <BlockInput
                              value={isFiniteMintTerm ? blockEndRelative : 0}
                              onChange={onBlockEndRelativeChange}
                              disabled={!isFiniteMintTerm}
                              error={!!blockEndError}
                              helperText={blockEndError}
                            />
                          </InputRow>
                        </InlineColumn>
                      ) : (
                        <InlineColumn flex={3}>
                          <LabelRow>
                            <Header>Block End Absolute</Header>
                            <SubHeader>Minted ends at block {blockEndAbsolute}</SubHeader>
                          </LabelRow>
                          <InputRow>
                            <BlockInput
                              value={isFiniteMintTerm ? blockEndAbsolute : 0}
                              onChange={onBlockEndAbsoluteChange}
                              disabled={!isFiniteMintTerm}
                              error={!!blockEndError}
                              helperText={blockEndError}
                            />
                          </InputRow>
                        </InlineColumn>
                      )}
                    </>
                  )}
                </>
              ) : (
                <SubHeader>
                  <MintingTerm>
                    Minting will immediately end once the max supply is reached
                  </MintingTerm>
                </SubHeader>
              )}
            </LineRowExpandOnMobile>
          </OpenMintContainer>
        )}

        <Divider />

        {showAdvancedOptions && (
          <>
            <LineRow>
              <LabelRow>
                <Header>Premine</Header>
                <SubHeader>Mine tokens before mint</SubHeader>
              </LabelRow>
              <InputRow>
                <Checkbox checked={hasPremine} onChange={setHasPremine} />
              </InputRow>
            </LineRow>

            {hasPremine && (
              <PremineContainer>
                <StackedRow>
                  <LabelRow>Premine Amount</LabelRow>
                  <InputRow>
                    <BigIntInput
                      value={premineAmount}
                      onChange={setPremineAmount}
                      decimals={decimals}
                      error={!!premineAmountError}
                      helperText={premineAmountError}
                    />
                  </InputRow>
                </StackedRow>
                <StackedRow>
                  <LabelRow>Premine To Address</LabelRow>
                  <InputRow>
                    <TextInput
                      disabled
                      value={premineAddress}
                      onChange={setPremineAddress}
                      error={!!premineAddressError}
                      helperText={premineAddressError}
                    />
                  </InputRow>
                </StackedRow>
              </PremineContainer>
            )}
            <Divider />
          </>
        )}

        <NetworkFeeSelectorWrapper
          selectedFee={selectedNetworkFee}
          onChange={onSelectedNetworkFeeChange}
        />

        <Divider />

        <RequiredNote>* Required</RequiredNote>

        {generalError && <GeneralError>{generalError}</GeneralError>}
        <FormFooter>
          <InputRow>
            <Button onClick={commitRuneEtching} loading={loading}>
              {loading ? 'See Wallet' : 'Submit'}
            </Button>
          </InputRow>
        </FormFooter>
      </Form>
      <WalletPickerMenu isOpen={isWalletModalOpen} onClose={() => setIsWalletModalOpen(false)} />
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  max-width: 750px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  .MuiInputBase-input {
    height: 22px;
    color: ${COLORS.white};
    background-color: ${COLORS.inputs};
  }
`

const Title = styled.h2`
  font-size: 30px;
  padding-bottom: 20px;
`

const Form = styled(DrawerCard)`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const FormHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: ${BREAKPOINTS.medium}) {
    flex-direction: column;
  }
`

const FormFooter = styled.div`
  display: flex;
  gap: 10px;
  flex: 1;
  justify-content: center;
`

const RequiredNote = styled.div`
  text-align: right;
`

const AdvancedOptions = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  span {
    padding: 0px;
    width: 35px;
  }
`

const GeneratedRuneNameFormat = styled.code`
  color: ${COLORS.buttons.primary};
`

const StackedRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const Column = styled.div<{ flex?: number }>`
  gap: 5px;
  display: flex;
  flex-direction: column;
  ${(props) =>
    props.flex &&
    css`
      flex: ${props.flex};
    `}
`

const InlineColumn = styled(Column)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  span {
    padding: 0px;
  }
`

const LineRow = styled.div`
  display: flex;
  gap: 10px;
  flex: 1;
`

const LineRowExpandOnMobile = styled(LineRow)`
  @media (max-width: ${BREAKPOINTS.medium}) {
    flex-direction: column;
  }
`

const InputRow = styled.div<{ flex?: number }>`
  display: flex;
  ${(props) =>
    props.flex &&
    css`
      flex: ${props.flex};
    `}
`

const LabelRow = styled.div<{ flex?: number }>`
  font-weight: 500;
  ${(props) =>
    props.flex &&
    css`
      flex: ${props.flex};
    `}
`

const Header = styled.div``

const CenteredHeader = styled(Header)`
  text-align: center;
`

const SubHeader = styled.div`
  font-weight: 300;
  font-size: 14px;
  color: ${hexToRgb(COLORS.white, 0.8)};
  line-height: 18px;
`

const TextInputWrapper = styled(TextInput)`
  width: 100%;
`

const SymbolDecimalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`

const RuneSymbolInput = styled(TextInputWrapper)`
  max-width: 80px;
`

const PremineContainer = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  div {
    width: 100%;
  }
  input {
    width: 100%;
  }
`

const IntegerInput = styled(NumberInputWithButtons)`
  input {
    width: 100px;
    height: 55px;
    border-radius: 8px;
    color: ${COLORS.white};
    border-radius: 8px;
    background-color: ${COLORS.inputs};
  }
`

const OpenMintContainer = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
`

const BlockTerms = styled.div`
  color: ${hexToRgb(COLORS.white, 0.8)};
`

const BlockInput = styled(NumberInput)`
  input {
    width: 100px !important;
  }
`

const GeneralError = styled.div`
  color: ${COLORS.negative};
  text-align: center;
`

const MintingTerm = styled.li`
  padding-left: 3px;
  @supports (-moz-appearance: none) {
    padding-left: 12px;
  }
`

const NetworkFeeSelectorWrapper = styled(NetworkFeeSelector)``

import { useCallback, useState } from 'react'

import type { Rune, RuneDetails } from '@packages/interfaces'

import { RuneSelectButton } from 'src/orders/components/RuneSelectButton'
import { SelectRuneModal } from 'src/orders/components/SelectRuneModal'
import { useOrderContext } from 'src/orders'

export type RuneSelectorProps = {
  runeDetails: RuneDetails | undefined
  onRuneSelectCompleted?: (rune: Rune | undefined) => void
}

export function RuneSelector({ runeDetails, onRuneSelectCompleted }: RuneSelectorProps) {
  const { setSelectedRune } = useOrderContext()

  const [isSelectRuneModalOpen, setIsSelectRuneModalOpen] = useState(false)

  const handleRuneSelect = useCallback(
    (rune: Rune) => {
      setSelectedRune(rune)
    },
    [setSelectedRune]
  )

  const onSelectRuneModalClose = useCallback(
    (rune: Rune | undefined) => {
      setIsSelectRuneModalOpen(false)
      if (rune) {
        onRuneSelectCompleted?.(rune)
      }
    },
    [onRuneSelectCompleted]
  )

  return (
    <>
      <RuneSelectButton
        selectedRune={runeDetails}
        selectedRuneName={runeDetails?.runeName}
        onClick={() => {
          setIsSelectRuneModalOpen(true)
        }}
        isLoadingNewRune={false}
      />
      <SelectRuneModal
        isOpen={isSelectRuneModalOpen}
        onSelectRune={handleRuneSelect}
        onClose={onSelectRuneModalClose}
      />
    </>
  )
}

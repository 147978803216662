import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { styled } from 'styled-components'
import { useSearchParams } from 'react-router-dom'
import { useEffect, type MouseEvent } from 'react'

import { PAGE_TAB_SEARCH_PARAM_NAME } from 'src/pages/constants'

import { BREAKPOINTS, COLORS, FONTS } from '../../constants'
import { hexToRgb } from '../../utils'
import { BUTTON_HOVER_STYLES } from './styles'

const DEFAULT_SEARCH_PARAM_NAME = PAGE_TAB_SEARCH_PARAM_NAME

export interface ButtonGroupOptions {
  value: string
  label: string
  title?: string
  subtitle?: string
  disabled?: boolean
}

interface Props {
  options: ButtonGroupOptions[]
  value: string
  onChange: (value: string) => void
  exclusive?: boolean
  className?: string
  updateSearchParams: boolean
  searchParamName?: string
  disabled?: boolean
}

export function LargeButtonGroup({
  value,
  onChange,
  options,
  exclusive = false,
  className,
  disabled,
  updateSearchParams = false,
  searchParamName = DEFAULT_SEARCH_PARAM_NAME,
}: Props) {
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const searchParamTab = searchParams.get(searchParamName)
    if (searchParamTab && updateSearchParams) {
      handleOnChange(searchParamTab, true) // pass true to indicate that this is an initial load, don't push new history
    } else if (!searchParamTab && updateSearchParams) {
      // default to first option if no tab is selected
      handleOnChange(options[0].value, true) // default tab selection without pushing history
    }

    function popStateEventHandler(event: any) {
      const search = event.target?.location?.search
      const urlSearchParams = new URLSearchParams(search)
      const tab = urlSearchParams.get(searchParamName)
      if (tab) {
        handleOnChange(tab, true) // no history push on back navigation
      }
    }

    addEventListener('popstate', popStateEventHandler)

    return () => removeEventListener('popstate', popStateEventHandler)
  }, [searchParams, updateSearchParams, searchParamName])

  // Modify onChange function to avoid pushing history unnecessarily
  function handleOnChange(tab: string, replaceState = false) {
    onChange(tab) // update the component's state with the new tab
    if (replaceState) {
      // Replace the current history entry instead of pushing a new one
      const newUrl = new URL(window.location.href)
      newUrl.searchParams.set(searchParamName, tab)
      window.history.replaceState(null, '', newUrl.toString())
    } else {
      // This will push a new history entry (only when explicitly needed)
      const newUrl = new URL(window.location.href)
      newUrl.searchParams.set(searchParamName, tab)
      window.history.pushState(null, '', newUrl.toString())
    }
  }

  function handleChange(_event: MouseEvent<HTMLElement>, selectedValue: string): void {
    if (updateSearchParams) {
      handleOnChange(selectedValue)
    } else {
      onChange(selectedValue)
    }
  }

  return (
    <ToggleButtonGroupWrapper
      value={value}
      exclusive={exclusive}
      onChange={handleChange}
      className={className}
      disabled={disabled}
    >
      {options.map((option) => {
        return (
          <ToggleButton
            key={option.label}
            value={option.value}
            disabled={value === option.value || option.disabled}
          >
            {option.title && <Title>{option.title}</Title>}
            {option.label}
            {option.subtitle && <Subtitle>{option.subtitle}</Subtitle>}
          </ToggleButton>
        )
      })}
    </ToggleButtonGroupWrapper>
  )
}

const ToggleButtonGroupWrapper = styled(ToggleButtonGroup)`
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  @media (max-width: ${BREAKPOINTS.medium}) {
    justify-content: center;
    gap: 10px;
  }

  button {
    text-wrap: nowrap;
    display: flex;
    flex-direction: column;
    text-transform: none;
    gap: 5px;
    height: 42px;
    background-color: ${COLORS.background.container};
    color: ${hexToRgb(COLORS.white, 0.5)};
    border-radius: 100px !important;
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    font-family: ${FONTS.text};

    ${BUTTON_HOVER_STYLES}
    &:hover {
      background-color: ${COLORS.background.container};
      color: ${hexToRgb(COLORS.white, 0.5)};
    }
    @media (max-width: ${BREAKPOINTS.medium}) {
      flex: 1;
      max-width: 250px;
      font-size: 13px;
      height: 35px;
    }
  }

  .Mui-selected {
    background-color: ${COLORS.white};
    color: ${COLORS.black};
    &:hover {
      background-color: ${COLORS.white};
      color: ${COLORS.black};
    }
  }
`

const Title = styled.h3`
  color: ${COLORS.black};
  font-weight: 300;
`

const Subtitle = styled.div`
  font-weight: 300;
  font-size: 12px;
  color: ${COLORS.black};
`

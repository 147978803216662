import { useMemo } from 'react'

import { API_ENDPOINTS } from '@packages/constants'

import { useUpdateOnBlockPaginationApi } from 'src/api'
import { replaceUrlParams } from 'src/shared/utils'

export interface RewardHistoryQueryParams {
  limit: number
}

export interface RewardEventHistoryResponse {
  id: bigint
  points: number
  eventType: string
  season: number | undefined
  eventTransactionId: string
  createdAt: number
  address: string
  includedTimestamp: bigint | undefined
}

export function useRewardHistoryApi<T extends RewardEventHistoryResponse>({
  address,
  queryParams,
  disabled = false,
}: {
  address: string
  queryParams: RewardHistoryQueryParams
  disabled?: boolean
}) {
  const apiParams = useMemo(() => {
    return {
      endpoint: replaceUrlParams(API_ENDPOINTS.GET.accounts.rewardHistory, {
        address: address,
      }),
      otherQueryParams: queryParams,
      disabled,
    }
  }, [address, disabled, queryParams])

  const res = useUpdateOnBlockPaginationApi<T>(apiParams)

  return res
}

import type { ButtonGroupOptions } from 'src/shared/components'

import type { RuneDetailsTabs } from './types'

export const RUNE_DETAILS_TABS: Record<
  RuneDetailsTabs,
  {
    value: RuneDetailsTabs
    label: string
  }
> = {
  Overview: {
    value: 'Overview',
    label: 'Overview',
  },
  Orderbook: {
    value: 'Orderbook',
    label: 'Orderbook',
  },
  'Trade History': {
    value: 'Trade History',
    label: 'Trade History',
  },
}

export const RUNE_DETAILS_TABS_OPTIONS = Object.values(RUNE_DETAILS_TABS).map<ButtonGroupOptions>(
  (tab) => {
    return {
      label: tab.label,
      value: tab.value,
    }
  }
)

export const DEFAULT_TAB: RuneDetailsTabs = 'Overview'

export const RUNE_DETAILS_TAB_SEARCH_PARAM = 'tab'

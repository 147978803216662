import { useMemo } from 'react'

import { API_ENDPOINTS, ORDERS_SORT_ORDER } from '@packages/constants'
import { Order, OrderStatus, Provider as AggregateProviders } from '@packages/interfaces'

import { replaceUrlParams } from 'src/shared/utils'
import { useUpdateOnBlockAggregatePaginationApi } from 'src/api/hooks/useUpdateOnBlockAggregatePaginationApi'

export interface OrderStatusQueryParams {
  status: OrderStatus
  provider?: AggregateProviders | undefined
  limit: number
  sortOrderBy: ORDERS_SORT_ORDER
  sortOrder: 'asc' | 'desc'
}

// Omit is specifically to allow this function to work with BidOrders. Because they have different it and statuses. And don't have provider field.
export function useOrdersByStatusApi<T extends Omit<Order, 'id' | 'provider' | 'status'>>({
  runeName,
  queryParams,
  disabled,
  type,
}: {
  runeName: string
  queryParams: OrderStatusQueryParams
  disabled?: boolean
  type: 'sell' | 'bid'
}) {
  const requestOptions = useMemo(() => {
    const endpoint =
      type === 'sell'
        ? API_ENDPOINTS.GET.runes.orders.byStatus
        : API_ENDPOINTS.GET.runes.bidOrders.byStatus

    return {
      endpoint: `${replaceUrlParams(endpoint, {
        runeName: runeName.toUpperCase(),
      })}`,
      otherQueryParams: queryParams,
      disabled,
    }
  }, [disabled, queryParams, runeName, type])

  const response = useUpdateOnBlockAggregatePaginationApi<T>(requestOptions)

  return response
}

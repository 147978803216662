import { useCallback } from 'react'
import { styled } from 'styled-components'

import { LargeButtonGroup } from 'src/shared/components'

import { RUNE_DETAILS_TABS_OPTIONS, RUNE_DETAILS_TAB_SEARCH_PARAM } from './constants'

import type { RuneDetailsTabs } from './types'

export type RunDetailsTabsProps = {
  onTabChange: (tabId: RuneDetailsTabs) => void
  selectedTab: RuneDetailsTabs
}

export function RunDetailsTabs({ onTabChange, selectedTab }: RunDetailsTabsProps) {
  const handleTabChange = useCallback(
    (tab: string) => {
      onTabChange(tab as RuneDetailsTabs)
    },
    [onTabChange]
  )

  return (
    <TabsGroup
      value={selectedTab}
      onChange={handleTabChange}
      options={RUNE_DETAILS_TABS_OPTIONS}
      exclusive={true}
      updateSearchParams={false}
      searchParamName={RUNE_DETAILS_TAB_SEARCH_PARAM}
    />
  )
}

const TabsGroup = styled(LargeButtonGroup)``

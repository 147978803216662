import { useAutoAnimate } from '@formkit/auto-animate/react'
import { Skeleton } from '@mui/material'
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { styled } from 'styled-components'

import { SATOSHI_DUST_THRESHOLD } from '@packages/constants'
import { BTC_PRICE_SYMBOL, RuneDetails } from '@packages/interfaces'
import { calculateFeeFromBps, formatBtc, formatRuneName, ManualError } from '@packages/utils'

import { ACCOUNT_DETAILS_TABS } from 'src/account'
import { useGoogleAnalyticsContext } from 'src/analytics'
import { useMintButtonState, useMintDetails } from 'src/minting/hooks'
import { buildAccountDetailsUrl, buildPageTabSearchParam } from 'src/pages/constants'
import { MysticPointsDrawerCard, useGetRewardSize, useMysticPointsContext } from 'src/rewards'
import {
  AccordianHeader,
  AccordionContent,
  DrawerBoldColumn,
  DrawerButtons,
  DrawerCancelButton,
  DrawerCard,
  DrawerColumn,
  DrawerConfirmButton,
  DrawerRow,
  DrawerTitle,
  ErrorMessage,
  ErrorToast,
  HelpTooltip,
  LinkPageToast,
  WarningMessage,
} from 'src/shared/components'
import { COLORS } from 'src/shared/constants'
import { useDebounce } from 'src/shared/hooks'
import {
  calculateBulkMintNetworkFeeTotal,
  calculateBulkMintNetworkFeeWithoutSplitterTotal,
  calculateBulkMintTxOutputs,
  SignTransactionResult,
  useWalletContext,
  WalletPickerMenu,
} from 'src/wallet'
import { NetworkFeeSelector, SuccessfulTransactionToast } from 'src/web3'

import { MintRunesDrawerRecipientAddress } from './MintRunesDrawerRecipientAddress'
import { MintRunesDrawerRepeatMints } from './MintRunesDrawerRepeatMints'
import { TotalAmountHeader, TotalAmountRow } from './styles'

function transactionToast(result: SignTransactionResult) {
  if (result?.success) {
    toast(
      <SuccessfulTransactionToast message='Mint successful!' transactionId={result.txId ?? ''} />,
      { toastId: result.txId ?? `${Date.now()}` }
    )
  } else if (result.error) {
    console.error(result)
    toast(<ErrorToast errorMessage={result.error} />, { toastId: result.error + Date.now() })
  }
}

export type MintFormRef = {
  resetForm: () => void
  onConfirm: () => void
  onCancel: () => void
}

export type MintRunesDrawerContentProps = {
  isOpen: boolean
  onClose: () => void
  onConfirm?: () => void
  runeDetails: RuneDetails | undefined
  onLoadingTextChange: (loadingText: string | undefined) => void
  loadingText: string | undefined
  isFormDisabled?: boolean
  recipientAddress?: string | undefined
  numberOfMints?: number | undefined
}

const RECALCULATE_TOTALS_TIME = 150

export const MintRunesDrawerContent = forwardRef<MintFormRef, MintRunesDrawerContentProps>(
  function MintRunesDrawerContentWrapper(
    {
      runeDetails,
      isOpen,
      onClose,
      onConfirm,
      onLoadingTextChange,
      loadingText,
      isFormDisabled = false,
      numberOfMints: numberOfMintsFromOuterState,
      recipientAddress: recipientAddressFromOuterState,
    }: MintRunesDrawerContentProps,
    ref
  ) {
    const [animateRefParent] = useAutoAnimate()
    const [isTotalDetailsExpanded, setIsTotalDetailsExpanded] = useState(false)
    const [isWalletModalOpen, setIsWalletModalOpen] = useState(false)
    const {
      settings,
      mintRune,
      mintBulkRune,
      estimateMintBulkRuneVsize,
      isConnected,
      runesAddress,
      btcBalances,
    } = useWalletContext()

    const mintDetails = useMintDetails({ runeDetails })

    const { initiateEvent, confirmEvent, executeEvent, abandonEvent } = useGoogleAnalyticsContext()

    const [numberOfMints, setNumberOfMints] = useState(numberOfMintsFromOuterState ?? 1)
    const [numberOfMintsError, setNumberOfMintsError] = useState<string | undefined>()
    const [recipientAddress, setRecipientAddress] = useState(recipientAddressFromOuterState ?? '')
    const [selectedNetworkFee, setSelectedNetworkFee] = useState(0)
    const [totals, setTotals] = useState<{
      networkFeeTotal: bigint
      networkFeeOnlyMintsTotal: bigint
      serviceFeeTotal: bigint
      mintUtxosTotal: bigint
      satsTotal: bigint
      childEstimatedVsize: bigint
    }>()
    const [error, setError] = useState<string>()
    const [loadingTotalForQuantity, setLoadingTotalForQuantity] = useState<number[]>([])

    const { refetchMysticPointsBalance } = useMysticPointsContext()
    const { data: mysticPoints } = useGetRewardSize({
      runeId: runeDetails?.runeId,
      eventType: 'mint',
      fee: totals?.networkFeeOnlyMintsTotal ?? 0,
      disabled: !isOpen,
      recalculateRewardSizeTime: RECALCULATE_TOTALS_TIME,
    })

    useEffect(() => {
      if (isOpen) {
        if (runeDetails) {
          initiateEvent('mint', { token_name: runeDetails.runeName, token_type: 'rune' })
        }
      }
    }, [isOpen])

    useEffect(() => {
      if (runesAddress && !recipientAddressFromOuterState) {
        setRecipientAddress(runesAddress.addrString)
      }
    }, [recipientAddressFromOuterState, runesAddress])

    useDebounce(
      async () => {
        if (!selectedNetworkFee || !recipientAddress) return
        try {
          setLoadingTotalForQuantity((prev) => [...prev, numberOfMints])
          const { parent: parentEstimatedVsize, child: childEstimatedVsize } =
            await estimateMintBulkRuneVsize({
              quantity: BigInt(numberOfMints),
              addFee: false,
            })

          const mintUtxosTotal = calculateBulkMintTxOutputs(numberOfMints) * SATOSHI_DUST_THRESHOLD

          let networkFeeTotal = calculateBulkMintNetworkFeeTotal({
            feeRate: BigInt(selectedNetworkFee),
            parentEstimatedVsize,
            childEstimatedVsize,
            numberOfMints,
          })

          const serviceFeeTotal =
            numberOfMints == 1
              ? 0n
              : calculateFeeFromBps(networkFeeTotal, settings.serviceFees.feeBps.mint)

          const { parent: parentEstimatedVsizeUpdated } = await estimateMintBulkRuneVsize({
            quantity: BigInt(numberOfMints),
            addFee: serviceFeeTotal != 0n,
          })

          networkFeeTotal = calculateBulkMintNetworkFeeTotal({
            feeRate: BigInt(selectedNetworkFee),
            parentEstimatedVsize: parentEstimatedVsizeUpdated,
            childEstimatedVsize,
            numberOfMints,
          })

          const networkFeeOnlyMintsTotal = calculateBulkMintNetworkFeeWithoutSplitterTotal({
            feeRate: BigInt(selectedNetworkFee),
            childEstimatedVsize,
            numberOfMints,
          })
          const satsTotal = BigInt(mintUtxosTotal) + serviceFeeTotal + networkFeeTotal

          setTotals({
            serviceFeeTotal,
            mintUtxosTotal,
            networkFeeTotal,
            networkFeeOnlyMintsTotal,
            satsTotal,
            childEstimatedVsize: BigInt(childEstimatedVsize),
          })
        } catch (error) {
          console.error(error)
          if (error instanceof ManualError) {
            setNumberOfMintsError((error as ManualError).message)
          } else {
            switch (error) {
              // placeholder
              case '':
                break
              default:
                setNumberOfMintsError(
                  'Something unexpected has gone wrong, please contact support on our Discord'
                )
                break
            }
          }
        } finally {
          setLoadingTotalForQuantity((prev) => prev.filter((q) => q !== numberOfMints))
        }
      },
      [numberOfMints, selectedNetworkFee, recipientAddress],
      RECALCULATE_TOTALS_TIME
    )

    const totalMints = useMemo(() => {
      return (runeDetails?.runesAmountPerMint ?? 0n) * BigInt(numberOfMints)
    }, [runeDetails?.runesAmountPerMint, numberOfMints])

    function resetForm() {
      if (runeDetails) {
        abandonEvent(true, 'mint', {
          token_name: runeDetails.runeName,
          token_type: 'rune',
          mint_amount: numberOfMints,
          fee: selectedNetworkFee,
        })
      }
      setNumberOfMints(1)
      setSelectedNetworkFee(0)
      setError(undefined)
      setNumberOfMintsError(undefined)
    }

    async function onConfirmClick() {
      if (!isConnected) {
        setIsWalletModalOpen(true)
        return
      }

      if (!runeDetails) {
        return
      }

      confirmEvent('mint', {
        token_name: runeDetails.runeName,
        token_type: 'rune',
        mint_amount: numberOfMints,
        fee: selectedNetworkFee,
      })

      try {
        onLoadingTextChange('Minting...')
        setError(undefined)

        if (numberOfMints === 1) {
          onLoadingTextChange('See Wallet')
          const result = await mintRune({
            runeId: runeDetails.runeId,
            runeName: runeDetails.runeName,
            fee: BigInt(selectedNetworkFee),
            recipientAddress,
          })
          transactionToast(result)
          if (result?.success) {
            executeEvent('mint', {
              token_name: runeDetails.runeName,
              token_type: 'rune',
              mint_amount: numberOfMints,
              fee: selectedNetworkFee,
            })
            refetchMysticPointsBalance()
          }
          onClose()
          resetForm()
        } else {
          const result = await mintBulkRune({
            runeId: runeDetails.runeId,
            runeName: runeDetails.runeName,
            fee: BigInt(selectedNetworkFee),
            recipientAddress,
            quantity: BigInt(numberOfMints),
            onStatusChange: (status) => {
              switch (status) {
                case 'build':
                  onLoadingTextChange('Building txs')
                  break
                case 'wallet-prompt':
                  onLoadingTextChange('See Wallet')
                  break
                case 'temp-key-sign':
                  onLoadingTextChange('Finalizing txs')
                  break
                case 'api-submit':
                  onLoadingTextChange('Submitting txs')
                  break
              }
            },
          })

          if (result.success && result.signedTransactionResults) {
            const anyErrors = result.signedTransactionResults.some((r) => !r.success)
            if (anyErrors) {
              toast(
                <LinkPageToast
                  message={'Bulk mint submitted. Some broadcasts failed, but will be retried'}
                  buttonMessage='View status'
                  reactRouterPathname={buildAccountDetailsUrl(runesAddress?.addrString ?? '')}
                  reactRouterSearch={buildPageTabSearchParam(ACCOUNT_DETAILS_TABS.mints).toString()}
                  styleVariant={'warning'}
                />,
                { toastId: Date.now() }
              )
            } else {
              toast(
                <LinkPageToast
                  message={'Bulk mint submitted'}
                  buttonMessage='View status'
                  reactRouterPathname={buildAccountDetailsUrl(runesAddress?.addrString ?? '')}
                  reactRouterSearch={buildPageTabSearchParam(ACCOUNT_DETAILS_TABS.mints).toString()}
                />,
                { toastId: Date.now() }
              )
            }
            executeEvent('mint', {
              token_name: runeDetails.runeName,
              token_type: 'rune',
              mint_amount: numberOfMints,
              fee: selectedNetworkFee,
            })
            refetchMysticPointsBalance()
            onClose()
            resetForm()
          } else {
            abandonEvent(false, 'mint', {
              token_name: runeDetails.runeName,
              token_type: 'rune',
              mint_amount: numberOfMints,
              fee: selectedNetworkFee,
            })
            setError('Minting failed: ' + result.error)
          }
        }
      } catch (error) {
        console.error(error as Error)
        if (error instanceof ManualError) {
          setError((error as ManualError).message)
        } else {
          switch (error) {
            // placeholder
            case '':
              break
            default:
              setError('Something unexpected has gone wrong, please contact support on our Discord')
              break
          }
        }
      } finally {
        onLoadingTextChange(undefined)
      }
    }

    function onNumberOfMintsChange(mints: number) {
      setNumberOfMintsError(undefined)
      if (runeDetails) {
        if (mints + Number(runeDetails.numberOfMints ?? 0n) > Number(runeDetails.maxMints ?? 0n)) {
          setNumberOfMintsError('You are trying to mint more times than the remaining mints.')
        }
      }
      setNumberOfMints(mints)
    }

    function handleOnCancel() {
      if (runeDetails) {
        abandonEvent(false, 'mint', {
          token_name: runeDetails.runeName,
          token_type: 'rune',
          mint_amount: numberOfMints,
          fee: selectedNetworkFee,
        })
      }
      onLoadingTextChange(undefined)
    }

    const mintFormRef = useRef<MintFormRef>(null)
    useImperativeHandle(
      ref ?? mintFormRef,
      () => {
        return {
          resetForm,
          onConfirm: onConfirmClick,
          onCancel: handleOnCancel,
        }
      },
      [handleOnCancel, onConfirmClick, resetForm]
    )

    const isTotalsLoading = loadingTotalForQuantity.length > 0

    const { text: mintButtonText, disabled: isMintButtonDisabled } = useMintButtonState(mintDetails)
    const confirmButtonText =
      (!isConnected
        ? 'Connect to mint'
        : loadingText ?? (isTotalsLoading ? 'Calculating Totals' : '')) || mintButtonText
    const isConfirmButtonDisabled =
      isMintButtonDisabled || isTotalsLoading || !!numberOfMintsError || isFormDisabled

    return (
      <>
        <DrawerCard>
          <MintRunesDrawerTitle>Confirm</MintRunesDrawerTitle>
        </DrawerCard>

        {runeDetails && (
          <DrawerCard>
            <DrawerTitle>Mint {formatRuneName(runeDetails)}</DrawerTitle>
          </DrawerCard>
        )}

        <MintRunesDrawerRepeatMints
          totalMints={totalMints}
          numberOfMints={numberOfMints}
          runeDetails={runeDetails}
          numberOfMintsError={numberOfMintsError}
          onNumberOfMintsChange={onNumberOfMintsChange}
          isDisabled={isFormDisabled}
          mode={'read'}
        />

        <MintRunesDrawerRecipientAddress
          isDisabled={isFormDisabled}
          recipientAddress={recipientAddress}
          onRecipientAddressChange={setRecipientAddress}
          mode={'read'}
        />

        <DrawerCard>
          <DrawerRow>
            <NetworkFeeSelector
              selectedFee={selectedNetworkFee}
              onChange={(fee) => setSelectedNetworkFee(fee)}
              onlyAllowFastestFee
              addFastestFeeBuffer={numberOfMints > 24}
              disabled={isFormDisabled}
            />
          </DrawerRow>
        </DrawerCard>

        <DrawerCard>
          <TotalAmountRow>
            <TotalAmountHeader>BTC Balance</TotalAmountHeader>
            <TotalColumn>
              {btcBalances?.chainBalanceBtc} {BTC_PRICE_SYMBOL}
            </TotalColumn>
          </TotalAmountRow>
        </DrawerCard>

        <TotalsDrawerCard>
          <TotalAmountRow>
            <TotalAmountHeader>
              <AccordianHeader
                linkText='Mint Total'
                expanded={isTotalDetailsExpanded}
                onExpand={() => setIsTotalDetailsExpanded(!isTotalDetailsExpanded)}
              />
            </TotalAmountHeader>

            <TotalColumn>
              {isTotalsLoading ? (
                <SkeletonWrapper />
              ) : (
                `${formatBtc(totals?.satsTotal ?? 0n)} ${BTC_PRICE_SYMBOL}`
              )}
            </TotalColumn>
          </TotalAmountRow>

          <div ref={animateRefParent}>
            <AccordionContentWrapper expanded={isTotalDetailsExpanded}>
              <DrawerRow>
                <TotalAmountHeader>
                  Mint UTXOs{' '}
                  <HelpTooltip content='This is the minimum amount required in a UTXO that will hold a runestone multiplied by the selected number of mints' />
                </TotalAmountHeader>
                <TotalColumn>
                  {isTotalsLoading ? (
                    <SkeletonWrapper />
                  ) : (
                    `${formatBtc(totals?.mintUtxosTotal ?? 0n)} ${BTC_PRICE_SYMBOL}`
                  )}
                </TotalColumn>
              </DrawerRow>
              <DrawerRow>
                <TotalAmountHeader>
                  Total Network Fees{' '}
                  <HelpTooltip content='This includes all of the network fees that will be used to broadcast the mint transactions once the splitter tx has been included in a block' />
                </TotalAmountHeader>
                <TotalColumn>
                  {isTotalsLoading ? (
                    <SkeletonWrapper />
                  ) : (
                    `≈ ${formatBtc(totals?.networkFeeTotal ?? 0n)} ${BTC_PRICE_SYMBOL}`
                  )}
                </TotalColumn>
              </DrawerRow>
              <DrawerRow>
                <TotalAmountHeader>Service Fee</TotalAmountHeader>
                <TotalColumn>
                  {isTotalsLoading ? (
                    <SkeletonWrapper />
                  ) : (
                    `${formatBtc(totals?.serviceFeeTotal ?? 0n)} ${BTC_PRICE_SYMBOL}`
                  )}
                </TotalColumn>
              </DrawerRow>
            </AccordionContentWrapper>
          </div>
        </TotalsDrawerCard>

        <MysticPointsDrawerCard points={mysticPoints} place='mint' />

        {(mintDetails?.mintedPercentWithPendingMints ?? 0) >= 85 && (
          <DrawerCard>
            <DrawerRow>
              <DrawerColumn>
                <WarningMessage message='Warning: this rune is almost minted out and your transaction may fail. If the mint closes before your transaction confirms your BTC will still be lost and spent as network fees.' />
              </DrawerColumn>
            </DrawerRow>
          </DrawerCard>
        )}

        {error && <ErrorMessage message={error} />}

        <DrawerButtons>
          {loadingText && (
            <DrawerCancelButton onClick={handleOnCancel}>Cancel Tx</DrawerCancelButton>
          )}
          <DrawerConfirmButton
            onClick={onConfirm ?? onConfirmClick}
            loading={!!loadingText || isTotalsLoading}
            disabled={isConfirmButtonDisabled}
          >
            {confirmButtonText}
          </DrawerConfirmButton>
        </DrawerButtons>

        <WalletPickerMenu isOpen={isWalletModalOpen} onClose={() => setIsWalletModalOpen(false)} />
      </>
    )
  }
)

const MintRunesDrawerTitle = styled(DrawerBoldColumn)`
  text-transform: uppercase;
  font-size: 19px;
  font-weight: 500;
`

const TotalColumn = styled(DrawerColumn)`
  text-align: right;
  text-wrap: nowrap;
`

const TotalsDrawerCard = styled(DrawerCard)`
  gap: 0px;
`

const SkeletonWrapper = styled(Skeleton)`
  border-radius: 5px;
  height: 30px;
  background-color: ${COLORS.background.container};
  width: 100%;
`

const AccordionContentWrapper = styled(AccordionContent)`
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  gap: 5px;
`

import { useCallback, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import {
  ACCOUNT_DETAILS_WALLET_TAB_SEARCH_PARAM_NAME,
  buildAccountDetailsWalletTabSearchParam,
} from 'src/pages/constants'
import { SWITCH_ACCOUNTS_TABS } from 'src/account/constants'
import { concatSearchParams } from 'src/shared/utils'
import { useFeatureFlagContext } from 'src/featureFlags'


export const DEFAULT_ACCOUNT_DETAILS_WALLET_TAB = SWITCH_ACCOUNTS_TABS.myWallet

export function useAccountWalletTabs() {
  const { isBiddingEnabled } = useFeatureFlagContext()
  const [searchParams, setSearchParams] = useSearchParams()

  const currentTab = searchParams.get(ACCOUNT_DETAILS_WALLET_TAB_SEARCH_PARAM_NAME)

  const handleChangeTab = useCallback(
    (tab: string) => {
      setSearchParams((prev) => {
        const newSearchParams = concatSearchParams(
          prev,
          buildAccountDetailsWalletTabSearchParam(tab)
        )

        return newSearchParams
      })
    },
    [setSearchParams]
  )

  useEffect(() => {
    if (!isBiddingEnabled && currentTab === SWITCH_ACCOUNTS_TABS.escrow) {
      handleChangeTab(DEFAULT_ACCOUNT_DETAILS_WALLET_TAB)
    }
  }, [isBiddingEnabled, currentTab])

  return {
    currentTab: currentTab ?? DEFAULT_ACCOUNT_DETAILS_WALLET_TAB,
    changeTab: handleChangeTab,
  }
}

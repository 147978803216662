import { BitcoinNetworkType } from 'sats-connect'

export const ENVIRONMENT = import.meta.env.VITE_NODE_ENV as string
export const GIT_COMMIT_SHA = import.meta.env.VITE_GIT_SHA as string

export const IS_TESTNET_DEPLOYMENT =
  import.meta.env.VITE_IS_TESTNET_DEPLOYMENT === 'true' ||
  import.meta.env.VITE_BTC_NETWORK != 'mainnet'

// Selecting bitcoin network either based on legacy IS_TESTNET_DEPLOYMENT environment vairable or new BTC_NETWORK
let deployedBitcoinNetwork: BitcoinNetworkType
if (import.meta.env.VITE_BTC_NETWORK == undefined || import.meta.env.VITE_BTC_NETWORK == '') {
  deployedBitcoinNetwork = IS_TESTNET_DEPLOYMENT
    ? BitcoinNetworkType.Testnet
    : BitcoinNetworkType.Mainnet
} else {
  switch (import.meta.env.VITE_BTC_NETWORK) {
    case 'mainnet':
      deployedBitcoinNetwork = BitcoinNetworkType.Mainnet
      break
    case 'testnet':
      deployedBitcoinNetwork = BitcoinNetworkType.Testnet
      break
    case 'signet':
      deployedBitcoinNetwork = BitcoinNetworkType.Signet
      break
    case 'fractal-mainnet':
      deployedBitcoinNetwork = BitcoinNetworkType.FractalMainnet
      break
    case 'fractal-testnet':
      deployedBitcoinNetwork = BitcoinNetworkType.FractalTestnet
      break
    default:
      deployedBitcoinNetwork = BitcoinNetworkType.Signet
      break
  }
}
export const DEPLOYED_BITCOIN_NETWORK: BitcoinNetworkType = deployedBitcoinNetwork
export const DEPLOYED_BITCOIN_NETWORK_STRING: string = deployedBitcoinNetwork.toLowerCase()
export const AVAILABLE_BITCOIN_NETWORKS: BitcoinNetworkType[] = [
  BitcoinNetworkType.Mainnet,
  BitcoinNetworkType.FractalMainnet,
]
export const BITCOIN_NETWORK_HOME_PAGES: { [network in BitcoinNetworkType]: string } = {
  [BitcoinNetworkType.Mainnet]: 'https://app.mystic.com/',
  [BitcoinNetworkType.Testnet]: '',
  [BitcoinNetworkType.FractalMainnet]: 'https://fractal.mystic.com/',
  [BitcoinNetworkType.Signet]: '',
  [BitcoinNetworkType.FractalTestnet]: '',
}

export const DISABLE_ETCHING = import.meta.env.VITE_DISABLE_ETCHING === 'true'
export const DISABLE_WRITES = import.meta.env.VITE_DISABLE_WRITES === 'true'
export const ENABLE_EXTRA_WALLETS = import.meta.env.VITE_ENABLE_EXTRA_WALLETS === 'true'

const bidOrderMinimumTotalPrice = parseInt(import.meta.env.VITE_BID_ORDER_MINIMUM_TOTAL_PRICE, 10)
export const BID_ORDER_MINIMUM_TOTAL_PRICE: number =
  isNaN(bidOrderMinimumTotalPrice) || !bidOrderMinimumTotalPrice ? 10000 : bidOrderMinimumTotalPrice

export const BANNER_MESSAGE = import.meta.env.VITE_BANNER_MESSAGE as string

export const API_URL = import.meta.env.VITE_API_URL as string

const requiredEnvVars = [API_URL, GIT_COMMIT_SHA, ENVIRONMENT]

requiredEnvVars.forEach((env, i) => {
  if (!env) {
    console.log(i, env)
    throw new Error('Required env variable not set')
  }
})

export const LINKS = {
  twitter: 'https://twitter.com/lfg',
  telegram: 'https://t.me/LFGBRC20',
  discord: 'https://discord.com/invite/lfgmystic',
  // github: '',
}

export const TRX_VIRTUAL_SIZE_BYTES = {
  mint: 150,
  etch: 196,
  sell: 82, // just the sell side of the order psbt not the buy side
  buy: {
    initial: 100,
    each: 90,
    serviceFee: 30,
  },
  bidSell: {
    initial: 112,
    additionalInput: 23,
    runeChange: 45,
    serviceFee: 45,
  },
  bidBuy: {
    each: 77,
    additionalInput: 23,
    change: 45,
  },
  transfer: 196,
}

export const SENTRY = {
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE
    ? Number(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE)
    : 0.0001,
  replaysSessionSampleRate: import.meta.env.VITE_REPLAYS_SESSIONS_SAMPLE_RATE
    ? Number(import.meta.env.VITE_REPLAYS_SESSIONS_SAMPLE_RATE)
    : 0.0001,
  replaysOnErrorSampleRate: import.meta.env.VITE_REPLAYS_ON_ERROR_SAMPLE_RATE
    ? Number(import.meta.env.VITE_REPLAYS_ON_ERROR_SAMPLE_RATE)
    : 1,
}

export const RUNE_INSCRIPTION_IMAGE_URL_BASE =
  import.meta.env.VITE_RUNE_INSCRIPTION_IMAGE_URL_BASE ?? 'https://ordinals.com/content/'

export const IS_BIDDING_ENABLED = import.meta.env.VITE_IS_BIDDING_ENABLED === 'true'

import { Link, LinkProps } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import { styled } from 'styled-components'

import { COLORS } from 'src/shared/constants'
import { BUTTON_HOVER_STYLES } from 'src/shared/components/buttons'

export type InternalLinkProps = {
  text: string
  showIcon?: boolean
} & LinkProps

export function InternalLink({ text, showIcon = true, ...props }: InternalLinkProps) {
  return (
    <LinkContainer {...props}>
      <span>{text}</span>
      {showIcon && <LinkIcon>
        <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
      </LinkIcon>}
    </LinkContainer>
  )
}

const LinkContainer = styled(Link)`
  display: flex;
  align-items: center;
  gap: 6px;
  height: 1em;

  color: ${COLORS.link} !important;

  ${BUTTON_HOVER_STYLES}
`

const LinkIcon = styled.span`
  svg {
    color: ${COLORS.link};
  }
  min-width: 1em;
`

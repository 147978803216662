import Decimal from 'decimal.js-light'
import { BigNumberish } from 'ethers'

export function bigIntToInteger(num: bigint) {
  return parseInt(num.toString())
}

export function bigIntToFloat(num: bigint) {
  return parseFloat(num.toString())
}

export function bigIntToDecimal(num: bigint) {
  return new Decimal(num.toString())
}

export function bigIntToBigNumber(num: bigint): BigNumberish {
  return num.toString()
}

export function parseRuneAmountToBigInt(amount: string, divisibility: bigint): bigint {
  // Split into integer and fractional parts
  const [integerPart = '0', fractionalPart = '0'] = amount.split('.')

  // Pad/truncate fractional part to match divisibility
  const paddedFraction = fractionalPart
    .slice(0, Number(divisibility))
    .padEnd(Number(divisibility), '0')
  // Combine integer and fractional parts as BigInt
  const integerBigInt = BigInt(integerPart) * BigInt(10n ** divisibility)
  const fractionalBigInt = BigInt(paddedFraction || '0')

  return integerBigInt + fractionalBigInt
}

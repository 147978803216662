import { styled } from 'styled-components'

import { RuneNameInput } from 'src/runes'
import Search from 'src/pages/assets/search.svg?react'
import { FONTS, COLORS } from 'src/shared/constants'
import { hexToRgb } from 'src/shared/utils'

import { TextInputProps } from './TextInput'

export const SearchInput = (props: TextInputProps) => {
  return (
    <InputContainer>
      <SearchIcon />
      <SearchInputWrapper
        placeholder='Search by Rune Name'
        {...props}
      />
    </InputContainer>
  )
}

const SearchInputWrapper = styled(RuneNameInput)`
  width: 100%;
    
  .MuiInputBase-input {
    font-family: ${FONTS.text};
  }

  .MuiInputBase-input::placeholder {
    opacity: 0.35;
    text-transform: uppercase;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  .MuiOutlinedInput-root {
    border: none;
    
    &:hover {
      border: none;
    }
  }
`

const InputContainer = styled.div`
  width: 100%;
  border-radius: 17px;
  border: 1px solid ${hexToRgb(COLORS.buttons.primary, 0.25)};
  display: flex;
  flex-direction: row;
  align-items: center;
`

const SearchIcon = styled(Search)`
  margin-left: 16px;
`

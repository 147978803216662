const HOUR_IN_MS = 60 * 60 * 1000
const DAY_IN_MS = 24 * HOUR_IN_MS
const WEEK_IN_MS = 7 * DAY_IN_MS
const YEAR_IN_MS = 365.25 * DAY_IN_MS

export const EXPIRED_TIME_MAP: Record<string, { value: string; label: string; ms: number }> = {
  '': {
    value: '',
    label: 'Choose expiry length',
    ms: 0,
  },
  '1d': {
    value: '1d',
    label: '1 day',
    ms: DAY_IN_MS,
  },
  '1w': {
    value: '1w',
    label: '1 week',
    ms: WEEK_IN_MS,
  },
  '1m': {
    value: '1m',
    label: '1 month',
    ms: WEEK_IN_MS * 4,
  },
  '1y': {
    value: '1y',
    label: '1 year',
    ms: YEAR_IN_MS,
  },
}

export const EXPIRED_TIME_OPTIONS = Object.values(EXPIRED_TIME_MAP)

import { UNCONFIRMED_TX_LIMIT } from '@packages/constants'
import { CompactSizeLen } from '@packages/scure-btc-signer/script'
import { toVsize } from '@packages/scure-btc-signer/utxo'

export function calculateBulkMintNetworkFeeTotal({
  numberOfMints,
  feeRate,
  parentEstimatedVsize,
  childEstimatedVsize,
}: {
  numberOfMints: number | bigint
  feeRate: bigint
  parentEstimatedVsize: number
  childEstimatedVsize: number
}): bigint {
  return (
    feeRate * BigInt(parentEstimatedVsize) +
    feeRate * BigInt(childEstimatedVsize) * BigInt(numberOfMints)
  )
}

export function calculateBulkMintNetworkFeeWithoutSplitterTotal({
  numberOfMints,
  feeRate,
  childEstimatedVsize,
}: {
  numberOfMints: number | bigint
  feeRate: bigint
  childEstimatedVsize: number
}): bigint {
  return feeRate * BigInt(childEstimatedVsize) * BigInt(numberOfMints)
}

export function calculateBulkMintTxOutputs(numberOfMints: number | bigint) {
  return (BigInt(numberOfMints) + UNCONFIRMED_TX_LIMIT - 1n) / UNCONFIRMED_TX_LIMIT
}

// only use this for bulk mint transactions - there are a lot of assumptions here

const PARENT_INPUTS_DEFAULT_LENGTH = 1
const PARENT_MINT_SCRIPT_LENGTH_FOR_CHILD = 35
const PARENT_MINT_SCRIPT_LENGTH_FOR_FEE = 24
const PARENT_MINT_SCRIPT_LENGTH_FOR_REFUND = 23

function estimateMintParentWeight(numberOfMints: number, addFee: boolean): number {
  let estimatedWeight = 32 // base size for non-witness data
  // Add output sizes

  const outputs = new Array(Number(calculateBulkMintTxOutputs(numberOfMints)))
  outputs.fill(PARENT_MINT_SCRIPT_LENGTH_FOR_CHILD)
  if (addFee) {
    outputs.push(PARENT_MINT_SCRIPT_LENGTH_FOR_FEE)
  }
  outputs.push(PARENT_MINT_SCRIPT_LENGTH_FOR_REFUND)
  estimatedWeight += 4 * CompactSizeLen.encode(outputs.length).length
  for (const scriptLength of outputs) {
    estimatedWeight += 32 + 4 * scriptLength
  }
  // Add input sizes
  estimatedWeight += 4 * PARENT_INPUTS_DEFAULT_LENGTH

  for (let i = 0; i < PARENT_INPUTS_DEFAULT_LENGTH; i++) {
    estimatedWeight += 160 // base input size
  }
  return estimatedWeight
}

export function estimatedMintParentVsize(mints: number, addFee: boolean): number {
  return toVsize(estimateMintParentWeight(mints, addFee))
}

const CHILD_INPUTS_DEFAULT_LENGTH = 1
const CHILD_OUTPUTS_SCRIPT_LENGTHS = [35, 13]

// only use this for P2TR transactions - there are a lot of assumptions here
function estimatedMintChildWeight(): number {
  let estimatedWeight = 32 // base size for non-witness data
  // Add output sizes
  estimatedWeight += 4 * CompactSizeLen.encode(CHILD_OUTPUTS_SCRIPT_LENGTHS.length).length
  for (const scriptLength of CHILD_OUTPUTS_SCRIPT_LENGTHS) {
    estimatedWeight += 32 + 4 * scriptLength
  }
  // Add input sizes
  estimatedWeight += 4 * CHILD_INPUTS_DEFAULT_LENGTH //
  for (let i = 0; i < CHILD_INPUTS_DEFAULT_LENGTH; i++) {
    estimatedWeight += 160 // base input size
    // Assuming minimal or no ScriptSig for P2TR, just the length byte
    estimatedWeight += 4 // P2TR ScriptSig typically empty, counted minimally
    estimatedWeight += 66 // Estimated size for a Schnorr signature
  }
  return estimatedWeight
}

export function estimatedMintChildVsize(): number {
  return toVsize(estimatedMintChildWeight())
}

import { DrawerCard, DrawerRow } from 'src/shared/components'
import { Header } from 'src/minting/components/MintRunes/styles'
import { ExpiredTimeSelect } from 'src/runes/components'

type ExpiredTimeDrawerCardProps = {
  className?: string
  expiredTime: number | undefined
  onChange: (value: number) => void
  disabled?: boolean
}

export function ExpiredTimeDrawerCard({
  onChange,
  expiredTime,
  className,
  disabled
}: ExpiredTimeDrawerCardProps) {
  return (
    <DrawerCard className={className}>
      <DrawerRow>
        <Header>Expires in</Header>
      </DrawerRow>
      <DrawerRow>
        <ExpiredTimeSelect
          onChange={onChange}
          value={expiredTime}
          helperText={!expiredTime ? 'Required' : undefined}
          error={!expiredTime}
          disabled={disabled}
        />
      </DrawerRow>
    </DrawerCard>
  )
}

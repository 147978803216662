import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
  Context,
  useCallback,
} from 'react'
import Decimal from 'decimal.js-light'
import { useLocation, useSearchParams } from 'react-router-dom'

import {
  Market,
  Order,
  Rune,
  RuneBalance,
  RuneDetails,
  RuneOutpointDetails,
  BidOrder,
} from '@packages/interfaces'
import { API_ENDPOINTS } from '@packages/constants'
import { formatNumber, formatUsd, normalizeRuneName, priceSatsToPriceUsd } from '@packages/utils'

import { useApi, usePaginationApi } from 'src/api'
import { useMarket } from 'src/markets'
import { combineSearchParams, replaceUrlParams } from 'src/shared/utils'
import { useWalletContext } from 'src/wallet'
import { ROUTES, RUNE_TOOLS_SWAP_SIDE_SEARCH_PARAM_NAME } from 'src/pages/constants'
import { isOnRoute } from 'src/pages'

import { OrderSummary } from './interfaces'
import {
  BuyRunesDrawer,
  CancelOrderDrawer,
  SellRunesDrawer,
  PlaceBidOrderDrawer,
  BuyBidOrderDrawer,
} from './components'
import { useAvailableBalanceMinusOrders } from './hooks'
import { calculatePrice, calculateWeightedAveragePrice, isBidOrder } from './utils'
import { DEFAULT_RUNE_SWAPPER_SIDE, DEFAULT_SWAP_TOOL_SIDE } from './constants'

interface OrderContextType {
  availableRuneBalances: RuneBalance[]

  isLoadingNewRune: boolean

  selectedRuneName: string
  selectedRune?: Rune
  selectedRuneDetails?: RuneDetails
  detailsFetchError?: string
  selectedRuneMarket?: Market
  marketFetchError?: string
  setSelectedRuneName: (runeName: string) => void
  setSelectedRune: (rune: Rune) => void

  selectedRuneAvailableBalance: bigint

  setSelectedCancelOrder: (order: Order | BidOrder) => void

  isBTConTop: boolean
  setIsBTConTop: (isBTConTop: boolean) => void

  isStaleOrderDataTimestamp: number
  setIsStaleOrderDataTimestamp: React.Dispatch<React.SetStateAction<number>>
  recentlyFilledOrders: Order[]
  recentlyCancelledOrders: Array<BidOrder | Order>

  orderSummary?: OrderSummary
  selectedSellOrders: Order[]
  setSelectedSellOrders: React.Dispatch<React.SetStateAction<Order[]>>
  selectedRuneOutpoints: RuneOutpointDetails[]
  setSelectedRuneOutpoints: React.Dispatch<React.SetStateAction<RuneOutpointDetails[]>>

  desiredRunesSellAmount?: bigint
  desiredBtcSellAmount?: bigint
  setDesiredRunesSellAmount: (amount?: bigint) => void
  setDesiredBtcSellAmount: (amount?: bigint) => void

  proceedsAddress?: string
  setProceedsAddress: React.Dispatch<React.SetStateAction<string | undefined>>

  expiresAt?: number
  setExpiresAt: React.Dispatch<React.SetStateAction<number>>

  openBuyDrawer: () => void
  isBuyDrawerOpen: boolean

  openPlaceBidDrawer: () => void
  isPlaceBidDrawerOpen: boolean
  openBuyBidOrderDrawer: () => void
  isBuyBidOrderDrawerOpen: boolean

  openSellDrawer: () => void
  isSellDrawerOpen: boolean
  openCancelDrawer: () => void
  isCancelDrawerOpen: boolean
}

const MarketOrderContext = createContext<OrderContextType | undefined>(undefined)
const LimitOrderContext = createContext<OrderContextType | undefined>(undefined)

export type OrderStateType = 'market' | 'limit'

export type ExtendedOrderContextType = {
  setSelectedRuneOutpointsShared: OrderContextType['setSelectedRuneOutpoints']
  setDesiredRunesSellAmountShared: OrderContextType['setDesiredRunesSellAmount']
} & OrderContextType

export const useOrderContext = (type: OrderStateType = 'market'): ExtendedOrderContextType => {
  const marketOrderContext = useContext(MarketOrderContext)
  const limitOrderContext = useContext(LimitOrderContext)

  if (marketOrderContext === undefined) {
    throw new Error(`useOrderContext(type=${type}) must be used within a MarketOrderProvider`)
  }

  if (limitOrderContext === undefined) {
    throw new Error(`useOrderContext(type=${type}) must be used within a LimitOrderContext`)
  }

  const setSelectedRune: OrderContextType['setSelectedRune'] = useCallback<
    OrderContextType['setSelectedRune']
  >(
    (rune) => {
      limitOrderContext.setSelectedRune(rune)
      marketOrderContext.setSelectedRune(rune)
    },
    [limitOrderContext, marketOrderContext]
  )

  const setSelectedRuneName: OrderContextType['setSelectedRuneName'] = useCallback<
    OrderContextType['setSelectedRuneName']
  >(
    (runeName) => {
      marketOrderContext.setSelectedRuneName(runeName)
      limitOrderContext.setSelectedRuneName(runeName)
    },
    [limitOrderContext, marketOrderContext]
  )

  const setSelectedRuneOutpointsShared: OrderContextType['setSelectedRuneOutpoints'] = useCallback<
    OrderContextType['setSelectedRuneOutpoints']
  >(
    (runeOutpoints) => {
      if (type === 'market') {
        marketOrderContext.setSelectedRuneOutpoints(runeOutpoints)
      } else if (type === 'limit') {
        limitOrderContext.setSelectedRuneOutpoints(runeOutpoints)
      }
    },
    [limitOrderContext, marketOrderContext, type]
  )

  const setDesiredRunesSellAmountShared: OrderContextType['setDesiredRunesSellAmount'] =
    useCallback<OrderContextType['setDesiredRunesSellAmount']>(
      (amount?: bigint) => {
        if (type === 'market') {
          marketOrderContext.setDesiredRunesSellAmount(amount)
        } else if (type === 'limit') {
          limitOrderContext.setDesiredRunesSellAmount(amount)
        }
      },
      [limitOrderContext, marketOrderContext, type]
    )

  const sharedState: Partial<ExtendedOrderContextType> = useMemo(() => {
    return {
      setSelectedRune,
      setSelectedRuneName,
      setSelectedRuneOutpointsShared,
      setDesiredRunesSellAmountShared,
    }
  }, [
    setDesiredRunesSellAmountShared,
    setSelectedRune,
    setSelectedRuneName,
    setSelectedRuneOutpointsShared,
  ])

  return useMemo<ExtendedOrderContextType>(() => {
    if (type === 'limit') {
      return { ...limitOrderContext, ...sharedState } as ExtendedOrderContextType
    } else {
      return { ...marketOrderContext, ...sharedState } as ExtendedOrderContextType
    }
  }, [limitOrderContext, marketOrderContext, sharedState, type])
}

export function createOrderProvider(
  ContextComponent: Context<OrderContextType | undefined>,
  type: string
) {
  return function OrderProvider({ children }: { children: ReactNode }) {
    const { runesAddress, btcPrice } = useWalletContext()

    const [proceedsAddress, setProceedsAddress] = useState<string | undefined>()
    const [previousRunesAddress, setPreviousRunesAddress] = useState<string | undefined>()

    // NOTE: The expiration time for bid orders
    const [expiresAt, setExpiresAt] = useState<number>(0)

    const [isBuyRunesDrawerOpen, setIsBuyRunesDrawerOpen] = useState(false)
    const [isSellRunesDrawerOpen, setIsSellRunesDrawerOpen] = useState(false)
    const [isPlaceBidDrawerOpen, setIsPlaceBidDrawerOpen] = useState(false)
    const [isCancelOrderDrawerOpen, setIsCancelOrderDrawerOpen] = useState(false)
    const [isBuyBidOrderDrawerOpen, setIsBuyBidOrderDrawerOpen] = useState(false)

    const [selectedRuneName, setSelectedRuneName] = useState<string>('')
    const [rune, setRune] = useState<Rune>()

    const [isBTConTop, setIsBTConTop] = useState(DEFAULT_RUNE_SWAPPER_SIDE)
    const [orderSummary, setOrderSummary] = useState<OrderSummary>()

    const [selectedSellOrders, setSelectedSellOrders] = useState<Order[]>([])
    const selectedBidOrders = useMemo<BidOrder[]>(() => {
      return selectedSellOrders.filter((order) => isBidOrder(order)) as unknown as BidOrder[]
    }, [selectedSellOrders])
    //const [previouslySelectedSellOrders, setPreviouslySelectedSellOrders] = useState<Order[]>([])

    const [selectedRuneOutpoints, setSelectedRuneOutpoints] = useState<RuneOutpointDetails[]>([])

    const [isStaleOrderDataTimestamp, setIsStaleOrderDataTimestamp] = useState<number>(0)
    const [recentlyFilledOrders, setRecentlyFilledOrders] = useState<Order[]>([])
    const [recentlyCancelledOrders, setRecentlyCancelledOrders] = useState<Array<BidOrder | Order>>(
      []
    )

    const [desiredRunesSellAmount, setDesiredRunesSellAmount] = useState<bigint>()
    const [desiredBtcSellAmount, setDesiredBtcSellAmount] = useState<bigint>()

    const [selectedCancelOrder, setSelectedCancelOrder] = useState<Order | BidOrder>()

    const { data: runeDetails, error: runeDetailsError } = useApi<RuneDetails>({
      endpoint: replaceUrlParams(API_ENDPOINTS.GET.runes.details, {
        runeName: selectedRuneName?.toUpperCase() ?? '',
      }),
      disable: !selectedRuneName,
      enableCaching: true,
    })

    const selectedRune = rune ?? runeDetails

    const { market, error: marketError } = useMarket(runeDetails?.runeId)

    const { availableBalance, forceRefresh: fetchAvailableBalance } =
      useAvailableBalanceMinusOrders({
        runeId: selectedRune?.runeId,
      })

    const { paginatedData: runeBalances, forceRefresh: fetchAvailableBalances } =
      usePaginationApi<RuneBalance>(
        {
          endpoint: `${replaceUrlParams(API_ENDPOINTS.GET.runes.balances.byAccount, {
            address: runesAddress?.addrString ?? '',
          })}?hideOrderBalances=true`,
          disabled: !runesAddress,
          limit: 200,
        },
        true
      )

    const location = useLocation()
    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(
      function cleanupOnRouteChange() {
        if (
          isOnRoute(location.pathname, ROUTES.marketDetails) ||
          isOnRoute(location.pathname, ROUTES.accountDetails) ||
          isOnRoute(location.pathname, ROUTES.runeDetails)
        ) {
          // Do nothing
        } else {
          reset()
        }
      },
      [location]
    )

    useEffect(() => {
      const side =
        searchParams.get(RUNE_TOOLS_SWAP_SIDE_SEARCH_PARAM_NAME) || DEFAULT_SWAP_TOOL_SIDE
      if (side === 'buy') {
        setIsBTConTop(true)
      } else if (side === 'sell') {
        setIsBTConTop(false)
      }
    }, [searchParams])

    useEffect(
      function resetRuneSwapperAndConnectedState() {
        const currentRunesAddress = runesAddress?.addrString
        if (currentRunesAddress !== previousRunesAddress) {
          setSelectedSellOrders((orders) =>
            orders.filter((order) => order.placedByAddress !== currentRunesAddress)
          )
          setPreviousRunesAddress(currentRunesAddress)
          setSelectedRuneOutpoints([])
          setDesiredRunesSellAmount(undefined)
        }
      },
      [runesAddress]
    )

    // Calculate Orders summary on any change in Swapper tool
    useEffect(() => {
      let priceSats = '0'
      let totalSats = 0n
      let totalRunes = 0n
      if (selectedRune) {
        if (type === 'market') {
          // Calculating based on selected orders
          priceSats = calculateWeightedAveragePrice({ orders: selectedSellOrders, format: false })
          totalSats = selectedSellOrders.reduce((acc, item) => acc + item.satsAmount, 0n)
          totalRunes = selectedSellOrders.reduce((acc, item) => acc + item.runesAmount, 0n)
        } else {
          // Calculating based on proportions between SatsAmount and RunesAmount
          priceSats = calculatePrice({
            satsAmount: desiredBtcSellAmount ?? 0n,
            runesAmount: desiredRunesSellAmount ?? 0n,
            rune: selectedRune,
            format: false,
          })
          totalSats = desiredBtcSellAmount ?? 0n
          totalRunes = desiredRunesSellAmount ?? 0n
        }
      }
      let priceUsd = '$0.00'
      let totalUsd = '$0.00'

      if (btcPrice) {
        if (priceSats !== '' && !isNaN(priceSats as any)) {
          priceUsd = priceSatsToPriceUsd({
            satPriceUsd: btcPrice.satPriceUsd,
            priceSats,
            format: true,
          })
        }
        totalUsd = formatUsd({
          usd: new Decimal(`${totalSats}`).mul(btcPrice?.satPriceUsd).toNumber(),
        })
      }

      setOrderSummary({
        priceSats: formatNumber({ numStr: priceSats, showAllDecimals: true }),
        priceUsd,
        totalSats,
        totalUsd,
        totalRunes,
      })
    }, [
      selectedRune,
      selectedSellOrders,
      selectedRuneOutpoints,
      desiredBtcSellAmount,
      desiredRunesSellAmount,
      btcPrice,
      isBTConTop,
    ])

    // unselect or select more orders via the table
    // useEffect(() => {
    //   if (selectedSellOrders.length === previouslySelectedSellOrders.length) {
    //     return
    //   }
    //   const runeChanged = previouslySelectedSellOrders[0]?.runeId !== selectedRune?.runeId

    //   const newOrderSatsAmount = selectedSellOrders.reduce((acc, item) => acc + item.satsAmount, 0n)
    //   const newOrderRunesAmount = selectedSellOrders.reduce(
    //     (acc, item) => acc + item.runesAmount,
    //     0n
    //   )

    //   if (newOrderRunesAmount !== desiredRunesSellAmount) {
    //     if (selectedSellOrders.length === 0 && runeChanged) {
    //       setDesiredRunesSellAmount(undefined)
    //     } else {
    //       setDesiredRunesSellAmount(newOrderRunesAmount)
    //     }
    //   }
    //   if (newOrderSatsAmount !== desiredBtcSellAmount) {
    //     if (selectedSellOrders.length === 0 && runeChanged) {
    //       setDesiredBtcSellAmount(undefined)
    //     } else {
    //       setDesiredBtcSellAmount(newOrderSatsAmount)
    //     }
    //   }

    //   // NOTE: This leads to a bug where the swap tool changes to buy when the user selects a bid order
    //   // if (selectedSellOrders.length !== 0) {
    //   //   onIsBuyingChange(true)
    //   // }

    //   setPreviouslySelectedSellOrders(selectedSellOrders)
    // }, [selectedSellOrders])

    useEffect(() => {
      if (!selectedRune) return

      setDesiredRunesSellAmount(0n)
    }, [selectedRune])

    function reset() {
      setSelectedRuneName('')
      setRune(undefined)

      setProceedsAddress(undefined)

      setOrderSummary(undefined)
      setSelectedSellOrders([])
      setSelectedRuneOutpoints([])
      setIsStaleOrderDataTimestamp(0)
      setDesiredRunesSellAmount(undefined)
      setDesiredBtcSellAmount(undefined)
    }

    function fetchBalances() {
      fetchAvailableBalance()
      fetchAvailableBalances()
    }

    const onIsBTConTopChange = useCallback(
      (_isBTConTop: boolean) => {
        setSearchParams(combineSearchParams(searchParams, { side: _isBTConTop ? 'buy' : 'sell' }))

        setIsBTConTop(_isBTConTop)
      },
      [searchParams, setSearchParams]
    )

    const onSelectedRuneNameChange = useCallback((runeName: string) => {
      reset()
      setSelectedRuneName(runeName)
    }, [])

    const onSelectedRuneChange = useCallback((_rune: Rune) => {
      reset()
      setRune(_rune)
      setSelectedRuneName(_rune.runeName)
    }, [])

    async function closeSellDrawer({
      clearSelectedOutpoints,
    }: {
      clearSelectedOutpoints?: boolean
    }) {
      if (clearSelectedOutpoints) {
        setSelectedRuneOutpoints([])
        setDesiredBtcSellAmount(undefined)
        setDesiredRunesSellAmount(undefined)
        setIsStaleOrderDataTimestamp(Date.now())
      }
      setIsSellRunesDrawerOpen(false)
      setTimeout(() => fetchBalances(), 1000 * 5)
    }

    async function closePlaceBidDrawer() {
      setIsPlaceBidDrawerOpen(false)
    }

    async function closeBuyBidDrawer() {
      setIsBuyBidOrderDrawerOpen(false)
    }

    async function closeBuyDrawer({
      filledOrders,
      validOrders,
    }: {
      filledOrders?: Order[]
      validOrders?: Order[]
    }) {
      if (filledOrders?.length && filledOrders.length > 0) {
        setRecentlyFilledOrders((prev) => [...filledOrders, ...prev])
        setSelectedSellOrders([])
        setDesiredBtcSellAmount(undefined)
        setDesiredRunesSellAmount(undefined)
        setIsStaleOrderDataTimestamp(Date.now())
      }
      if (validOrders) {
        setSelectedSellOrders(
          selectedSellOrders.filter((order) => validOrders.find((o) => o.id === order.id))
        )
        setIsStaleOrderDataTimestamp(Date.now())
      }
      setIsBuyRunesDrawerOpen(false)
    }

    async function closeCancelDrawer({ cancelledOrder }: { cancelledOrder?: Order | BidOrder }) {
      setSelectedCancelOrder(undefined)
      setIsCancelOrderDrawerOpen(false)
      if (cancelledOrder) {
        setRecentlyCancelledOrders((prev) => (cancelledOrder ? [cancelledOrder, ...prev] : prev))
      }

      await fetchBalances()
    }

    const balance = runeBalances.flat().find((r) => r.runeId === selectedRune?.runeId)

    const isLoadingNewRune =
      !market ||
      !runeDetails ||
      normalizeRuneName(selectedRuneName) !== market.runeName ||
      normalizeRuneName(selectedRuneName) !== runeDetails.runeName

    const state = useMemo(() => {
      return {
        availableRuneBalances: runeBalances.flat(),
        isLoadingNewRune,

        setSelectedRuneName: onSelectedRuneNameChange,
        setSelectedRune: onSelectedRuneChange,
        selectedRuneName,
        selectedRune: isLoadingNewRune ? undefined : selectedRune,
        selectedRuneDetails: isLoadingNewRune ? undefined : runeDetails,
        detailsFetchError: runeDetailsError,
        selectedRuneMarket: isLoadingNewRune ? undefined : market,
        marketFetchError: marketError,

        selectedRuneAvailableBalance:
          isLoadingNewRune && balance?.runeId !== selectedRune?.runeId
            ? 0n
            : balance?.runesAmount ?? availableBalance,

        setSelectedCancelOrder,

        isBTConTop,
        setIsBTConTop: onIsBTConTopChange,

        orderSummary,
        selectedSellOrders,
        setSelectedSellOrders,
        selectedRuneOutpoints,
        setSelectedRuneOutpoints,

        isStaleOrderDataTimestamp,
        setIsStaleOrderDataTimestamp,
        recentlyFilledOrders,
        recentlyCancelledOrders,

        desiredRunesSellAmount,
        desiredBtcSellAmount,
        setDesiredRunesSellAmount,
        setDesiredBtcSellAmount,

        proceedsAddress,
        setProceedsAddress,

        expiresAt,
        setExpiresAt,

        openBuyDrawer: () => {
          setIsBuyRunesDrawerOpen(true)
        },
        isBuyDrawerOpen: isBuyRunesDrawerOpen,

        openSellDrawer: () => setIsSellRunesDrawerOpen(true),
        isSellDrawerOpen: isSellRunesDrawerOpen,

        openPlaceBidDrawer: () => {
          setIsPlaceBidDrawerOpen(true)
        },
        isPlaceBidDrawerOpen: isPlaceBidDrawerOpen,

        openBuyBidOrderDrawer: () => {
          setIsBuyBidOrderDrawerOpen(true)
        },
        isBuyBidOrderDrawerOpen: isBuyBidOrderDrawerOpen,

        openCancelDrawer: () => {
          setIsCancelOrderDrawerOpen(true)
        },
        isCancelDrawerOpen: isCancelOrderDrawerOpen,
      }
    }, [
      availableBalance,
      balance?.runeId,
      balance?.runesAmount,
      desiredBtcSellAmount,
      desiredRunesSellAmount,
      expiresAt,
      isBuyBidOrderDrawerOpen,
      isBuyRunesDrawerOpen,
      isBTConTop,
      isCancelOrderDrawerOpen,
      isLoadingNewRune,
      isPlaceBidDrawerOpen,
      isSellRunesDrawerOpen,
      isStaleOrderDataTimestamp,
      market,
      marketError,
      onIsBTConTopChange,
      onSelectedRuneChange,
      onSelectedRuneNameChange,
      orderSummary,
      proceedsAddress,
      recentlyCancelledOrders,
      recentlyFilledOrders,
      runeBalances,
      runeDetails,
      runeDetailsError,
      selectedRune,
      selectedRuneName,
      selectedRuneOutpoints,
      selectedSellOrders,
    ])

    return (
      <ContextComponent.Provider value={state}>
        {children}
        {selectedRune && (
          <>
            <BuyRunesDrawer
              isOpen={isBuyRunesDrawerOpen}
              onClose={closeBuyDrawer}
              orders={selectedSellOrders}
              rune={selectedRune}
            />
            <SellRunesDrawer
              isOpen={isSellRunesDrawerOpen}
              onClose={closeSellDrawer}
              outpoints={selectedRuneOutpoints}
              rune={selectedRune}
              runesAmount={desiredRunesSellAmount}
              satsAmount={desiredBtcSellAmount}
              proceedsAddress={proceedsAddress}
            />
            <PlaceBidOrderDrawer
              isOpen={isPlaceBidDrawerOpen}
              onClose={closePlaceBidDrawer}
              rune={selectedRune}
              satsAmount={desiredBtcSellAmount}
              runesAmount={desiredRunesSellAmount}
              proceedsAddress={proceedsAddress}
              expiresAt={expiresAt}
            />
            <BuyBidOrderDrawer
              isOpen={isBuyBidOrderDrawerOpen}
              onClose={closeBuyBidDrawer}
              rune={selectedRune}
              runesBalance={
                isLoadingNewRune && balance?.runeId !== selectedRune?.runeId
                  ? 0n
                  : balance?.runesAmount ?? availableBalance
              }
              outpoints={selectedRuneOutpoints}
              orders={selectedBidOrders}
              proceedsAddress={proceedsAddress}
            />
          </>
        )}
        {selectedCancelOrder && (
          <CancelOrderDrawer
            isOpen={isCancelOrderDrawerOpen}
            onClose={closeCancelDrawer}
            order={selectedCancelOrder}
          />
        )}
      </ContextComponent.Provider>
    )
  }
}

export const MarketOrderProvider = createOrderProvider(MarketOrderContext, 'market')
export const LimitOrderProvider = createOrderProvider(LimitOrderContext, 'limit')

import { ButtonGroupOptions } from 'src/shared/components'

import { SwapToolMode } from './types'

const MARKET_VALUE: SwapToolMode = 'market'
const LIMIT_VALUE: SwapToolMode = 'limit'

export const SWAP_TOOL_TABS: ButtonGroupOptions[] = [
  {
    value: MARKET_VALUE,
    label: 'Market',
  },
  {
    label: 'Limit',
    value: LIMIT_VALUE,
  },
] as const

import { styled } from 'styled-components'
import { useState } from 'react'
import { useAutoAnimate } from '@formkit/auto-animate/react'

import { Rune, RuneBalance } from '@packages/interfaces'
import { API_ENDPOINTS } from '@packages/constants'
import { formatRuneName } from '@packages/utils'

import { Button, DotsLoader, MODAL_POSITION, Modal } from 'src/shared/components'
import { COLORS, FONTS } from 'src/shared/constants'
import { RuneNameInput, RuneSymbolDisplay, formatRunesAmount } from 'src/runes'
import { hexToRgb, replaceUrlParams } from 'src/shared/utils'
import { useWalletContext } from 'src/wallet'
import { usePaginationApi } from 'src/api'
import { useMintsMarketSearch } from 'src/minting'

interface Props {
  isOpen: boolean
  onSelectRune: (rune: Rune) => void
  onClose: (rune?: Rune) => void
}

export function SelectRuneModal({ isOpen, onClose, onSelectRune }: Props) {
  const [animateRefParent] = useAutoAnimate()

  const { runesAddress } = useWalletContext()
  const [modalPosition, setModalPosition] = useState<MODAL_POSITION>('center')
  const { handleOnChange, loading, hasSearched, searchResults, searchQuery } = useMintsMarketSearch(
    { limit: 3 }
  )

  const { paginatedData: runeBalances } = usePaginationApi<RuneBalance>({
    endpoint: replaceUrlParams(API_ENDPOINTS.GET.runes.balances.byAccount, {
      address: runesAddress?.addrString ?? '',
    }),
    disabled: !runesAddress,
  })

  function handleOnSelectRune(rune: Rune) {
    handleOnChange('')
    onSelectRune(rune)
    onClose(rune)
    setModalPosition('center')
  }

  function handleOnClose() {
    onClose()
    handleOnChange('')
    setModalPosition('center')
  }

  function handleOnSearchFocus() {
    setModalPosition('top')
  }

  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={handleOnClose}
      title='Select a Rune'
      position={modalPosition}
    >
      <ModalContent>
        <SearchInput
          value={searchQuery}
          onChange={handleOnChange}
          onFocus={handleOnSearchFocus}
          label='Search by Rune Name'
        />
        <ModalLinkBreak />
        <BottomSectionHeader>
          {searchQuery.length === 0 ? 'Your Runes' : 'Search Results'}
        </BottomSectionHeader>
        {searchQuery.length === 0 &&
          runeBalances.flat().map((rune) => {
            return (
              <RuneButton
                key={rune.runeId}
                onClick={() => {
                  handleOnSelectRune(rune)
                }}
              >
                <RuneNameButtonContent>
                  <RuneSymbolDisplay runeSymbol={rune.runeSymbolChar} />
                  {formatRuneName(rune)}
                </RuneNameButtonContent>
                {formatRunesAmount({
                  rune,
                  runesAmount: rune.runesAmount,
                  displayShortFormat: true,
                })}
              </RuneButton>
            )
          })}
        {loading && <DotsLoader />}
        {hasSearched && searchQuery.length !== 0 && searchResults[0] &&  (
          <ResultsSection ref={animateRefParent}>
            {searchResults[0].map((rune) => {
              return (
                <RuneButton
                  key={rune.runeId}
                  onClick={() => {
                    handleOnSelectRune(rune)
                  }}
                >
                  {rune.runeSymbolChar && rune.runeSymbolChar + ' '}
                  {formatRuneName(rune)}
                </RuneButton>
              )
            })}
          </ResultsSection>
        )}
      </ModalContent>
    </ModalWrapper>
  )
}

const ModalWrapper = styled(Modal)``

const ModalContent = styled.div`
  width: 100%;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 200px;
  height: 100%;
`

const SearchInput = styled(RuneNameInput)`
  width: 100%;
  max-width: 500px;
  text-transform: uppercase;

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`

const ResultsSection = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: 500px;
  overflow: scroll;
  padding-bottom: 100px;
`

const RuneButton = styled(Button)`
  text-wrap: nowrap;
  background-color: transparent;
  color: ${COLORS.white};
  border-radius: 5px;
  justify-content: space-between;
  &:hover {
    background-color: ${hexToRgb(COLORS.white, 0.3)};
  }
`

const RuneNameButtonContent = styled.div``

const ModalLinkBreak = styled.div`
  border-bottom: 1px solid white;
  width: 100%;
  padding: 10px 0px;
`

const BottomSectionHeader = styled.h3`
  font-family: ${FONTS.headers};
  font-weight: 400;
  text-transform: uppercase;
  font-size: 15px;
  text-align: left;
  color: ${hexToRgb(COLORS.white, 0.75)};
`

import { BidOrder, Order } from '@packages/interfaces'

import { AddressDetails } from 'src/wallet'

export function isOwnOrder(order: Order | BidOrder, runesAddress?: AddressDetails) {
  if (isAggregateOrder(order)) {
    return order.provider === 'Mystic' && order.placedByAddress === runesAddress?.addrString
  }

  if (isBidOrder(order)) {
    return order.placedByAddress === runesAddress?.addrString
  }

  return false
}

export function isOwnMagicEdenOrder(order: Order, runesAddress?: AddressDetails) {
  return order.provider === 'MagicEden' && order.placedByAddress === runesAddress?.addrString
}

export function isOwnUnisatOrder(order: Order, runesAddress?: AddressDetails) {
  return order.provider === 'Unisat' && order.placedByAddress === runesAddress?.addrString
}

export function isOwnOkxOrder(order: Order, runesAddress?: AddressDetails) {
  return order.provider === 'Okx' && order.placedByAddress === runesAddress?.addrString
}

export function isBidOrder(order: Order | Order | BidOrder): order is BidOrder {
  return 'expiresAtTimestamp' in order
}

export function isAggregateOrder(order: Order | BidOrder): order is Order {
  return 'provider' in order
}

import { styled } from 'styled-components'
import { useCallback, useState } from 'react'
import { toast } from 'react-toastify'

import { BidOrder, Order } from '@packages/interfaces'
import { formatUsd, formatRuneName, ManualError } from '@packages/utils'

import {
  Divider,
  Drawer,
  DrawerBoldColumn,
  DrawerButtons,
  DrawerCancelButton,
  DrawerCard,
  DrawerColumn,
  DrawerConfirmButton,
  DrawerRow,
  DrawerTitle,
  ErrorMessage,
  SuccessToast,
  TieredTableCell,
} from 'src/shared/components'
import { useWalletContext } from 'src/wallet'
import { RunesAmountDisplay } from 'src/runes'
import { unixTimestampToDateString } from 'src/shared/utils'
import { useGoogleAnalyticsContext } from 'src/analytics'

import { PriceSatsDisplay } from './PriceSatsDisplay'
import { BtcAmountDisplay } from './BtcAmountDisplay'

interface Props {
  isOpen: boolean
  onClose: ({ cancelledOrder }: { cancelledOrder?: Order | BidOrder }) => void

  order: Order | BidOrder
}

export function CancelOrderDrawer({ isOpen, onClose, order }: Props) {
  const [loadingText, setLoadingText] = useState<string>()
  const [error, setError] = useState<string>()

  const { cancelSellRunes, cancelPlaceBidOrder } = useWalletContext()
  const { customEvent } = useGoogleAnalyticsContext()

  const isBidOrder = 'expiresAtTimestamp' in order && order.expiresAtTimestamp

  function resetForm() {
    setError(undefined)
    setLoadingText(undefined)
  }

  const cancelSellOrder = useCallback(async () => {
    const response = await cancelSellRunes({ order })

    if (response.success) {
      console.log('Order cancelled', order)

      toast(<SuccessToast message='Order cancelled' />, {
        toastId: 'order-cancelled-' + order.id,
      })
      customEvent('cancel', true, 'swap', {
        token_name: order.runeName,
        token_type: 'rune',
        order_amount: order.runesAmount,
        price: order.satsAmount,
      })
      onClose({ cancelledOrder: order })
      resetForm()
    } else {
      setError(response.error)
    }
  }, [cancelSellRunes, customEvent, onClose, order])

  const cancelBidOrder = useCallback(async () => {
    const response = await cancelPlaceBidOrder({ order: order as BidOrder })

    if (response.success) {
      console.log('Order cancelled', order)

      toast(<SuccessToast message='Order cancelled' />, {
        toastId: 'order-cancelled-' + order.id,
      })
      customEvent('cancel', true, 'swap', {
        token_name: order.runeName,
        token_type: 'rune',
        order_amount: order.runesAmount,
        price: order.satsAmount,
      })
      onClose({ cancelledOrder: order })
      resetForm()
    } else {
      setError(response.error)
    }
  }, [cancelPlaceBidOrder, customEvent, onClose, order])

  async function onConfirmClick() {
    try {
      setLoadingText('Cancelling')
      setError(undefined)

      if (isBidOrder) {
        await cancelBidOrder()
      } else {
        await cancelSellOrder()
      }
    } catch (error) {
      console.error(error)
      if (error instanceof ManualError) {
        setError((error as ManualError).message)
      } else {
        switch (error) {
          // placeholder
          case '':
            break
          default:
            setError('Error cancelling order')
            break
        }
      }
    } finally {
      setLoadingText(undefined)
    }
  }

  function handleOnClose() {
    setError(undefined)
    onClose({})
  }

  const buttonText = loadingText ?? 'Confirm Cancel'
  const title = isBidOrder ? 'Cancel Bid Order' : 'Cancel Sell Order'

  return (
    <Drawer isOpen={isOpen} onClose={handleOnClose}>
      <DrawerCard>
        <DrawerTitle>{title}</DrawerTitle>
        <DrawerRow>
          <DrawerBoldColumn>Rune</DrawerBoldColumn>
          <DrawerColumn>{formatRuneName(order)}</DrawerColumn>
        </DrawerRow>
        <DrawerRow>
          <DrawerBoldColumn>Placed At</DrawerBoldColumn>
          <DrawerColumn>{unixTimestampToDateString(order.placedAtTimestamp, true)}</DrawerColumn>
        </DrawerRow>
        <DrawerRow>
          <DrawerBoldColumn>Price</DrawerBoldColumn>
          <DrawerColumn>
            <TieredTableCell
              header={
                <PriceSatsDisplay priceSats={order.priceSats} runeSymbol={order.runeSymbolChar} />
              }
              subHeader={order.priceUsd}
            />
          </DrawerColumn>
        </DrawerRow>
        <DrawerRow>
          <DrawerBoldColumn>Quantity</DrawerBoldColumn>
          <QuantityColum>
            <RunesAmountDisplay rune={order} runesAmount={order.runesAmount} />
          </QuantityColum>
        </DrawerRow>
        <Divider />
        <DrawerRow>
          <DrawerBoldColumn>Total BTC</DrawerBoldColumn>
          <DrawerColumn>
            <TieredTableCell
              header={<BtcAmountDisplay btcAmount={order.satsAmount} showPriceSymbol />}
              subHeader={formatUsd({ usd: order.valueUsd })}
            />
          </DrawerColumn>
        </DrawerRow>
      </DrawerCard>

      {error && <ErrorMessage message={error} />}
      <DrawerButtons>
        {loadingText ? (
          <DrawerCancelButton onClick={() => setLoadingText(undefined)}>
            Cancel Tx
          </DrawerCancelButton>
        ) : (
          <DrawerCancelButton onClick={handleOnClose}>Exit</DrawerCancelButton>
        )}
        <DrawerConfirmButton loading={!!loadingText} onClick={onConfirmClick}>
          {buttonText}
        </DrawerConfirmButton>
      </DrawerButtons>
    </Drawer>
  )
}

const QuantityColum = styled(DrawerColumn)`
  font-weight: 700;
`

import { createContext, ReactNode, useContext, useState } from 'react'

import { IS_BIDDING_ENABLED } from 'src/settings'

interface FeatureFlagContextType {
  isBiddingEnabled: boolean
  setIsBiddingEnabled: (value: boolean) => void
}

const FeatureFlagContext = createContext<FeatureFlagContextType | undefined>(undefined)

export const useFeatureFlagContext = () => {
  const context = useContext(FeatureFlagContext)
  if (context === undefined) {
    throw new Error('useFeatureFlagContext must be used within a FeatureFlagContext')
  }
  return context
}

export const FeatureFlagProvider = ({ children }: { children: ReactNode }) => {
  const [isBiddingEnabled, setIsBiddingEnabled] = useState(IS_BIDDING_ENABLED)

  return (
    <FeatureFlagContext.Provider
      value={{
        isBiddingEnabled,
        setIsBiddingEnabled,
      }}
    >
      {children}
    </FeatureFlagContext.Provider>
  )
}

import { styled } from 'styled-components'

import { DrawerBoldColumn, DrawerRow } from 'src/shared/components'
import { BREAKPOINTS } from 'src/shared/constants'

export const Header = styled(DrawerBoldColumn)`
  display: flex;
  gap: 10px;
  font-size: 17px;
  font-weight: 500;
`

export const TotalAmountRow = styled(DrawerRow)`
  @media (max-width: ${BREAKPOINTS.medium}) {
    flex-direction: row;
    gap: 5px;
  }
`

export const TotalAmountHeader = styled(DrawerBoldColumn)`
  flex: 2;
  font-size: 16px;
`

import { type ButtonGroupOptions } from 'src/shared/components'
import { ROUTES } from 'src/pages'

import { type RuneToolsTab } from './types'

export const MARKET_ROUTES = [ROUTES.markets, ROUTES.marketDetails]
export const MINT_ROUTES = [ROUTES.mint, ROUTES.runeDetails]
export const TRANSFER_ROUTES = [ROUTES.accountDetails]

export const RUNE_TOOLS_TABS: Record<
  RuneToolsTab,
  {
    value: RuneToolsTab
    label: string
  }
> = {
  swap: {
    value: 'swap',
    label: 'Swap',
  },
  mint: {
    value: 'mint',
    label: 'Mint',
  },
  transfer: {
    value: 'transfer',
    label: 'Transfer',
  },
}

export const RUNE_TOOLS_TABS_OPTIONS = Object.values(RUNE_TOOLS_TABS).map<ButtonGroupOptions>(
  (tab) => {
    return {
      label: tab.label,
      value: tab.value,
    }
  }
)

export const DEFAULT_TAB: RuneToolsTab = 'swap'

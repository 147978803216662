import { styled } from 'styled-components'

import {
  type RewardHistoryQueryParams,
  useRewardHistoryApi,
  MysticPointsLogo,
  RewardEventHistoryResponse,
} from 'src/rewards'
import { useWalletContext } from 'src/wallet'
import { BREAKPOINTS } from 'src/shared/constants'
import { VirtualizedTable } from 'src/shared/components'
import { TransactionExternalLink } from 'src/web3'
import { unixTimestampToDateString } from 'src/shared/utils'
import { useIsMobile } from 'src/shared/hooks'

const QUERY_PARAMS: RewardHistoryQueryParams = {
  limit: 40,
}

type RewardHistoryTableProps = {
  address: string
}

export function RewardHistoryTable({ address }: RewardHistoryTableProps) {
  const { isConnected } = useWalletContext()

  const isMobile = useIsMobile()

  const { paginatedData, fetchPage, newPageLoading, hasNextPage } = useRewardHistoryApi({
    address: address || '',
    disabled: !isConnected,
    queryParams: QUERY_PARAMS,
  })
  return (
    <Container>
      <VirtualizedTableWrapper
        columns={[
          {
            dataKey: 'points',
            label: 'Amount',
            width: isMobile ? 40 : 35,
            formatter: ({ data }) => {
              return (
                <TextCell>
                  <MysticPointsLogo />
                  <span>{` ${data?.points || 0}`}</span>
                </TextCell>
              )
            },
          },
          {
            dataKey: 'eventType',
            label: 'Action',
            width: isMobile ? 50 : 30,
            formatter: ({ data }) => {
              return <TextCell>{data?.eventType || ''}</TextCell>
            },
          },
          {
            dataKey: 'includedTimestamp',
            label: 'Date',
            width: isMobile ? 30 : 20,
            formatter: ({ data }) => {
              if (!data?.includedTimestamp) {
                return null
              }

              return (
                <TransactionExternalLink transactionId={data.eventTransactionId}>
                  {unixTimestampToDateString(data.includedTimestamp, true)}
                </TransactionExternalLink>
              )
            },
          },
        ]}
        defaultSortBy='desc'
        defaultSort='includedTimestamp'
        paginatedData={paginatedData}
        fetchPage={fetchPage}
        loading={newPageLoading}
        hasNextPage={hasNextPage}
        rowHeight={50}
        viewableRows={25}
        headerShown={false}
        emptyDataMessage='No rewards found'
        useWindowScroll={false}
      />
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const VirtualizedTableWrapper = styled(VirtualizedTable<RewardEventHistoryResponse>)`
  height: 400px;
  min-height: 50vh;

  @media (max-width: ${BREAKPOINTS.medium}) {
    min-height: unset;
    height: 330px;
  }
`

const TextCell = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 600;
  text-transform: uppercase;
`

import { useCallback, useState } from 'react'
import { styled } from 'styled-components'

import { BidOrder, FilledBidOrder, Order, Rune } from '@packages/interfaces'
import { formatUsd } from '@packages/utils'
import { BID_ORDERS_SORT_COLUMN, ORDERS_SORT_ORDER, SORT_ORDER } from '@packages/constants'

import { RunesAmountDisplay } from 'src/runes'
import { TieredTableCell, ToggleSwitch, VirtualizedTable } from 'src/shared/components'
import { BREAKPOINTS } from 'src/shared/constants'
import { useIsMobile } from 'src/shared/hooks'
import { unixTimestampToDateString } from 'src/shared/utils'
import { TransactionExternalLink } from 'src/web3'

import { useOrderHistoryForTable } from '../hooks'
import { formatPriceSymbol, isBidOrder } from '../utils'
import { BtcAmountDisplay } from './BtcAmountDisplay'
import { TableHeaderContainerWrapper, TableSwitchesContainer } from './styles'
import { useGetOrderState } from '../hooks/useGetOrderState'

interface Props {
  rune: Rune
}

export function OrderHistoryTable({ rune }: Props) {
  const isMobile = useIsMobile()
  const { isBidOrderState, toggleOrderState } = useGetOrderState()
  const handleToggleBidOrders = useCallback(
    (isBidOrdersEnabled: boolean) => {
      toggleOrderState(isBidOrdersEnabled ? 'bid' : 'sell')
    },
    [toggleOrderState]
  )
  const [queryParams, setQueryParams] = useState({})

  const { filledOrders, fetchPage, newPageLoading, hasNextPage } = useOrderHistoryForTable({
    rune,
    type: isBidOrderState ? 'bid' : 'sell',
    otherQueryParams: queryParams,
  })

  function onSort(orderBy: ORDERS_SORT_ORDER | BID_ORDERS_SORT_COLUMN, order: SORT_ORDER) {
    setQueryParams({ sortOrderBy: orderBy, sortOrder: order })
  }

  return (
    <Container>
      <VirtualizedTableWrapper
        columns={[
          {
            dataKey: 'priceSats',
            width: 25,
            label: isMobile
              ? 'Price'
              : `Price (${formatPriceSymbol({ runeSymbol: rune.runeSymbolChar })})`,
            onSort: (sortOrder: SORT_ORDER) => onSort('priceSats', sortOrder),
            formatter: ({ data: order }) => (
              <TieredTableCell header={order.priceSats} subHeader={order.priceUsd} />
            ),
          },
          {
            dataKey: 'runesAmount',
            width: 20,
            label: isMobile ? 'Qty' : 'Quantity',
            formatter: ({ data: order }) => (
              <Quantity>
                <RunesAmountDisplay
                  rune={rune}
                  runesAmount={order.runesAmount}
                  shortFormat={isMobile}
                />
              </Quantity>
            ),
          },
          {
            dataKey: 'satsAmount',
            width: 25,
            label: 'Total',
            onSort: (sortOrder: SORT_ORDER) => onSort('total', sortOrder),
            formatter: ({ data: order }) => (
              <TieredTableCell
                header={
                  <BtcAmountDisplay
                    btcAmount={order.satsAmount}
                    showPriceSymbol
                    shortFormat={isMobile}
                  />
                }
                subHeader={formatUsd({ usd: order.valueUsd })}
              />
            ),
          },
          {
            dataKey: 'filledAtTimestamp',
            width: 30,
            label: 'Date',
            formatter: ({ data: order }) =>
              order.fillTransactionId && order.filledAtTimestamp ? (
                <TransactionExternalLink
                  transactionId={order.fillTransactionId}
                  displayIcon={!isMobile}
                >
                  {unixTimestampToDateString(order.filledAtTimestamp, true)}
                </TransactionExternalLink>
              ) : (
                <></>
              ),
          },
          ...(isBidOrderState
            ? [
                {
                  dataKey: 'expiresAtTimestamp',
                  width: 20,
                  label: 'Expires',
                  formatter: ({ data: bidOrder }: { data: Order | BidOrder }) => {
                    if (isBidOrder(bidOrder)) {
                      return unixTimestampToDateString(bidOrder.expiresAtTimestamp, true)
                    }

                    return ''
                  },
                },
              ]
            : []),
        ]}
        paginatedData={filledOrders}
        fetchPage={fetchPage}
        loading={newPageLoading}
        hasNextPage={hasNextPage}
        rowHeight={50}
        viewableRows={25}
        headerShown={false}
        emptyDataMessage='No trade history'
        useWindowScroll={false}
        defaultSortBy='desc'
        defaultSort='filledAtTimestamp'
        customHeader={
          <TableHeaderContainerWrapper>
            <TableSwitchesContainer>
              <ToggleSwitch
                value={isBidOrderState}
                onChange={handleToggleBidOrders}
                options={[false, true]}
                labels={['Sell Orders', 'Bid Orders']}
              />
            </TableSwitchesContainer>
          </TableHeaderContainerWrapper>
        }
      />
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const Quantity = styled.div`
  font-weight: 700 !important;
`

const VirtualizedTableWrapper = styled(VirtualizedTable<Order | FilledBidOrder>)`
  height: 400px;
  min-height: 50vh;

  @media (max-width: ${BREAKPOINTS.medium}) {
    min-height: unset;
    height: 330px;
  }
`

import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import _Select, { SelectChangeEvent } from '@mui/material/Select'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { createGlobalStyle, styled } from 'styled-components'
import { BitcoinNetworkType } from 'sats-connect'

import btcIcon from 'src/wallet/assets/bitcoin.svg'
import btcTestnetIcon from 'src/web3/assets/bitcoin-testnet.svg'
import fractalIcon from 'src/web3/assets/fractal.png'
import { hexToRgb } from 'src/shared/utils'
import { COLORS, FONTS } from 'src/shared/constants'
import {
  AVAILABLE_BITCOIN_NETWORKS,
  BITCOIN_NETWORK_HOME_PAGES,
  DEPLOYED_BITCOIN_NETWORK,
} from 'src/settings'
import { applyOnlyBorderGlow, NOISE_BG_CSS } from 'src/shared/styles'

const options = AVAILABLE_BITCOIN_NETWORKS.map((network) => {
  return {
    value: network,
  }
})

function getLabel(network: BitcoinNetworkType) {
  let label = 'Bitcoin'
  switch (network) {
    case BitcoinNetworkType.Mainnet:
      label = 'Bitcoin'
      break
    case BitcoinNetworkType.Testnet:
      label = 'Bitcoin Testnet'
      break
    case BitcoinNetworkType.Signet:
      label = 'Bitcoin Regtest'
      break
    case BitcoinNetworkType.FractalMainnet:
      label = 'Fractal'
      break
    case BitcoinNetworkType.FractalTestnet:
      label = 'Fractal Testnet'
      break
  }

  return label
}

function getIcon(network: BitcoinNetworkType) {
  let icon = btcIcon
  switch (network) {
    case BitcoinNetworkType.Mainnet:
      icon = btcIcon
      break
    case BitcoinNetworkType.Testnet:
      icon = btcTestnetIcon
      break
    case BitcoinNetworkType.Signet:
      icon = btcTestnetIcon
      break
    case BitcoinNetworkType.FractalMainnet:
      icon = fractalIcon
      break
    case BitcoinNetworkType.FractalTestnet:
      icon = fractalIcon
      break
  }

  return icon
}

interface Props {
  className?: string
}

export function ChainSelector({ className }: Props) {
  const handleChange = (event: SelectChangeEvent) => {
    const newNetwork = event.target.value as BitcoinNetworkType
    if (newNetwork !== DEPLOYED_BITCOIN_NETWORK) {
      window.open(BITCOIN_NETWORK_HOME_PAGES[newNetwork], '_self')
    }
  }

  return (
    <FormControlWrapper className={className}>
      <GlobalMenuStyles />
      <SelectWrapper
        id={'network-select'}
        value={DEPLOYED_BITCOIN_NETWORK}
        label={'network'}
        onChange={handleChange}
        IconComponent={KeyboardArrowDownIcon}
        className='network-select-component'
        MenuProps={{ classes: { paper: 'network-select-menu' } }}
      >
        {options.map((option) => {
          return (
            <MenuItemWrapper value={option.value} key={option.value}>
              <NetworkIcon src={getIcon(option.value)} />
              {getLabel(option.value)}
            </MenuItemWrapper>
          )
        })}
      </SelectWrapper>
    </FormControlWrapper>
  )
}

const GlobalMenuStyles = createGlobalStyle`
  .network-select-menu {
      margin-top: 5px;
      border-radius: 6px;
      min-width: 250px;
      text-transform: uppercase;

      ${NOISE_BG_CSS}
      ${applyOnlyBorderGlow({})}
      font-weight: 500;
      color: ${COLORS.white};

      hr {
          ${applyOnlyBorderGlow({})}
          border-color: ${hexToRgb(COLORS.white, 0.5)};
      }
    

    .MuiMenu-list {
        .Mui-selected {
            background-color: ${hexToRgb(COLORS.white, 0.2)} !important;
        }
    }
}
`

const FormControlWrapper = styled(FormControl)`
  border-radius: 100px;
`

const SelectWrapper = styled(_Select<string>)`
  border-radius: 100px;
  background-color: ${COLORS.buttons.primary};
  font-weight: 500;
  font-family: ${FONTS.text};
  color: ${COLORS.black};
  font-weight: 700;
  font-size: 15px;
  border: none;

  background-color: ${COLORS.buttons.primary};
  white-space: nowrap;

  &:hover {
    background-color: ${hexToRgb(COLORS.white, 0.5)};
  }

  .MuiSelect-select {
    padding: 7px 14px;
    display: flex;
    gap: 5px;
    font-weight: 500;
    font-family: ${FONTS.text};
    text-transform: uppercase;
  }
  .MuiOutlinedInput-notchedOutline {
    display: none;
  }
  .MuiMenu-paper {
    margin-top: 3px;
  }

  svg {
    color: ${COLORS.black};
  }
`

const MenuItemWrapper = styled(MenuItem)`
  font-weight: 500;
  min-height: auto;
  display: flex;
  gap: 5px;
  font-family: ${FONTS.text};
  font-size: 15px;
  cursor: pointer;
  &:hover {
    background-color: ${hexToRgb(COLORS.white, 0.1)};
  }
  min-height: 35px;
`

const NetworkIcon = styled.img`
  width: 20px;
`

import { InputToSign, SellOrderInfo } from '../submissions/SellOrderSubmission'
import { Rune } from './Rune'

export interface Order extends Rune {
  id: string

  runesAmount: bigint
  satsAmount: bigint

  valueUsd: bigint

  priceUsd: string
  pricePureUsd?: string
  priceSats: string

  isActive: boolean
  status: OrderStatus

  /**
   * @deprecated Do not rely on this value if the service fee rate changes this value will be incorrect
   */
  marketplaceFeeAmountSats?: bigint
  /**
   * @deprecated Do not rely on this value if the service fee rate changes this value will be incorrect
   */
  marketplaceFeeAmountUsd?: bigint // using usd decimals
  marketplaceFeeReceivedByAddress?: string

  satsPerVbyteFee?: bigint

  placedByAddress: string
  placedAtTimestamp: bigint

  originalOutpointIds?: string[]
  boxTransactionId?: string
  boxedAtBlockHeight?: bigint
  boxedAtTimestamp?: bigint

  cancelledAtTimestamp?: bigint

  fillTransactionId?: string
  fillBroadcastAtTimestamp?: bigint
  filledByAddress?: string
  filledAtBlockHeight?: bigint
  filledAtTimestamp?: bigint

  voidedAtBlockHeight?: bigint
  voidedByTransactionId?: string
  voidedAtTimestamp?: bigint
  provider: Provider
}

export interface OrderSorted extends Order {
  purePriceSats: number
}

export type Provider = 'Mystic' | 'MagicEden' | 'Unisat' | 'Okx'

export enum OrderStatus {
  PlacedPending = 'PlacedPending',
  PlacedActive = 'PlacedActive',
  Placed = 'Placed',
  FilledPending = 'FilledPending',
  Filled = 'Filled',
  Cancelled = 'Cancelled',
  Voided = 'Voided',
  Any = 'Any',
}

export function isOrderStatus(status: string): status is OrderStatus {
  return Object.values(OrderStatus).includes(status as OrderStatus)
}

export interface FillPendingOrder extends Order {
  status: OrderStatus.FilledPending
  fillTransactionId: string
  fillBroadcastAtTimestamp: bigint
}

export interface FilledOrder extends Order {
  status: OrderStatus.Filled
  fillTransactionId: string
  fillBroadcastAtTimestamp: bigint
  filledByAddress: string
  filledAtBlockHeight: bigint
  filledAtTimestamp: bigint
}

export interface VoidedOrder extends Order {
  status: OrderStatus.Voided
  voidedAtBlockHeight: bigint
  voidedByTransactionId: string
  voidedAtTimestamp: bigint
}

export interface CancelledOrder extends Order {
  status: OrderStatus.Cancelled
  cancelledAtTimestamp: bigint
}

export type OrderProvider = { provider: string }
export type AggregateFilledOrder = FilledOrder & OrderProvider

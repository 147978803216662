import { useMemo } from 'react'

import { API_ENDPOINTS } from '@packages/constants'
import { OrderStatus, BidOrder } from '@packages/interfaces'

import { replaceUrlParams } from 'src/shared/utils'
import { useUpdateOnBlockPaginationApi } from 'src/api'
import { AddressDetails } from 'src/wallet'

export function useAccountBidOrderHistory({
  runeId,
  address,
  runesAddress,
  otherQueryParams
}: {
  runeId?: string
  address: string
  runesAddress: AddressDetails | undefined
  otherQueryParams?: { [key: string]: any }
}) {
  const endpoint = useMemo(() => {
    if (runeId) {
      return `${replaceUrlParams(API_ENDPOINTS.GET.runes.bidOrders.byRuneForAccount, {
        address,
        runeId,
      })}?status=${OrderStatus.Any}${
        runesAddress ? `&runesAddress=${runesAddress?.addrString}` : ''
      }`
    } else {
      return `${replaceUrlParams(API_ENDPOINTS.GET.runes.bidOrders.byAccount, {
        address,
      })}?status=${OrderStatus.Any}${
        runesAddress ? `&runesAddress=${runesAddress?.addrString}` : ''
      }`
    }
  }, [address, runeId, runesAddress])

  const res = useUpdateOnBlockPaginationApi<BidOrder>({
    endpoint,
    otherQueryParams
  })

  return res
}

import Decimal from 'decimal.js-light'

import { Rune } from '@packages/interfaces'
import { MAX_SATS_PRICE_DECIMALS, bigIntToDecimal, formatNumber } from '@packages/utils'

export function parsePriceStr(price: string | undefined): number {
  return parseFloat(price?.replace(/,/g, '') ?? '0')
}

export function calculateTotalSatsFromDesiredPrice({
  desiredPriceSats,
  desiredRunesAmount = 0n,
  rune,
}: {
  desiredPriceSats: string | number
  desiredRunesAmount?: bigint
  rune: Rune
}): bigint {
  const totalSats = BigInt(
    new Decimal(desiredPriceSats)
      .mul(`${desiredRunesAmount}`)
      .div(10 ** Number(rune.runeDecimals))
      .toFixed(0)
  )
  return totalSats
}

export function calculateTotalSatsFromDesiredTotal({
  desiredPriceSats,
  desiredTotalSats = 0n,
  rune,
}: {
  desiredPriceSats: string | number
  desiredTotalSats?: bigint
  rune: Rune
}): bigint {
  const runesAmount =
    BigInt(
      new Decimal(`${desiredTotalSats}`)
        .div(new Decimal(desiredPriceSats))
        // .mul(10 ** Number(rune.runeDecimals))
        .toFixed(0)
    ) * BigInt(10 ** Number(rune.runeDecimals))
  return runesAmount
}

export function calculatePrice({
  satsAmount,
  runesAmount,
  rune,
  format = true,
}: {
  satsAmount: bigint
  runesAmount: bigint
  rune: Rune
  format?: boolean
}) {
  const formattedRunesAmount = bigIntToDecimal(runesAmount).div(`${10n ** rune.runeDecimals}`)
  const numStr = formattedRunesAmount.gt(new Decimal(0))
    ? bigIntToDecimal(satsAmount).div(formattedRunesAmount).toFixed(MAX_SATS_PRICE_DECIMALS)
    : '0'

  if (format) {
    return formatNumber({
      numStr,
      showAllDecimals: true,
    })
  } else {
    return numStr
  }
}

export function calculatePriceDifference(priceA: string, priceB: string, maxDigits?: number) {
  const pricePercentageDifference = Math.round(
    (100 * (parsePriceStr(priceA) - parsePriceStr(priceB))) / parsePriceStr(priceB)
  )

  // Check if the absolute value of the percentage difference exceeds maxDigits
  if (maxDigits && pricePercentageDifference.toString().length > maxDigits) {
    return 'much'
  }

  return pricePercentageDifference.toString() + '%' // Return the percentage difference as a string
}

import { useMemo } from 'react'

import { Order, OrderStatus } from '@packages/interfaces'

import { useOrdersByRuneForAccountApi } from './useOrdersByRuneForAccountApi'

export function useOpenOrdersByAddressForTable({
  address,
  runeId,
  type,
  status,
  otherQueryParams,
}: {
  address: string
  runeId?: string
  type: 'sell' | 'bid'
  status?: OrderStatus | 'Any'
  otherQueryParams?: { [key: string]: any }
}) {
  const showBidOrders = type === 'bid'

  const {
    paginatedData: sellOrders,
    fetchPage: fetchSellOrdersPage,
    newPageLoading: newSellOrdersPageLoading,
    hasNextPage: hasNextSellOrdersPage,
    forceRefresh: forceRefreshSellOrders,
    loading: sellOrdersLoading,
  } = useOrdersByRuneForAccountApi<Order>({
    address,
    runeId: runeId,
    status: status ?? OrderStatus.Placed,
    type: 'sell',
    otherQueryParams
  })

  const {
    paginatedData: bidOrders,
    loading: bidOrdersLoading,
    fetchPage: fetchBidOrdersPage,
    newPageLoading: newBidOrdersPageLoading,
    hasNextPage: hasNextBidOrdersPage,
    forceRefresh: forceRefreshBidOrders,
  } = useOrdersByRuneForAccountApi<Order>({
    address,
    runeId: runeId,
    status: status ?? OrderStatus.Placed,
    type: 'bid',
    otherQueryParams
  })

  const orders = showBidOrders ? bidOrders : sellOrders
  const fetchPage = showBidOrders ? fetchBidOrdersPage : fetchSellOrdersPage
  const newPageLoading = showBidOrders ? newBidOrdersPageLoading : newSellOrdersPageLoading
  const hasNextPage = showBidOrders ? hasNextBidOrdersPage : hasNextSellOrdersPage
  const forceRefresh = showBidOrders ? forceRefreshBidOrders : forceRefreshSellOrders
  const loading = showBidOrders ? bidOrdersLoading : sellOrdersLoading

  return useMemo(() => {
    return {
      orders,
      fetchPage,
      newPageLoading,
      hasNextPage,
      forceRefresh,
      loading,
    }
  }, [orders, fetchPage, newPageLoading, hasNextPage, forceRefresh, loading])
}

import { styled } from 'styled-components'
import { useEffect, useState } from 'react'

import { BatchMint, MintStatus, SingleMint } from '@packages/interfaces'
import { API_ENDPOINTS } from '@packages/constants'

import { HelpTooltip, LoadingIcon, VirtualizedTable } from 'src/shared/components'
import { useUpdateOnBlockPaginationApi } from 'src/api'
import { RuneDetailsLink } from 'src/runes'
import { useIsMobile } from 'src/shared/hooks'
import { replaceUrlParams, unixTimestampToDateString } from 'src/shared/utils'
import { TransactionExternalLink } from 'src/web3'
import { BREAKPOINTS, COLORS } from 'src/shared/constants'

interface Props {
  paymentAddress: string
}

interface ChildStatusCountsMap {
  [batchId: string]: {
    [batchMintStatus in MintStatus]: number
  }
}

export function BatchMintsTable({ paymentAddress }: Props) {
  const isMobile = useIsMobile()
  const [mintStatusCounts, setMintStatusCounts] = useState<ChildStatusCountsMap>({})

  const {
    paginatedData: batchMints,
    fetchPage,
    newPageLoading,
    hasNextPage,
  } = useUpdateOnBlockPaginationApi<BatchMint | SingleMint>({
    endpoint: replaceUrlParams(API_ENDPOINTS.GET.runes.mints.byAddress, {
      address: paymentAddress,
    }),
    limit: isMobile ? 20 : 40,
  })

  useEffect(() => {
    batchMints.flat().map((mint) => {
      const statusCounts: { [status in MintStatus]: number } = {
        minted: 0,
        pending: 0,
        failed: 0,
      }

      if (mint.type == 'batch') {
        mint.childTxs.forEach((child) => {
          statusCounts[child.status]++
        })
        setMintStatusCounts((prev) => ({
          ...prev,
          [mint.splitterTxId]: statusCounts,
        }))
      } else {
        statusCounts[mint.status]++

        setMintStatusCounts((prev) => ({
          ...prev,
          [mint.txId]: statusCounts,
        }))
      }
    })
  }, [batchMints])

  return (
    <Container>
      <VirtualizedTableWrapper
        columns={[
          {
            dataKey: 'runeName',
            label: 'Token',
            width: isMobile ? 40 : 30,
            formatter: ({ data: mint }) => <RuneDetailsLink rune={mint} swapToolTab={'mint'} />,
          },
          {
            dataKey: 'quantity',
            label: 'Mints',
            width: isMobile ? 30 : 15,
          },
          {
            dataKey: 'pending',
            label: 'Pending',
            width: 15,
            hideOnMobile: true,
            formatter: ({ data: mint }) => {
              if (
                mint.type == 'batch'
                  ? !mintStatusCounts[mint.splitterTxId]?.pending
                  : !mintStatusCounts[mint.txId]?.pending
              )
                return <ZeroCountStatus />

              return (
                <StatusType color={COLORS.white}>
                  {mint.type == 'batch'
                    ? mintStatusCounts[mint.splitterTxId]?.pending
                    : mintStatusCounts[mint.txId]?.pending}
                  <LoadingIcon />
                </StatusType>
              )
            },
          },
          {
            dataKey: 'minted',
            label: 'Minted',
            width: 15,
            hideOnMobile: true,
            formatter: ({ data: mint }) => {
              if (
                mint.type == 'batch'
                  ? !mintStatusCounts[mint.splitterTxId]?.minted
                  : !mintStatusCounts[mint.txId]?.minted
              )
                return <ZeroCountStatus />

              return (
                <StatusType color={COLORS.positive}>
                  {mint.type == 'batch'
                    ? mintStatusCounts[mint.splitterTxId]?.minted
                    : mintStatusCounts[mint.txId]?.minted}
                </StatusType>
              )
            },
          },
          {
            dataKey: 'failed',
            label: 'Failed',
            width: 15,
            hideOnMobile: true,
            formatter: ({ data: mint }) => {
              if (
                mint.type == 'batch'
                  ? !mintStatusCounts[mint.splitterTxId]?.failed
                  : !mintStatusCounts[mint.txId]?.failed
              )
                return <ZeroCountStatus />
              return (
                <StatusType color={COLORS.negative}>
                  {mint.type == 'batch'
                    ? mintStatusCounts[mint.splitterTxId]?.failed
                    : mintStatusCounts[mint.txId]?.failed}
                </StatusType>
              )
            },
          },
          {
            dataKey: 'status',
            label: 'Status',
            width: 30,
            disableSort: true,
            hideOnDesktop: true,
            formatter: ({ data: mint }) => {
              const statuses =
                mint.type == 'batch'
                  ? mintStatusCounts[mint.splitterTxId]
                  : mintStatusCounts[mint.txId]
              if (statuses?.pending > 0) {
                return (
                  <StatusType color={COLORS.white}>
                    Pending
                    <LoadingIcon />
                  </StatusType>
                )
              }
              if (statuses?.failed > 0) {
                return <StatusType color={COLORS.negative}>Failed</StatusType>
              }
              return <StatusType color={COLORS.positive}>Minted</StatusType>
            },
          },
          {
            dataKey: 'mintedAtTimestamp',
            label: 'Date',
            width: 25,
            hideOnMobile: true,
            formatter: ({ data: mint }) => unixTimestampToDateString(mint.mintedAtTimestamp, true),
          },
          {
            dataKey: 'splitterTxId',
            label: 'Tx',
            disableSort: true,
            content: (
              <SplitterTxLabel>
                Tx{' '}
                <HelpTooltip content='This transaction was used to split your BTC in order to fund the minting transactions or to complete single mints' />
              </SplitterTxLabel>
            ),
            width: 15,
            hideOnMobile: true,
            formatter: ({ data: mint }) => (
              <TransactionExternalLink
                transactionId={mint.type == 'batch' ? mint.splitterTxId : mint.txId}
              />
            ),
          },
        ]}
        defaultSortBy='desc'
        defaultSort='mintedAtTimestamp'
        paginatedData={batchMints}
        fetchPage={fetchPage}
        loading={newPageLoading}
        hasNextPage={hasNextPage}
        rowHeight={isMobile ? 30 : 35}
        viewableRows={10}
        useWindowScroll={false}
      />
    </Container>
  )
}

const Container = styled.div``

const StatusType = styled.div<{ color: string }>`
  font-weight: 700;
  color: ${({ color }) => color} !important;
  display: flex;
  gap: 5px;
  align-items: center;
`

const SplitterTxLabel = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`

const VirtualizedTableWrapper = styled(VirtualizedTable<BatchMint | SingleMint>)`
  height: 400px;
  min-height: 50vh;

  @media (max-width: ${BREAKPOINTS.medium}) {
    min-height: unset;
    height: 330px;
  }
`

function ZeroCountStatus() {
  return <StatusType color={COLORS.white}>-</StatusType>
}

import { styled } from 'styled-components'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { BREAKPOINTS } from 'src/shared/constants'
import { LargeButtonGroup } from 'src/shared/components'
import { OpenOrdersByAddress } from 'src/orders'
import { useWalletContext } from 'src/wallet'
import { RuneBalancesTable } from 'src/runes'
import { EtchedRunesTable, EtchRevealTable } from 'src/etching'
import { BatchMintsTable } from 'src/minting'
import { RewardHistoryTable } from 'src/rewards'
import { SWITCH_ACCOUNTS_TABS } from 'src/account/constants'

import { AccountDetailsOpenOrders } from './AccountDetailsOpenOrders'
import { AccountDetailsOrderHistory } from './AccountDetailsOrderHistory'
import { AccountHistoryTable } from './AccountHistoryTable'

export const ACCOUNT_DETAILS_TABS = {
  balances: 'Balances',
  openOrders: 'Open Orders',
  orderHistory: 'Order History',
  mints: 'Mints',
  commitedEtches: 'Commited Etches',
  revealedEtches: 'Revealed Etches',
  history: 'History',
  mysticPoints: 'Mystic Points',
}

const defaultTabOptions = [
  { label: ACCOUNT_DETAILS_TABS.balances, value: ACCOUNT_DETAILS_TABS.balances },
  { label: ACCOUNT_DETAILS_TABS.history, value: ACCOUNT_DETAILS_TABS.history },
]

interface AccountDetailsPageContentProps {
  selectedWallet: string
}

export function AccountDetailsPageTable({ selectedWallet }: AccountDetailsPageContentProps) {
  const { address } = useParams() // this should be their ordinals / runes address
  const { runesAddress, paymentAddress } = useWalletContext()
  const [selectedTab, setSelectedTab] = useState(ACCOUNT_DETAILS_TABS.balances)
  const [tabOptions, setTabOptions] = useState(defaultTabOptions)

  const [selectedOrdersType, setSelectedOrdersType] = useState<'bid' | 'sell'>('sell')

  function handleTabOnChange(value: string) {
    setSelectedTab(value)
  }

  const isConnectedAddress = runesAddress?.addrString === address

  useEffect(() => {
    // length prevents infinite loop
    if (isConnectedAddress && tabOptions.length === 2) {
      // tabOptions.push({
      //   label: ACCOUNT_DETAILS_TABS.commitedEtches,
      //   value: ACCOUNT_DETAILS_TABS.commitedEtches,
      // })
      // tabOptions.push({
      //   label: ACCOUNT_DETAILS_TABS.revealedEtches,
      //   value: ACCOUNT_DETAILS_TABS.revealedEtches,
      // })
      setTabOptions([
        ...tabOptions,
        {
          label: ACCOUNT_DETAILS_TABS.openOrders,
          value: ACCOUNT_DETAILS_TABS.openOrders,
        },
        {
          label: ACCOUNT_DETAILS_TABS.orderHistory,
          value: ACCOUNT_DETAILS_TABS.orderHistory,
        },
        {
          label: ACCOUNT_DETAILS_TABS.mints,
          value: ACCOUNT_DETAILS_TABS.mints,
        },
        {
          label: ACCOUNT_DETAILS_TABS.mysticPoints,
          value: ACCOUNT_DETAILS_TABS.mysticPoints,
        },
      ])
    } else if (!isConnectedAddress && tabOptions.length === 5) {
      setTabOptions(defaultTabOptions)
    }
  }, [runesAddress?.addrString, isConnectedAddress])

  let tabbedContent
  if (address) {
    if (selectedTab === ACCOUNT_DETAILS_TABS.balances) {
      tabbedContent = <RuneBalancesTable address={address} />
    } else if (selectedTab === ACCOUNT_DETAILS_TABS.commitedEtches) {
      tabbedContent = <EtchRevealTable />
    } else if (selectedTab === ACCOUNT_DETAILS_TABS.revealedEtches) {
      tabbedContent = <EtchedRunesTable />
    } else if (selectedTab === ACCOUNT_DETAILS_TABS.history) {
      tabbedContent = <AccountHistoryTable address={address} />
    } else if (selectedTab === ACCOUNT_DETAILS_TABS.mints && paymentAddress) {
      tabbedContent = <BatchMintsTable paymentAddress={paymentAddress.addrString} />
    } else if (selectedTab === ACCOUNT_DETAILS_TABS.openOrders) {
      tabbedContent = (
        <AccountDetailsOpenOrders
          address={address}
          initialOrdersType={selectedOrdersType}
          onOrdersTypeChange={setSelectedOrdersType}
        />
      )
    } else if (selectedTab === ACCOUNT_DETAILS_TABS.orderHistory) {
      tabbedContent = (
        <AccountDetailsOrderHistory
          address={address}
          initialOrdersType={selectedOrdersType}
          onOrdersTypeChange={setSelectedOrdersType}
        />
      )
    } else if (selectedTab === ACCOUNT_DETAILS_TABS.mysticPoints) {
      tabbedContent = <RewardHistoryTable address={address} />
    }
  }

  return (
    <Container>
      {selectedWallet === SWITCH_ACCOUNTS_TABS.myWallet ? (
        <>
          <LargeButtonGroup
            options={tabOptions}
            value={selectedTab}
            onChange={handleTabOnChange}
            exclusive
            updateSearchParams
          />
          {tabbedContent}
        </>
      ) : (
        <OpenOrdersByAddress
          showOrdersTypeSwitch={false}
          ordersType='bid'
          address={address ?? ''}
          status='Any'
        />
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: ${BREAKPOINTS.medium}) {
    gap: 10px;
  }
`

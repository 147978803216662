import { styled } from 'styled-components'
import { useEffect, useState } from 'react'
import Decimal from 'decimal.js-light'
import InfoIcon from '@mui/icons-material/Info'
import { toast } from 'react-toastify'

import { Rune, RuneOutpointDetails } from '@packages/interfaces'
import {
  btcToSats,
  calculateFeeFromBps,
  formatBtc,
  formatRuneName,
  formatUsd,
  ManualError,
  satsToBtc,
} from '@packages/utils'
import { SATOSHI_DUST_THRESHOLD } from '@packages/constants'

import {
  Accordion,
  Divider,
  Drawer,
  DrawerBoldColumn,
  DrawerButtons,
  DrawerCancelButton,
  DrawerCard,
  DrawerColumn,
  DrawerConfirmButton,
  DrawerRow,
  DrawerTieredContent,
  DrawerTitle,
  ErrorMessage,
  LinkPageToast,
  PaginatedTable,
} from 'src/shared/components'
import { BREAKPOINTS, COLORS } from 'src/shared/constants'
import { formatRunesAmount, RuneOutpointExternalLink, RunesAmountDisplay } from 'src/runes'
import { useWalletContext } from 'src/wallet'
import { useDebounce } from 'src/shared/hooks'
import { buildAccountDetailsUrl, buildPageTabSearchParam } from 'src/pages/constants'
import { ACCOUNT_DETAILS_TABS } from 'src/account'
import { chunkArray, hexToRgb } from 'src/shared/utils'
import { DISABLE_WRITES } from 'src/settings'
import { useGoogleAnalyticsContext } from 'src/analytics'
import { MysticPointsDrawerCard, useGetRewardSize, useMysticPointsContext } from 'src/rewards'

import { calculatePrice } from '../utils'
import { PriceSatsDisplay } from './PriceSatsDisplay'
import { PriceUsdDisplay } from './PriceUsdDisplay'
import { RunesTotalColumn } from './styles'

interface Props {
  isOpen: boolean
  onClose: ({ clearSelectedOutpoints }: { clearSelectedOutpoints?: boolean }) => void
  rune: Rune
  outpoints: RuneOutpointDetails[]
  satsAmount?: bigint
  runesAmount?: bigint
  proceedsAddress?: string
}

const BIG_INT_ZERO = BigInt(0)
const OUTPOINTS_PER_PAGE = 10
const RECALCULATE_TOTALS_TIME = 250

export function SellRunesDrawer({
  isOpen,
  onClose,
  outpoints,
  satsAmount: _satsAmount,
  runesAmount,
  proceedsAddress: _proceedsAddress,
  rune,
}: Props) {
  const { btcPrice, sellRunes, paymentAddress, runesAddress, supportsMultiSignature, settings } =
    useWalletContext()

  const [satsAmount, setSatsAmount] = useState(_satsAmount ?? BIG_INT_ZERO)
  const [runesAmountTotal, setRunesAmountTotal] = useState<bigint>(0n)

  const [proceedsAddress, setProceedsAddress] = useState(_proceedsAddress ?? '')

  const [isDetailsLoading, setIsDetailsLoading] = useState(false)

  const [paginatedOutpoints, setPaginatedOutpoints] = useState<RuneOutpointDetails[][]>([[]])
  const [orderDetails, setOrderDetails] = useState<{
    satsTotal: bigint
    usdTotal: number
    priceSats: string
    marketplaceFeeAmountBtcTotal: number
  }>({ satsTotal: 0n, usdTotal: 0, priceSats: '0', marketplaceFeeAmountBtcTotal: 0 })
  const [loadingText, setLoadingText] = useState<string>()
  const [selectedNetworkFee, setSelectedNetworkFee] = useState(0)
  const [error, setError] = useState<string>()
  const { initiateEvent, confirmEvent, executeEvent, abandonEvent } = useGoogleAnalyticsContext()

  const { refetchMysticPointsBalance } = useMysticPointsContext()
  const { data: mysticPoints } = useGetRewardSize({
    runeId: rune?.runeId,
    eventType: 'sell',
    fee: btcToSats(orderDetails?.marketplaceFeeAmountBtcTotal ?? 0),
    disabled: !isOpen,
    recalculateRewardSizeTime: RECALCULATE_TOTALS_TIME,
  })

  useEffect(() => {
    if (_satsAmount) setSatsAmount(_satsAmount)
  }, [_satsAmount])

  useEffect(() => {
    let total = outpoints.reduce((acc, curr) => {
      return acc + curr.amount
    }, 0n)
    total = !!runesAmount && total > runesAmount ? runesAmount : total

    setRunesAmountTotal(total)
    setPaginatedOutpoints(chunkArray(outpoints, OUTPOINTS_PER_PAGE))
  }, [outpoints, runesAmount])

  useDebounce(
    async () => {
      if (satsAmount === 0n) return
      try {
        setError(undefined)
        setLoadingText('Recalculating...')
        setIsDetailsLoading(true)

        const priceSats = calculatePrice({ runesAmount: runesAmountTotal, satsAmount, rune })
        const serviceFeeSatsTotal = calculateFeeFromBps(
          satsAmount,
          settings.serviceFees.feeBps.order
        )

        setOrderDetails({
          satsTotal: satsAmount,
          usdTotal: new Decimal(`${satsAmount}`).mul(btcPrice?.satPriceUsd ?? 0).toNumber(),
          priceSats,
          marketplaceFeeAmountBtcTotal: satsToBtc(serviceFeeSatsTotal),
        })
        validateForm()
      } catch (error) {
        console.error((error as Error).message)
        if (error instanceof ManualError) {
          setError((error as ManualError).message)
        } else {
          switch (error) {
            // placeholder
            case '':
              break
            default:
              setError('Something unexpected has gone wrong, please contact support on our Discord')
              break
          }
        }
      } finally {
        setLoadingText(undefined)
        setIsDetailsLoading(false)
      }
    },
    [outpoints, satsAmount, btcPrice, runesAmountTotal],
    RECALCULATE_TOTALS_TIME
  )

  function validateForm() {
    let validationError: string | undefined
    setError(undefined)
    if (satsAmount < SATOSHI_DUST_THRESHOLD) {
      validationError = 'BTC amount is below the dust threshold'
    } else if (!proceedsAddress) {
      validationError = 'Enter a valid proceeds address'
    } else if (!runesAddress) {
      validationError = 'No runes address found'
    }
    setError(validationError)

    return validationError
  }

  useEffect(() => {
    if (satsAmount != 0n) validateForm()
  }, [satsAmount])

  useEffect(() => {
    if (_proceedsAddress) {
      setProceedsAddress(_proceedsAddress)
    } else {
      if (paymentAddress) {
        setProceedsAddress(paymentAddress.addrString)
      }
    }
  }, [_proceedsAddress, paymentAddress])

  useEffect(() => {
    if (isOpen) {
      initiateEvent('swap', {
        token_name: rune.runeName,
        token_type: 'rune',
        order_amount: runesAmountTotal,
        order_action: 'sell',
        price: orderDetails.satsTotal,
      })
    }
  }, [isOpen])

  function resetForm() {
    setError(undefined)
    setSelectedNetworkFee(0)
  }

  async function onConfirmClick() {
    confirmEvent('swap', {
      token_name: rune.runeName,
      token_type: 'rune',
      order_amount: runesAmountTotal,
      order_action: 'sell',
      fee: selectedNetworkFee,
      price: orderDetails.satsTotal,
    })
    try {
      const validationError = validateForm()
      if (validationError != undefined) {
        abandonEvent(false, 'swap', {
          token_name: rune.runeName,
          token_type: 'rune',
          order_amount: runesAmountTotal,
          order_action: 'sell',
          fee: selectedNetworkFee,
          price: orderDetails.satsTotal,
        })
        return
      }

      setLoadingText('Generating tx')

      const response = await sellRunes({
        rune,
        proceeds: satsAmount,
        runeOutpoints: outpoints,
        proceedsAddress,
        sellAmount: runesAmountTotal,
        fee: BigInt(selectedNetworkFee),
        onStatusChange: (status) => {
          switch (status) {
            case 'build':
              setLoadingText('Building tx')
              break
            case 'transfer-wallet-prompt':
              setLoadingText('1/2 Tx: Preparing Runes')
              break
            case 'sell-wallet-prompt-boxed':
              setLoadingText('2/2 Tx: Sell Order')
              break
            case 'sell-wallet-prompt-not-boxed':
              setLoadingText('See wallet')
              break
            case 'api-submit':
              setLoadingText('Submitting order')
              break
          }
        },
      })

      if (response.success) {
        refetchMysticPointsBalance()
        executeEvent('swap', {
          token_name: rune.runeName,
          token_type: 'rune',
          order_amount: runesAmountTotal,
          order_action: 'sell',
          fee: selectedNetworkFee,
          price: orderDetails.satsTotal,
        })

        toast(
          <LinkPageToast
            message={'Order submitted.'}
            buttonMessage={'View order'}
            reactRouterPathname={buildAccountDetailsUrl(runesAddress?.addrString ?? '')}
            reactRouterSearch={buildPageTabSearchParam(ACCOUNT_DETAILS_TABS.openOrders).toString()}
          />,
          { toastId: Date.now() }
        )

        onClose({ clearSelectedOutpoints: true })
        resetForm()
      } else {
        abandonEvent(false, 'swap', {
          token_name: rune.runeName,
          token_type: 'rune',
          order_amount: runesAmountTotal,
          order_action: 'sell',
          fee: selectedNetworkFee,
          price: orderDetails.satsTotal,
        })
      }
    } catch (error) {
      console.error((error as Error).message)
      if (error instanceof ManualError) {
        setError((error as ManualError).message)
      } else {
        switch (error) {
          // placeholder
          case '':
            break
          default:
            setError('Something unexpected has gone wrong, please contact support on our Discord')
            break
        }
      }
    } finally {
      setLoadingText(undefined)
    }
  }

  function handleOnClose() {
    abandonEvent(true, 'swap', {
      token_name: rune.runeName,
      token_type: 'rune',
      order_amount: runesAmountTotal,
      order_action: 'sell',
      fee: selectedNetworkFee,
      price: orderDetails.satsTotal,
    })
    setError(undefined)
    onClose({})
  }

  function handleOnCancel() {
    abandonEvent(false, 'swap', {
      token_name: rune.runeName,
      token_type: 'rune',
      order_amount: runesAmountTotal,
      order_action: 'sell',
      fee: selectedNetworkFee,
      price: orderDetails.satsTotal,
    })
    setLoadingText(undefined)
  }

  const buttonText = loadingText ?? 'Confirm Order'
  const isButtonDisabled = !satsAmount || !proceedsAddress || !!error
  // const outpointsTotal = outpoints.reduce((total, outpoint) => total + outpoint.amount, 0n)
  // const needBoxingTx = outpointsTotal !== runesAmountTotal

  return (
    <Drawer isOpen={isOpen} onClose={handleOnClose} canClose={!loadingText}>
      <DrawerCard>
        <DrawerTitle>Sell {formatRuneName(rune)}</DrawerTitle>
        <DrawerRow>
          <DrawerBoldColumn>Price</DrawerBoldColumn>
          <DrawerColumn>
            <DrawerTieredContent
              content={
                <>
                  &nbsp;
                  <PriceSatsDisplay
                    priceSats={orderDetails.priceSats}
                    runeSymbol={rune.runeSymbolChar}
                  />
                </>
              }
              subContent={<PriceUsdDisplay priceSats={orderDetails.priceSats} />}
              loading={isDetailsLoading}
            />
          </DrawerColumn>
        </DrawerRow>
        <DrawerRow>
          <DrawerBoldColumn>{formatRuneName(rune)}</DrawerBoldColumn>
          <RunesTotalColumn $colorVariant='negative'>
            -
            <RunesAmountDisplay
              rune={rune}
              runesAmount={runesAmountTotal}
              showPriceSymbol={false}
            />
          </RunesTotalColumn>
        </DrawerRow>
        <Divider />
        <DrawerRow>
          <DrawerBoldColumn>Total BTC</DrawerBoldColumn>
          <DrawerColumn>
            <DrawerTieredContent
              content={<BtcTotal>+{formatBtc(orderDetails.satsTotal)}</BtcTotal>}
              subContent={<UsdTotal>&nbsp;{formatUsd({ usd: orderDetails.usdTotal })}</UsdTotal>}
              loading={isDetailsLoading}
            />
          </DrawerColumn>
        </DrawerRow>
      </DrawerCard>
      <DrawerCard>
        <Accordion linkText='View Rune Outpoints'>
          <PaginatedTableWrapper
            columns={[
              {
                dataKey: 'amount',
                label: 'Quantity',
                width: 60,
                formatter: ({ data: outpoint }) =>
                  formatRunesAmount({
                    rune,
                    runesAmount: outpoint.amount,
                  }),
              },
              {
                dataKey: 'outpointId',
                label: 'Tx',
                width: 40,
                formatter: ({ data: outpoint }) => (
                  <RuneOutpointExternalLink runeOutpoint={outpoint} />
                ),
              },
            ]}
            paginatedData={paginatedOutpoints}
            fetchPage={async () => {}}
            rowHeight={30}
            rowsPerPage={OUTPOINTS_PER_PAGE}
          />
        </Accordion>
      </DrawerCard>
      <DrawerCard>
        <DrawerRow>
          <DrawerBoldColumn>Proceeds Address</DrawerBoldColumn>
        </DrawerRow>
        <DrawerRow>
          <AddressColumn>{proceedsAddress}</AddressColumn>
        </DrawerRow>
      </DrawerCard>
      {/* {needBoxingTx && (
        <>
          <DrawerCard>
            <DrawerRow>
              <NetworkFeeSelector
                selectedFee={selectedNetworkFee}
                onChange={(fee) => setSelectedNetworkFee(fee)}
              />
            </DrawerRow>
          </DrawerCard>
          <DrawerCard>
            <InfoContainer>
              <InfoIcon />
              You will be prompted to sign 2 transactions. The first transaction will{' '}
              {outpoints.length > 1 ? 'combine' : 'split'} your runes. The second transaction will
              create the sell order.
            </InfoContainer>
          </DrawerCard>
        </>
      )} */}

      {!supportsMultiSignature && (
        <DrawerCard>
          <InfoContainer>
            <InfoIcon />
            You will be prompted to sign {outpoints.length} transaction
            {outpoints.length > 1 ? 's' : ''}.{' '}
            {outpoints.length > 1 ? 'Each lot will be listed as a separate order.' : ''}
          </InfoContainer>
        </DrawerCard>
      )}

      {orderDetails?.marketplaceFeeAmountBtcTotal > 0 && (
        <MysticPointsDrawerCard points={mysticPoints} place='trade' />
      )}

      {error && <ErrorMessage message={error} />}
      <DrawerButtons>
        {loadingText ? (
          <DrawerCancelButton onClick={() => handleOnCancel()}>Cancel Tx</DrawerCancelButton>
        ) : (
          <DrawerCancelButton onClick={handleOnClose}>Exit</DrawerCancelButton>
        )}
        <DrawerConfirmButton
          onClick={onConfirmClick}
          loading={!!loadingText}
          disabled={isButtonDisabled || DISABLE_WRITES}
        >
          {buttonText}
        </DrawerConfirmButton>
      </DrawerButtons>
    </Drawer>
  )
}

const BtcTotal = styled.div`
  font-weight: 700;
  color: ${COLORS.positive};
`

const UsdTotal = styled.div`
  color: ${hexToRgb(COLORS.white, 0.7)};
  font-size: 14px;
`

const PaginatedTableWrapper = styled(PaginatedTable<RuneOutpointDetails>)`
  tfoot {
    display: none;
  }
  thead {
    position: relative !important;
  }
`

const InfoContainer = styled.div`
  font-weight: 300;
  font-size: 15px;
  display: flex;
  gap: 5px;
  @media (max-width: ${BREAKPOINTS.medium}) {
    font-size: 14px;
  }
`

const AddressColumn = styled(DrawerColumn)`
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  span {
    width: 45px;
    padding: 0px;
  }
  svg {
    height: 30px;
  }
  @media (max-width: ${BREAKPOINTS.medium}) {
    font-size: 12px;
  }
`

// const CheckboxWrapper = styled(Checkbox)`
//   svg {
//     width: 25px;
//     height: 25px;
//   }
// `

import { styled } from 'styled-components'

import { API_ENDPOINTS } from '@packages/constants'
import { RuneDetails } from '@packages/interfaces'

import { useUpdateOnBlockPaginationApi } from 'src/api'
import { VirtualizedTable } from 'src/shared/components'
import { RuneDetailsLink } from 'src/runes'
import { unixTimestampToDateString } from 'src/shared/utils'
import { TransactionExternalLink } from 'src/web3'
import { MintStateBadge, MintedPercentBadge } from 'src/minting'
import { useIsMobile } from 'src/shared/hooks'
import { BREAKPOINTS } from 'src/shared/constants'

export function LatestEtchingsTable() {
  const isMobile = useIsMobile()
  const {
    paginatedData: latestEtchings,
    fetchPage,
    newPageLoading,
    hasNextPage,
  } = useUpdateOnBlockPaginationApi<RuneDetails>({
    endpoint: API_ENDPOINTS.GET.runes.etchings.latest,
    limit: isMobile ? 20 : 40,
  })

  return (
    <VirtualizedTableWrapper
      columns={[
        {
          dataKey: 'runeName',
          label: 'Token',
          width: 50,
          disableSort: true,
          formatter: ({ data: rune }) => <RuneDetailsLink rune={rune} swapToolTab={'mint'} />,
        },
        {
          dataKey: 'mintedPercent',
          label: 'Percent Minted',
          width: 20,
          formatter: ({ data: mint }) => <MintedPercentBadge runeDetails={mint} />,
        },
        {
          dataKey: 'etchedAtTimestamp',
          label: 'Etched At',
          width: 30,
          formatter: ({ data: rune }) => (
            <EtchedAtTimestamp displayShortId transactionId={rune.etchedTransactionId}>
              {unixTimestampToDateString(rune.etchedAtTimestamp)}
            </EtchedAtTimestamp>
          ),
        },
        {
          dataKey: 'isMintable',
          label: 'Mint State',
          hideOnMobile: true,
          hideOnDesktop: true,
          formatter: ({ data: rune }) => <MintStateBadge runeDetails={rune} />,
        },
      ]}
      defaultSortBy='desc'
      defaultSort='etchedAtTimestamp'
      paginatedData={latestEtchings}
      fetchPage={fetchPage}
      loading={newPageLoading}
      hasNextPage={hasNextPage}
      viewableRows={20}
      rowHeight={isMobile ? 40 : 30}
      useWindowScroll={false}
    />
  )
}

const EtchedAtTimestamp = styled(TransactionExternalLink)`
  @media (max-width: ${BREAKPOINTS.medium}) {
    font-size: 12px;
  }
`

const VirtualizedTableWrapper = styled(VirtualizedTable<RuneDetails>)`
  height: 400px;
  min-height: 50vh;

  @media (max-width: ${BREAKPOINTS.medium}) {
    min-height: unset;
    height: 330px;
  }
`

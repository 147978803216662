import type { RuneDetails } from '@packages/interfaces'

import { TabPanelContentContainer, type TabPanelProps } from 'src/shared/components'
import { OpenSellOrdersTable } from 'src/orders'

type OrderBookTabPanelProps = TabPanelProps & {
  runeDetails: RuneDetails | undefined
}

export function OrderBookTabPanel({ visible, runeDetails }: OrderBookTabPanelProps) {
  if (!runeDetails) {
    return null
  }

  return (
    <TabPanelContentContainer $visible={visible}>
      <OpenSellOrdersTable />
    </TabPanelContentContainer>
  )
}

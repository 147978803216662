import { hex } from '@scure/base'
import { AddressType, getAddressInfo } from 'bitcoin-address-validation'
import * as P from 'micro-packed'

import * as btc from '@packages/scure-btc-signer'
import * as payment from '@packages/scure-btc-signer/payment'

import { IS_TESTNET_DEPLOYMENT } from 'src/settings'

import { AddressDetails } from '../interfaces'
import { WALLET_NAME } from '../constants'

export function formatShortAddress(address: string): string {
  if (address.length >= 10) {
    return `${address.slice(0, 5)}..${address.slice(-5)}`
  } else {
    return address
  }
}

export function formatMediumAddress(address: string): string {
  if (address.length >= 20) {
    return `${address.slice(0, 10)}..${address.slice(-10)}`
  } else {
    return formatShortAddress(address)
  }
}

export function getAddressDetails({
  addressItem,
  walletName,
}: {
  walletName: string
  addressItem?: { address: string; publicKey: string }
}): AddressDetails {
  if (addressItem === undefined || addressItem.address === undefined) throw new Error('no address')

  const btcNetwork = IS_TESTNET_DEPLOYMENT ? btc.TEST_NETWORK : btc.NETWORK
  const addrString = addressItem.address
  const addressInfo = getAddressInfo(addrString)
  let p2: payment.P2Ret
  let pubKey = hex.decode(addressItem.publicKey)

  switch (addressInfo.type) {
    case AddressType.p2tr: {
      if (
        pubKey.length === 33 &&
        (walletName === WALLET_NAME.leather || walletName === WALLET_NAME.unisat)
      ) {
        // leather & unisat
        // TODO: can we blindly slice the first byte?
        // console.log('p2tr pubKey sliced', walletName, pubKey)
        pubKey = pubKey.slice(1)
      }
      p2 = payment.p2tr(pubKey, undefined, btcNetwork)
      break
    }
    case AddressType.p2wpkh:
      p2 = payment.p2wpkh(pubKey, btcNetwork)
      break
    case AddressType.p2sh:
      p2 = btc.p2sh(btc.p2wpkh(pubKey, btcNetwork), btcNetwork)
      break
    default:
      console.log(addressInfo)
      console.log(addressItem)
      throw new Error('Unsupported address type')
  }

  const addressDetails: AddressDetails = {
    addrString: addrString,
    pubKey: pubKey,
    p2: p2,
  }

  return addressDetails
}

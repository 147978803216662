import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { AddressPurpose } from 'sats-connect'

import { API_ENDPOINTS } from '@packages/constants'
import {
  EscrowWalletBalanceResponse,
  EscrowWalletWithdrawResponse,
  EscrowWalletWithdrawSubmission,
  GenericChallenge,
} from '@packages/interfaces'

import { apiFetch } from 'src/api'
import { replaceUrlParams } from 'src/shared/utils'
import { useFeatureFlagContext } from 'src/featureFlags'

import { MIN_TX_VSIZE, SignTransactionResult, useWalletContext } from './WalletContext'

type WithdrawBtcTransactionStatus = 'build' | 'wallet-prompt' | 'api-submit'
interface EscrowWalletContextType {
  btcBalance?: bigint
  lockedBtcBalance?: bigint
  pendingBalance?: bigint
  escrowWallet?: string

  fetchEscrowWalletInfo: () => Promise<void>
  withdrawBtc: (payload: {
    recipientAddress: string
    transferAmount: bigint
    fee: bigint
    onStatusChange(status: WithdrawBtcTransactionStatus): void
  }) => Promise<SignTransactionResult>
}

const EscrowWalletContext = createContext<EscrowWalletContextType | undefined>(undefined)

export const useEscrowWalletContext = () => {
  const context = useContext(EscrowWalletContext)
  if (context === undefined) {
    throw new Error('useEscrowWalletContext must be used within a EscrowWalletProvider')
  }
  return context
}

export const EscrowWalletProvider = ({ children }: { children: ReactNode }) => {
  const { blockTip, runesAddress, walletName, signMessage } = useWalletContext()
  const { isBiddingEnabled } = useFeatureFlagContext()

  const [btcBalance, setBtcBalance] = useState<bigint>()
  const [pendingBalance, setPendingBalance] = useState<bigint>()
  const [lockedBtcBalance, setLockedBtcBalance] = useState<bigint>()
  const [address, setAddress] = useState<string>()

  // fetch escrow wallet btc balance
  const fetchEscrowWalletInfo = async () => {
    const response = await apiFetch<EscrowWalletBalanceResponse>(
      replaceUrlParams(API_ENDPOINTS.GET.escrowWallet.getBalance, {
        address: runesAddress!.addrString,
      }),
      {}
    )

    setBtcBalance(BigInt(response.balance))
    setPendingBalance(BigInt(response.pendingBalance))
    setLockedBtcBalance(BigInt(response.lockedBalance))
    setAddress(response.escrowAddress)
  }

  // Request BTC withdraw from user escrow wallet via backend endpoint
  const _withdrawBtc = async ({
    recipientAddress,
    transferAmount,
    fee,
    onStatusChange,
  }: {
    recipientAddress: string
    transferAmount: bigint
    fee: bigint
    onStatusChange(status: WithdrawBtcTransactionStatus): void
  }): Promise<SignTransactionResult> => {
    if (!address || !walletName || !runesAddress?.addrString) {
      throw new Error('Bidding address not available')
    }

    onStatusChange('build')
    // Requests message signing to prove address ownership for the backend.
    const { challenge } = await apiFetch<GenericChallenge>(
      `${API_ENDPOINTS.GET.auth.challenge}?message="Sign this message to withdraw from bidding wallet"`
    )

    onStatusChange('wallet-prompt')

    const signatureResponse = await signMessage(challenge, AddressPurpose.Payment)

    if (!signatureResponse.success || !signatureResponse.signedMessage) {
      return signatureResponse
    }

    const withdraw: EscrowWalletWithdrawSubmission = {
      amount: String(transferAmount),
      address: runesAddress?.addrString,
      signature: signatureResponse.signedMessage,
      challenge: challenge,
      feePerByte: String(fee),
    }

    onStatusChange('api-submit')
    const withdrawResponse = await apiFetch<EscrowWalletWithdrawResponse>(
      replaceUrlParams(API_ENDPOINTS.POST.escrowWallet.withdraw, {
        address: runesAddress!.addrString,
      }),
      {},
      { method: 'POST', body: withdraw }
    )

    if (!withdrawResponse.txId) {
      return {
        success: false,
        error: 'Failed to withdraw from bidding wallet',
      }
    }

    // Somewhat inaccurate, but better then relyng on speed of transaction propagation.
    setBtcBalance((balance) =>
      balance ? balance - transferAmount - BigInt(MIN_TX_VSIZE) * fee : balance
    )
    return {
      success: true,
      txId: withdrawResponse.txId,
    }
  }

  useEffect(() => {
    if (!isBiddingEnabled) {
      return
    }

    if (runesAddress) {
      fetchEscrowWalletInfo()
    }
  }, [runesAddress?.addrString, blockTip, isBiddingEnabled])

  return (
    <EscrowWalletContext.Provider
      value={{
        escrowWallet: address,
        btcBalance,
        pendingBalance,
        lockedBtcBalance,
        fetchEscrowWalletInfo,
        withdrawBtc: _withdrawBtc,
      }}
    >
      {children}
    </EscrowWalletContext.Provider>
  )
}

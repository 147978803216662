import Grid from '@mui/material/Grid'
import { useMemo } from 'react'
import { styled } from 'styled-components'

import { AccountDetailsPageContent, AccountDetailsPageTable } from 'src/account'
import { useAccountWalletTabs } from 'src/account/hooks'
import { MintSearch } from 'src/minting'
import { RuneTools } from 'src/runes/components/RuneTools'
import { BREAKPOINTS } from 'src/shared/constants'
import { useIsMobile } from 'src/shared/hooks'
import { SWITCH_ACCOUNTS_TABS } from 'src/account/constants'

import { PageContainer } from './PageContainer'

export function AccountDetailsPage() {
  const isMobile = useIsMobile()
  const { currentTab: selectedWallet, changeTab } = useAccountWalletTabs()

  const content = useMemo(
    () => (
      <>
        <Grid item order={0} md={12} xs={12}>
          <AccountDetailsPageContent selectedWallet={selectedWallet} onWalletChange={changeTab} />
        </Grid>
        <Grid item order={isMobile ? 2 : 1} md={12} xs={12}>
          <AccountDetailsPageTable selectedWallet={selectedWallet} />
        </Grid>
      </>
    ),
    [selectedWallet, changeTab, isMobile]
  )

  return (
    <PageContainer>
      <MintSearchContainer>
        <MintSearch linkType='runeDetail' />
      </MintSearchContainer>
      <Grid container spacing={3} direction={isMobile ? 'column' : 'row'}>
        {isMobile ? (
          content
        ) : (
          <Grid item order={0} container md={7} xs={12} direction='row' spacing={3}>
            {content}
          </Grid>
        )}
        <Grid item md={5} xs={12} order={isMobile ? 1 : 1}>
          <RuneTools isEscrowWallet={selectedWallet === SWITCH_ACCOUNTS_TABS.escrow} />
        </Grid>
      </Grid>
    </PageContainer>
  )
}

const MintSearchContainer = styled.div`
  padding-bottom: 20px;

  @media (max-width: ${BREAKPOINTS.medium}) {
    padding-bottom: 0;
  }
`

import { createSearchParams } from 'react-router-dom'

export function combineSearchParams(
  currentSearchParams: URLSearchParams,
  newParams: { [key: string]: string }
) {
  const existingParams: { [key: string]: string } = {}
  for (const key of currentSearchParams.keys()) {
    const value = currentSearchParams.get(key)
    if (value) existingParams[key] = value
  }

  return { ...existingParams, ...newParams }
}

export function concatSearchParams(...searchParams: URLSearchParams[]): URLSearchParams {
  const result = createSearchParams()

  searchParams.forEach((searchParam) => {
    const keys = searchParam.keys()

    for (const key of keys) {
      const value = searchParam.get(key)
      if (value) {
        result.set(key, value)
      }
    }
  })

  return result
}

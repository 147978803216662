import { isOnRoute, ROUTES } from 'src/pages'

import { MARKET_ROUTES, MINT_ROUTES, TRANSFER_ROUTES } from './constants'

export function isOnMarketRoutes(pathname: string) {
  return MARKET_ROUTES.some((route) => isOnRoute(pathname, route))
}

export function isOnMintRoutes(pathname: string) {
  return MINT_ROUTES.some((route) => isOnRoute(pathname, route))
}

export function isOnTransferRouters(pathname: string) {
  return TRANSFER_ROUTES.some((route) => isOnRoute(pathname, route))
}

export function isOnRuneDetailsRoute(pathname: string) {
  return isOnRoute(pathname, ROUTES.runeDetails)
}

export const WALLET_NAME = {
  xverse: 'Xverse',
  magicEden: 'Magic Eden',
  unisat: 'Unisat',
  leather: 'Leather',
  okx: 'OKX',
  default: 'default',
} as const

export type WalletName = (typeof WALLET_NAME)[keyof typeof WALLET_NAME]

// TODO: check that this function is needed
export const transformToMagicEdenName = (walletName: WalletName): string => {
  switch (walletName) {
    case 'Xverse':
      return 'xverse'
    case 'Magic Eden':
      return 'magic_eden'
    case 'Unisat':
      return 'unisat'
    case 'Leather':
      return 'leather'
    case 'OKX':
      return 'okx'
    case 'default':
      return 'unknown'
  }
}

import { styled } from 'styled-components'

export const TabPanelContentContainer = styled.div<{ $visible?: boolean }>`
  width: 100%;
  display: ${(props) => (props.$visible === undefined ? 'flex' : props.$visible ? 'flex' : 'none')};
  flex-direction: column;
`

export type TabPanelProps = {
  visible: boolean
}

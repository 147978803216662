import { styled } from 'styled-components'

import { satsToBtc } from '@packages/utils'
import { API_ENDPOINTS } from '@packages/constants'

import { VirtualizedTable } from 'src/shared/components'
import { replaceUrlParams, unixTimestampToDateString } from 'src/shared/utils'
import { useUpdateOnBlockPaginationApi } from 'src/api'
import { useWalletContext } from 'src/wallet'
import { COLORS } from 'src/shared/constants'
import { TransactionExternalLink } from 'src/web3'

interface EscrowTransaction {
  txId: string
  timestamp: number
  blockHeight: number
  amountSats: number
  eventType: 'deposit' | 'withdrawal' | 'bidOrderSell' | 'bidOrderBuy'
}

export function EscrowTransactionsTable() {
  const { runesAddress } = useWalletContext()
  const {
    paginatedData: popularMints,
    loading,
    fetchPage,
    hasNextPage,
  } = useUpdateOnBlockPaginationApi<EscrowTransaction>({
    endpoint: `${replaceUrlParams(API_ENDPOINTS.GET.escrowWallet.getEventHistory, {
      address: runesAddress?.addrString ?? '',
    })}`,
    limit: 50,
  })

  return (
    <Container>
      <VirtualizedTableWrapper
        columns={[
          {
            dataKey: 'amountSats',
            label: 'AMOUNT',
            formatter: ({ data: tx }) => {
              let color = COLORS.labels.primary
              let symbol = ''

              if (['withdrawal', 'bidOrderSell'].includes(tx.eventType)) {
                color = COLORS.negative
                symbol = '- '
              } else if (['deposit', 'bidOrderBuy'].includes(tx.eventType)) {
                color = COLORS.positive
                symbol = '+ '
              }

              return (
                <Amount>
                  <AmountValue $color={color}>
                    {symbol}
                    {satsToBtc(tx.amountSats)}
                  </AmountValue>
                  <AmountCurrency>BTC</AmountCurrency>
                </Amount>
              )
            },
          },
          {
            dataKey: 'timestamp',
            label: 'DATE',
            formatter: ({ data: tx }) => {
              return (
                <TransactionExternalLink transactionId={tx.txId} displayIcon>
                  {unixTimestampToDateString(BigInt(tx.timestamp), true)}
                </TransactionExternalLink>
              )
            },
          },
          {
            dataKey: 'status',
            label: 'STATUS',
            width: 20,
            disableSort: true,
            hideOnMobile: true,
            formatter: ({ data: tx }) => {
              let type = 'unknown'
              let color = COLORS.labels.secondary

              if (tx.eventType === 'withdrawal') {
                type = 'withdraw'
              } else if (tx.eventType === 'bidOrderBuy') {
                type = 'buy'
                color = COLORS.negative
              } else if (tx.eventType === 'bidOrderSell') {
                type = 'sell'
                color = COLORS.positive
              } else if (tx.eventType === 'deposit') {
                type = 'deposit'
              }

              return <TypeButton $color={color}>{type.toUpperCase()}</TypeButton>
            },
          },
        ]}
        defaultSortBy='desc'
        defaultSort='timestamp'
        paginatedData={popularMints}
        fetchPage={fetchPage}
        loading={loading}
        hasNextPage={hasNextPage}
        rowHeight={50}
        viewableRows={25}
        headerShown={false}
        emptyDataMessage='No transactions found'
        useWindowScroll={false}
      />
    </Container>
  )
}

const Container = styled.div``

const VirtualizedTableWrapper = styled(VirtualizedTable<EscrowTransaction>)`
  height: 280px;

  .MuiPaper-root {
    border: unset;
    border-radius: unset;
  }

  table {
    padding: unset;
  }
`

const Amount = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`

const AmountValue = styled.span<{
  $color: string
}>`
  ${({ $color }) => `
  color: ${$color};
  `}
`

const AmountCurrency = styled.span`
  color: ${COLORS.labels.secondary};
`

const TypeButton = styled.div<{
  $color: string
}>`
  border: 1px solid ${({ $color }) => $color};
  border-radius: 6px;
  padding: 3px 8px;

  color: ${({ $color }) => $color} !important;
  font-weight: 500 !important;
  font-size: 14px !important;

  text-align: center;
`

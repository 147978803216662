import { createSearchParams } from 'react-router-dom'

import type { RuneToolsTab } from 'src/runes'

export const ROUTES = {
  home: '/',
  etch: '/etch',
  mint: '/mint',
  markets: '/markets',
  marketDetails: '/market/:runeName',
  runeDetails: '/rune/:runeName',
  accountDetails: '/account/:address',
  demoWasm: '/demo-wasm',
  settings: '/settings',
  mysticPoints: '/mystic-points',
  escrowWallet: '/bidding-wallet',
}

export const PAGE_TAB_SEARCH_PARAM_NAME = 'tab'
export const RUNE_TOOLS_TAB_SEARCH_PARAM_NAME = 'runeToolsTab'
export const RUNE_TOOLS_SWAP_SIDE_SEARCH_PARAM_NAME = 'side'
export const RUNE_TOOLS_SWAP_TYPE_SEARCH_PARAM_NAME = 'swapType'
export const ACCOUNT_DETAILS_WALLET_TAB_SEARCH_PARAM_NAME = 'walletTab'

export function buildAccountDetailsUrl(address: string) {
  return `${ROUTES.accountDetails.replace(':address', address)}`
}

/** @deprecated use buildMarketDetailsUrl instead */
export function buildRuneDetailsUrl(runeName: string) {
  return ROUTES.runeDetails.replace(':runeName', runeName)
}

export function buildMarketDetailsUrl(runeName: string) {
  return `${ROUTES.marketDetails.replace(':runeName', runeName)}`
}

export function buildRuneToolsTabSearchParam(tab: RuneToolsTab) {
  return createSearchParams([[RUNE_TOOLS_TAB_SEARCH_PARAM_NAME, tab]])
}

export function buildPageTabSearchParam(tab: string) {
  return createSearchParams([[PAGE_TAB_SEARCH_PARAM_NAME, tab]])
}

export function buildSwapToolSideSearchParam(side: 'buy' | 'sell') {
  return createSearchParams([[RUNE_TOOLS_SWAP_SIDE_SEARCH_PARAM_NAME, side]])
}

export function buildSwapTypeSearchParam(swapType: 'market' | 'limit') {
  return createSearchParams([[RUNE_TOOLS_SWAP_TYPE_SEARCH_PARAM_NAME, swapType]])
}

export function buildAccountDetailsWalletTabSearchParam(walletTab: string) {
  return createSearchParams([[ACCOUNT_DETAILS_WALLET_TAB_SEARCH_PARAM_NAME, walletTab]])
}

import { type ReactNode } from 'react'
import _Drawer from '@mui/material/Drawer'
import { styled } from 'styled-components'

import { BREAKPOINTS, COLORS } from '../constants'
import { hexToRgb } from '../utils'
import { NOISE_BG_CSS } from '../styles'
import { Button, CloseButton } from './buttons'
import { TieredContent } from './TieredContent'

interface Props {
  isOpen: boolean
  onClose: () => void
  canClose?: boolean
  children: ReactNode
  className?: string
}

export function Drawer({ isOpen, onClose, canClose = true, children, className }: Props) {
  function handleOnClose() {
    if (canClose) {
      onClose()
    }
  }
  return (
    <DrawerWrapper open={isOpen} onClose={handleOnClose} className={className} anchor={'right'}>
      <CloseButtonWrapper onClose={onClose} canClose={canClose} />
      <Container>{children}</Container>
    </DrawerWrapper>
  )
}

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const DrawerCard = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${COLORS.white};
  width: 100%;
  // min-height: 50px;
  padding: 20px;
  background-color: ${COLORS.background.container};
  flex-direction: column;
  gap: 15px;

  @media (max-width: ${BREAKPOINTS.medium}) {
    padding: 15px;
  }
`

export const DrawerTitle = styled.h3`
  font-weight: 500;
`

export const DrawerButtons = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;

  button {
    min-height: 40px;
    min-width: 175px;
    padding: 8px 12px;
  }

  @media (max-width: ${BREAKPOINTS.medium}) {
    button {
      min-width: 150px;
    }
  }
`

export const DrawerCancelButton = styled(Button)`
  color: ${COLORS.buttons.primary};
  background-color: transparent;
  border: 2px solid ${COLORS.buttons.primary};
`

export const DrawerConfirmButton = styled(Button)``

export const DrawerRow = styled.div`
  display: flex;
  @media (max-width: ${BREAKPOINTS.medium}) {
    font-size: 14px;
    flex-direction: column;
  }
`

export const DrawerBoldColumn = styled.div`
  flex: 1;
  font-weight: 500;
  display: flex;
  align-items: flex-start;
  gap: 5px;
`

export const DrawerColumn = styled.div`
  flex: 1;
  display: flex;
  gap: 5px;
  justify-content: flex-end;
`

export const DrawerTieredContent = styled(TieredContent)`
  div {
    text-align: right;
    @media (max-width: ${BREAKPOINTS.medium}) {
      text-align: left;
    }
  }
  span {
    text-align: right;
    @media (max-width: ${BREAKPOINTS.medium}) {
      text-align: left;
    }
  }
`

const DrawerWrapper = styled(_Drawer)`
  .MuiDrawer-modal {
    ${NOISE_BG_CSS}
  }
  .MuiDrawer-paper {
    border-left: 1px solid ${hexToRgb(COLORS.white, 0.6)};
    padding: 20px;
    padding-top: 55px;
    ${NOISE_BG_CSS}
    width: 35vw;

    @media (max-width: ${BREAKPOINTS.large}) {
      width: 60%;
    }

    @media (max-width: ${BREAKPOINTS.medium}) {
      width: 75%;
    }

    @media (max-width: ${BREAKPOINTS.small}) {
      width: 100%;
      border-left: unset;
    }
  }
`

const CloseButtonWrapper = styled(CloseButton)`
  position: absolute;
  top: 12px;
  right: 12px;
`

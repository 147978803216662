import { useState } from 'react'

import { API_ENDPOINTS } from '@packages/constants'
import { RuneBalance } from '@packages/interfaces'

import { apiFetch, useUpdateOnBlock } from 'src/api'
import { replaceUrlParams } from 'src/shared/utils'
import { useWalletContext } from 'src/wallet'

export function useRuneBalanceById({
    runeId,
    hideOrderBalances = true
  } : { 
    runeId?: string,
    hideOrderBalances?: boolean
  }) {
  const { runesAddress } = useWalletContext()

  const [runeBalance, setRuneBalance] = useState<RuneBalance | undefined>()

  async function fetchRuneBalance() {
    if (runesAddress?.addrString && runeId) {
      const balance = await apiFetch<RuneBalance>(
        `${replaceUrlParams(API_ENDPOINTS.GET.runes.balances.runeBalanceByAccount, {
          runeId,
          address: runesAddress.addrString,
        })}${hideOrderBalances ? '?hideOrderBalances=true' : ''}`
      )

      setRuneBalance(balance)
    } else {
      setRuneBalance(undefined)
    }
  }

  useUpdateOnBlock(fetchRuneBalance, [runeId, runesAddress?.addrString], true)

  return { runeBalance, forceRefresh: fetchRuneBalance }
}

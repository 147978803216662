import { useEffect, useState } from 'react'
import { styled } from 'styled-components'
import Decimal from 'decimal.js-light'
import { toast } from 'react-toastify'

import { Rune } from '@packages/interfaces'
import {
  btcToSats,
  calculateFeeFromBps,
  formatRuneName,
  formatUsd,
  ManualError,
  satsToBtc,
} from '@packages/utils'
import { SATOSHI_DUST_THRESHOLD } from '@packages/constants'

import { useGoogleAnalyticsContext } from 'src/analytics'
import { MysticPointsDrawerCard, useGetRewardSize, useMysticPointsContext } from 'src/rewards'
import { RunesAmountDisplay } from 'src/runes'
import { DISABLE_WRITES } from 'src/settings'
import {
  AccordianHeader,
  Divider,
  Drawer,
  DrawerBoldColumn,
  DrawerButtons,
  DrawerCancelButton,
  DrawerCard,
  DrawerColumn,
  DrawerConfirmButton,
  DrawerRow,
  DrawerTieredContent,
  DrawerTitle,
  ErrorMessage,
  LinkPageToast,
} from 'src/shared/components'
import { BREAKPOINTS, COLORS } from 'src/shared/constants'
import { hexToRgb } from 'src/shared/utils'
import { useEscrowWalletContext, useWalletContext } from 'src/wallet'
import { useDebounce } from 'src/shared/hooks'
import { buildAccountDetailsUrl, buildPageTabSearchParam } from 'src/pages/constants/routes'
import { ACCOUNT_DETAILS_TABS } from 'src/account/components'

import { PriceSatsDisplay } from './PriceSatsDisplay'
import { PriceUsdDisplay } from './PriceUsdDisplay'
import { calculatePrice } from '../utils'
import { RunesTotalColumn } from './styles'

const BIG_INT_ZERO = BigInt(0)
const RECALCULATE_TOTALS_TIME = 250

type Props = {
  isOpen: boolean
  onClose: () => void
  rune: Rune
  satsAmount: bigint | undefined
  runesAmount: bigint | undefined
  proceedsAddress: string | undefined
  expiresAt: number | undefined
}

export function PlaceBidOrderDrawer({
  isOpen,
  onClose,
  rune,
  satsAmount: _satsAmount,
  runesAmount: _runesAmount,
  proceedsAddress: _proceedsAddress,
  expiresAt,
}: Props) {
  const { initiateEvent, confirmEvent, executeEvent, abandonEvent } = useGoogleAnalyticsContext()
  const { btcPrice, placeBidOrder, paymentAddress, runesAddress, settings } = useWalletContext()

  const [proceedsAddress, setProceedsAddress] = useState(_proceedsAddress ?? '')
  const [satsAmount, setSatsAmount] = useState(_satsAmount ?? BIG_INT_ZERO)
  const [runesAmountTotal, setRunesAmountTotal] = useState<bigint>(_runesAmount ?? BIG_INT_ZERO)
  const [selectedNetworkFee, setSelectedNetworkFee] = useState(0)
  const { fetchEscrowWalletInfo } = useEscrowWalletContext()

  const [isDetailsLoading, setIsDetailsLoading] = useState(false)
  const [loadingText, setLoadingText] = useState<string>()
  const [error, setError] = useState<string>()

  const [orderDetails, setOrderDetails] = useState<{
    satsTotal: bigint
    usdTotal: number
    priceSats: string
    marketplaceFeeAmountBtcTotal: number
  }>({ satsTotal: BIG_INT_ZERO, usdTotal: 0, priceSats: '0', marketplaceFeeAmountBtcTotal: 0 })

  const { refetchMysticPointsBalance } = useMysticPointsContext()
  const { data: mysticPoints } = useGetRewardSize({
    runeId: rune?.runeId,
    eventType: 'sell',
    fee: btcToSats(orderDetails?.marketplaceFeeAmountBtcTotal ?? 0),
    disabled: !isOpen,
    recalculateRewardSizeTime: RECALCULATE_TOTALS_TIME,
  })

  useEffect(() => {
    if (_proceedsAddress) {
      setProceedsAddress(_proceedsAddress)
    } else {
      if (paymentAddress) {
        setProceedsAddress(paymentAddress.addrString)
      }
    }
  }, [_proceedsAddress, paymentAddress])

  useEffect(() => {
    if (isOpen) {
      setRunesAmountTotal(_runesAmount ?? BIG_INT_ZERO)
    }
  }, [isOpen, _runesAmount])

  useEffect(() => {
    if (isOpen) {
      setSatsAmount(_satsAmount ?? BIG_INT_ZERO)
    }
  }, [isOpen, _satsAmount])

  useDebounce(
    async () => {
      if (satsAmount === 0n) return
      try {
        setError(undefined)
        setLoadingText('Recalculating...')
        setIsDetailsLoading(true)

        const priceSats = calculatePrice({ runesAmount: runesAmountTotal, satsAmount, rune })
        const serviceFeeSatsTotal = calculateFeeFromBps(
          satsAmount,
          settings.serviceFees.feeBps.order
        )

        setOrderDetails({
          satsTotal: satsAmount,
          usdTotal: new Decimal(`${satsAmount}`).mul(btcPrice?.satPriceUsd ?? 0).toNumber(),
          priceSats,
          marketplaceFeeAmountBtcTotal: satsToBtc(serviceFeeSatsTotal),
        })
        validateForm()
      } catch (error) {
        console.error(error)
        if (error instanceof ManualError) {
          setError((error as ManualError).message)
        } else {
          switch (error) {
            // placeholder
            case '':
              break
            default:
              setError('Something unexpected has gone wrong, please contact support on our Discord')
              break
          }
        }
      } finally {
        setLoadingText(undefined)
        setIsDetailsLoading(false)
      }
    },
    [satsAmount, btcPrice, runesAmountTotal],
    RECALCULATE_TOTALS_TIME
  )

  function validateForm() {
    let validationError: string | undefined
    setError(undefined)
    if (satsAmount < SATOSHI_DUST_THRESHOLD) {
      validationError = 'BTC amount is below the dust threshold'
    } else if (!proceedsAddress) {
      validationError = 'Enter a valid proceeds address'
    } else if (!runesAddress) {
      validationError = 'No runes address found'
    } else if (!expiresAt) {
      validationError = 'Enter an expiration time'
    }
    setError(validationError)

    return validationError
  }

  useEffect(() => {
    validateForm()
  }, [satsAmount, runesAmountTotal, proceedsAddress, expiresAt])

  useEffect(() => {
    if (isOpen) {
      initiateEvent('swap', {
        token_name: rune.runeName,
        token_type: 'rune',
        order_amount: runesAmountTotal,
        order_action: 'sell',
        price: orderDetails.satsTotal,
      })
    }
  }, [isOpen])

  function resetForm() {
    setError(undefined)
    setSelectedNetworkFee(0)
  }

  async function onConfirmClick() {
    confirmEvent('swap', {
      token_name: rune.runeName,
      token_type: 'rune',
      order_amount: runesAmountTotal,
      order_action: 'sell',
      fee: selectedNetworkFee,
      price: orderDetails.satsTotal,
    })
    try {
      const validationError = validateForm()
      if (validationError != undefined) {
        abandonEvent(false, 'swap', {
          token_name: rune.runeName,
          token_type: 'rune',
          order_amount: runesAmountTotal,
          order_action: 'sell',
          fee: selectedNetworkFee,
          price: orderDetails.satsTotal,
        })
        return
      }

      if (!expiresAt) {
        throw new Error('Enter an expiration time')
      }

      setLoadingText('Generating tx')

      const response = await placeBidOrder({
        rune,
        proceeds: satsAmount,
        expiresAt: (BigInt(new Date().getTime()) + BigInt(expiresAt)) / BigInt(1000),
        proceedsAddress,
        sellAmount: runesAmountTotal,
        fee: BigInt(selectedNetworkFee),
        onStatusChange: (status) => {
          switch (status) {
            case 'build':
              setLoadingText('Building tx')
              break
            case 'transfer-wallet-prompt':
              setLoadingText('1/2 Tx: Preparing Runes')
              break
            case 'sell-wallet-prompt-boxed':
              setLoadingText('2/2 Tx: Sell Order')
              break
            case 'sell-wallet-prompt-not-boxed':
              setLoadingText('See wallet')
              break
            case 'api-submit':
              setLoadingText('Submitting order')
              break
          }
        },
      })

      if (response.success) {
        refetchMysticPointsBalance()
        fetchEscrowWalletInfo()
        executeEvent('swap', {
          token_name: rune.runeName,
          token_type: 'rune',
          order_amount: runesAmountTotal,
          order_action: 'sell',
          fee: selectedNetworkFee,
          price: orderDetails.satsTotal,
        })

        toast(
          <LinkPageToast
            message={'Order submitted.'}
            buttonMessage={'View order'}
            reactRouterPathname={buildAccountDetailsUrl(runesAddress?.addrString ?? '')}
            reactRouterSearch={buildPageTabSearchParam(ACCOUNT_DETAILS_TABS.openOrders).toString()}
          />,
          { toastId: Date.now() }
        )

        onClose()
        resetForm()
      } else {
        abandonEvent(false, 'swap', {
          token_name: rune.runeName,
          token_type: 'rune',
          order_amount: runesAmountTotal,
          order_action: 'sell',
          fee: selectedNetworkFee,
          price: orderDetails.satsTotal,
        })
      }
    } catch (error) {
      console.error(error)
      if (error instanceof ManualError) {
        setError((error as ManualError).message)
      } else {
        switch (error) {
          // placeholder
          case '':
            break
          default:
            setError('Something unexpected has gone wrong, please contact support on our Discord')
            break
        }
      }
    } finally {
      setLoadingText(undefined)
    }
  }

  function handleOnCancel() {
    abandonEvent(false, 'swap', {
      token_name: rune.runeName,
      token_type: 'rune',
      order_amount: runesAmountTotal,
      order_action: 'sell',
      fee: selectedNetworkFee,
      price: orderDetails.satsTotal,
    })
    setLoadingText(undefined)
  }

  function handleOnClose() {
    abandonEvent(true, 'swap', {
      token_name: rune.runeName,
      token_type: 'rune',
      order_amount: runesAmountTotal,
      order_action: 'sell',
      fee: selectedNetworkFee,
      price: orderDetails.satsTotal,
    })
    setError(undefined)
    onClose()
  }

  const buttonText = loadingText ?? 'Confirm Order'
  const isButtonDisabled = isDetailsLoading || !satsAmount || !proceedsAddress || !!error
  const priceSats = calculatePrice({ satsAmount, runesAmount: runesAmountTotal, rune })
  const usdTotal = new Decimal(`${satsAmount}`).mul(btcPrice?.satPriceUsd ?? 0).toNumber()
  const btcTotal = satsToBtc(satsAmount)

  return (
    <Drawer isOpen={isOpen} onClose={handleOnClose} canClose={!loadingText}>
      <DrawerCard>
        <DrawerTitle>Buy {formatRuneName(rune)}</DrawerTitle>
        <DrawerRow>
          <DrawerBoldColumn>Price</DrawerBoldColumn>
          <DrawerColumn>
            <DrawerTieredContent
              content={
                <>
                  &nbsp;
                  <PriceSatsDisplay priceSats={priceSats} runeSymbol={rune.runeSymbolChar} />
                </>
              }
              subContent={<PriceUsdDisplay priceSats={priceSats} />}
              loading={false}
            />
          </DrawerColumn>
        </DrawerRow>
        <DrawerRow>
          <DrawerBoldColumn>{formatRuneName(rune)}</DrawerBoldColumn>
          <RunesTotalColumn $colorVariant='positive'>
            +
            <RunesAmountDisplay
              rune={rune}
              runesAmount={runesAmountTotal}
              showPriceSymbol={false}
            />
          </RunesTotalColumn>
        </DrawerRow>

        <Divider />
        <DrawerRow>
          <DrawerBoldColumn>
            <AccordianHeader linkText='Total BTC' expanded={true} onExpand={() => {}} />
          </DrawerBoldColumn>
          <DrawerColumn>
            <DrawerTieredContent
              content={<BtcTotal>-{btcTotal}</BtcTotal>}
              subContent={<UsdTotal>&nbsp;{formatUsd({ usd: usdTotal })}</UsdTotal>}
              loading={false}
            />
          </DrawerColumn>
        </DrawerRow>
      </DrawerCard>

      <DrawerCard>
        <DrawerRow>
          <DrawerBoldColumn>Proceeds Address</DrawerBoldColumn>
        </DrawerRow>
        <DrawerRow>
          <AddressColumn>{proceedsAddress}</AddressColumn>
        </DrawerRow>
      </DrawerCard>

      {orderDetails?.marketplaceFeeAmountBtcTotal > 0 && (
        <MysticPointsDrawerCard points={mysticPoints} place={'trade'} />
      )}

      {error && <ErrorMessage message={error} />}
      <DrawerButtons>
        {loadingText ? (
          <DrawerCancelButton onClick={() => handleOnCancel()}>Cancel Tx</DrawerCancelButton>
        ) : (
          <DrawerCancelButton onClick={handleOnClose}>Exit</DrawerCancelButton>
        )}
        <DrawerConfirmButton
          onClick={onConfirmClick}
          loading={!!loadingText || isDetailsLoading}
          disabled={isButtonDisabled || DISABLE_WRITES}
        >
          {buttonText}
        </DrawerConfirmButton>
      </DrawerButtons>
    </Drawer>
  )
}

const BtcTotal = styled.div`
  font-weight: 700;
  color: ${COLORS.negative};
`

const UsdTotal = styled.div`
  color: ${hexToRgb(COLORS.white, 0.7)};
  font-size: 14px;
`

const AddressColumn = styled(DrawerColumn)`
  flex: 0;
  font-size: 14px;
  display: flex;
  align-items: center;
  span {
    width: 45px;
    padding: 0px;
  }
  svg {
    height: 30px;
  }
  @media (max-width: ${BREAKPOINTS.medium}) {
    font-size: 12px;
  }
`

import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import {
  AddressPurpose,
  BitcoinNetworkType,
  getAddress,
  getCapabilities,
  sendBtcTransaction,
  signMessage,
  signMultipleTransactions,
  signTransaction,
} from 'sats-connect'
import { base64, hex } from '@scure/base'
import { AddressTxsUtxo } from '@mempool/mempool.js/lib/interfaces/bitcoin/addresses'

import * as btc from '@packages/scure-btc-signer'
import * as psbt from '@packages/scure-btc-signer/psbt'
import * as wasm from '@packages/rune-wasm-frontend'
import {
  BidOrder,
  BidOrderInfo,
  BidOrderSubmission,
  BidOrderSubmissionResult,
  Block,
  BtcPrice,
  BuyBidOrderRequest,
  BuyBidOrderRequestResult,
  BuyBidOrderSubmission,
  BuyBidOrderSubmissionResult,
  BuySellOrderRequest,
  BuySellOrderRequestResult,
  BuySellOrderSubmission,
  BuySellOrderSubmissionResult,
  CancelSubmission,
  GenericChallenge,
  MintsSubmission,
  MintsSubmissionResult,
  Order,
  Provider as AggregateProviders,
  RecommendedFees,
  Rune,
  RuneOutpointDetails,
  SellOrderSubmission,
  Settings,
} from '@packages/interfaces'
import { P2TROut } from '@packages/scure-btc-signer/payment'
import { API_ENDPOINTS, SATOSHI_DUST_THRESHOLD } from '@packages/constants'
import { calculateFeeFromBps, ManualError } from '@packages/utils'

import { useInterval, useLocalStorage } from 'src/shared/hooks'
import {
  DEPLOYED_BITCOIN_NETWORK,
  IS_TESTNET_DEPLOYMENT,
  TRX_VIRTUAL_SIZE_BYTES,
} from 'src/settings'
import { apiFetch } from 'src/api'
import { hexToBase64, replaceUrlParams } from 'src/shared/utils'
import { useGoogleAnalyticsContext } from 'src/analytics'

import {
  useUnisatProvider,
  useAddressLocalStorage,
  useLeatherProvider,
  useOkxProvider,
  useMagicEdenProvider,
} from './hooks'
import {
  BuildBulkMintResult,
  BuildBulkMintSerializedResult,
  buildBulkMintTxs,
  buildEtchingCommit,
  buildEtchingReveal,
  buildMintTx,
  buildSellPsbt,
  createRuneTransferTx,
  EtchingCommit,
  finalizeTx,
  getAddressBtcBalances,
  getAddressDetails,
  getBlockTip,
  getBtcPrice,
  getOutpointId,
  getRecommendedNetworkFees,
  getRuneTransactionInputs,
  JsEtchingParams,
  SignBulkMintResult,
  SignBulkMintSerializedResult,
  getCleanUtxos,
  finalizeAndBroadcastTx,
  estimatedMintParentVsize,
  estimatedMintChildVsize,
  getXverseProvider,
  calculateBulkMintNetworkFeeTotal,
  getCleanUtxosFromAddress,
  utxoToInput,
  createBtcTransferTx,
  uint8ArrayEquals,
} from './utils'
import { isCustomBitcoinProvider } from './providers'
import {
  AddressDetails,
  BtcBalances,
  BtcSignerNetwork,
  BtcSignerOutput,
  RuneUtxoOutpoint,
} from './interfaces'
import { WALLET_NAME, WalletName } from './constants'
import BulkMintWebWorker from './utils/bulkMintWebWorker?worker&inline'
import { IncorrectAccountPopup } from './components'

import type {
  Address,
  BitcoinProvider,
  Capability,
  InputToSign,
  Recipient,
  SignMultipleTransactionsPayload,
  SignTransactionPayload,
} from 'sats-connect'

const BLOCK_INTERVAL_MS = 1000 * 60 * 1
const NETWORK_FEES_INTERVAL_MS = 1000 * 15
const BTC_PRICE_INTERVAL_MS = 1000 * 60 * 3
export const MIN_TX_VSIZE = 64

interface WalletContextType {
  settings: Settings
  btcBalances?: BtcBalances
  btcPrice?: BtcPrice
  blockTip?: Block
  recommendedNetworkFees?: RecommendedFees

  paymentAddress?: AddressDetails
  ordinalsAddress?: AddressDetails
  runesAddress?: AddressDetails
  walletName?: WalletName
  supportsMultiSignature: boolean

  network: BitcoinNetworkType
  isConnected: boolean
  capabilities?: Set<Capability>
  capabilityState: 'loading' | 'loaded' | 'missing' | 'cancelled'
  isReady: boolean
  toggleNetwork: () => void
  connectWallet: (provider: BitcoinProvider, walletName: WalletName) => Promise<void>
  disconnectWallet: () => Promise<void>

  signMessage: (message: string, addressPurpose: AddressPurpose) => Promise<SignMessageResult>
  signTransaction: (payload: SignTransactionPayload) => Promise<SignTransactionResult>
  signMultipleTransactions: (
    payload: SignMultipleTransactionsPayload
  ) => Promise<SignMultipleTransactionsResult>
  sendBitcoin: (recipients: Recipient[]) => Promise<SendBitcoinResult>

  commitEtchRune: (payload: {
    etchParams: JsEtchingParams
    fee: bigint
  }) => Promise<CommitEtchRuneResult>

  revealEtchRune: (payload: {
    etchParams: JsEtchingParams
    etchingCommit: EtchingCommit
    fee: bigint
  }) => Promise<SignTransactionResult>

  mintRune: (payload: {
    runeId: string
    runeName: string
    recipientAddress: string
    fee: bigint
  }) => Promise<SignTransactionResult>

  mintBulkRune: (payload: {
    runeId: string
    runeName: string
    recipientAddress: string
    fee: bigint
    quantity: bigint
    onStatusChange(status: BulkMintSignTransactionStatus): void
  }) => Promise<SignMultipleTransactionsResult>

  estimateMintBulkRuneVsize: (payload: {
    quantity: bigint
    addFee: boolean
  }) => Promise<{ child: number; parent: number }>

  getRunesOutpointsForSale: (
    params: GetRuneOutpointsForSaleParams
  ) => Promise<RuneOutpointDetails[]>

  sellRunesUnboxed: (payload: MultiSellRunesParams) => Promise<SignMultipleTransactionsResult>

  sellRunes: (payload: SellRunesParams) => Promise<SignMultipleTransactionsResult>

  cancelSellRunes: (payload: CancelSellRunesParams) => Promise<SignMessageResult>

  requestBuyRunes: (params: RequestBuyRunesParams) => Promise<BuySellOrderRequestResult[]>

  submitBuyRunes: (
    params: SubmitBuyRunesParams
  ) => Promise<SignTransactionResult[] | BuySellOrderRequestResult>

  groupOrdersByPlacedAddress: (orders: BidOrderInfo[]) => BidOrderInfo[]

  groupOrdersByRuneProccedAddress: (orders: BidOrderInfo[]) => BidOrderInfo[]

  buildBidOrderPsbt: (params: {
    fee: bigint
    proceedsAddress: string
    rune: Rune
    orders: BidOrderInfo[]
  }) => Promise<{ buyTx: btc.Transaction; indicesToSign: number[]; transactionFee: bigint }>

  transferRunes: (payload: {
    recipientAddress: string
    rune: Rune
    transferAmount: bigint
    fee: bigint
    onStatusChange(status: TransferRunesTransactionStatus): void
  }) => Promise<SignTransactionResult>

  estimateTransferRunesVsize: (payload: {
    rune: Rune
    transferAmount: bigint
    fee: bigint
  }) => Promise<number>

  btcSignerNetwork: BtcSignerNetwork

  placeBidOrder: (payload: PlaceBidOrderParams) => Promise<SignMultipleTransactionsResult>
  requestBuyBidOrder: (payload: RequestBuyBidOrderParams) => Promise<BuyBidOrderRequestResult>
  cancelPlaceBidOrder: (payload: CancelPlaceBidOrderParams) => Promise<SignMessageResult>
  submitBuyBidOrder: (
    payload: SubmitBuyBidOrderParams
  ) => Promise<SignTransactionResult | BuyBidOrderRequestResult>

  estimateBtcTransferVsize: (payload: { transferAmount: bigint; fee: bigint }) => Promise<number>
  transferBtc: (payload: {
    recipientAddress: string
    transferAmount: bigint
    fee: bigint
    onStatusChange(status: TransferBtcTransactionStatus): void
  }) => Promise<SignTransactionResult>
}

export interface SellRunesParams {
  proceeds: bigint
  proceedsAddress: string
  rune: Rune
  sellAmount: bigint
  runeOutpoints: RuneOutpointDetails[]
  fee: bigint
  onStatusChange(status: SellRunesTransactionStatus): void
}

// NOTE: Bid orders drawer types
export interface PlaceBidOrderParams {
  proceeds: bigint
  proceedsAddress: string
  sellAmount: bigint
  rune: Rune
  fee: bigint
  expiresAt: bigint
  onStatusChange(status: SellRunesTransactionStatus): void
}

export interface RequestBuyBidOrderParams {
  rune: Rune
  orders: BidOrder[]
}

export interface CancelPlaceBidOrderParams {
  order: BidOrder
}
export interface BuyBidOrderParams {
  orders: BidOrder[]
  proceedsAddress: string
  sellAmount: bigint
  rune: Rune
  fee: bigint
  onStatusChange(status: BuyRunesTransactionStatus): void
}

export interface SubmitBuyBidOrderParams extends RequestBuyBidOrderParams {
  fee: bigint
  proceedsAddress: string
  onStatusChange(status: BuyRunesTransactionStatus): void
}

export interface MultiSellRunesParams {
  proceeds: bigint[]
  proceedsAddress: string
  rune: Rune
  sellAmount: bigint[]
  runeOutpoints: RuneOutpointDetails[]
  fee: bigint
  onStatusChange(status: SellRunesTransactionStatus): void
}

interface CancelSellRunesParams {
  order: Order | BidOrder
}

interface GetRuneOutpointsForSaleParams {
  runeId: string
  sellAmount: bigint
}

interface RequestBuyRunesParams {
  rune: Rune
  fee: number
  orders: Order[]
}

interface SubmitBuyRunesParams {
  rune: Rune
  validOrders: BuySellOrderRequestResult[]
  fee: bigint
  onStatusChange(status: BuyRunesTransactionStatus): void
}

type BulkMintSignTransactionStatus = 'build' | 'wallet-prompt' | 'temp-key-sign' | 'api-submit'

type SellRunesTransactionStatus =
  | 'build'
  | 'transfer-wallet-prompt'
  | 'sell-wallet-prompt-not-boxed'
  | 'sell-wallet-prompt-boxed'
  | 'api-submit'

type BuyRunesTransactionStatus = 'build' | 'wallet-prompt' | 'api-submit'

type TransferRunesTransactionStatus = 'build' | 'wallet-prompt'

type TransferBtcTransactionStatus = 'build' | 'wallet-prompt'

interface WalletActionResult {
  success: boolean
  error?: string
}

export interface WalletActionErrorResult extends WalletActionResult {
  success: false
  error: string
}

interface SignMessageResult extends WalletActionResult {
  success: boolean
  error?: string
  signedMessage?: string
}

export interface SignMultiTransactionResult extends SignTransactionResult {
  provider: string
}

export interface SignTransactionResult extends WalletActionResult {
  success: boolean
  signedPsbtBase64?: string
  txId?: string
  error?: string
}

export interface SignMultipleTransactionsResult extends WalletActionResult {
  success: boolean
  signedTransactionResults?: SignTransactionResult[]
  error?: string
}

interface SendBitcoinSuccessResult extends WalletActionResult {
  success: true
  txId: string
}

interface BuildBuyOrderPsbt {
  sellPsbtBase64: string
  fee: bigint
  usedUtxo?: string[]
  unconfirmedFutureUtxo?: psbt.TransactionInputUpdate[]
}

type SendBitcoinResult = SendBitcoinSuccessResult | WalletActionErrorResult

const WalletContext = createContext<WalletContextType | undefined>(undefined)

export const useWalletContext = () => {
  const context = useContext(WalletContext)
  if (context === undefined) {
    throw new ManualError('useWalletContext must be used within a WalletProvider')
  }
  return context
}

export interface CommitEtchRuneResult {
  commitTxResult: SignTransactionResult
  revealAmount: bigint
  txId: string
  revealScript: P2TROut
  revealKey: Uint8Array
  commitTx: btc.Transaction
}

function formatUtxo({ txId, vout, value }: { txId: string; vout: number; value: number }) {
  return {
    value,
    status: {
      confirmed: false,
      block_height: 0,
      block_hash: '',
      block_time: 0,
    },
    txid: txId,
    vout,
  }
}

export const WalletProvider = ({ children }: { children: ReactNode }) => {
  const [wasmInitialized, setWasmInitialized] = useState(false)
  const [walletName, setWalletName] = useLocalStorage<WalletName | undefined>('walletName')

  const [network, setNetwork] = useLocalStorage<BitcoinNetworkType>(
    'network',
    DEPLOYED_BITCOIN_NETWORK
  )
  const [btcSignerNetwork, setBtcSignerNetwork] = useLocalStorage<BtcSignerNetwork>(
    'btcSignerNetwork',
    DEPLOYED_BITCOIN_NETWORK === BitcoinNetworkType.Testnet ? btc.TEST_NETWORK : btc.NETWORK
  )

  const [paymentAddress, setPaymentAddress] = useAddressLocalStorage(
    'paymentAddress',
    walletName ?? ''
  )
  const [ordinalsAddress, setOrdinalsAddress] = useAddressLocalStorage(
    'ordinalsAddress',
    walletName ?? ''
  )
  const [runesAddress, setRunesAddress] = useAddressLocalStorage('runesAddress', walletName ?? '')

  const [capabilityState, setCapabilityState] = useState<
    'loading' | 'loaded' | 'missing' | 'cancelled'
  >('loading')
  const [capabilities, setCapabilities] = useState<Set<Capability>>()
  const [provider, setProvider] = useState<BitcoinProvider | undefined>()

  const [btcBalances, setBtcBalances] = useLocalStorage<BtcBalances | undefined>('btcBalances')
  const [blockTip, setBlockTip] = useLocalStorage<Block | undefined>('blockTip')
  const [recommendedNetworkFees, setRecommendedNetworkFees] = useLocalStorage<
    RecommendedFees | undefined
  >('recommendedNetworkFees')
  const [btcPrice, setBtcPrice] = useLocalStorage<BtcPrice | undefined>('btcPrice')

  const [settings, setSettings] = useState<Settings>({
    serviceFees: {
      receiverAddress: '',
      feeBps: {
        mint: 0,
        order: 0,
        orderMagicEden: 0,
        orderOkx: 0,
        orderUnisat: 0,
      },
      orderMinimumForFee: {
        satAmountUnisat: 0n,
      },
    },
  })

  const [showIncorrectAccountPopup, setShowIncorrectAccountPopup] = useState(false)
  const { connectWalletEvent } = useGoogleAnalyticsContext()

  useEffect(() => {
    if (!wasmInitialized) {
      wasm.init()
      setWasmInitialized(true)
    }

    apiFetch<Settings>(API_ENDPOINTS.GET.settings.get).then((response) => {
      setSettings(response)
    })
  }, [])

  useEffect(() => {
    const runCapabilityCheck = async () => {
      let runs = 0
      const MAX_RUNS = 20
      setCapabilityState('loading')

      try {
        await getCapabilities({
          onFinish(response) {
            setCapabilities(new Set(response))
            setCapabilityState('loaded')
          },
          onCancel() {
            setCapabilityState('cancelled')
          },
          payload: {
            network: {
              type: network,
            },
          },
        })
      } catch (e) {
        runs++
        if (runs === MAX_RUNS) {
          setCapabilityState('missing')
        }
      }
      await new Promise((resolve) => setTimeout(resolve, 100))
    }

    runCapabilityCheck()

    setBtcSignerNetwork(IS_TESTNET_DEPLOYMENT ? btc.TEST_NETWORK : btc.NETWORK)
  }, [network])

  // Update btc balance on address or block change
  useEffect(() => {
    if (paymentAddress) {
      getAddressBtcBalances({
        setBtcBalances,
        address: paymentAddress,
        forceUpdate: btcBalances === undefined,
      })
    }
  }, [paymentAddress?.addrString, blockTip])

  // chain info
  useEffect(() => {
    getBlockTip({ setBlockTip, currentBlockTip: blockTip })
    getRecommendedNetworkFees({ setRecommendedNetworkFees })
    getBtcPrice({ setBtcPrice })
  }, [])

  useInterval(async () => {
    await getBlockTip({ setBlockTip, currentBlockTip: blockTip })
  }, BLOCK_INTERVAL_MS)

  useInterval(async () => {
    await getRecommendedNetworkFees({ setRecommendedNetworkFees })
  }, NETWORK_FEES_INTERVAL_MS)

  useInterval(async () => {
    await getBtcPrice({ setBtcPrice })
  }, BTC_PRICE_INTERVAL_MS)

  // reconnect to wallet provider on refresh

  const magicEdenProvider = useMagicEdenProvider()
  useEffect(() => {
    if (magicEdenProvider && !provider && walletName === WALLET_NAME.magicEden) {
      setProvider(magicEdenProvider)
    }
  }, [magicEdenProvider, provider, walletName])

  const unisatProvider = useUnisatProvider()
  useEffect(() => {
    if (unisatProvider && !provider && walletName === WALLET_NAME.unisat) {
      setProvider(unisatProvider)
    }
  }, [unisatProvider, provider, walletName])

  const leatherProvider = useLeatherProvider()
  useEffect(() => {
    if (leatherProvider && !provider && walletName === WALLET_NAME.leather) {
      setProvider(leatherProvider)
    }
  }, [leatherProvider, provider, walletName])

  const okxProvider = useOkxProvider()
  useEffect(() => {
    if (okxProvider && !provider && walletName === WALLET_NAME.okx) {
      setProvider(okxProvider)
    }
  }, [okxProvider, provider, walletName])

  const xverseProvider = getXverseProvider()
  useEffect(() => {
    if (walletName && !provider) {
      switch (walletName) {
        case WALLET_NAME.xverse:
        case WALLET_NAME.default:
          if (xverseProvider) {
            setProvider(xverseProvider)
          }
          break
      }
    }
  }, [walletName, provider, xverseProvider])

  const isReady =
    paymentAddress !== undefined &&
    ordinalsAddress !== undefined &&
    runesAddress !== undefined &&
    !!walletName
  const isConnected = !!provider && isReady

  const disconnectWallet = async () => {
    if (isCustomBitcoinProvider(provider)) {
      await provider.disconnect()
    }

    setPaymentAddress(undefined)
    setOrdinalsAddress(undefined)
    setRunesAddress(undefined)
    setProvider(undefined)
    setWalletName(undefined)
    setBtcBalances(undefined)
  }

  const toggleNetwork = () => {
    setNetwork(network)
    disconnectWallet()
  }

  const connectWallet = async (_provider: BitcoinProvider, walletName: WalletName) => {
    connectWalletEvent(walletName)

    function addressItemToAddressDetails(addressItem: Address | undefined) {
      return getAddressDetails({ walletName, addressItem })
    }

    await getAddress({
      getProvider: async () => _provider,
      payload: {
        purposes: [AddressPurpose.Ordinals, AddressPurpose.Payment],
        message: 'Welcome to Mystic - a Rune marketplace.',
        network: {
          type: network,
        },
      },
      onFinish: (response) => {
        const paymentAddressItem = response.addresses.find(
          (address) => address.purpose === AddressPurpose.Payment
        )
        const _paymentAddress = addressItemToAddressDetails(paymentAddressItem)
        setPaymentAddress(_paymentAddress)

        const ordinalsAddressItem = response.addresses.find(
          (address) => address.purpose === AddressPurpose.Ordinals
        )
        const _ordinalsAddress = addressItemToAddressDetails(ordinalsAddressItem)
        setOrdinalsAddress(_ordinalsAddress)
        // set rune address to ordinals address for now
        setRunesAddress(_ordinalsAddress)
        setProvider(_provider)
        setWalletName(walletName)
      },
      onCancel: () => alert('Request canceled'),
    })
  }

  const _signMessage = async (
    message: string,
    addressPurpose: AddressPurpose
  ): Promise<SignMessageResult> => {
    return new Promise((resolve) => {
      const address = addressPurpose === AddressPurpose.Payment ? paymentAddress : ordinalsAddress
      if (!address) {
        resolve({
          success: false,
          error: 'Not connected, address not available',
        })
        return
      }

      signMessage({
        getProvider: async () => provider,
        payload: {
          network: {
            type: network,
          },
          address: address.addrString,
          message,
        },
        onFinish: (signedMessage) => {
          resolve({ success: true, signedMessage })
        },
        onCancel: () => {
          resolve({ success: false, error: 'Signing message canceled' })
        },
      }).catch((error) => {
        resolve({ success: false, error: error.message })
      })
    })
  }

  const _signTransaction = async (
    payload: SignTransactionPayload
  ): Promise<SignTransactionResult> => {
    if (walletName === WALLET_NAME.unisat && isCustomBitcoinProvider(provider)) {
      try {
        const res = await provider.getCurrentAddress()
        if (
          !payload.inputsToSign.find((input) =>
            res.addresses.find((address) => address.address === input.address)
          )
        ) {
          setShowIncorrectAccountPopup(true)
          throw new ManualError('Incorrect active account')
        }
      } catch (error) {
        throw new ManualError(
          'Error fetching wallet information. Please ensure the Wallet plugin is connected.'
        )
      }
    }

    return new Promise((resolve) => {
      signTransaction({
        getProvider: async () => provider,
        payload,
        onFinish: (response) => {
          resolve({
            success: true,
            signedPsbtBase64: response.psbtBase64,
            txId: response.txId,
          })
        },
        onCancel: () =>
          resolve({
            success: false,
            error: 'Canceled',
          }),
      }).catch((error) => {
        resolve({
          success: false,
          error: error.message,
        })
      })
    })
  }

  const SUPPORT_MULTI_SIGN: string[] = []
  const _signMultipleTransactions = async (
    payload: SignMultipleTransactionsPayload
  ): Promise<SignMultipleTransactionsResult> => {
    // If the wallet doesn't support signPsbts then just use many signPsbt
    if (!SUPPORT_MULTI_SIGN.find((supportedWallet) => supportedWallet === walletName)) {
      const signTransactionPayloads = payload.psbts.map((psbtPayload) => ({
        network: payload.network,
        message: payload.message,
        psbtBase64: psbtPayload.psbtBase64,
        inputsToSign: psbtPayload.inputsToSign,
        broadcast: false, // Default to false
      }))

      const signTransactionResults: SignTransactionResult[] = []
      for (const signTransactionPayload of signTransactionPayloads) {
        signTransactionResults.push(await _signTransaction(signTransactionPayload))
      }

      if (
        signTransactionResults.find(
          (signTransactionResult) => !signTransactionResult.success || signTransactionResult.error
        )
      ) {
        return {
          success: false,
          error:
            signTransactionResults.find(
              (signTransactionResult) =>
                !signTransactionResult.success || signTransactionResult.error
            )?.error ?? 'Cancelled',
        }
      } else {
        return {
          success: true,
          signedTransactionResults: signTransactionResults.map((res) => {
            return {
              success: true,
              signedPsbtBase64: res.signedPsbtBase64,
              txId: res.txId,
            }
          }),
        }
      }
    }

    if (walletName === WALLET_NAME.unisat && isCustomBitcoinProvider(provider)) {
      try {
        const res = await provider.getCurrentAddress()
        if (
          !payload.psbts.find((psbt) =>
            psbt.inputsToSign.find((input) =>
              res.addresses.find((address) => address.address === input.address)
            )
          )
        ) {
          setShowIncorrectAccountPopup(true)
          throw new ManualError('Incorrect active account')
        }
      } catch (error) {
        throw new ManualError(
          'Error fetching wallet information. Please ensure the Wallet plugin is connected.'
        )
      }
    }

    // console.log(payload)
    return new Promise((resolve) => {
      signMultipleTransactions({
        getProvider: async () => provider,
        payload,
        onFinish: (responses) => {
          const signedTransactionResults = Array.isArray(responses)
            ? responses.map(
                (res) =>
                  ({
                    success: true,
                    signedPsbtBase64: res.psbtBase64,
                    txId: res.txId,
                  }) as SignTransactionResult
              )
            : [] // If responses is not an array, use an empty array

          resolve({
            success: true,
            signedTransactionResults,
          })
        },
        onCancel: () =>
          resolve({
            success: false,
            error: 'Canceled',
          }),
      }).catch((error) => {
        resolve({
          success: false,
          error: error.message,
        })
      })
    })
  }

  // Creates single mint PSBT, requests PSBT signing and then tries to broadcast it using site backend
  const _mintRune = async ({
    runeId,
    runeName,
    recipientAddress,
    fee,
  }: {
    runeId: string
    runeName: string
    recipientAddress: string
    fee: bigint
  }): Promise<SignTransactionResult> => {
    if (!paymentAddress) {
      throw new ManualError('Payment address not available')
    }

    const tx = await buildMintTx({
      paymentAddress: paymentAddress,
      recipientAddress,
      runeId,
      network: btcSignerNetwork,
      fee,
    })

    if (!tx) {
      throw new ManualError('Failed to create transaction')
    }

    const indicesToSign: number[] = []
    for (let i = 0; i < tx.inputsLength; i++) {
      indicesToSign.push(i)
    }

    const result = await _signTransaction({
      broadcast: false,
      inputsToSign: [{ address: paymentAddress.addrString, signingIndexes: indicesToSign }],
      network: {
        type: network,
      },
      message: `Sign to mint ${runeName} Runes`,
      psbtBase64: base64.encode(tx.toPSBT()),
    })

    if (!result.success || !result.signedPsbtBase64) {
      throw new ManualError('Failed to mint Runes')
    }

    const signedPsbt = base64.decode(result.signedPsbtBase64)
    const signedTx = btc.Transaction.fromPSBT(signedPsbt)
    signedTx.finalize()

    const mintsSubmission: MintsSubmission = {
      splitterSignerAddress: '',
      minterSignerAddress: paymentAddress.addrString,
      splitterPsbtBase64: '',
      mintPsbtsBase64: [base64.encode(signedTx.toPSBT())],
      runeId,
      quantity: 1n,
      fee: fee,
    }

    // if (!result.txId && walletName === WALLET_NAME.magicEden) {
    //   result.txId = await finalizeAndBroadcastTx(result.signedPsbtBase64)
    // }

    const response = await apiFetch<MintsSubmissionResult>(
      replaceUrlParams(API_ENDPOINTS.POST.runes.mints.submitMints, { runeName }),
      undefined,
      {
        body: mintsSubmission,
        method: 'POST',
      }
    )

    if (!!response.error || !response.splitterTxId) {
      console.error('Failed to mint Runes', response.error ?? 'No error', response.splitterTxId)
      return {
        success: false,
        error: response.error ?? 'Failed to mint Runes',
      }
    }

    const results: SignTransactionResult[] = [
      {
        txId: response.splitterTxId,
        success: true,
        signedPsbtBase64: result.signedPsbtBase64,
      },
    ]

    for (const txId of response.mintTxIds ?? []) {
      results.push({
        success: true,
        txId,
        signedPsbtBase64: '',
      })
    }
    for (const error of response.mintErrors ?? []) {
      results.push({
        success: false,
        error: error[1],
      })
    }

    return results[0]
  }

  // Estimates bulk mint PSBT vsize for estimating transaction fee
  const _estimateMintBulkRuneVsize = async ({
    quantity,
    addFee,
  }: {
    quantity: bigint
    addFee: boolean
  }): Promise<{ parent: number; child: number }> => {
    if (!paymentAddress) {
      throw new ManualError('Payment address not available')
    }

    return {
      parent: estimatedMintParentVsize(Number(quantity), addFee),
      child: estimatedMintChildVsize(),
    }
  }

  // Creates bulk mint PSBTs, requests PSBTs signing and then tries to broadcast them using site backend
  const _mintBulkRune = async ({
    runeId,
    runeName,
    recipientAddress,
    fee,
    quantity,
    onStatusChange,
  }: {
    runeId: string
    runeName: string
    recipientAddress: string
    fee: bigint
    quantity: bigint
    onStatusChange(status: BulkMintSignTransactionStatus): void
  }): Promise<SignMultipleTransactionsResult> => {
    if (!paymentAddress) {
      throw new ManualError('Payment address not available')
    }

    onStatusChange('build')

    // need to call estimate twice to get the correct vsize of the parent and child transactions
    const estimate = await buildBulkMintTxs({
      paymentAddress: paymentAddress,
      recipientAddress,
      serviceFeeReceiverAddress: settings.serviceFees.receiverAddress,
      serviceFeeMintBps: settings.serviceFees.feeBps.mint,
      runeId,
      network: btcSignerNetwork,
      estimatedTotalNetworkFee: 0n,
      childEstimatedVsize: BigInt(TRX_VIRTUAL_SIZE_BYTES.mint),
      fee,
      quantity,
    })
    const parentEstimatedVsize = estimate.parentTx.estimatedVsize
    const childEstimatedVsize = estimate.childTxStrings[0][0].estimatedP2TRVsize
    const estimatedTotalNetworkFee = calculateBulkMintNetworkFeeTotal({
      feeRate: fee,
      parentEstimatedVsize,
      childEstimatedVsize,
      numberOfMints: quantity,
    })

    const bulk = await buildBulkMintTxs({
      paymentAddress: paymentAddress,
      recipientAddress,
      serviceFeeReceiverAddress: settings.serviceFees.receiverAddress,
      serviceFeeMintBps: settings.serviceFees.feeBps.mint,
      runeId,
      network: btcSignerNetwork,
      fee,
      estimatedTotalNetworkFee,
      childEstimatedVsize: BigInt(childEstimatedVsize),
      quantity,
    })

    const indicesToSign: number[] = []
    for (let i = 0; i < bulk.parentTx.inputsLength; i++) {
      indicesToSign.push(i)
    }

    onStatusChange('wallet-prompt')
    const result = await _signTransaction({
      broadcast: false,
      inputsToSign: [{ address: paymentAddress.addrString, signingIndexes: indicesToSign }],
      network: {
        type: network,
      },
      message: `Sign to prepare bulk mint of ${runeName}`,
      psbtBase64: base64.encode(bulk.parentTx.toPSBT()),
    })

    if (!result.success || !result.signedPsbtBase64) {
      console.warn('Failed to mint runes', result.error)
      return result
    }

    onStatusChange('temp-key-sign')
    const worker = new BulkMintWebWorker()

    function signBulkMintTxsInWorker(bulk: BuildBulkMintResult): Promise<SignBulkMintResult> {
      return new Promise((resolve, reject) => {
        worker.onmessage = (event: MessageEvent) => {
          const serializedResult: SignBulkMintSerializedResult = event.data
          const response: SignBulkMintResult = {
            ...serializedResult,
            parentTx: btc.Transaction.fromPSBT(base64.decode(serializedResult.parentTxBase64)),
            childTxs: serializedResult.childTxsBase64.map((tx) =>
              btc.Transaction.fromPSBT(base64.decode(tx))
            ),
          }
          resolve(response)
        }
        worker.onerror = (error: ErrorEvent) => {
          console.error('Worker error:', error)
          reject(new Error('Error preparing mints'))
        }

        const bulkSerialized: BuildBulkMintSerializedResult = {
          ...bulk,
          parentTxSignedBase64: result.signedPsbtBase64!,
          childTxsBase64: bulk.childTxStrings.map((txString) =>
            txString.map((tx) => base64.encode(tx.toPSBT()))
          ),
        }
        worker.postMessage({
          action: 'signBulkMintTxs',
          bulk: bulkSerialized,
        })
      })
    }

    const signed = await signBulkMintTxsInWorker(bulk)

    const splitterPsbtBase64 = base64.encode(signed.parentTx.toPSBT())
    const mintsSubmission: MintsSubmission = {
      splitterSignerAddress: paymentAddress.addrString,
      minterSignerAddress: bulk.tempKeyAddress,
      splitterPsbtBase64,
      mintPsbtsBase64: signed.childTxs.map((tx) => base64.encode(tx.toPSBT())),
      runeId,
      quantity,
    }

    onStatusChange('api-submit')
    const response = await apiFetch<MintsSubmissionResult>(
      replaceUrlParams(API_ENDPOINTS.POST.runes.mints.submitMints, { runeName }),
      undefined,
      {
        body: mintsSubmission,
        method: 'POST',
      }
    )

    if (!!response.error || !response.splitterTxId) {
      console.error('Failed to mint Runes', response.error ?? 'No error', response.splitterTxId)
      return {
        success: false,
        error: response.error ?? 'Failed to mint Runes',
      }
    }

    const results: SignTransactionResult[] = [
      {
        txId: response.splitterTxId,
        success: true,
        signedPsbtBase64: splitterPsbtBase64,
      },
    ]

    for (const txId of response.mintTxIds ?? []) {
      results.push({
        success: true,
        txId,
        signedPsbtBase64: '',
      })
    }
    for (const error of response.mintErrors ?? []) {
      results.push({
        success: false,
        error: error[1],
      })
    }

    return { success: true, signedTransactionResults: results }
  }

  // Creates transfer PSBT, requests PSBT signing and broadcasts it to blockchain via frontend
  const _transferRunes = async ({
    recipientAddress,
    rune,
    transferAmount,
    fee,
    onStatusChange,
  }: {
    recipientAddress: string
    rune: Rune
    transferAmount: bigint
    fee: bigint
    onStatusChange(status: TransferRunesTransactionStatus): void
  }): Promise<SignTransactionResult> => {
    if (!paymentAddress || !runesAddress) {
      throw new ManualError('Runes or Payment address not available')
    }

    onStatusChange('build')
    const { tx: runeTransferTx, inputsToSign } = await createRuneTransferTx({
      runesAddress,
      paymentAddress,
      recipientAddress,
      runeId: rune.runeId,
      transferAmount,
      network: btcSignerNetwork,
      fee,
    })

    if (!runeTransferTx) {
      throw new ManualError('Failed to create transaction')
    }

    onStatusChange('wallet-prompt')
    const result = await _signTransaction({
      broadcast: true,
      network: { type: network },
      message: `Transfer ${transferAmount.toLocaleString()} ${
        rune.runeSymbolChar ?? rune.runeName
      }`,
      psbtBase64: base64.encode(runeTransferTx.toPSBT()),
      inputsToSign,
    })

    if (!result.success || !result.signedPsbtBase64) {
      throw new ManualError(result.error)
    }

    if (!result.txId && walletName === WALLET_NAME.magicEden) {
      result.txId = await finalizeAndBroadcastTx(result.signedPsbtBase64)
    }

    return result
  }

  //  Estimates rune transfer PSBT vsize for estimating transaction fee
  const _estimateTransferRunesVsize = async ({
    rune,
    transferAmount,
    fee,
  }: {
    rune: Rune
    transferAmount: bigint
    fee: bigint
  }) => {
    if (!paymentAddress || !runesAddress) {
      throw new ManualError('Runes or Payment address not available')
    }

    const { tx: runeTransferTx } = await createRuneTransferTx({
      runesAddress,
      paymentAddress,
      recipientAddress: paymentAddress.addrString, // not needed for estimate
      runeId: rune.runeId,
      transferAmount,
      network: btcSignerNetwork,
      fee,
    })

    return runeTransferTx.estimatedP2TRVsize
  }

  // Fetches list of outpoints containing runes @runeId that are unused
  // that have totalRuneAmount bigger or equel to @sellAmount
  const _getRunesOutpointsForSale = async ({
    runeId,
    sellAmount,
  }: GetRuneOutpointsForSaleParams): Promise<RuneOutpointDetails[]> => {
    if (!runesAddress) {
      throw new ManualError('Rune address not available')
    }

    const outpoints: RuneUtxoOutpoint[] = await getRuneTransactionInputs(
      runesAddress,
      runeId,
      sellAmount
    )

    return outpoints.map((outpoint) => ({
      runeId,
      outpointId: getOutpointId(outpoint),
      amount: outpoint.amount,
      value: BigInt(outpoint.utxo.value),
      txId: outpoint.utxo.txid,
      vout: BigInt(outpoint.utxo.vout),
    }))
  }

  // Legacy boxing function
  const _sellRunesUnboxed = async ({
    proceedsAddress,
    proceeds,
    rune,
    sellAmount,
    runeOutpoints = [],
    onStatusChange,
  }: MultiSellRunesParams): Promise<SignMultipleTransactionsResult> => {
    if (!runesAddress) {
      throw new ManualError('Rune address not available')
    }
    if (!paymentAddress) {
      throw new ManualError('Payment address not available')
    }

    onStatusChange('build')

    const formattedOutpoints: RuneUtxoOutpoint[] = await Promise.all(
      runeOutpoints.map(async (outpoint) => {
        const runeUtxo: RuneUtxoOutpoint = {
          runeId: rune.runeId,
          amount: outpoint.amount,
          utxo: formatUtxo({
            txId: outpoint.txId,
            vout: Number(outpoint.vout),
            value: Number(outpoint.value),
          }),
        }

        return runeUtxo
      })
    )

    //Create a new tx per utxo
    const sellTxs: btc.Transaction[] = []
    const sellSubmissions: SellOrderSubmission[] = []
    for (let index = 0; index < proceeds.length; index++) {
      const sellTx = await buildSellPsbt({
        proceedsAddress,
        proceedsAmount: proceeds[index],
        runeAddressDetails: runesAddress,
        runeId: rune.runeId,
        runeUtxo: formattedOutpoints[index],
        network: btcSignerNetwork,
      })

      if (!sellTx) {
        throw new ManualError('Failed to create transaction')
      }

      sellTxs.push(sellTx)
    }

    onStatusChange('sell-wallet-prompt-not-boxed')

    const sellResult = await _signMultipleTransactions({
      message: 'Sign to sell Runes',
      network: {
        type: network,
      },
      psbts: sellTxs.map((sellTx) => {
        return {
          psbtBase64: base64.encode(sellTx.toPSBT()),
          inputsToSign: [
            {
              address: runesAddress.addrString,
              signingIndexes: [0],
              sigHash: btc.SigHash.SINGLE_ANYONECANPAY,
            },
          ],
        }
      }),
    })

    if (!sellResult.success || !sellResult.signedTransactionResults) {
      throw new ManualError('Failed to create sell tx')
    }

    for (let index = 0; index < proceeds.length; index++) {
      if (
        sellResult.signedTransactionResults[index].success &&
        sellResult.signedTransactionResults[index].signedPsbtBase64
      ) {
        const sellSubmission: SellOrderSubmission = {
          runeId: rune.runeId,
          amountRunes: sellAmount[index],
          amountSats: proceeds[index],
          signedByAddress: runesAddress.addrString,
          sellPsbt: {
            dataBase64: sellResult.signedTransactionResults[index].signedPsbtBase64!,
            assertions: {
              boxedOutpointId: getOutpointId(formattedOutpoints[index]),
              btcProceedsAddress: proceedsAddress,
            },
          },
        }

        sellSubmissions.push(sellSubmission)
      }
    }

    onStatusChange('api-submit')
    await apiFetch(
      replaceUrlParams(API_ENDPOINTS.POST.runes.orders.sell, {
        runeName: rune.runeName,
      }),
      {},
      { method: 'POST', body: sellSubmissions }
    )

    return {
      success: true,
      signedTransactionResults: sellResult.signedTransactionResults,
    }
  }

  type SaleInformation = {
    amountRunes: bigint
    amountSats: bigint
    boxedOutpointId: string
    boxTransactionPsbt?: string
    boxTransactionId?: string
    boxTransactionSpendingOutputs?: string[]
  }

  // Creates Sell Order PSBT, requests it signing and then tries to create Sell Order on backend
  const _sellRunes = async ({
    proceedsAddress,
    proceeds,
    rune,
    sellAmount,
    fee,
    runeOutpoints = [],
    onStatusChange,
  }: SellRunesParams): Promise<SignMultipleTransactionsResult> => {
    if (!runesAddress) {
      throw new ManualError('Rune address not available')
    }
    if (!paymentAddress) {
      throw new ManualError('Payment address not available')
    }
    // Adjust return type to array for multiple orders

    const sellTxs: btc.Transaction[] = []
    const saleObjects: SaleInformation[] = []
    const sellSubmissions: SellOrderSubmission[] = []
    let remainingSellAmount = sellAmount

    for (const outpoint of runeOutpoints) {
      if (remainingSellAmount <= 0n) break // Stop if we've fulfilled the sell amount

      const formattedOutpoint = {
        runeId: rune.runeId,
        amount: outpoint.amount,
        utxo: formatUtxo({
          txId: outpoint.txId,
          vout: Number(outpoint.vout),
          value: Number(outpoint.value),
        }),
      }

      // Determine if we need to split this outpoint
      let mustBox = false
      let boxedOutpoint: RuneUtxoOutpoint

      if (remainingSellAmount < formattedOutpoint.amount) {
        // Split the outpoint to match the remaining sell amount
        mustBox = true
      }

      if (mustBox) {
        const {
          tx: runeTransferTx,
          inputsToSign,
          spendingUtxos,
        } = await createRuneTransferTx({
          runesAddress,
          paymentAddress,
          recipientAddress: runesAddress.addrString,
          runeId: rune.runeId,
          transferAmount: remainingSellAmount,
          network: btcSignerNetwork,
          runeUtxoOutpoints: [formattedOutpoint],
          fee,
        })

        onStatusChange('transfer-wallet-prompt')

        const boxResult = await _signTransaction({
          broadcast: false,
          network: { type: network },
          message: 'Prepare Runes for sale',
          psbtBase64: base64.encode(runeTransferTx.toPSBT()),
          inputsToSign,
        })

        if (!boxResult.success || !boxResult.signedPsbtBase64) {
          throw new ManualError('Failed to prepare Runes')
        }

        const finalizedBoxTx = finalizeTx(boxResult.signedPsbtBase64)

        const boxTransactionResult: SignTransactionResult = {
          success: true,
          txId: finalizedBoxTx.id,
          signedPsbtBase64: hexToBase64(finalizedBoxTx.hex),
        }

        boxedOutpoint = {
          runeId: rune.runeId,
          amount: remainingSellAmount, // Use only the remaining amount
          utxo: formatUtxo({
            txId: finalizedBoxTx.id,
            vout: 0,
            value: Number(SATOSHI_DUST_THRESHOLD),
          }),
        }

        remainingSellAmount -= boxedOutpoint.amount // remainingSellAmount = 0n
        saleObjects.push({
          amountRunes: boxedOutpoint.amount,
          amountSats: (proceeds * boxedOutpoint.amount) / sellAmount,
          boxedOutpointId: getOutpointId(boxedOutpoint),
          boxTransactionPsbt: boxTransactionResult.signedPsbtBase64,
          boxTransactionId: boxTransactionResult.txId,
          boxTransactionSpendingOutputs: spendingUtxos.map((outpoint) => getOutpointId(outpoint)),
        })
      } else {
        // Use the entire outpoint amount if no split is needed
        boxedOutpoint = formattedOutpoint
        remainingSellAmount -= formattedOutpoint.amount // Reduce the remaining amount to sell
        saleObjects.push({
          amountRunes: boxedOutpoint.amount,
          amountSats: (proceeds * boxedOutpoint.amount) / sellAmount,
          boxedOutpointId: getOutpointId(boxedOutpoint),
        })
      }

      const sellTx = await buildSellPsbt({
        proceedsAddress,
        proceedsAmount: (proceeds * boxedOutpoint.amount) / sellAmount,
        runeAddressDetails: runesAddress,
        runeId: rune.runeId,
        runeUtxo: boxedOutpoint,
        network: btcSignerNetwork,
      })

      if (!sellTx) {
        throw new ManualError('Failed to create transaction')
      }

      sellTxs.push(sellTx)
    }

    onStatusChange('sell-wallet-prompt-not-boxed')

    const sellResult = await _signMultipleTransactions({
      message: 'Sign to sell Runes',
      network: {
        type: network,
      },
      psbts: sellTxs.map((sellTx) => {
        return {
          psbtBase64: base64.encode(sellTx.toPSBT()),
          inputsToSign: [
            {
              address: runesAddress.addrString,
              signingIndexes: [0],
              sigHash: btc.SigHash.SINGLE_ANYONECANPAY,
            },
          ],
        }
      }),
    })

    if (!sellResult.success || !sellResult.signedTransactionResults) {
      throw new ManualError('Failed to create sell tx')
    }

    for (let index = 0; index < saleObjects.length; index++) {
      const sale = sellResult.signedTransactionResults[index]
      if (sale.success && sale.signedPsbtBase64) {
        const sellSubmission: SellOrderSubmission = {
          runeId: rune.runeId,
          amountRunes: saleObjects[index].amountRunes,
          amountSats: saleObjects[index].amountSats,
          signedByAddress: runesAddress.addrString,

          sellPsbt: {
            dataBase64: sale.signedPsbtBase64,
            assertions: {
              boxedOutpointId: runeOutpoints[index].outpointId,
              btcProceedsAddress: proceedsAddress,
            },
          },
        }

        if (saleObjects[index].boxTransactionPsbt != undefined) {
          sellSubmission.boxTransaction = {
            dataBase64: saleObjects[index].boxTransactionPsbt!,
            assertions: {
              transactionId: saleObjects[index].boxTransactionId!,
              changeAddress: runesAddress.addrString,
              spendingOutpointIds: saleObjects[index].boxTransactionSpendingOutputs!,
            },
          }
        }

        sellSubmissions.push(sellSubmission)
      }
    }

    onStatusChange('api-submit')
    await apiFetch(
      replaceUrlParams(API_ENDPOINTS.POST.runes.orders.sell, {
        runeName: rune.runeName,
      }),
      {},
      { method: 'POST', body: sellSubmissions }
    )

    return {
      success: true,
      signedTransactionResults: sellResult.signedTransactionResults,
    }
  }

  // Attempts to create a Bid Order object using the site backend.
  // This function requests a signing message to confirm ownership of the current address.
  const _placeBidOrder = async ({
    rune,
    proceeds,
    proceedsAddress,
    sellAmount,
    expiresAt,
    onStatusChange,
  }: PlaceBidOrderParams): Promise<SignMultipleTransactionsResult> => {
    if (!runesAddress) {
      throw new ManualError('Rune address not available')
    }
    if (!paymentAddress) {
      throw new ManualError('Payment address not available')
    }

    // Requests message signing to prove address ownership for the backend.
    const { challenge } = await apiFetch<GenericChallenge>(
      `${API_ENDPOINTS.GET.auth.challenge}?message="Sign this message to place your bid order"`
    )
    const signatureResponse = await _signMessage(challenge, AddressPurpose.Ordinals)

    if (!signatureResponse.success || !signatureResponse.signedMessage) {
      return signatureResponse
    }

    const bidOrder: BidOrderSubmission = {
      runeId: rune.runeId,
      amountRunes: sellAmount,
      amountSats: proceeds,
      signedByAddress: runesAddress.addrString,
      challenge: challenge,
      signature: signatureResponse.signedMessage,
      runeProceedsAddress: proceedsAddress,
      expiresAt: expiresAt,
    }

    onStatusChange('api-submit')
    const placeBidOrderResponse = await apiFetch<BidOrderSubmissionResult>(
      replaceUrlParams(API_ENDPOINTS.POST.runes.bidOrders.sell, {
        runeName: rune.runeName,
      }),
      {},
      { method: 'POST', body: bidOrder }
    )

    if (!placeBidOrderResponse.orderPlaced) {
      return {
        success: false,
        error: 'Failed to place bid order',
      }
    }

    return {
      success: true,
      signedTransactionResults: [],
    }
  }

  // Fetches and verifies the validity of bid @orders for @rune using the site backend.
  const _requestBuyBidOrder = async ({
    rune,
    orders,
  }: RequestBuyBidOrderParams): Promise<BuyBidOrderRequestResult> => {
    const buyRequest: BuyBidOrderRequest = {
      runeId: rune.runeId,
      orderIds: orders.map((order) => order.id),
    }

    const buyRequestResponse = await apiFetch<BuyBidOrderRequestResult>(
      replaceUrlParams(API_ENDPOINTS.POST.runes.bidOrders.requestBuy, {
        runeName: rune.runeName,
        orderId: orders[0].id.toString(),
      }),
      {},
      {
        body: buyRequest,
        method: 'POST',
      }
    )

    return buyRequestResponse
  }

  // Creates a Bid Order PSBT, requests PSBT signing, and attempts to broadcast it through the site backend.
  const _submitBuyBidOrder = async ({
    rune,
    fee,
    orders,
    proceedsAddress,
    onStatusChange,
  }: SubmitBuyBidOrderParams): Promise<SignTransactionResult | BuyBidOrderRequestResult> => {
    try {
      if (!runesAddress) {
        throw new ManualError('Rune address not available')
      }
      if (!paymentAddress) {
        throw new ManualError('Payment address not available')
      }

      onStatusChange('build')

      // Verifying order validity
      const buyBidOrderRequestRes = await _requestBuyBidOrder({ rune, orders: orders })

      if (buyBidOrderRequestRes.validOrders.length === 0) {
        throw new ManualError('None of your selected bid orders are available')
      }

      if (buyBidOrderRequestRes.validOrders.length < orders.length) {
        return buyBidOrderRequestRes
      }

      // Constructing Bid order PSBT
      const { buyTx, indicesToSign } = await _buildBidOrderPsbt({
        fee,
        orders: buyBidOrderRequestRes.validOrders,
        proceedsAddress: proceedsAddress,
        rune,
      })

      onStatusChange('wallet-prompt')
      // Request Bid Order PSBT signing
      const result = await _signTransaction({
        broadcast: false,
        inputsToSign: [
          {
            address: runesAddress.addrString,
            signingIndexes: indicesToSign,
            sigHash: btc.SigHash.ALL,
          },
        ],
        network: {
          type: network,
        },
        message: 'Sign to sell Runes',
        psbtBase64: base64.encode(buyTx.toPSBT()),
      })

      if (!result.success || !result.signedPsbtBase64) {
        throw new ManualError(result.error)
      }

      const buySubmission: BuyBidOrderSubmission = {
        runeId: rune.runeId,
        orders: buyBidOrderRequestRes.validOrders,
        buyerAddress: runesAddress.addrString,
        btcProceedsAddress: proceedsAddress,
        recieverAddress: runesAddress.addrString,
        recieverPubkey: hex.encode(runesAddress.pubKey),
        psbt: {
          dataBase64: result.signedPsbtBase64,
        },
      }

      onStatusChange('api-submit')

      const buySubmissionResponse = await apiFetch<BuyBidOrderSubmissionResult>(
        replaceUrlParams(API_ENDPOINTS.POST.runes.bidOrders.submitBuy, {
          runeName: rune.runeName,
          orderId: buyBidOrderRequestRes.validOrders[0].orderId.toString(),
        }),
        {},
        {
          body: buySubmission,
          method: 'POST',
        }
      )

      if (buySubmissionResponse.allOrdersFilled) {
        return { ...result, txId: buySubmissionResponse.txId }
      } else if (buySubmissionResponse.retry) {
        return buySubmissionResponse.retry
      } else if (buySubmissionResponse.error) {
        return { ...result, success: false, error: buySubmissionResponse.error }
      }

      return { ...result, success: false, error: 'Failed to submit buy bid order' }
    } catch (error) {
      console.log(error)
      throw new ManualError('Uncaught error')
    }
  }

  // Request Bid Order canceling via backend endpoint
  const _cancelPlaceBidOrder = async ({
    order,
  }: {
    order: BidOrder
  }): Promise<SignMessageResult> => {
    if (!runesAddress) {
      throw new ManualError('Runes address not available')
    }
    // Requests message signing to prove address ownership for the backend.
    const { challenge } = await apiFetch<GenericChallenge>(
      `${API_ENDPOINTS.GET.auth.challenge}?message="Sign this message to cancel your bid order"`
    )
    const signatureResponse = await _signMessage(challenge, AddressPurpose.Ordinals)

    if (!signatureResponse.success || !signatureResponse.signedMessage) {
      return signatureResponse
    }

    const cancelRequest: CancelSubmission = {
      runeId: order.runeId,
      orderId: String(order.id),
      signature: signatureResponse.signedMessage,
      address: runesAddress.addrString,
      challenge,
    }
    const cancelSuccessful = await apiFetch<boolean>(
      replaceUrlParams(API_ENDPOINTS.POST.runes.bidOrders.cancel, {
        runeName: order.runeName,
        orderId: order.id.toString(),
      }),
      {},
      {
        body: cancelRequest,
        method: 'POST',
      }
    )

    if (!cancelSuccessful) {
      return {
        success: false,
        error: 'Failed to cancel bid order',
      }
    }

    return {
      success: true,
      signedMessage: '',
    }
  }

  // DEPRECATED - Merges and splits UTXOs
  // const _sellRunes = async ({
  //   proceedsAddress,
  //   proceeds,
  //   rune,
  //   sellAmount,
  //   fee,
  //   runeOutpoints = [],
  //   onStatusChange,
  // }: SellRunesParams): Promise<SignTransactionResult> => {
  //   if (!runesAddress) {
  //     throw new ManualError('Rune address not available')
  //   }
  //   if (!paymentAddress) {
  //     throw new ManualError('Payment address not available')
  //   }

  //   onStatusChange('build')

  //   const formattedOutpoints: RuneUtxoOutpoint[] = await Promise.all(
  //     runeOutpoints.map(async (outpoint) => {
  //       const runeUtxo: RuneUtxoOutpoint = {
  //         runeId: rune.runeId,
  //         amount: outpoint.amount,
  //         utxo: formatUtxo({
  //           txId: outpoint.txId,
  //           vout: Number(outpoint.vout),
  //           value: Number(outpoint.value),
  //         }),
  //       }

  //       return runeUtxo
  //     })
  //   )
  //   const outpointsTotal = formattedOutpoints.reduce(
  //     (total, outpoint) => total + outpoint.amount,
  //     0n
  //   )

  //   // box transaction
  //   let boxedOutpoint: RuneUtxoOutpoint
  //   let boxTransactionResult: SignTransactionResult | undefined
  //   let spendingOutpointIds: string[] = []

  //   let mustBox = false

  //   // if formattedOutpoints.length === 1, check if there are more than one rune type on that outpoint. if so, mustBox = true
  //   if (formattedOutpoints.length === 1) {
  //     const outpointId = `${formattedOutpoints[0].utxo.txid}:${formattedOutpoints[0].utxo.vout}`
  //     const runeOutpoints = await apiFetch<RuneOutpoint[]>(
  //       `${replaceUrlParams(API_ENDPOINTS.GET.runes.outpoints.runeAmountsForOutpoint, {
  //         outpointId,
  //       })}`
  //     )
  //     if (runeOutpoints.length > 1) {
  //       mustBox = true
  //     }
  //     if (formattedOutpoints[0].utxo.value > 10000) {
  //       mustBox = true
  //     }
  //   }

  //   // if splitting or combining outpoints use transfer tx
  //   if (outpointsTotal !== sellAmount || formattedOutpoints.length > 1 || mustBox) {
  //     const {
  //       tx: runeTransferTx,
  //       inputsToSign,
  //       spendingUtxos,
  //     } = await createRuneTransferTx({
  //       runesAddress,
  //       paymentAddress,
  //       recipientAddress: runesAddress.addrString,
  //       runeId: rune.runeId,
  //       transferAmount: sellAmount,
  //       network: btcSignerNetwork,
  //       runeUtxoOutpoints: formattedOutpoints,
  //       fee,
  //     })

  //     if (!runeTransferTx) {
  //       throw new ManualError('Failed to create transaction')
  //     }

  //     onStatusChange('transfer-wallet-prompt')
  //     const boxResult = await _signTransaction({
  //       broadcast: false,
  //       network: { type: network },
  //       message: 'Prepare Runes for sale',
  //       psbtBase64: base64.encode(runeTransferTx.toPSBT()),
  //       inputsToSign,
  //     })

  //     if (!boxResult.success) {
  //       throw new ManualError('Failed to prepare Runes')
  //     }

  //     onStatusChange('build')

  //     const finalizedBoxTx = finalizeTx(boxResult.signedPsbtBase64)

  //     spendingOutpointIds = spendingUtxos.map((outpoint) => getOutpointId(outpoint))

  //     boxTransactionResult = {
  //       success: true,
  //       txId: finalizedBoxTx.id,
  //       signedPsbtBase64: hexToBase64(finalizedBoxTx.hex),
  //     }

  //     boxedOutpoint = {
  //       runeId: rune.runeId,
  //       amount: sellAmount,
  //       utxo: formatUtxo({
  //         txId: finalizedBoxTx.id,
  //         vout: 0,
  //         value: Number(SATOSHI_DUST_THRESHOLD),
  //       }),
  //     }
  //   } else {
  //     // preboxed outpoint
  //     boxedOutpoint = formattedOutpoints[0]
  //   }

  //   const sellTx = await buildSellPsbt({
  //     proceedsAddress,
  //     proceedsAmount: proceeds,
  //     runeAddressDetails: runesAddress,
  //     runeId: rune.runeId,
  //     runeUtxo: boxedOutpoint,
  //     network: btcSignerNetwork,
  //   })

  //   if (!sellTx) {
  //     throw new ManualError('Failed to create transaction')
  //   }

  //   if (boxTransactionResult) {
  //     onStatusChange('sell-wallet-prompt-boxed')
  //   } else {
  //     onStatusChange('sell-wallet-prompt-not-boxed')
  //   }

  //   const sellResult = await _signTransaction({
  //     broadcast: false,
  //     inputsToSign: [
  //       {
  //         address: runesAddress.addrString,
  //         signingIndexes: [0],
  //         sigHash: btc.SigHash.SINGLE_ANYONECANPAY,
  //       },
  //     ],
  //     network: {
  //       type: network,
  //     },
  //     message: 'Sign to sell Runes',
  //     psbtBase64: base64.encode(sellTx.toPSBT()),
  //   })

  //   if (!sellResult.success) {
  //     throw new ManualError('Failed to create sell tx')
  //   }

  //   const sellSubmission: SellSubmission = {
  //     runeId: rune.runeId,
  //     amountRunes: sellAmount,
  //     amountSats: proceeds,
  //     signedByAddress: runesAddress.addrString,
  //     sellPsbt: {
  //       dataBase64: sellResult.signedPsbtBase64,
  //       assertions: {
  //         boxedOutpointId: getOutpointId(boxedOutpoint),
  //         btcProceedsAddress: proceedsAddress,
  //       },
  //     },
  //   }

  //   if (boxTransactionResult) {
  //     sellSubmission.boxTransaction = {
  //       dataBase64: boxTransactionResult.signedPsbtBase64,
  //       assertions: {
  //         transactionId: boxTransactionResult.txId!,
  //         changeAddress: runesAddress.addrString,
  //         spendingOutpointIds: spendingOutpointIds,
  //       },
  //     }
  //   }

  //   onStatusChange('api-submit')
  //   await apiFetch(
  //     replaceUrlParams(API_ENDPOINTS.POST.runes.orders.sell, {
  //       runeName: rune.runeName,
  //     }),
  //     {},
  //     { method: 'POST', body: sellSubmission }
  //   )

  //   return {
  //     success: true,
  //     txId: sellResult.txId!,
  //     signedPsbtBase64: sellResult.signedPsbtBase64,
  //   }
  // }

  // Requests cancellation of a Sell Order via the backend endpoint.
  const _cancelSellRunes = async ({ order }: CancelSellRunesParams): Promise<SignMessageResult> => {
    if (!runesAddress) {
      throw new ManualError('Runes address not available')
    }
    // Request message signing to provide prove of address ownership for backend
    const { challenge } = await apiFetch<GenericChallenge>(
      `${API_ENDPOINTS.GET.auth.challenge}?message="Sign this message to cancel your order"`
    )
    const signatureResponse = await _signMessage(challenge, AddressPurpose.Ordinals)

    if (!signatureResponse.success || !signatureResponse.signedMessage) {
      return signatureResponse
    }

    const cancelRequest: CancelSubmission = {
      runeId: order.runeId,
      orderId: String(order.id),
      signature: signatureResponse.signedMessage,
      address: runesAddress.addrString,
      challenge,
    }
    const cancelSuccessful = await apiFetch<boolean>(
      replaceUrlParams(API_ENDPOINTS.POST.runes.orders.cancel, {
        runeName: order.runeName,
        orderId: order.id.toString(),
      }),
      {},
      {
        body: cancelRequest,
        method: 'POST',
      }
    )

    if (!cancelSuccessful) {
      return {
        success: false,
        error: 'Failed to cancel order',
      }
    }

    return {
      success: true,
      signedMessage: '',
    }
  }

  // Fetches and verifies the validity of sell @orders for @rune using the site backend.
  const _requestBuyRunes = ({
    rune,
    fee,
    orders,
  }: RequestBuyRunesParams): Promise<BuySellOrderRequestResult[]> => {
    if (!paymentAddress) {
      throw new ManualError('Payment address not available')
    }

    const buyRequest: BuySellOrderRequest = {
      runeId: rune.runeId,
      orders,
      feeRate: fee,
      address: paymentAddress.addrString,
      pubkey: hex.encode(paymentAddress.pubKey),
      ...(runesAddress && runesAddress.addrString !== paymentAddress.addrString
        ? {
            recieverAddress: runesAddress.addrString,
            recieverPubkey: hex.encode(runesAddress.pubKey),
          }
        : {}),
    }

    return apiFetch<BuySellOrderRequestResult[]>(
      replaceUrlParams(API_ENDPOINTS.POST.runes.orders.requestBuy, {
        runeName: rune.runeName,
      }),
      {},
      {
        body: buyRequest,
        method: 'POST',
      }
    )
  }

  // Constructs the Sell Order PSBT for Mystic Orders based on provided data.
  async function buildBuyOrderPsbt({
    sellPsbtBase64,
    fee,
    usedUtxo,
    unconfirmedFutureUtxo,
  }: BuildBuyOrderPsbt): Promise<{ buyTx: btc.Transaction; indicesToSign: number[] }> {
    if (!runesAddress) {
      throw new ManualError('Rune address not available')
    }
    if (!paymentAddress) {
      throw new ManualError('Payment address not available')
    }

    const orderPsbt = btc.Transaction.fromPSBT(base64.decode(sellPsbtBase64))
    let orderCost = 0n
    const orderInputs: psbt.TransactionInputUpdate[] = Array.from(
      Array(orderPsbt.inputsLength).keys()
    ).map((i) => orderPsbt.getInput(i))

    const orderOutputs: BtcSignerOutput[] = Array.from(Array(orderPsbt.outputsLength).keys()).map(
      (i) => {
        const output = orderPsbt.getOutput(i)
        if (!output || !output.amount || !output.script)
          throw new ManualError('Bad order psbt output')

        orderCost += output.amount

        return {
          amount: output.amount,
          script: output.script,
        }
      }
    )

    const paymentUtxos = await getCleanUtxos(paymentAddress)

    // Filter out outputs used in different transaction
    if (usedUtxo && usedUtxo.length > 0) {
      paymentUtxos.filter(
        (utxo) =>
          !usedUtxo.some(
            (x) => x === Buffer.from(utxo.txid!).toString('hex') + ':' + String(utxo.index!)
          )
      )

      if (unconfirmedFutureUtxo && unconfirmedFutureUtxo.length > 0) {
        paymentUtxos.push(...unconfirmedFutureUtxo)
      }
    }
    const runeReceiverValue = 546n
    const runeReceiverAddress = runesAddress.addrString

    const serviceFeeValue = calculateFeeFromBps(orderCost, settings.serviceFees.feeBps.order)
    const serviceFeeReceiverAddress = settings.serviceFees.receiverAddress

    const buyOutputs = [
      { amount: runeReceiverValue, address: runeReceiverAddress },
      ...orderOutputs,
      ...(serviceFeeValue !== 0n
        ? [{ amount: serviceFeeValue, address: serviceFeeReceiverAddress }]
        : []),
    ]

    const selected = btc.selectUTXO(paymentUtxos, buyOutputs, 'default', {
      requiredInputs: orderInputs,
      feePerByte: fee,
      changeAddress: paymentAddress.addrString,
      bip69: false, // Do not sort outputs
      network: btcSignerNetwork,
      createTx: false,
    })

    if (!selected) {
      throw new ManualError('Failed to find sufficient UTXO')
    }

    const paymentInputCount = selected.inputs.length - orderInputs.length
    if (paymentInputCount < 1) {
      throw new ManualError('Buy order tx construction failed; missing inputs')
    }

    const paymentInputs = selected.inputs.slice(0, paymentInputCount)

    const buyTx = new btc.Transaction()

    // add first payment input & buyer output to receive runes
    buyTx.addInput(paymentInputs[0])
    buyTx.addOutputAddress(runeReceiverAddress, runeReceiverValue, btcSignerNetwork)

    // merge all sale psbt input & output
    for (let i = 0; i < orderPsbt.inputsLength; i++) {
      buyTx.addInput(orderPsbt.getInput(i))
    }
    for (let i = 0; i < orderPsbt.outputsLength; i++) {
      buyTx.addOutput(orderPsbt.getOutput(i))
    }

    // add service fee output if non-zero
    if (serviceFeeValue > 0n) {
      buyTx.addOutputAddress(serviceFeeReceiverAddress, serviceFeeValue, btcSignerNetwork)
    }

    // add remaining payment utxo
    const indicesToSign = [0]
    for (let i = 1; i < paymentInputCount; i++) {
      indicesToSign.push(buyTx.addInput(paymentInputs[i]))
    }

    if (selected.change) {
      buyTx.addOutputAddress(
        paymentAddress.addrString,
        selected.outputs[selected.outputs.length - 1].amount,
        btcSignerNetwork
      )
    }

    return { buyTx, indicesToSign }
  }

  // Parses the Sell Order PSBT from aggregate markets,
  // constructs a btc.Transaction from the provided base64 data,
  // and extracts the list of resulting outpoints from this PSBT broadcast.
  async function buildBuyOrderPsbtAggregateOrder({
    sellPsbtBase64,
    inputsToSign,
  }: {
    sellPsbtBase64: string
    inputsToSign: number[]
  }): Promise<{
    buyTx: btc.Transaction
    usedUtxo: string[]
    unconfirmedOutputs: AddressTxsUtxo[]
  }> {
    if (!runesAddress) {
      throw new ManualError('Rune address not available')
    }
    if (!paymentAddress) {
      throw new ManualError('Payment address not available')
    }

    const orderPsbt = btc.Transaction.fromPSBT(base64.decode(sellPsbtBase64))
    const orderInputs: psbt.TransactionInputUpdate[] = Array.from(
      Array(orderPsbt.inputsLength).keys()
    ).map((i) => orderPsbt.getInput(i))
    const buyTx = new btc.Transaction({ allowUnknownOutputs: true })

    const usedUtxo: string[] = []
    for (let i = 0; i < orderPsbt.inputsLength; i++) {
      buyTx.addInput(orderInputs[i])
      const input = orderInputs[i]
      const outpointId = Buffer.from(input.txid!).toString('hex') + ':' + String(input.index!)
      if (inputsToSign.some((t) => t === i)) {
        usedUtxo.push(outpointId)
      }
    }

    const unconfirmedOutputs: AddressTxsUtxo[] = []
    let counter = 0
    for (let i = 0; i < orderPsbt.outputsLength; i++) {
      buyTx.addOutput(orderPsbt.getOutput(i))
      const output = orderPsbt.getOutput(i)

      const script = output.script
      const decoded = btc.OutScript.decode(script!)
      let address: string | undefined

      try {
        address = btc.Address(btc.NETWORK).encode(decoded)
      } catch (error) {
        continue
      }
      if (address === paymentAddress.addrString) {
        counter++
        // We only need second output. First output is always rune output.
        // Other output to this address are always change/possible to use in other transactions
        if (counter === 2) {
          unconfirmedOutputs.push({
            status: {
              // We don't care about values in those fields
              confirmed: false,
              block_hash: '',
              block_height: 1,
              block_time: 1,
            },
            txid: 'temporary',
            value: Number(output.amount!),
            vout: i,
          })
        }
      }
    }

    // Requered step to save proprietary field
    buyTx.setGlobalProp(orderPsbt)
    return { buyTx, usedUtxo, unconfirmedOutputs }
  }

  // Groups Bid orders by placed Address
  const _groupOrdersByPlacedAddress = (orders: BidOrderInfo[]): BidOrderInfo[] => {
    const result: Record<string, BidOrderInfo> = {}

    orders.forEach((item) => {
      if (result[item.escrowAddress]) {
        result[item.escrowAddress].amountSats += item.amountSats
        result[item.escrowAddress].amountRunes += item.amountRunes
      } else {
        result[item.escrowAddress] = { ...item }
      }
    })

    return Object.values(result)
  }

  // Groups Bid orders by placed Rune procced Address
  const _groupOrdersByRuneProccedAddress = (orders: BidOrderInfo[]): BidOrderInfo[] => {
    const result: Record<string, BidOrderInfo> = {}

    orders.forEach((item) => {
      if (result[item.proceedAddress]) {
        result[item.proceedAddress].amountSats += item.amountSats
        result[item.proceedAddress].amountRunes += item.amountRunes
      } else {
        result[item.proceedAddress] = { ...item }
      }
    })

    return Object.values(result)
  }

  // Constructs the Bid Order PSBT based on provided data.
  const _buildBidOrderPsbt = async ({
    orders,
    proceedsAddress,
    rune,
    fee,
  }: {
    fee: bigint
    proceedsAddress: string
    rune: Rune
    orders: BidOrderInfo[]
  }): Promise<{ buyTx: btc.Transaction; indicesToSign: number[]; transactionFee: bigint }> => {
    if (!runesAddress) {
      throw new ManualError('Rune address not available')
    }
    if (!paymentAddress) {
      throw new ManualError('Payment address not available')
    }

    const buyTx = new btc.Transaction({ allowUnknownOutputs: true })
    const runeReceiverValue = 546n
    const runeReceiverAddress = proceedsAddress
    const totalRuneAmount = orders.reduce((sum, el) => sum + el.amountRunes, 0n)
    const totalSatAmount = orders.reduce((sum, el) => sum + el.amountSats, 0n)
    // Get runeUtxos for bid order fulfilment
    const runeOutpoints = await getRuneTransactionInputs(runesAddress, rune.runeId, totalRuneAmount)
    const runeUTxosTotalAmount = runeOutpoints.reduce((sum, utxo) => sum + utxo.amount, 0n)

    const groupedByPlacedAddOrders: BidOrderInfo[] = _groupOrdersByPlacedAddress(orders)
    const groupedByRuneProccedOrders: BidOrderInfo[] = _groupOrdersByRuneProccedAddress(orders)

    // Generate rune outputs for Escrow Wallets
    const runeIdList: string[] = []
    const runeAmountList: bigint[] = []
    const runeIndexList: Uint32Array = new Uint32Array(groupedByRuneProccedOrders.length)
    let runeOutpointsCount = 0
    // Creates rune outpoints for Bid orders providers
    for (let i = 0; i < groupedByRuneProccedOrders.length; i++) {
      const orderInfo = groupedByRuneProccedOrders[i]
      buyTx.addOutputAddress(orderInfo.proceedAddress, runeReceiverValue, btcSignerNetwork)
      runeIdList.push(rune.runeId)
      runeAmountList.push(orderInfo.amountRunes)
      runeIndexList[i] = i
    }
    runeOutpointsCount += runeIndexList.length
    //Adding rune change output if needed
    if (runeUTxosTotalAmount > totalRuneAmount) {
      buyTx.addOutputAddress(runeReceiverAddress, runeReceiverValue, btcSignerNetwork)
      runeOutpointsCount++
    }
    // Generating Runestone based on rune inputs and rune outpoints
    const bidOrderRunestone = wasm.encode_simple_transfers(
      runeIdList,
      runeAmountList,
      runeIndexList,
      runeUTxosTotalAmount > totalRuneAmount ? groupedByRuneProccedOrders.length : 0
    )
    // Get escrow wallet BTC inputs
    const changeOutputs = []
    let escrowInputSum: bigint = 0n
    // Generate and adding BTC inputs from Escrow wallets
    for (let i = 0; i < groupedByPlacedAddOrders.length; i++) {
      const orderInfo = groupedByPlacedAddOrders[i]
      let paymentUtxos = await getCleanUtxosFromAddress(orderInfo.escrowAddress)
      const parsedAddr = btc.Address(btc.NETWORK).decode(orderInfo.escrowAddress)
      const escrowScript = btc.OutScript.encode(parsedAddr)
      // Some Filtering/optimiation
      if (paymentUtxos.some((utxo) => utxo.value >= orderInfo.amountSats)) {
        paymentUtxos = paymentUtxos
          .filter((utxo) => utxo.value >= orderInfo.amountSats)
          .sort((a, b) => a.value - b.value)
      }

      for (let j = 0; j < paymentUtxos.length; j++) {
        const paymentUtxo = paymentUtxos[j]
        escrowInputSum += BigInt(paymentUtxo.value)
        buyTx.addInput({
          txid: paymentUtxo.txid,
          index: paymentUtxo.vout,
          witnessUtxo: {
            amount: BigInt(paymentUtxo.value),
            script: escrowScript,
          },
        })
        //escrowInputCount++

        if (escrowInputSum >= orderInfo.amountSats) {
          break
        }
      }
      if (escrowInputSum < orderInfo.amountSats) {
        throw new ManualError('Bid order provider has insuficient anount of BTC')
      }
      // We can't give change with less then SATOSHI_DUST_THRESHOLD BTC
      if (escrowInputSum >= orderInfo.amountSats + SATOSHI_DUST_THRESHOLD) {
        changeOutputs.push({
          address: orderInfo.escrowAddress,
          amount: escrowInputSum - orderInfo.amountSats,
          network: btcSignerNetwork,
        })
      }
    }

    const serviceFeeValue = calculateFeeFromBps(totalSatAmount, settings.serviceFees.feeBps.order)
    const serviceFeeReceiverAddress = settings.serviceFees.receiverAddress
    // Add rune provider rune outpoints
    const indicesToSign = []
    for (let i = 0; i < runeOutpoints.length; i++) {
      const runeUtxo = runeOutpoints[i]
      indicesToSign.push(buyTx.inputsLength)
      buyTx.addInput(utxoToInput(runesAddress.p2, runeUtxo.utxo))
    }

    // This is reward output with temporary 0n BTC
    buyTx.addOutputAddress(proceedsAddress, 0n, btcSignerNetwork)
    // Remembering reward output index
    const rewardOutputIndex = buyTx.outputsLength - 1
    // Adding Bid order runestone
    buyTx.addOutput({ script: bidOrderRunestone, amount: 0n })
    if (serviceFeeValue > 0) {
      buyTx.addOutputAddress(serviceFeeReceiverAddress, serviceFeeValue, btcSignerNetwork)
    }

    // Add Escrow Wallet change
    if (changeOutputs.length > 0) {
      changeOutputs.forEach((output) =>
        buyTx.addOutputAddress(output.address, output.amount, output.network)
      )
    }

    const transactionFee = BigInt(buyTx.estimatedP2TRVsize + 10 * runeOutpoints.length) * fee

    // Updates the reward output to include the correct reward amount,
    // since the transaction fee and service fee are deducted from it.
    buyTx.updateOutput(rewardOutputIndex, {
      amount:
        totalSatAmount -
        serviceFeeValue -
        BigInt(runeOutpointsCount) * SATOSHI_DUST_THRESHOLD +
        BigInt(runeOutpoints.length) * SATOSHI_DUST_THRESHOLD -
        transactionFee,
    })
    return { buyTx, indicesToSign, transactionFee }
  }

  // Complex function that generates a Sell Order PSBT based on @validOrders,
  // requests their signing, and attempts to broadcast them using the site backend.
  const _submitBuyRunes = async ({
    rune,
    validOrders,
    fee,
    onStatusChange,
  }: SubmitBuyRunesParams): Promise<SignTransactionResult[] | BuySellOrderRequestResult> => {
    onStatusChange('build')

    if (!runesAddress) {
      throw new ManualError('Rune address not available')
    }

    if (!paymentAddress) {
      throw new ManualError('Payment address not available')
    }

    const usedUtxo: string[] = []
    const unconfirmedFutureUtxo: AddressTxsUtxo[] = []
    let providerName: string = ''
    const psbtPayload: { inputsToSign: InputToSign[]; psbtBase64: string }[] = []
    // Iterates over the provided @validOrders list and generates an aggregate market PSBT.
    // It should include orders from at most one provider, as ensured by the runeSwapper algorithm.
    for (let index = 0; index < validOrders.length; index++) {
      const provider = validOrders[index].provider as AggregateProviders
      const buyRequestResponse = validOrders[index]
      if (!buyRequestResponse.psbt) {
        throw new ManualError('Failed to create buy request')
      }
      switch (provider) {
        case 'Mystic': {
          break
        }
        case 'Unisat':
        case 'Okx':
        case 'MagicEden': {
          const {
            buyTx,
            usedUtxo: usedAggregateUtxo,
            unconfirmedOutputs,
          } = await buildBuyOrderPsbtAggregateOrder({
            sellPsbtBase64: buyRequestResponse.psbt!.dataBase64,
            inputsToSign: buyRequestResponse.psbt!.inputsToSign[0].signingIndexes,
          })
          psbtPayload.push({
            inputsToSign: buyRequestResponse.psbt?.inputsToSign.map((t) => {
              return {
                address: t.address,
                sigHash: t.sigHash,
                signingIndexes: t.signingIndexes.map((x) => Number(x)),
              }
            }),
            psbtBase64: base64.encode(buyTx.toPSBT()),
          })
          usedUtxo.push(...usedAggregateUtxo)
          unconfirmedFutureUtxo.push(...unconfirmedOutputs)
          // We can savely save it like this, because,
          // Right now we are guaranteed to only have single unique aggregate market provider
          providerName = provider
          break
        }
        default:
          throw new ManualError('Invalid orders')
      }
    }

    let buyMysticRequestResponse = undefined
    let needSecondSigning = false
    // Mystic orders have a separate function for PSBT creation.
    if (validOrders.some((o) => o.provider === 'Mystic')) {
      buyMysticRequestResponse = validOrders.find((o) => o.provider === 'Mystic')
      // First, we try to create the PSBT without unconfirmed rune outpoints from the aggregate market transaction.
      try {
        const { buyTx, indicesToSign } = await buildBuyOrderPsbt({
          sellPsbtBase64: buyMysticRequestResponse!.psbt!.dataBase64,
          fee,
          usedUtxo,
        })
        // we are not including a runestone
        const firstOutput = buyTx.getOutput(0)
        if (!firstOutput.script || !uint8ArrayEquals(firstOutput.script, runesAddress.p2.script)) {
          throw new ManualError('Rune receiver output script mismatch')
        }

        psbtPayload.push({
          inputsToSign: [
            {
              address: runesAddress.addrString,
              signingIndexes: indicesToSign,
              sigHash: btc.SigHash.ALL,
            },
          ],
          psbtBase64: base64.encode(buyTx.toPSBT()),
        })
      } catch (e) {
        // If @validOrders contain only Mystic contracts at this stage, we throw error.
        if (providerName == '') {
          throw e
        }

        // Otherwise, we check that adding unconfirmed outputs from aggregate markets transaction is enough to generate a valid PSBT.

        const requeredSatAmount = validOrders
          .find((t) => t.provider === 'Mystic')!
          .validOrders.reduce((sum, item) => sum + item.satsAmount, 0n)
        const paymentUtxos = (await getCleanUtxosFromAddress(paymentAddress.addrString)).filter(
          (utxo) => !usedUtxo.some((x) => x === utxo.txid + ':' + utxo.vout)
        )
        const availableSatAmount =
          paymentUtxos.reduce((sum, item) => sum + BigInt(item.value), 0n) +
          unconfirmedFutureUtxo.reduce((sum, item) => sum + BigInt(item.value), 0n)

        if (availableSatAmount < requeredSatAmount) {
          throw new ManualError('Failed to find sufficient UTXO')
        }

        const serviceFeeValue = calculateFeeFromBps(
          requeredSatAmount,
          settings.serviceFees.feeBps.order
        )
        const networkFee =
          Number(fee) *
          1.1 * // As this is estimations, it is better to be sure
          (TRX_VIRTUAL_SIZE_BYTES.buy.each +
            TRX_VIRTUAL_SIZE_BYTES.buy.initial +
            TRX_VIRTUAL_SIZE_BYTES.sell +
            (serviceFeeValue > 0 ? TRX_VIRTUAL_SIZE_BYTES.buy.serviceFee : 0))

        if (availableSatAmount + BigInt(networkFee) < requeredSatAmount) {
          throw new ManualError('Failed to find sufficient UTXO')
        }

        // All good, user have enough btc to complete Mystic orders using unconfirmed outputs
        needSecondSigning = true
      }
    }

    onStatusChange('wallet-prompt')

    const complexResult: SignMultiTransactionResult[] = []
    // We can sign in one step when there are orders from a single provider.
    if (!needSecondSigning) {
      const result = await _signMultipleTransactions({
        message: 'Sign to buy Runes',
        network: {
          type: network,
        },
        psbts: psbtPayload,
      })

      if (!result.success || !result.signedTransactionResults) {
        throw new ManualError(result.error)
      }

      onStatusChange('api-submit')

      // providerName is non-empty only in the case of aggregate market orders.
      if (providerName !== '') {
        // Unisat and Okx require bid IDs.
        const bidIds = validOrders
          .filter((t) => (t.provider === 'Unisat' || t.provider === 'Okx') && t.bidId != undefined)
          .map((o) => o.bidId!)

        const buyAggregateSubmission: BuySellOrderSubmission = {
          runeId: rune.runeId,
          provider: providerName,
          bidIds,
          address: paymentAddress.addrString,
          psbt: {
            dataBase64: result.signedTransactionResults[0].signedPsbtBase64!,
          },
          orders: validOrders.find((t) => t.provider === providerName)!.validOrders,
        }

        const buyAggregateSubmissionResponse = await apiFetch<BuySellOrderSubmissionResult>(
          replaceUrlParams(API_ENDPOINTS.POST.runes.orders.submitBuy, {
            runeName: rune.runeName,
          }),
          {},
          {
            body: buyAggregateSubmission,
            method: 'POST',
          }
        )

        if (buyAggregateSubmissionResponse.allOrdersFilled) {
          complexResult.push({
            success: true,
            signedPsbtBase64: (result.signedTransactionResults[0] as SignTransactionResult)
              .signedPsbtBase64,
            txId: buyAggregateSubmissionResponse.txId,
            provider: providerName,
          })
        } else if (buyAggregateSubmissionResponse.retry) {
          return buyAggregateSubmissionResponse.retry
        } else if (buyAggregateSubmissionResponse.error) {
          return [{ ...result, success: false, error: buyAggregateSubmissionResponse.error }]
        } else {
          return [{ ...result, success: false, error: 'Unable to fill orders' }]
        }
      }

      if (buyMysticRequestResponse) {
        const buySubmission: BuySellOrderSubmission = {
          runeId: rune.runeId,
          provider: buyMysticRequestResponse.provider,
          address: runesAddress.addrString,
          psbt: {
            dataBase64: result.signedTransactionResults[1].signedPsbtBase64!,
          },
          orders: buyMysticRequestResponse.validOrders,
        }
        const buySubmissionResponse = await apiFetch<BuySellOrderSubmissionResult>(
          replaceUrlParams(API_ENDPOINTS.POST.runes.orders.submitBuy, {
            runeName: rune.runeName,
          }),
          {},
          {
            body: buySubmission,
            method: 'POST',
          }
        )
        if (buySubmissionResponse.allOrdersFilled) {
          complexResult.push({
            success: true,
            signedPsbtBase64: (result.signedTransactionResults[1] as SignTransactionResult)
              .signedPsbtBase64,
            txId: buySubmissionResponse.txId,
            provider: buyMysticRequestResponse.provider,
          })
        } else if (buySubmissionResponse.retry) {
          return buySubmissionResponse.retry
        } else if (buySubmissionResponse.error) {
          return [{ ...result, success: false, error: buySubmissionResponse.error }]
        }
      }
    } else {
      // If we have a combination of aggregate market orders and Mystic orders,
      // we need to sign the aggregate market orders first and, after a delay, sign the Mystic orders PSBT.
      const resultAggregate = await _signTransaction({
        broadcast: false,
        inputsToSign: [
          {
            address: paymentAddress.addrString,
            signingIndexes: psbtPayload[0].inputsToSign[0].signingIndexes,
          },
        ],
        message: 'Sign to buy Runes',
        network: {
          type: network,
        },
        psbtBase64: psbtPayload[0].psbtBase64,
      })

      if (!resultAggregate.success) {
        throw new ManualError(resultAggregate.error)
      }
      onStatusChange('api-submit')

      const bidIds = validOrders
        .filter((t) => (t.provider === 'Unisat' || t.provider === 'Okx') && t.bidId != undefined)
        .map((o) => o.bidId!)

      const buyAggregateSubmission: BuySellOrderSubmission = {
        runeId: rune.runeId,
        provider: providerName,
        bidIds,
        address: runesAddress.addrString,
        psbt: {
          dataBase64: resultAggregate.signedPsbtBase64!,
        },
        orders: validOrders.find((t) => t.provider === providerName)!.validOrders,
      }

      const buyAggregateSubmissionResponse = await apiFetch<BuySellOrderSubmissionResult>(
        replaceUrlParams(API_ENDPOINTS.POST.runes.orders.submitBuy, {
          runeName: rune.runeName,
        }),
        {},
        {
          body: buyAggregateSubmission,
          method: 'POST',
        }
      )

      if (buyAggregateSubmissionResponse.allOrdersFilled) {
        complexResult.push({
          success: true,
          signedPsbtBase64: (resultAggregate as SignTransactionResult).signedPsbtBase64,
          txId: buyAggregateSubmissionResponse.txId,
          provider: providerName,
        })
      } else if (buyAggregateSubmissionResponse.retry) {
        return buyAggregateSubmissionResponse.retry
      } else if (buyAggregateSubmissionResponse.error) {
        return [{ ...resultAggregate, success: false, error: buyAggregateSubmissionResponse.error }]
      }

      // Now, we attempt to sign and broadcast the transaction for Mystic Orders.
      buyMysticRequestResponse = validOrders.find((o) => o.provider === 'Mystic')
      // Only after broadcasting the aggregate market transaction can we deduce outpointIds for @unconfirmedFutureUtxo.
      unconfirmedFutureUtxo.forEach((t) => (t.txid = buyAggregateSubmissionResponse.txId!))
      const { buyTx, indicesToSign } = await buildBuyOrderPsbt({
        sellPsbtBase64: buyMysticRequestResponse!.psbt!.dataBase64,
        fee,
        usedUtxo,
        unconfirmedFutureUtxo,
      })
      // we are not including a runestone
      const firstOutput = buyTx.getOutput(0)
      if (!firstOutput.script || !uint8ArrayEquals(firstOutput.script, runesAddress.p2.script)) {
        throw new ManualError('Rune receiver output script mismatch')
      }
      const resultMystic = await _signTransaction({
        broadcast: false,
        message: 'Sign to buy Runes',
        network: {
          type: network,
        },
        inputsToSign: [
          {
            address: runesAddress.addrString,
            signingIndexes: indicesToSign,
            sigHash: btc.SigHash.ALL,
          },
        ],
        psbtBase64: base64.encode(buyTx.toPSBT()),
      })

      if (!resultMystic.success) {
        throw new ManualError(resultMystic.error)
      }

      const buySubmission: BuySellOrderSubmission = {
        runeId: rune.runeId,
        provider: buyMysticRequestResponse!.provider,
        address: runesAddress.addrString,
        psbt: {
          dataBase64: resultMystic.signedPsbtBase64!,
        },
        orders: buyMysticRequestResponse!.validOrders,
      }
      const buySubmissionResponse = await apiFetch<BuySellOrderSubmissionResult>(
        replaceUrlParams(API_ENDPOINTS.POST.runes.orders.submitBuy, {
          runeName: rune.runeName,
        }),
        {},
        {
          body: buySubmission,
          method: 'POST',
        }
      )
      if (buySubmissionResponse.allOrdersFilled) {
        complexResult.push({
          success: true,
          signedPsbtBase64: (resultMystic as SignTransactionResult).signedPsbtBase64,
          txId: buySubmissionResponse.txId,
          provider: buyMysticRequestResponse!.provider,
        })
      } else if (buySubmissionResponse.retry) {
        return buySubmissionResponse.retry
      } else if (buySubmissionResponse.error) {
        return [{ ...resultMystic, success: false, error: buySubmissionResponse.error }]
      }
    }
    return complexResult
  }

  // Legacy function
  const _commitEtchRune = async ({
    etchParams,
    fee,
  }: {
    etchParams: JsEtchingParams
    fee: bigint
  }): Promise<CommitEtchRuneResult> => {
    if (!paymentAddress || !runesAddress) {
      throw new ManualError('Payment or Rune address not available')
    }

    const etchingCommit = await buildEtchingCommit({
      paymentAddress: paymentAddress,
      recipientAddress: runesAddress,
      network: btcSignerNetwork,
      fee,
      params: etchParams,
    })

    if (!etchingCommit.commitTx) {
      throw new ManualError('Failed to create commit transaction')
    }

    const indicesToSign: number[] = []
    for (let i = 0; i < etchingCommit.commitTx.inputsLength; i++) {
      indicesToSign.push(i)
    }

    const result = await _signTransaction({
      broadcast: true,
      inputsToSign: [{ address: paymentAddress.addrString, signingIndexes: indicesToSign }],
      network: {
        type: network,
      },
      message: `Sign to commit ${etchParams.rune} Etching`,
      psbtBase64: base64.encode(etchingCommit.commitTx.toPSBT()),
    })

    if (!result.success || !result.signedPsbtBase64) {
      throw new ManualError(`Failed to commit ${etchParams.rune} Etching`)
    }

    // console.log('psbt', result.signedPsbtBase64)
    // console.log('txid', result.txId)

    const tx = btc.Transaction.fromPSBT(base64.decode(result.signedPsbtBase64))

    return {
      commitTxResult: result,
      revealAmount: etchingCommit.revealAmount,
      txId: result.txId!,
      revealScript: etchingCommit.revealScript,
      revealKey: etchingCommit.revealKey,
      commitTx: tx,
    }
  }

  // Legacy function
  const _revealEtchRune = async ({
    etchParams,
    etchingCommit,
    fee,
  }: {
    etchParams: JsEtchingParams
    etchingCommit: EtchingCommit
    fee: bigint
  }): Promise<SignTransactionResult> => {
    if (!paymentAddress || !runesAddress) {
      throw new ManualError('Payment or Rune address not available')
    }

    const etchingReveal = await buildEtchingReveal({
      paymentAddress: paymentAddress,
      recipientAddress: runesAddress,
      network: btcSignerNetwork,
      fee,
      params: etchParams,
      etchingCommit: etchingCommit,
    })

    if (!etchingReveal.revealTx) {
      throw new ManualError('Failed to create reveal transaction')
    }

    const indicesToSign: number[] = []
    for (let i = 0; i < etchingReveal.revealTx.inputsLength; i++) {
      indicesToSign.push(i)
    }

    const result = await _signTransaction({
      broadcast: true,
      inputsToSign: [{ address: runesAddress.addrString, signingIndexes: indicesToSign }],
      network: {
        type: network,
      },
      message: `Sign to reveal ${etchParams.rune} Etching`,
      psbtBase64: base64.encode(etchingReveal.revealTx.toPSBT()),
    })

    if (!result.success) {
      throw new ManualError(`Failed to reveal ${etchParams.rune} Etching`)
    }

    // console.log('psbt', result.signedPsbtBase64)
    // console.log('txid', result.txId)

    return result
  }

  // const _signMultipleTransactions = async (
  //   payload: SignMultipleTransactionsPayload
  // ): Promise<SignMultipleTransactionsResult> => {
  //   return new Promise((resolve) => {
  //     signMultipleTransactions({
  //       getProvider: async () => provider,
  //       payload,
  //       onFinish: (response) => {
  //         resolve({
  //           success: true,
  //           signedTransactionResults: response.map((result) => ({
  //             success: true,
  //             signedPsbtBase64: result.psbtBase64,
  //             txId: result.txId,
  //           })),
  //         })
  //       },
  //       onCancel: () =>
  //         resolve({
  //           success: false,
  //           error: 'Canceled',
  //         }),
  //     }).catch((e) => {
  //       resolve({
  //         success: false,
  //         error: e.message,
  //       })
  //     })
  //   })
  // }

  // Legacy function
  const sendBitcoin = async (recipients: Recipient[]): Promise<SendBitcoinResult> => {
    return new Promise((resolve) => {
      sendBtcTransaction({
        getProvider: async () => provider,
        payload: {
          network: {
            type: network,
          },
          recipients,
          senderAddress: paymentAddress!.addrString,
        },
        onFinish: (response) => {
          resolve({
            success: true,
            txId: response,
          })
        },
        onCancel: () =>
          resolve({
            success: false,
            error: 'Canceled',
          }),
      }).catch((e) => {
        resolve({
          success: false,
          error: e.message,
        })
      })
    })
  }

  //  Estimates escrow transfer PSBT vsize for estimating transaction fee
  const _estimateBtcTransferVsize = async ({
    transferAmount,
    fee,
  }: {
    transferAmount: bigint
    fee: bigint
  }) => {
    if (!paymentAddress) {
      throw new ManualError('Payment address not available')
    }

    const { tx: btcTransferTx } = await createBtcTransferTx({
      paymentAddress,
      recipientAddress: paymentAddress.addrString, // not needed for estimate
      transferAmount,
      network: btcSignerNetwork,
      fee,
    })

    // console.log('tx', btcTransferTx)

    return btcTransferTx.estimatedVsize
  }

  // Creates escrow transfer PSBT, requests PSBT signing and broadcasts it to blockchain via frontend
  const _transferBtc = async ({
    recipientAddress,
    transferAmount,
    fee,
    onStatusChange,
  }: {
    recipientAddress: string
    transferAmount: bigint
    fee: bigint
    onStatusChange(status: TransferBtcTransactionStatus): void
  }): Promise<SignTransactionResult> => {
    if (!paymentAddress) {
      throw new ManualError('Payment address not available')
    }

    onStatusChange('build')
    const { tx: btcTransferTx } = await createBtcTransferTx({
      paymentAddress,
      recipientAddress,
      transferAmount,
      network: btcSignerNetwork,
      fee,
    })

    if (!btcTransferTx) {
      throw new ManualError('Failed to create transaction')
    }

    onStatusChange('wallet-prompt')

    const indicesToSign: number[] = []
    for (let i = 0; i < btcTransferTx.inputsLength; i++) {
      indicesToSign.push(i)
    }

    const result = await _signTransaction({
      broadcast: true,
      network: { type: network },
      message: `Transfer ${transferAmount.toLocaleString()} BTC`,
      psbtBase64: base64.encode(btcTransferTx.toPSBT()),
      inputsToSign: [{ address: paymentAddress.addrString, signingIndexes: indicesToSign }],
    })

    if (!result.success || !result.signedPsbtBase64) {
      throw new ManualError(result.error)
    }

    if (!result.txId && walletName === WALLET_NAME.magicEden) {
      result.txId = await finalizeAndBroadcastTx(result.signedPsbtBase64)
    }

    return result
  }

  return (
    <WalletContext.Provider
      value={{
        settings,
        btcBalances,
        btcPrice,
        recommendedNetworkFees,
        blockTip,
        paymentAddress,
        ordinalsAddress,
        runesAddress,
        walletName: walletName,
        supportsMultiSignature: !!SUPPORT_MULTI_SIGN.find(
          (supportedWallet) => supportedWallet === walletName
        ),
        isConnected,
        network,
        capabilities,
        capabilityState,
        isReady,
        toggleNetwork,
        connectWallet,
        disconnectWallet,
        signMessage: _signMessage,
        signTransaction: _signTransaction,
        signMultipleTransactions: _signMultipleTransactions,
        sendBitcoin,
        commitEtchRune: _commitEtchRune,
        revealEtchRune: _revealEtchRune,
        mintRune: _mintRune,
        mintBulkRune: _mintBulkRune,
        estimateMintBulkRuneVsize: _estimateMintBulkRuneVsize,
        transferRunes: _transferRunes,
        estimateTransferRunesVsize: _estimateTransferRunesVsize,
        getRunesOutpointsForSale: _getRunesOutpointsForSale,
        sellRunesUnboxed: _sellRunesUnboxed,
        sellRunes: _sellRunes,
        cancelSellRunes: _cancelSellRunes,
        requestBuyRunes: _requestBuyRunes,
        submitBuyRunes: _submitBuyRunes,
        groupOrdersByPlacedAddress: _groupOrdersByPlacedAddress,
        groupOrdersByRuneProccedAddress: _groupOrdersByRuneProccedAddress,
        buildBidOrderPsbt: _buildBidOrderPsbt,
        placeBidOrder: _placeBidOrder,
        requestBuyBidOrder: _requestBuyBidOrder,
        cancelPlaceBidOrder: _cancelPlaceBidOrder,
        submitBuyBidOrder: _submitBuyBidOrder,

        estimateBtcTransferVsize: _estimateBtcTransferVsize,
        transferBtc: _transferBtc,

        btcSignerNetwork,
      }}
    >
      <IncorrectAccountPopup
        isOpen={showIncorrectAccountPopup}
        onClose={() => setShowIncorrectAccountPopup(false)}
      />
      {children}
    </WalletContext.Provider>
  )
}

import { useParams } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import { styled } from 'styled-components'

import { RuneTools } from 'src/runes/components/RuneTools'
import { useIsMobile } from 'src/shared/hooks'
import { RuneDetailsPageContent } from 'src/runes'
import { MintSearch } from 'src/minting'
import { BREAKPOINTS } from 'src/shared/constants'

import { PageContainer } from './PageContainer'

/** @deprecated All mint functionality is available on the market page */
export function RuneDetailsPage() {
  const { runeName } = useParams()
  const isMobile = useIsMobile()

  if (typeof runeName == 'undefined') {
    return <PageContainer>Invalid Rune Name: {runeName}</PageContainer>
  }

  return (
    <PageContainer>
      <MintSearchContainer>
        <MintSearch linkType='runeDetail' />
      </MintSearchContainer>
      <Grid container spacing={3}>
        <Grid order={isMobile ? 1 : 0} item md={7} xs={12}>
          <RuneDetailsPageContent runeName={runeName} />
        </Grid>
        <Grid order={isMobile ? 0 : 1} item md={5} xs={12}>
          <RuneTools />
        </Grid>
      </Grid>
    </PageContainer>
  )
}

const MintSearchContainer = styled.div`
  padding-bottom: 20px;

  @media (max-width: ${BREAKPOINTS.medium}) {
    padding-bottom: 0;
  }
`

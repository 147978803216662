import { styled } from 'styled-components'

import { RuneDetails } from '@packages/interfaces'

import { VirtualizedTable } from 'src/shared/components'
import { SearchInput } from 'src/shared/components/inputs/SearchInput'
import { RuneDetailsLink } from 'src/runes'
import { MintedPercentBadge } from 'src/minting'
import { BREAKPOINTS } from 'src/shared/constants'
import { MarketDetailsLink } from 'src/markets'

import { useMintsMarketSearch } from '../hooks'

interface Props {
  linkType: 'runeDetail' | 'marketDetail'
}

const searchParams = {
  orderBy: 'holderCount',
} as const

export function MintSearch({ linkType }: Props) {
  const {
    handleOnChange,
    loading,
    hasSearched,
    searchResults,
    searchQuery,
    fetchPage,
    hasNextPage,
  } = useMintsMarketSearch(searchParams)

  const onLinkClick = () => {
    handleOnChange('')
  }

  return (
    <Container>
      <SearchInput value={searchQuery} onChange={handleOnChange} />
      {/* {loading && searchQuery && <DotsLoader />} */}
      {hasSearched && searchQuery && (
        <TableSection>
          <VirtualizedTableWrapper
            columns={[
              {
                dataKey: 'runeName',
                label: 'Token',
                width: 50,
                formatter: ({ data: mint }) => {
                  return (
                    <div onClick={onLinkClick}>
                      {linkType === 'runeDetail' ? (
                        <RuneDetailsLink rune={mint} swapToolTab={'mint'} />
                      ) : (
                        <MarketDetailsLink rune={mint} />
                      )}
                    </div>
                  )
                },
              },
              {
                dataKey: 'mintedPercent',
                label: 'Percent Minted',
                width: 25,
                hideOnMobile: true,
                disableSort: true,
                formatter: ({ data: mint }) => <MintedPercentBadge runeDetails={mint} />,
              },
              {
                dataKey: 'holderCount',
                label: 'Holder Count',
                width: 25,
              },
            ]}
            defaultSortBy='desc'
            defaultSort='holderCount'
            paginatedData={searchResults}
            fetchPage={fetchPage}
            loading={loading}
            hasNextPage={hasNextPage}
            emptyDataMessage='No results found.'
            rowHeight={30}
            viewableRows={5}
            useWindowScroll={false}
          />
        </TableSection>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  gap: 15px;
  flex-direction: column;
  align-items: center;
`

const TableSection = styled.div`
  width: 100%;
`

const VirtualizedTableWrapper = styled(VirtualizedTable<RuneDetails>)`
  height: 300px;

  @media (max-width: ${BREAKPOINTS.medium}) {
    height: 300px;
  }

  th {
    padding: 6px 0 !important;
  }
  td {
    padding: 20px 0 5px 0 !important;
  }
`

import { styled } from 'styled-components'
import { useCallback } from 'react'

import { DrawerCard, LargeButtonGroup } from 'src/shared/components'
import { COLORS } from 'src/shared/constants'

import { SWAP_TOOL_TABS } from './constants'

import type { SwapToolMode } from './types'

type SwapToolModeSelectorProps = {
  selectedMode: SwapToolMode
  onChange: (selectedMode: SwapToolMode) => void
}

export function SwapToolModeSelector({ selectedMode, onChange }: SwapToolModeSelectorProps) {
  const handleChange = useCallback(
    (tabValue: string) => {
      onChange(tabValue as SwapToolMode)
    },
    [onChange]
  )

  return (
    <Container>
      <ModeSelector
        options={SWAP_TOOL_TABS}
        value={selectedMode}
        onChange={handleChange}
        updateSearchParams={false}
        exclusive={true}
      />
    </Container>
  )
}

const Container = styled(DrawerCard)`
  display: flex;
  border-radius: 14px;
  padding: 15px;
`

const ModeSelector = styled(LargeButtonGroup)`
  gap: 10px;

  button {
    flex: 1 1 auto;
    border: 1px solid ${COLORS.buttons.primary};
    background-color: transparent;
    color: ${COLORS.labels.primary};

    padding: 8px 15px;
    border-radius: 12px !important;

    &.MuiToggleButtonGroup-lastButton {
      border-left: 1px solid ${COLORS.buttons.primary};
    }
  }
`

import { useSearchParams } from 'react-router-dom'

import { RUNE_TOOLS_TAB_SEARCH_PARAM_NAME } from 'src/pages/constants/routes'

import { RuneToolsTab } from './types'
import { DEFAULT_TAB } from './constants'

export function useRuneToolsState() {
  const [searchParams] = useSearchParams()

  const currentTool = searchParams.get(RUNE_TOOLS_TAB_SEARCH_PARAM_NAME) as RuneToolsTab

  return {
    currentRuneTool: currentTool,
    fallbackRuneTool: DEFAULT_TAB,
  }
}

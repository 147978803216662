import { useMemo } from 'react'

import { Order, OrderStatus } from '@packages/interfaces'
import { API_ENDPOINTS } from '@packages/constants'

import { useUpdateOnBlockPaginationApi } from 'src/api'
import { replaceUrlParams } from 'src/shared/utils'

export function useOrdersByRuneForAccountApi<T extends Order>({
  address,
  runeId,
  status,
  disabled,
  type,
  otherQueryParams,
}: {
  runeId: string | undefined
  address: string
  status: OrderStatus | 'Any'
  disabled?: boolean
  type: 'sell' | 'bid'
  otherQueryParams?: { [key: string]: any }
}) {
  const requestOptions = useMemo(() => {
    let endpoint: string = runeId
      ? `${replaceUrlParams(API_ENDPOINTS.GET.runes.orders.byRuneForAccount, {
          address,
          runeId,
        })}?status=${status}`
      : `${replaceUrlParams(API_ENDPOINTS.GET.runes.orders.byAccount, {
          address,
        })}?status=${status}`

    if (type === 'bid') {
      endpoint = runeId
        ? `${replaceUrlParams(API_ENDPOINTS.GET.runes.bidOrders.byRuneForAccount, {
            address,
            runeId,
          })}?status=${status}`
        : `${replaceUrlParams(API_ENDPOINTS.GET.runes.bidOrders.byAccount, {
            address,
          })}?status=${status}`
    }

    return {
      endpoint: endpoint,
      disabled,
      otherQueryParams,
    }
  }, [address, disabled, runeId, status, type, otherQueryParams])

  const result = useUpdateOnBlockPaginationApi<T>(requestOptions)

  return result
}

import { useMemo } from 'react'

import type { MintDetails } from 'src/minting/interfaces'
import { DISABLE_WRITES } from 'src/settings'

export type MintButtonState = {
  disabled: boolean
  disabledReason: 'preminted' | 'minted-out' | 'mint-over' | 'mint-not-started' | null
  text: string
  infoText: string[]
}

const DEFAULT_MINT_BUTTON_STATE: MintButtonState = {
  disabled: true,
  disabledReason: null,
  text: 'Mint',
  infoText: [],
}

export function useMintButtonState(mintDetails: MintDetails | undefined): MintButtonState {
  const state = useMemo<MintButtonState>(() => {
    if (!mintDetails) {
      return DEFAULT_MINT_BUTTON_STATE
    }

    const {
      isAllPremined,
      isMintedOut,
      isMintable,
      mintEnded,
      mintStarted,
      startsInBlocks,
      mintedPercent,
      mintsRemaining,
      endsInBlocks,
    } = mintDetails ?? {}

    let disabled = DEFAULT_MINT_BUTTON_STATE.disabled
    let disabledReason: MintButtonState['disabledReason'] = null
    let text = DEFAULT_MINT_BUTTON_STATE.text
    let infoText = DEFAULT_MINT_BUTTON_STATE.infoText

    if (isAllPremined) {
      disabled = true
      disabledReason = 'preminted'
      text = 'Minted Out'
    } else if (isMintable) {
      if (isMintedOut) {
        disabled = true
        disabledReason = 'minted-out'
        text = 'Minted Out'
      } else if (mintEnded) {
        disabled = true
        disabledReason = 'mint-over'
        text = 'Mint Over'
      } else if (!mintStarted) {
        disabled = true
        disabledReason = 'mint-over'
        text = 'Mint Not Started'
        infoText = [`Mint starts in ${startsInBlocks} block${startsInBlocks > 1 ? 's' : ''}`]
      } else if (mintStarted && !mintEnded) {
        infoText = []
        if (mintedPercent >= 85) {
          infoText.push(`${mintsRemaining.toLocaleString()} Mints Remaining`)
        }
        if (endsInBlocks <= 10 && endsInBlocks > 0) {
          infoText.push(`Mint ends in ${endsInBlocks} block${endsInBlocks > 1 ? 's' : ''}`)
        }

        disabled = false
        text = 'Confirm Mint'
      }
    }

    if (DISABLE_WRITES) {
      disabled = true
    }

    return {
      disabled: disabled,
      disabledReason,
      text: text,
      infoText: infoText,
    }
  }, [mintDetails])

  return state
}

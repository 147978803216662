import { styled } from 'styled-components'
import { useState } from 'react'

import { RuneDetails } from '@packages/interfaces'

import { Button } from 'src/shared/components'
import { DISABLE_WRITES } from 'src/settings'
import { WalletPickerMenu, useWalletContext } from 'src/wallet'

import { MintRunesDrawer } from './MintRunes/MintRunesDrawer'
import { useMintDetails } from '../hooks'

interface Props {
  runeDetails: RuneDetails
}

export function MintingInfo({ runeDetails }: Props) {
  const [isWalletDrawerOpen, setIsWalletDrawerOpen] = useState(false)
  const { isConnected } = useWalletContext()
  const [isMintDrawerOpen, setIsMintDrawerOpen] = useState(false)
  const mintDetails = useMintDetails({ runeDetails })

  function openMintDrawer() {
    setIsMintDrawerOpen(true)
  }

  function closeMintDrawer() {
    setIsMintDrawerOpen(false)
  }

  if (!mintDetails) return

  const {
    mintStarted,
    mintEnded,
    isMintedOut,
    isMintable,
    isAllPremined,
    startsInBlocks,
    endsInBlocks,
  } = mintDetails

  let mintSection
  if (isAllPremined) {
    mintSection = <MintButton disabled>Premined</MintButton>
  } else if (isMintable) {
    if (isMintedOut) {
      mintSection = <MintButton disabled>Minted Out</MintButton>
    } else if (mintEnded) {
      mintSection = <MintButton disabled>Mint Over</MintButton>
    } else if (!mintStarted) {
      mintSection = (
        <>
          <MintInfo>
            Mint starts in {startsInBlocks} block{startsInBlocks > 1 ? 's' : ''}
          </MintInfo>
          <MintButton disabled>Mint Not Started</MintButton>
        </>
      )
    } else if (mintStarted && !mintEnded) {
      mintSection = (
        <>
          {mintDetails.mintedPercent >= 85 && (
            <MintInfo>{mintDetails.mintsRemaining.toLocaleString()} Mints Remaining</MintInfo>
          )}
          {endsInBlocks <= 10 && endsInBlocks > 0 && (
            <MintInfo>
              Mint ends in {endsInBlocks} block{endsInBlocks > 1 ? 's' : ''}
            </MintInfo>
          )}

          {isConnected ? (
            <MintButton onClick={openMintDrawer} disabled={DISABLE_WRITES}>
              Mint
            </MintButton>
          ) : (
            <MintButton onClick={() => setIsWalletDrawerOpen(true)}>Connect to Mint</MintButton>
          )}
        </>
      )
    }
  }

  return (
    <>
      {mintSection}
      <MintRunesDrawer
        isOpen={isMintDrawerOpen}
        onClose={closeMintDrawer}
        runeDetails={runeDetails}
      />
      <WalletPickerMenu isOpen={isWalletDrawerOpen} onClose={() => setIsWalletDrawerOpen(false)} />
    </>
  )
}

const MintButton = styled(Button)``

const MintInfo = styled.div`
  text-align: center;
`

import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

import {
  buildSwapToolSideSearchParam,
  RUNE_TOOLS_SWAP_SIDE_SEARCH_PARAM_NAME,
  RUNE_TOOLS_SWAP_TYPE_SEARCH_PARAM_NAME,
} from 'src/pages'
import { concatSearchParams } from 'src/shared/utils'

import { DEFAULT_SWAP_TOOL_MODE, DEFAULT_SWAP_TOOL_SIDE } from '../constants'

export const useGetOrderState = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const swapToolMode =
    searchParams.get(RUNE_TOOLS_SWAP_TYPE_SEARCH_PARAM_NAME) || DEFAULT_SWAP_TOOL_MODE
  const swapToolSide =
    searchParams.get(RUNE_TOOLS_SWAP_SIDE_SEARCH_PARAM_NAME) || DEFAULT_SWAP_TOOL_SIDE

  const isMarketMode = swapToolMode === 'market'
  const isLimitMode = swapToolMode === 'limit'
  const isBidOrderState =
    (isMarketMode && swapToolSide === 'sell') || (isLimitMode && swapToolSide === 'buy')
  const isSellOrderState =
    (isMarketMode && swapToolSide === 'buy') || (isLimitMode && swapToolSide === 'sell')

  const toggleOrderState = useCallback(
    (orderType: 'bid' | 'sell') => {
      if (swapToolMode === 'limit') {
        setSearchParams((prev) => {
          const newSearchParams = concatSearchParams(
            prev,
            buildSwapToolSideSearchParam(orderType === 'bid' ? 'buy' : 'sell')
          )

          return newSearchParams
        })
      } else if (swapToolMode === 'market') {
        setSearchParams((prev) => {
          const newSearchParams = concatSearchParams(
            prev,
            buildSwapToolSideSearchParam(orderType === 'bid' ? 'sell' : 'buy')
          )

          return newSearchParams
        })
      }
    },
    [setSearchParams, swapToolMode]
  )

  return {
    isBidOrderState,
    isSellOrderState,
    isLimitMode,
    isMarketMode,
    toggleOrderState,
  }
}

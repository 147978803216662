import { Rune } from '../Rune'

export interface BidOrder extends Rune {
  id: bigint

  runesAmount: bigint
  satsAmount: bigint

  valueUsd: bigint // using usd decimals

  priceUsd: string
  priceSats: string

  isActive: boolean
  status: EBidOrderStatus

  /**
   * @deprecated Do not rely on this value if the service fee rate changes this value will be incorrect
   */
  marketplaceFeeAmountSats?: bigint
  /**
   * @deprecated Do not rely on this value if the service fee rate changes this value will be incorrect
   */
  marketplaceFeeAmountUsd?: bigint // using usd decimals
  marketplaceFeeReceivedByAddress?: string

  satsPerVbyteFee?: bigint

  placedByAddress: string
  placedAtTimestamp: bigint
  runeProceedsAddress: string
  expiresAtTimestamp: bigint
  cancelledAtTimestamp?: bigint

  fillTransactionId?: string
  fillBroadcastAtTimestamp?: bigint
  filledByAddress?: string
  filledAtBlockHeight?: bigint
  filledAtTimestamp?: bigint

  voidedAtBlockHeight?: bigint
  voidedByTransactionId?: string
  voidedAtTimestamp?: bigint
}

export enum EBidOrderStatus {
  PlacedPending = 'PlacedPending',
  PlacedActive = 'PlacedActive',
  Placed = 'Placed',
  FilledPending = 'FilledPending',
  Filled = 'Filled',
  Cancelled = 'Cancelled',
  Voided = 'Voided',
  Expired = 'Expired',
  Any = 'Any',
}

export function isBidOrderStatus(status: string): status is EBidOrderStatus {
  return Object.values(EBidOrderStatus).includes(status as EBidOrderStatus)
}

export interface FillPendingBidOrder extends BidOrder {
  status: EBidOrderStatus.FilledPending
  fillTransactionId: string
  fillBroadcastAtTimestamp: bigint
}

export interface FilledBidOrder extends BidOrder {
  status: EBidOrderStatus.Filled
  fillTransactionId: string
  fillBroadcastAtTimestamp: bigint
  filledByAddress: string
  filledAtBlockHeight: bigint
  filledAtTimestamp: bigint
}

export interface VoidedBidOrder extends BidOrder {
  status: EBidOrderStatus.Voided
  voidedAtBlockHeight: bigint
  voidedByTransactionId: string
  voidedAtTimestamp: bigint
}

export interface CancelledBidOrder extends BidOrder {
  status: EBidOrderStatus.Cancelled
  cancelledAtTimestamp: bigint
}

export interface ExpiredOrder extends BidOrder {
  status: EBidOrderStatus.Expired
}

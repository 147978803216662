import { useAutoAnimate } from '@formkit/auto-animate/react'
import { Divider } from '@mui/material'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { styled } from 'styled-components'
import Decimal from 'decimal.js-light'

import {
  BidOrder,
  BidOrderInfo,
  BTC_PRICE_SYMBOL,
  Order,
  Rune,
  RuneOutpointDetails,
} from '@packages/interfaces'
import {
  btcToSats,
  calculateFeeFromBps,
  formatBtc,
  formatNumber,
  formatRuneName,
  formatUsd,
  formatUsdPrice,
  ManualError,
  satsToBtc,
} from '@packages/utils'

import { MysticPointsDrawerCard, useGetRewardSize, useMysticPointsContext } from 'src/rewards'
import { useGoogleAnalyticsContext } from 'src/analytics'
import { formatRunesAmount, RunesAmountDisplay, RuneSymbolDisplay } from 'src/runes'
import { DISABLE_WRITES } from 'src/settings'
import {
  AccordianHeader,
  Accordion,
  AccordionContent,
  Drawer,
  DrawerBoldColumn,
  DrawerButtons,
  DrawerCancelButton,
  DrawerCard,
  DrawerColumn,
  DrawerConfirmButton,
  DrawerRow,
  DrawerTieredContent,
  DrawerTitle,
  ErrorMessage,
  TieredTableCell,
  VirtualizedTable,
  WarningMessage,
} from 'src/shared/components'
import { COLORS } from 'src/shared/constants'
import { useDebounce, useIsMobile } from 'src/shared/hooks'
import { hexToRgb } from 'src/shared/utils'
import { useWalletContext } from 'src/wallet'
import { NetworkFeeSelector, SuccessfulTransactionToast } from 'src/web3'

import { calculateWeightedAveragePrice, formatPriceSymbol } from '../utils'
import { PriceSatsDisplay } from './PriceSatsDisplay'
import { PriceUsdDisplay } from './PriceUsdDisplay'
import { RunesTotalColumn } from './styles'

const RECALCULATE_TOTALS_TIME = 250

interface Props {
  isOpen: boolean
  onClose: ({
    filledOrders,
    validOrders,
  }: {
    filledOrders?: BidOrder[]
    validOrders?: BidOrderInfo[]
  }) => void
  rune: Rune
  runesBalance: bigint
  outpoints: RuneOutpointDetails[]
  orders: BidOrder[]
  proceedsAddress: string | undefined
}

export function BuyBidOrderDrawer({
  isOpen,
  onClose,
  rune,
  runesBalance,
  orders,
  proceedsAddress: _proceedsAddress,
}: Props) {
  const isMobile = useIsMobile()
  const [animateRefParent] = useAutoAnimate()
  const [proceedsAddress, setProceedsAddress] = useState(_proceedsAddress ?? '')
  const [selectedNetworkFee, setSelectedNetworkFee] = useState(5)
  const [loadingText, setLoadingText] = useState<string>()
  const [error, setError] = useState<string>()
  const [validOrders, setValidOrders] = useState<BidOrderInfo[]>([])
  const [isOrderDetailsExpanded, setIsOrderDetailsExpanded] = useState(false)
  const [runesAmountTotal, setRunesAmountTotal] = useState<bigint>(0n)
  const [isTotalsLoading, setIsTotalsLoading] = useState(false)
  const [ordersTotals, setOrdersTotals] = useState<{
    ordersSatsTotal: bigint
    ordersBtcTotal: number
    ordersUsdTotal: number
    orderRuneTotal: bigint
    btcTotal: number
    usdTotal: number
    marketplaceFeeAmountBtcTotal: number
    marketplaceFeeAmountUsdTotal: number
    networkFeeBtcTotal: number
    networkFeeUsdTotal: number
  }>({
    ordersSatsTotal: 0n,
    ordersBtcTotal: 0,
    ordersUsdTotal: 0,
    orderRuneTotal: 0n,
    btcTotal: 0,
    usdTotal: 0,
    marketplaceFeeAmountBtcTotal: 0,
    marketplaceFeeAmountUsdTotal: 0,
    networkFeeBtcTotal: 0,
    networkFeeUsdTotal: 0,
  })

  const {
    btcPrice,
    requestBuyBidOrder,
    submitBuyBidOrder,
    // groupOrdersByPlacedAddress,
    // groupOrdersByRuneProccedAddress,
    buildBidOrderPsbt,
    paymentAddress,
    settings,
  } = useWalletContext()
  const { initiateEvent, confirmEvent, executeEvent, abandonEvent } = useGoogleAnalyticsContext()
  const { refetchMysticPointsBalance } = useMysticPointsContext()
  const { data: mysticPoints } = useGetRewardSize({
    runeId: rune?.runeId,
    eventType: 'buy',
    fee: btcToSats(ordersTotals?.marketplaceFeeAmountBtcTotal ?? 0),
    disabled: !isOpen,
    recalculateRewardSizeTime: RECALCULATE_TOTALS_TIME,
  })

  useEffect(() => {
    if (_proceedsAddress) {
      setProceedsAddress(_proceedsAddress)
    } else {
      if (paymentAddress) {
        setProceedsAddress(paymentAddress.addrString)
      }
    }
  }, [_proceedsAddress, paymentAddress])

  useDebounce(
    async () => {
      if (orders.length === 0 || !rune) {
        return
      }
      try {
        const buyResult = await requestBuyBidOrder({
          rune,
          orders,
        })
        setValidOrders(buyResult.validOrders)
      } catch (error) {
        console.error(error)
      }
    },
    [orders, rune],
    500
  )

  useEffect(() => {
    const total = orders.reduce((acc, curr) => {
      return acc + curr.runesAmount
    }, 0n)

    setRunesAmountTotal(total)
  }, [orders])

  useDebounce(
    async () => {
      if (
        orders.length === 0 ||
        validOrders.length === 0 ||
        !btcPrice ||
        !rune ||
        !selectedNetworkFee
      ) {
        return
      }
      setIsTotalsLoading(true)
      try {
        const btcPriceUsd = btcPrice?.btcPriceUsd ?? 0
        const satPriceUsd = btcPrice?.satPriceUsd ?? 0
        const totals = orders
          .map((order) => {
            return {
              satsTotal: order.satsAmount,
              runesAmountTotal: order.runesAmount,
            }
          })
          .reduce(
            (acc, curr) => {
              return {
                satsTotal: acc.satsTotal + curr.satsTotal,

                runesAmountTotal: acc.runesAmountTotal + curr.runesAmountTotal,
              }
            },
            {
              satsTotal: 0n,
              runesAmountTotal: 0n,
            }
          )

        const ordersBtcTotal = satsToBtc(totals.satsTotal)
        const serviceFeeSatsTotal = calculateFeeFromBps(
          totals.satsTotal,
          settings.serviceFees.feeBps.order
        )
        // Calculate accurate transaction fee by fully constructing PSBT
        const { transactionFee } = await buildBidOrderPsbt({
          fee: BigInt(selectedNetworkFee),
          orders: validOrders,
          proceedsAddress: proceedsAddress,
          rune: rune,
        })

        const networkFeeSatsTotal = Number(transactionFee)
        const btcTotal = satsToBtc(
          totals.satsTotal - BigInt(networkFeeSatsTotal) - BigInt(serviceFeeSatsTotal)
        )

        setOrdersTotals({
          ordersSatsTotal: totals.satsTotal,
          ordersBtcTotal,
          ordersUsdTotal: new Decimal(ordersBtcTotal).mul(btcPriceUsd).toNumber(),
          orderRuneTotal: totals.runesAmountTotal,
          btcTotal,
          usdTotal: new Decimal(btcTotal).mul(btcPriceUsd).toNumber(),
          marketplaceFeeAmountBtcTotal: satsToBtc(serviceFeeSatsTotal),
          marketplaceFeeAmountUsdTotal: new Decimal(`${serviceFeeSatsTotal}`)
            .mul(satPriceUsd)
            .toNumber(),
          networkFeeBtcTotal: satsToBtc(networkFeeSatsTotal),
          networkFeeUsdTotal: new Decimal(networkFeeSatsTotal).mul(satPriceUsd).toNumber(),
        })
        setIsTotalsLoading(false)
      } catch (error: any) {
        console.error(error)
      }
    },
    [selectedNetworkFee, btcPrice, orders, validOrders],
    RECALCULATE_TOTALS_TIME
  )

  useEffect(() => {
    if (isOpen) {
      initiateEvent('swap', {
        token_name: rune.runeName,
        token_type: 'rune',
        order_amount: runesAmountTotal,
        order_action: 'buy',
        price: ordersTotals.ordersSatsTotal,
      })
    }
  }, [isOpen])

  const filteredOrders = orders.filter((order) =>
    validOrders.some((validOrder) => validOrder.orderId === order.id)
  )

  function resetForm() {
    setError(undefined)
    setSelectedNetworkFee(0)
    setValidOrders([])
    setOrdersTotals({
      ordersSatsTotal: 0n,
      ordersBtcTotal: 0,
      ordersUsdTotal: 0,
      orderRuneTotal: 0n,
      btcTotal: 0,
      usdTotal: 0,
      marketplaceFeeAmountBtcTotal: 0,
      marketplaceFeeAmountUsdTotal: 0,
      networkFeeBtcTotal: 0,
      networkFeeUsdTotal: 0,
    })
  }

  async function onConfirmClick() {
    try {
      setLoadingText('Generating tx')
      setError(undefined)
      confirmEvent('swap', {
        token_name: rune.runeName,
        token_type: 'rune',
        order_amount: runesAmountTotal,
        order_action: 'buy',
        fee: selectedNetworkFee,
        price: ordersTotals.ordersSatsTotal,
      })

      const ordersToBuy = [...filteredOrders]

      const buyResult = await submitBuyBidOrder({
        rune,
        proceedsAddress,
        orders: ordersToBuy,
        fee: BigInt(selectedNetworkFee),
        onStatusChange: (status) => {
          switch (status) {
            case 'build':
              setLoadingText('Building tx')
              break
            case 'wallet-prompt':
              setLoadingText('See wallet')
              break
            case 'api-submit':
              setLoadingText('Submitting order')
              break
          }
        },
      })

      // If 'runeId' exists, then backend found out, that some orders are invalid
      if ('runeId' in buyResult) {
        setValidOrders(buyResult.validOrders)
        setError('Some of your selected orders are no longer available. See updated orders above.')
      } else {
        if (buyResult.success) {
          refetchMysticPointsBalance()
          executeEvent('swap', {
            token_name: rune.runeName,
            token_type: 'rune',
            order_amount: runesAmountTotal,
            order_action: 'buy',
            fee: selectedNetworkFee,
            price: ordersTotals.ordersSatsTotal,
          })
          toast(
            <SuccessfulTransactionToast
              message='Buy Order Broadcasted'
              transactionId={buyResult.txId ?? ''}
            />,
            { toastId: buyResult.txId ?? Date.now() }
          )
          onClose({ filledOrders: ordersToBuy })
          resetForm()
        } else {
          abandonEvent(true, 'swap', {
            token_name: rune.runeName,
            token_type: 'rune',
            order_amount: runesAmountTotal,
            order_action: 'buy',
            fee: selectedNetworkFee,
            price: ordersTotals.ordersSatsTotal,
          })
          setError(buyResult.error)
        }
      }
    } catch (error: any) {
      console.error(error)
      if (error instanceof ManualError) {
        setError((error as ManualError).message)
      } else {
        switch (error) {
          // placeholder
          case '':
            break
          default:
            setError('Something unexpected has gone wrong, please contact support on our Discord')
            break
        }
      }
    } finally {
      setLoadingText(undefined)
    }
  }

  function handleOnClose() {
    abandonEvent(true, 'swap', {
      token_name: rune.runeName,
      token_type: 'rune',
      order_amount: runesAmountTotal,
      order_action: 'buy',
      fee: selectedNetworkFee,
      price: ordersTotals.ordersSatsTotal,
    })
    setError(undefined)
    onClose({ validOrders })
    setOrdersTotals({
      ordersSatsTotal: 0n,
      ordersBtcTotal: 0,
      ordersUsdTotal: 0,
      orderRuneTotal: 0n,
      btcTotal: 0,
      usdTotal: 0,
      marketplaceFeeAmountBtcTotal: 0,
      marketplaceFeeAmountUsdTotal: 0,
      networkFeeBtcTotal: 0,
      networkFeeUsdTotal: 0,
    })
  }

  // NOTE: Dirty types
  // calculateWeightedAveragePrice doesn't use anything that is specific to BidOrder
  const priceSats = calculateWeightedAveragePrice({ orders: filteredOrders as unknown as Order[] })

  const buttonText = loadingText ?? 'Confirm Buy'
  const notEnoughBalance = runesBalance < ordersTotals.orderRuneTotal
  const noValidOrders = validOrders.length === 0
  const someOrdersNotAvailable = validOrders.length < orders.length

  return (
    <Drawer isOpen={isOpen} onClose={handleOnClose} canClose={!loadingText}>
      <DrawerCard>
        <DrawerTitle>Sell {formatRuneName(rune)}</DrawerTitle>
        <DrawerRow>
          <DrawerBoldColumn>Average Price</DrawerBoldColumn>
          <DrawerColumn>
            <DrawerTieredContent
              content={
                <>
                  &nbsp;
                  <PriceSatsDisplay priceSats={priceSats} runeSymbol={rune.runeSymbolChar} />
                </>
              }
              subContent={<PriceUsdDisplay priceSats={priceSats} />}
              loading={isTotalsLoading}
            />
          </DrawerColumn>
        </DrawerRow>
        <DrawerRow>
          <DrawerBoldColumn>{formatRuneName(rune)}</DrawerBoldColumn>
          <RunesTotalColumn $colorVariant='negative'>
            -
            <RunesAmountDisplay
              rune={rune}
              runesAmount={runesAmountTotal}
              showPriceSymbol={false}
            />
          </RunesTotalColumn>
        </DrawerRow>

        <Divider />
        <DrawerRow>
          <DrawerBoldColumn>
            <AccordianHeader
              linkText='Total BTC'
              expanded={isOrderDetailsExpanded}
              onExpand={() => setIsOrderDetailsExpanded(!isOrderDetailsExpanded)}
            />
          </DrawerBoldColumn>
          <DrawerColumn>
            <DrawerTieredContent
              content={<BtcTotal>+{ordersTotals.btcTotal}</BtcTotal>}
              subContent={<UsdTotal>&nbsp;{formatUsd({ usd: ordersTotals.usdTotal })}</UsdTotal>}
              loading={isTotalsLoading}
            />
          </DrawerColumn>
        </DrawerRow>

        <div ref={animateRefParent}>
          <AccordionContentWrapper expanded={isOrderDetailsExpanded}>
            <DrawerRow>
              <DrawerBoldColumn>Order Cost</DrawerBoldColumn>
              <DrawerColumn>
                <DrawerTieredContent
                  content={
                    <BtcTotal>
                      +{`${ordersTotals.ordersBtcTotal}`}{' '}
                      <RuneSymbolDisplay runeSymbol={BTC_PRICE_SYMBOL} />
                    </BtcTotal>
                  }
                  subContent={
                    <UsdTotal>&nbsp;{formatUsd({ usd: ordersTotals.ordersUsdTotal })}</UsdTotal>
                  }
                  loading={isTotalsLoading}
                />
              </DrawerColumn>
            </DrawerRow>
            {ordersTotals.marketplaceFeeAmountBtcTotal > 0 && (
              <DrawerRow>
                <DrawerBoldColumn>Service Fee</DrawerBoldColumn>
                <DrawerColumn>
                  <DrawerTieredContent
                    content={
                      <BtcTotalNegative>
                        -
                        {formatNumber({
                          numStr: `${ordersTotals.marketplaceFeeAmountBtcTotal}`,
                          showAllDecimals: true,
                        })}{' '}
                        <RuneSymbolDisplay runeSymbol={BTC_PRICE_SYMBOL} />
                      </BtcTotalNegative>
                    }
                    subContent={
                      <UsdTotal>
                        &nbsp;
                        {formatUsdPrice({ price: ordersTotals.marketplaceFeeAmountUsdTotal })}
                      </UsdTotal>
                    }
                    loading={isTotalsLoading}
                  />
                </DrawerColumn>
              </DrawerRow>
            )}
            <DrawerRow>
              <DrawerBoldColumn>Estimated Network Fee</DrawerBoldColumn>
              <DrawerColumn>
                <DrawerTieredContent
                  content={
                    <BtcTotalNegative>
                      -{`${ordersTotals.networkFeeBtcTotal}`}{' '}
                      <RuneSymbolDisplay runeSymbol={BTC_PRICE_SYMBOL} />
                    </BtcTotalNegative>
                  }
                  subContent={
                    <UsdTotal>
                      &nbsp;{formatUsdPrice({ price: ordersTotals.networkFeeUsdTotal })}
                    </UsdTotal>
                  }
                  loading={isTotalsLoading}
                />
              </DrawerColumn>
            </DrawerRow>
          </AccordionContentWrapper>
        </div>
      </DrawerCard>

      {!noValidOrders && (
        <DrawerCard>
          <Accordion linkText='View Orders'>
            <VirtualizedTableWrapper
              columns={[
                {
                  dataKey: 'runesAmount',
                  label: isMobile ? 'Qty' : 'Quantity',
                  formatter: ({ data: order }) =>
                    formatRunesAmount({
                      rune,
                      runesAmount: order.runesAmount,
                    }),
                },
                {
                  dataKey: 'priceSats',
                  label: isMobile
                    ? 'Price'
                    : `Price (${formatPriceSymbol({ runeSymbol: rune.runeSymbolChar })})`,
                  formatter: ({ data: order }) => (
                    <TieredTableCell header={order.priceSats} subHeader={order.priceUsd} />
                  ),
                },
                {
                  dataKey: 'satsAmount',
                  label: `Total (${BTC_PRICE_SYMBOL})`,
                  formatter: ({ data: order }) => (
                    <TieredTableCell
                      header={`${formatBtc(order.satsAmount)}`}
                      subHeader={formatUsd({ usd: order.valueUsd })}
                    />
                  ),
                },
              ]}
              defaultSortBy='desc'
              defaultSort='satsAmount'
              paginatedData={[filteredOrders]}
              fetchPage={async () => {}}
              hasNextPage={false}
              viewableRows={filteredOrders.length}
              rowHeight={30}
            />
          </Accordion>
          {someOrdersNotAvailable && (
            <WarningMessage message={'Some of your selected orders are no longer available'} />
          )}
        </DrawerCard>
      )}

      <DrawerCard>
        <DrawerRow>
          <NetworkFeeSelector
            selectedFee={selectedNetworkFee}
            onChange={(fee) => setSelectedNetworkFee(fee)}
          />
        </DrawerRow>
      </DrawerCard>

      {ordersTotals?.marketplaceFeeAmountBtcTotal > 0 && (
        <MysticPointsDrawerCard points={mysticPoints} place={'trade'} />
      )}

      {notEnoughBalance && (
        <ErrorMessage message={'Insufficient rune funds. '} />
        // TODO? Add this in right formating - Available:
        // ${formatNumber({ numStr: `${runesBalance}` })}
        // ${rune.runeSymbolChar ?? DEFAULT_RUNE_SYMBOL}
      )}
      {noValidOrders && <ErrorMessage message={'None of your selected orders are available'} />}
      {error && <ErrorMessage message={error} />}
      <DrawerButtons>
        {loadingText ? (
          <DrawerCancelButton onClick={() => setLoadingText(undefined)}>
            Cancel Tx
          </DrawerCancelButton>
        ) : (
          <DrawerCancelButton onClick={handleOnClose}>Exit</DrawerCancelButton>
        )}
        <DrawerConfirmButton
          disabled={notEnoughBalance || noValidOrders || isTotalsLoading || DISABLE_WRITES}
          loading={!!loadingText}
          onClick={onConfirmClick}
        >
          {buttonText}
        </DrawerConfirmButton>
      </DrawerButtons>
    </Drawer>
  )
}

const BtcTotal = styled(DrawerColumn)`
  display: flex;
  color: ${COLORS.positive};
  font-weight: 700;
`

const BtcTotalNegative = styled(DrawerColumn)`
  display: flex;
  color: ${COLORS.negative};
  font-weight: 700;
`

const UsdTotal = styled.div`
  color: ${hexToRgb(COLORS.white, 0.7)};
  font-size: 14px;
`

const VirtualizedTableWrapper = styled(VirtualizedTable<BidOrder>)`
  tfoot {
    display: none;
  }
  thead {
    position: relative !important;
  }
`

const AccordionContentWrapper = styled(AccordionContent)`
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  gap: 5px;
`

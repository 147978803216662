import { useMemo } from 'react'

import { useAccountOpenBidOrders } from './useAccountOpenBidOrders'
import { useAccountOpenSellOrders } from './useAccountOpenSellOrders'

export function useAccountOpenOrdersForTable({
  runeId,
  address,
  ordersType,
  otherQueryParams
}: {
  runeId?: string
  address: string
  ordersType: 'bid' | 'sell'
  otherQueryParams?: { [key: string]: any }
}) {
  const {
    paginatedData: openBidOrders,
    loading: openBidOrdersLoading,
    fetchPage: fetchOpenBidOrdersPage,
    hasNextPage: hasNextOpenBidOrdersPage,
    forceRefresh: forceRefreshOpenBidOrders,
    newPageLoading: newOpenBidOrdersPageLoading,
  } = useAccountOpenBidOrders({ runeId, address, otherQueryParams })
  const {
    paginatedData: openSellOrders,
    loading: openSellOrdersLoading,
    fetchPage: fetchOpenSellOrdersPage,
    hasNextPage: hasNextOpenSellOrdersPage,
    forceRefresh: forceRefreshOpenSellOrders,
    newPageLoading: newOpenSellOrdersPageLoading,
  } = useAccountOpenSellOrders({ runeId, address, otherQueryParams })

  const openOrders = ordersType === 'bid' ? openBidOrders : openSellOrders
  const fetchPage = ordersType === 'bid' ? fetchOpenBidOrdersPage : fetchOpenSellOrdersPage
  const hasNextPage = ordersType === 'bid' ? hasNextOpenBidOrdersPage : hasNextOpenSellOrdersPage
  const forceRefresh = ordersType === 'bid' ? forceRefreshOpenBidOrders : forceRefreshOpenSellOrders
  const loading = ordersType === 'bid' ? openBidOrdersLoading : openSellOrdersLoading
  const newPageLoading =
    ordersType === 'bid' ? newOpenBidOrdersPageLoading : newOpenSellOrdersPageLoading

  return useMemo(() => {
    return {
      paginatedData: openOrders,
      loading,
      fetchPage,
      hasNextPage,
      forceRefresh,
      newPageLoading,
    }
  }, [fetchPage, forceRefresh, hasNextPage, loading, newPageLoading, openOrders])
}

import { styled } from 'styled-components'
import FormControl from '@mui/material/FormControl'
import Switch from '@mui/material/Switch'

import { COLORS } from 'src/shared/constants'

import { SWITCH_CONTAINER_STYLES } from './Switch'

interface ToggleSwitchProps<T = boolean> {
  labels: [string, string]
  value: T
  options: [T, T]
  onChange: (value: T) => void
  className?: string
  disabled?: boolean
}

const UNCHECKED_INDEX = 0
const CHECKED_INDEX = 1

export function ToggleSwitch<T>({
  labels,
  value,
  options,
  onChange,
  disabled,
  className,
}: ToggleSwitchProps<T>) {
  const switchId = `toggle-switch-${labels[UNCHECKED_INDEX]}-${labels[CHECKED_INDEX]}`

  const checked = value === options[CHECKED_INDEX]

  return (
    <ToggleSwitchContainer className={className}>
      <ToggleSwitchLabel
        $active={!checked}
        htmlFor={switchId}
        onClick={(event) => {
          event.preventDefault()
          event.stopPropagation()
          onChange(options[UNCHECKED_INDEX])
        }}
      >
        {labels[UNCHECKED_INDEX]}
      </ToggleSwitchLabel>

      <Switch
        id={switchId}
        checked={checked}
        onChange={(event) => {
          if (event.target.checked) {
            const value = options[CHECKED_INDEX]
            onChange(value)
          } else {
            const value = options[UNCHECKED_INDEX]
            onChange(value)
          }
        }}
        disabled={disabled}
      />

      <ToggleSwitchLabel
        $active={checked}
        htmlFor={switchId}
        onClick={(event) => {
          event.preventDefault()
          event.stopPropagation()
          onChange(options[CHECKED_INDEX])
        }}
      >
        {labels[CHECKED_INDEX]}
      </ToggleSwitchLabel>
    </ToggleSwitchContainer>
  )
}

const ToggleSwitchContainer = styled(FormControl)`
  ${SWITCH_CONTAINER_STYLES}

  display: flex;
  flex-direction: row;
  align-items: center;

  & > label:last-child {
    margin-right: 16px;
  }
`

const ToggleSwitchLabel = styled.label<{ $active: boolean }>`
  cursor: pointer;

  color: ${({ $active }) => ($active ? COLORS.labels.primary : COLORS.labels.secondary)};
`

import { AddressTxsUtxo } from '@mempool/mempool.js/lib/interfaces/bitcoin/addresses'

import * as payment from '@packages/scure-btc-signer/payment'
import * as psbt from '@packages/scure-btc-signer/psbt'
import { API_ENDPOINTS } from '@packages/constants'

import { apiFetch } from 'src/api'
import { replaceUrlParams } from 'src/shared/utils'

import { AddressDetails } from '../interfaces'

export async function getCleanUtxos(addr: AddressDetails): Promise<psbt.TransactionInputUpdate[]> {
  if (!addr) {
    throw new Error('Invalid address')
  }
  try {
    const addressTxsUtxo = await apiFetch<AddressTxsUtxo[]>(
      `${replaceUrlParams(API_ENDPOINTS.GET.runes.outpoints.cleanUtxosForAccount, {
        address: addr.addrString,
      })}`,
      {},
      { disableJsonBigParsing: true }
    )

    const inputs: psbt.TransactionInputUpdate[] = addressTxsUtxo.map((utxo) =>
      utxoToInput(addr.p2, utxo)
    )

    return inputs
  } catch (error) {
    console.error(error)
    throw new Error('Failed to fetch UTXOs')
  }
}

export async function getCleanUtxosFromAddress(addr: string): Promise<AddressTxsUtxo[]> {
  if (!addr) {
    throw new Error('Invalid address')
  }
  try {
    const addressTxsUtxo = await apiFetch<AddressTxsUtxo[]>(
      `${replaceUrlParams(API_ENDPOINTS.GET.runes.outpoints.cleanUtxosForAccount, {
        address: addr,
      })}`,
      {},
      { disableJsonBigParsing: true }
    )

    return addressTxsUtxo
  } catch (error) {
    console.error(error)
    throw new Error('Failed to fetch UTXOs')
  }
}

function scriptHashUtxoToInput(
  utxo: AddressTxsUtxo,
  addr: payment.P2Ret
): psbt.TransactionInputUpdate {
  return {
    ...addr,
    txid: utxo.txid,
    index: utxo.vout,
    sequence: 0xffffffff, // no RBF
    witnessUtxo: {
      script: addr.script,
      amount: BigInt(utxo.value),
    },
  }
}

function taprootUtxoToInput(
  utxo: AddressTxsUtxo,
  addr: payment.P2TROut
): psbt.TransactionInputUpdate {
  return {
    ...addr,
    txid: utxo.txid,
    index: utxo.vout,
    sequence: 0xffffffff, // no RBF
    witnessUtxo: {
      script: addr.script,
      amount: BigInt(utxo.value),
    },
  }
}

function witnessPublicKeyHashUtxoToInput(
  utxo: AddressTxsUtxo,
  addr: payment.P2Ret
): psbt.TransactionInputUpdate {
  return {
    txid: utxo.txid,
    index: utxo.vout,
    sequence: 0xffffffff, // no RBF
    witnessUtxo: {
      script: addr.script,
      amount: BigInt(utxo.value),
    },
    redeemScript: addr.redeemScript,
  }
}

export function utxoToInput(addr: payment.P2Ret, utxo: AddressTxsUtxo) {
  if (addr.type === 'sh') {
    return scriptHashUtxoToInput(utxo, addr as payment.P2Ret)
  } else if (addr.type === 'tr') {
    return taprootUtxoToInput(utxo, addr as payment.P2TROut)
  } else if (addr.type === 'wpkh') {
    return witnessPublicKeyHashUtxoToInput(utxo, addr as payment.P2Ret)
  } else {
    throw new Error('Unsupported address type')
  }
}

/* eslint-disable react/display-name */
import _Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { TableVirtuoso, TableComponents, TableVirtuosoProps } from 'react-virtuoso'
import { forwardRef, ReactElement, useCallback, useMemo, useState } from 'react'
import { styled } from 'styled-components'

import { SORT_ORDER } from '@packages/constants'

import { useIsMobile, useWindowDimensions } from 'src/shared/hooks'
import { BREAKPOINTS } from 'src/shared/constants'

import { getComparator, stableSort } from './utils'
import {
  DotsLoaderWrapper,
  HEADER_HEIGHT,
  MOBILE_ROW_PADDING,
  NoDataCellWrapper,
  ROW_PADDING,
  TableCellWrapper,
  TableRowWrapper,
  TableLabel,
  TablePaper,
  LoadingCellWrapper,
  SortDescription,
  TableSortLabelWrapper,
  HeaderTableCellWrapper,
} from './styles'
import { ColumnData } from './interfaces'
import { LoadingSkeleton } from '../LoadingSkeleton'

const VirtuosoTableComponents: TableComponents<any, any> = {
  Scroller: forwardRef<HTMLDivElement>((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => <_Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />,
  TableHead: TableHead as any,
  TableRow: ({ ...props }) => <TableRow {...props} />,
  TableBody: forwardRef<HTMLTableSectionElement>((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
}

function fixedHeaderContent<T>({
  columns,
  onRequestSort,
  sortOrder,
  sortOrderBy,
  customHeader,
}: {
  columns: ColumnData<T>[]
  onRequestSort: (event: React.MouseEvent<unknown>, property: string, column: ColumnData<T>) => void
  sortOrder: SORT_ORDER
  sortOrderBy?: string
  customHeader?: ReactElement
}) {
  const createSortHandler =
    (property: string, column: ColumnData<T>) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property, column)
    }

  return (
    <>
      {customHeader && (
        <CustomHeaderSpaceContainer>
          <CustomHeaderContainer>{customHeader}</CustomHeaderContainer>
        </CustomHeaderSpaceContainer>
      )}
      <TableRow>
        {columns.map((column) => (
          <HeaderTableCellWrapper
            key={column.dataKey}
            variant='head'
            $hideOnMobile={column.hideOnMobile ?? false}
            $hideOnDesktop={column.hideOnDesktop ?? false}
            width={column.width}
          >
            {column.disableSort ? (
              <TableLabel> {column.content ? column.content : column.label}</TableLabel>
            ) : (
              <TableSortLabelWrapper
                active={sortOrderBy === column.dataKey}
                direction={sortOrderBy === column.dataKey ? sortOrder : 'asc'}
                onClick={createSortHandler(column.dataKey, column)}
              >
                {column.content ? column.content : column.label}
                {sortOrderBy === column.dataKey ? <SortDescription order={sortOrder} /> : null}
              </TableSortLabelWrapper>
            )}
          </HeaderTableCellWrapper>
        ))}
      </TableRow>
    </>
  )
}

function rowContent<T>({
  row,
  columns,
  index,
}: {
  index: number
  row: T
  columns: ColumnData<T>[]
}) {
  return (
    <>
      {columns.map((column) => {
        const data = column.formatter
          ? column.formatter({ data: row, index })
          : typeof (row as any)[column.dataKey] === 'bigint'
            ? (row as any)[column.dataKey].toString()
            : (row as any)[column.dataKey]

        return (
          <TableCellWrapper
            key={column.dataKey}
            $hideOnMobile={column.hideOnMobile ?? false}
            $hideOnDesktop={column.hideOnDesktop ?? false}
          >
            {data}
          </TableCellWrapper>
        )
      })}
    </>
  )
}

function emptyPlaceholder<T>({
  loading,
  minRowLength,
  columns,
  currentRowHeight,
  colSpan,
  emptyDataMessage,
}: {
  loading: boolean
  minRowLength: number
  columns: ColumnData<T>[]
  currentRowHeight: number
  colSpan: number
  emptyDataMessage?: string
}) {
  if (loading) {
    return (
      <TableBody>
        {[...Array(minRowLength).keys()].map((_, index) => (
          <TableRow key={`skeleton-row-${index}`}>
            {columns.map((_column, colIndex) => (
              <LoadingCellWrapper
                $hideOnMobile={false}
                $hideOnDesktop={false}
                key={`skeleton-${index}-${colIndex}`}
              >
                <LoadingSkeleton variant='text' height={currentRowHeight} />
              </LoadingCellWrapper>
            ))}
          </TableRow>
        ))}
      </TableBody>
    )
  }
  return (
    <TableBody>
      <TableRow>
        <NoDataCellWrapper $hideOnMobile={false} $hideOnDesktop={false} colSpan={colSpan}>
          {emptyDataMessage ?? 'No Data'}
        </NoDataCellWrapper>
      </TableRow>
    </TableBody>
  )
}

const skeletonFooter = ({ loading, rowsLength }: { loading: boolean; rowsLength: number }) => {
  return (
    <TableRowWrapper>
      <td>
        <DotsLoaderHideable $hide={!(loading && rowsLength > 0)} />
      </td>
    </TableRowWrapper>
  )
}

interface Props<T> {
  columns: ColumnData<T>[]
  paginatedData: T[][]
  className?: string
  loading?: boolean
  fetchPage: (page: number) => Promise<void>
  hasNextPage: boolean
  useInfiniteLoading?: boolean
  useFixedHeight?: boolean
  rowHeight?: number
  viewableRows: number
  headerShown?: boolean
  emptyDataMessage?: string
  fixedHeader?: boolean
  customHeader?: ReactElement
  defaultSort?: string
  defaultSortBy?: SORT_ORDER
}

export function VirtualizedTable<T>({
  columns,
  paginatedData,
  fetchPage,
  loading = false,
  className,
  useInfiniteLoading = true,
  useFixedHeight = false,
  rowHeight,
  viewableRows,
  headerShown = true,
  emptyDataMessage,
  fixedHeader = false,
  useWindowScroll = true,
  customHeader,
  defaultSort = undefined,
  defaultSortBy = 'asc',
}: Props<T> & TableVirtuosoProps<T, unknown>) {
  const [isBackendSort, setIsBackendSort] = useState(false)
  const [sortOrder, setSortOrder] = useState<SORT_ORDER>(defaultSortBy)
  const [sortOrderBy, setSortOrderBy] = useState<string | undefined>(defaultSort)
  const [page, setPage] = useState(0)

  const isMobile = useIsMobile()
  const { height } = useWindowDimensions()

  const rows = useMemo(() => paginatedData.flat(), [paginatedData])
  const rowsLength = rows.length ?? 0
  const currentRowHeight = rowHeight ?? (isMobile ? 45 : 60)
  const rowHeightWithPadding = currentRowHeight + (isMobile ? MOBILE_ROW_PADDING : ROW_PADDING) // padding
  let minRowLength = paginatedData.length > 0 ? paginatedData[0].length : 0
  if (viewableRows && minRowLength != viewableRows) {
    minRowLength = viewableRows
  }

  const headerHeight = headerShown ? HEADER_HEIGHT : 0
  const { minTableHeight } = useMemo(() => {
    // const minTableHeight = viewableRows * rowHeightWithPadding + headerHeight
    const minTableHeight =
      (paginatedData?.[0]?.length ?? viewableRows) * rowHeightWithPadding + headerHeight
    let height =
      useFixedHeight || loading
        ? viewableRows * rowHeightWithPadding + headerHeight
        : minTableHeight // header
    if (!loading && rowsLength < viewableRows) {
      height = rowHeightWithPadding * rowsLength + headerHeight
    } else if (!loading && (rowsLength === 0 || rowsLength === 1)) {
      height = rowHeightWithPadding * 3
    }

    return { tableHeight: height, minTableHeight }
  }, [rowHeightWithPadding, rowsLength, viewableRows, useFixedHeight, loading])

  const visibleRows = useMemo(() => {
    if (!isBackendSort) {
      return sortOrderBy ? stableSort(rows as any, getComparator(sortOrder, sortOrderBy)) : rows
    } else {
      return rows
    }
  }, [rows, sortOrder, sortOrderBy, isBackendSort])

  const colSpan = useMemo(
    () => columns.reduce((acc, column) => (isMobile && column.hideOnMobile ? acc : acc + 1), 0),
    [columns.length]
  )

  const onFetchPage = useCallback(
    (index: number) => {
      if (rowsLength - 1 <= index && !loading) {
        fetchPage(page + 1)
        setPage(page + 1)
      }
    },
    [fetchPage, loading, page, setPage, rowsLength]
  )

  const handleRequestSort = useCallback(
    (_event: React.MouseEvent<unknown>, property: string, column: ColumnData<T>) => {
      const isDesc = sortOrderBy === property && sortOrder === 'desc'
      const newSortOrder = isDesc ? 'asc' : 'desc'

      setSortOrder(newSortOrder)
      setSortOrderBy(property)
      if (column.onSort) {
        setIsBackendSort(true)
        return column.onSort(newSortOrder)
      } else {
        setIsBackendSort(false)
      }
    },
    [setSortOrder, setSortOrderBy, sortOrderBy, sortOrder, setIsBackendSort]
  )

  return (
    <TablePaper
      style={{
        width: '100%',
      }}
      $minHeight={minTableHeight}
      $useWindowScroll={useWindowScroll}
      className={className}
      $rowHeightWithPadding={rowHeightWithPadding}
      $fixedHeader={fixedHeader}
      $loading={loading}
    >
      <TableVirtuoso
        style={{ height: '100%' }}
        data={visibleRows as T[]}
        components={{
          ...VirtuosoTableComponents,
          EmptyPlaceholder: () =>
            emptyPlaceholder({
              loading,
              minRowLength,
              columns,
              currentRowHeight,
              colSpan,
              emptyDataMessage,
            }),
        }}
        fixedHeaderContent={() =>
          fixedHeaderContent({
            columns,
            onRequestSort: handleRequestSort,
            sortOrder,
            ...{ sortOrderBy },
            customHeader,
          })
        }
        useWindowScroll={useWindowScroll}
        itemContent={(index: number, row: T) => rowContent({ index, row, columns })}
        endReached={useFixedHeight || useInfiniteLoading ? onFetchPage : () => {}}
        increaseViewportBy={height / 2}
        fixedFooterContent={() => skeletonFooter({ loading, rowsLength })}
      />
    </TablePaper>
  )
}

const DotsLoaderHideable = styled(DotsLoaderWrapper)<{ $hide?: boolean }>`
  opacity: ${({ $hide }) => ($hide ? '0' : '1')};
`

const CustomHeaderSpaceContainer = styled.div`
  // desktop table header paddings
  padding-top: 30px;
  padding-bottom: 20px;
  @media (max-width: ${BREAKPOINTS.medium}) {
    padding-top: 0;
    padding-bottom: 0;
  }
`

const CustomHeaderContainer = styled.div`
  position: absolute;
  top: 0;
  // desktop table header paddings
  padding-top: 20px;
  padding-bottom: 20px;
  width: calc(100% - 20px - 20px);
  @media (max-width: ${BREAKPOINTS.medium}) {
    padding-top: 0;
    padding-bottom: 0;
    position: relative;
    width: calc(100vw - 60px);
  }
`

import { useState, useEffect } from 'react'
import { styled } from 'styled-components'
import { toast } from 'react-toastify'

import { formatBtc, formatUsdPrice, ManualError, satsToBtc } from '@packages/utils'

import {
  Checkbox,
  Drawer,
  DrawerBoldColumn,
  DrawerColumn,
  DrawerButtons,
  DrawerCancelButton,
  DrawerCard,
  DrawerConfirmButton,
  DrawerRow,
  ErrorMessage,
  AccordianHeader,
  AccordionContent,
} from 'src/shared/components'
import { NetworkFeeSelector, SuccessfulTransactionToast } from 'src/web3'
import { MIN_TX_VSIZE, useEscrowWalletContext, useWalletContext } from 'src/wallet'
import { BREAKPOINTS, COLORS } from 'src/shared/constants'

import { useEscrowTransferContext } from '../EscrowTransferContext'
import { Action } from '../EscrowTransferContext.tsx'

interface Props {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  quantity: bigint
  action: Action
}

export function ConfirmEscrowTransferDrawer({
  isOpen,
  onClose,
  onConfirm,
  quantity,
  action,
}: Props) {
  const { btcPrice, paymentAddress, estimateBtcTransferVsize, transferBtc } = useWalletContext()
  const { escrowWallet: escrowAddress, withdrawBtc } = useEscrowWalletContext()
  const [loadingText, setLoadingText] = useState<string>()
  const [error, setError] = useState<string>()
  const [isPromptConfirmed, setIsPromptConfirmed] = useState(true)
  const [isExpanded, setIsExpanded] = useState(false)
  const [vsize, setVsize] = useState(MIN_TX_VSIZE)

  function handleOnClose() {
    setError(undefined)
    onClose()
  }

  function handleOnCancel() {
    setLoadingText(undefined)
  }

  const { fee, setFee } = useEscrowTransferContext()

  async function handleConfirm() {
    if (!escrowAddress) {
      return setError('Bidding wallet address is not available')
    }

    try {
      let signTxResult

      if (action === 'deposit') {
        signTxResult = await transferBtc({
          recipientAddress: escrowAddress,
          transferAmount: quantity,
          fee: BigInt(fee),
          onStatusChange: (status) => {
            switch (status) {
              case 'build':
                setLoadingText('Building tx')
                break
              case 'wallet-prompt':
                setLoadingText('See Wallet')
                break
            }
          },
        })
      } else {
        signTxResult = await withdrawBtc({
          recipientAddress: escrowAddress,
          transferAmount: quantity,
          fee: BigInt(fee),
          onStatusChange: (status) => {
            switch (status) {
              case 'build':
                setLoadingText('Building tx')
                break
              case 'wallet-prompt':
                setLoadingText('See Wallet')
                break
              case 'api-submit':
                setLoadingText('Submitting txs')
                break
            }
          },
        })
      }

      if (signTxResult.success && 'txId' in signTxResult) {
        toast(
          <SuccessfulTransactionToast
            message='Transfer successful!'
            transactionId={signTxResult.txId ?? ''}
          />,
          { toastId: 'transfer-successful-' + signTxResult.txId }
        )

        onConfirm()
        onClose()
      } else {
        setError(signTxResult.error)
      }
    } catch (error) {
      console.error(error)

      if (error instanceof ManualError) {
        setError((error as ManualError).message)
      } else {
        switch (error) {
          // placeholder
          case '':
            break
          default:
            setError('Something unexpected has gone wrong, please contact support on our Discord')
            break
        }
      }
    } finally {
      setLoadingText(undefined)
    }
  }

  useEffect(() => {
    if (!fee) {
      return
    }

    ;(async () => {
      try {
        setVsize(await estimateBtcTransferVsize({ transferAmount: quantity, fee: BigInt(fee) }))
      } catch (error) {
        if (error instanceof ManualError) {
          setError((error as ManualError).message)
        } else {
          switch (error) {
            // placeholder
            case '':
              break
            default:
              setError('Something unexpected has gone wrong, please contact support on our Discord')
              break
          }
        }
      }
    })()
  }, [fee, quantity, action])

  useEffect(() => {
    setError(undefined)
  }, [fee])

  return (
    <Drawer isOpen={isOpen} onClose={handleOnClose}>
      <DrawerCard>
        <ConfirmTitle>{action}</ConfirmTitle>
      </DrawerCard>

      <DrawerCard>
        <DrawerQuantityRow>
          <DrawerColumn>BTC</DrawerColumn>
          <QuantityColumn>
            <div>{formatBtc(quantity)}</div>
            <QuantityUsd>
              {formatUsdPrice({
                price: btcPrice?.btcPriceUsd ? btcPrice?.btcPriceUsd * satsToBtc(quantity) : '0',
              })}
            </QuantityUsd>
          </QuantityColumn>
        </DrawerQuantityRow>
      </DrawerCard>

      <AddressCard>
        <DrawerRow>
          <DrawerBoldColumn>Proceed Address</DrawerBoldColumn>
        </DrawerRow>
        <DrawerRow>
          <DrawerRecipientAddressColumn>
            {paymentAddress?.addrString ?? ''}
          </DrawerRecipientAddressColumn>
        </DrawerRow>
        <AddressPrompt>
          <CheckboxWrapper
            checked={isPromptConfirmed}
            onChange={(val) => setIsPromptConfirmed(val)}
          />
          <PromptMessage>You will be promted to sign 1 transaction</PromptMessage>
        </AddressPrompt>
      </AddressCard>

      <DrawerCard>
        <DrawerRow>
          <NetworkFeeSelector
            showTitle={false}
            selectedFee={fee}
            onChange={(fee) => {
              setFee(fee)
            }}
          />
        </DrawerRow>
      </DrawerCard>

      <TotalCard>
        <DrawerFlexibleRow>
          <DrawerBoldColumn>
            <AccordianHeader
              expanded={isExpanded}
              onExpand={() => setIsExpanded(!isExpanded)}
              linkText='Total BTC'
            />
          </DrawerBoldColumn>
          <DrawerColumn>
            {formatBtc(BigInt(fee * vsize) + quantity)}
            <QuantityCurrency>BTC</QuantityCurrency>
          </DrawerColumn>
        </DrawerFlexibleRow>

        <AccordionContentWrapper expanded={isExpanded}>
          <DrawerFlexibleRow>
            <DrawerBoldColumn>Quantity</DrawerBoldColumn>
            <QuantityColumn>
              <TotalQuantityBtc>
                {formatBtc(quantity)} <QuantityCurrency>BTC</QuantityCurrency>
              </TotalQuantityBtc>
              <TotalQuantityUsd>
                {formatUsdPrice({
                  price: btcPrice?.btcPriceUsd ? btcPrice?.btcPriceUsd * satsToBtc(quantity) : '0',
                })}
              </TotalQuantityUsd>
            </QuantityColumn>
          </DrawerFlexibleRow>
          <DrawerFlexibleRow>
            <DrawerBoldColumn>Estimated Network Fee</DrawerBoldColumn>
            <QuantityColumn>
              <TotalQuantityBtc>
                {formatBtc(BigInt(fee * vsize))} <QuantityCurrency>BTC</QuantityCurrency>
              </TotalQuantityBtc>
              <TotalQuantityUsd>
                {formatUsdPrice({
                  price: btcPrice?.btcPriceUsd
                    ? btcPrice?.btcPriceUsd * satsToBtc(fee * vsize)
                    : '0',
                })}
              </TotalQuantityUsd>
            </QuantityColumn>
          </DrawerFlexibleRow>
        </AccordionContentWrapper>
      </TotalCard>

      <TotalCard>
        <DrawerFlexibleRow>
          <ResultLeftColumn>
            {action === 'deposit' ? 'Deposit' : 'Withdraw'} Amount
          </ResultLeftColumn>
          <DrawerColumn>
            <div>
              {formatBtc(quantity)} <QuantityCurrency>BTC</QuantityCurrency>
            </div>
          </DrawerColumn>
        </DrawerFlexibleRow>
      </TotalCard>

      {error && <ErrorMessage message={error} />}
      <DrawerButtons>
        {loadingText && (
          <DrawerCancelButton onClick={() => handleOnCancel()}>Cancel Tx</DrawerCancelButton>
        )}
        <ConfirmButton
          onClick={handleConfirm}
          disabled={!isPromptConfirmed || !!error}
          loading={!!loadingText}
        >
          {loadingText ?? `Confirm ${action}`}
        </ConfirmButton>
      </DrawerButtons>
    </Drawer>
  )
}

const ConfirmTitle = styled.h2`
  font-weight: 500;
  text-transform: uppercase;
`

const DrawerQuantityRow = styled(DrawerRow)`
  font-weight: 500;
  font-size: 14px;

  > div {
    flex: 1;
    justify-content: flex-start;
  }

  @media (max-width: ${BREAKPOINTS.medium}) {
    flex-direction: row;
  }
`

const DrawerFlexibleRow = styled(DrawerRow)`
  @media (max-width: ${BREAKPOINTS.medium}) {
    flex-direction: row;
  }
`

const DrawerRecipientAddressColumn = styled(DrawerBoldColumn)`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
`

const ConfirmButton = styled(DrawerConfirmButton)`
  padding: 9px 16px !important;

  @media (max-width: ${BREAKPOINTS.medium}) {
    width: 100%;
  }
`

const QuantityUsd = styled.div`
  color: ${COLORS.labels.secondary};
  font-weight: 400;
`

const TotalQuantityBtc = styled.div`
  text-align: end;
`

const TotalQuantityUsd = styled.div`
  color: ${COLORS.labels.secondary};
  font-weight: 400;
  text-align: end;
`

const QuantityColumn = styled.div`
  flex-direction: column;
  gap: unset;
`

const AddressCard = styled(DrawerCard)`
  font-size: 14px;
  font-weight: 500;
`

const AddressPrompt = styled.div`
  display: flex;
  gap: 8px;
`

const PromptMessage = styled.div`
  color: ${COLORS.labels.secondary};
`

const CheckboxWrapper = styled(Checkbox)`
  padding: unset;
  width: 20px;
  height: 20px;
`

const QuantityCurrency = styled.span`
  color: ${COLORS.labels.secondary};
`

const TotalCard = styled(DrawerCard)`
  font-size: 14px;
  font-weight: 500;
`

const AccordionContentWrapper = styled(AccordionContent)`
  display: flex;
  flex-direction: column;
  gap: 15px;
`

const ResultLeftColumn = styled(DrawerBoldColumn)`
  color: ${COLORS.labels.secondary};
`

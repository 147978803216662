import { useMemo } from 'react'

import { API_ENDPOINTS } from '@packages/constants'
import { Order, OrderStatus } from '@packages/interfaces'

import { replaceUrlParams } from 'src/shared/utils'
import { useUpdateOnBlockAggregatePaginationApi } from 'src/api/hooks/useUpdateOnBlockAggregatePaginationApi'

export function useAccountOpenSellOrders({
  runeId,
  address,
  otherQueryParams,
}: {
  runeId?: string
  address: string
  otherQueryParams?: { [key: string]: any }
}) {
  const endpoint = useMemo(() => {
    if (runeId) {
      return `${replaceUrlParams(API_ENDPOINTS.GET.runes.orders.byRuneForAccount, {
        address,
        runeId,
      })}?status=${OrderStatus.Placed}`
    } else {
      return `${replaceUrlParams(API_ENDPOINTS.GET.runes.orders.byAccount, {
        address,
      })}?status=${OrderStatus.Placed}`
    }
  }, [address, runeId])

  const res = useUpdateOnBlockAggregatePaginationApi<Order>({
    endpoint,
    otherQueryParams,
  })

  return res
}

import { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  MintRunesDrawer,
  MintRunesShortForm,
  useMintDetails,
  type MintRunesShortFormState,
} from 'src/minting'
import { useOrderContext } from 'src/orders'
import { useRuneDetails } from 'src/runes'
import { TabPanelContentContainer, type TabPanelProps } from 'src/shared/components'

type MintTabPanelProps = TabPanelProps

export function MintTabPanel({ visible }: MintTabPanelProps) {
  const { runeName = '' } = useParams<'runeName'>()
  const { selectedRuneDetails: selectedRuneFallback } = useOrderContext()
  const { data: runeDetails } = useRuneDetails(runeName, !runeName)
  const mintDetails = useMintDetails({ runeDetails })

  const [formState, setFormState] = useState<MintRunesShortFormState>()

  const [isMintDrawerOpen, setIsMintDrawerOpen] = useState(false)

  const handleClose = useCallback(() => {
    setIsMintDrawerOpen(false)
  }, [])

  const handleConfirm = useCallback(() => {
    setIsMintDrawerOpen(true)
  }, [])

  return (
    <TabPanelContentContainer $visible={visible}>
      <MintRunesShortForm
        runeDetails={selectedRuneFallback || runeDetails}
        mintDetails={mintDetails}
        onConfirm={handleConfirm}
        onFormStateChange={setFormState}
      />

      {runeDetails && (
        <MintRunesDrawer
          isOpen={isMintDrawerOpen}
          onClose={handleClose}
          runeDetails={runeDetails}
          recipientAddress={formState?.recipientAddress}
          numberOfMints={formState?.numberOfMints}
        />
      )}
    </TabPanelContentContainer>
  )
}

import { styled } from 'styled-components'

import { BREAKPOINTS, COLORS, FONTS } from 'src/shared/constants'
import { hexToRgb } from 'src/shared/utils'

export function MysticPointsPageContent() {
  return (
    <Container>
      <Title>Welcome to Mystic Points:</Title>
      <Subtitle>Your Path to Exclusive Rewards.</Subtitle>
      <TextContent>
        At Mystic, we believe in the magic of engagement. To show our appreciation for your activity
        and loyalty, we’ve introduced Mystic Points, a special rewards system designed to make your
        experience even more rewarding.
        <br />
        <br />
        Whether you’re minting or trading Bitcoin Runes, each action on Mystic.com brings you one
        step closer to unlocking powerful Mystic Runes rewards. The more you engage, the more you
        earn. It’s that simple. Mystic Points are not just about the here and now—they’re your key
        to long-term rewards that will enhance your journey and open up new possibilities.
      </TextContent>
      <Subtitle>How to Earn Mystic Points?</Subtitle>
      <TextContent>
        Mystic Points are earned through your activity on Mystic.com, including minting and trading
        Bitcoin Runes. Every time you interact with the platform, you’ll accumulate points, which
        will eventually translate into Mystic Runes.
        <br />
        <br />
        You can keep track of your points balance anytime through your Account Hub. It’s your
        gateway to seeing your progress and planning ahead for the rewards that await you!
      </TextContent>
      <Subtitle>Mystic Points: Frequently Asked Questions</Subtitle>
      <List>
        <li>
          <ListItemHeader>What are Mystic Points?</ListItemHeader>
          <ListItemContent>
            Mystic Points is a rewards programme that you earn through your activity on Mystic.com.
            Minting, trading, and engaging with the platform all contribute to your points balance,
            which will eventually be converted into Mystic Runes rewards.
          </ListItemContent>
        </li>
        <li>
          <ListItemHeader>How do I earn Mystic Points?</ListItemHeader>
          <ListItemContent>
            Earn Mystic Points by participating in key actions on the platform, such as minting and
            trading Bitcoin Runes. Every action adds to your points, helping you accumulate rewards
            over time. We will be announcing other ways to boost your Mystic Points soon.
          </ListItemContent>
        </li>
        <li>
          <ListItemHeader>Where can I check my Mystic Points balance?</ListItemHeader>
          <ListItemContent>
            You can check your Mystic Points balance anytime by visiting your Account Hub page.
            There, you’ll be able to track your points and see what rewards are just around the
            corner.
          </ListItemContent>
        </li>
        <li>
          <ListItemHeader>What can I do with Mystic Points?</ListItemHeader>
          <ListItemContent>
            Mystic Points can eventually be exchanged for Mystic Runes rewards. The more points you
            collect, the more rewards you can unlock, further enhancing your time on Mystic. Other
            exciting rewards will be revealed in the future.
          </ListItemContent>
        </li>
        <li>
          <ListItemHeader>How can I maximize my Mystic Points?</ListItemHeader>
          <ListItemContent>
            The best way to earn more Mystic Points is to stay engaged with the platform. The more
            you mint, trade, and interact, the faster your points will accumulate, bringing you
            closer to unlocking greater rewards.
          </ListItemContent>
        </li>
        <li>
          <ListItemHeader>Are Mystic Points transferable?</ListItemHeader>
          <ListItemContent>
            At this time, Mystic Points are non-transferable. They are earned through individual
            engagement with the platform and are tied to your personal account.
          </ListItemContent>
        </li>
      </List>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  border: 1px solid ${hexToRgb(COLORS.labels.primary, 0.25)};
  border-radius: 16px;

  @media (max-width: ${BREAKPOINTS.medium}) {
    padding: 15px;
  }
`

const Title = styled.h2`
  font-size: 36px;
  line-height: 36px;
  font-weight: 500;
  text-transform: uppercase;
`

const Subtitle = styled.h3`
  font-size: 20px;
  font-family: ${FONTS.headers};
  font-weight: 700;
`

const TextContent = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: ${hexToRgb(COLORS.labels.primary, 0.75)};
`

const List = styled.ul`
  display: flex;
  flex-direction: column;

  gap: 20px;

  padding-inline-start: 24px;
`

const ListItemHeader = styled.h4`
  font-size: 16px;
  font-weight: 500;
  text-align: left;
`

const ListItemContent = styled(TextContent)`
  padding-top: 10px;
`

import { useState, useEffect } from 'react'

import { apiFetch, apiFetchWithCache } from '../utils'

export function useApi<T>({
  endpoint,
  queryParams,
  disable,
  enableCaching,
}: {
  endpoint: string
  queryParams?: { [key: string]: any }
  disable?: boolean
  enableCaching?: boolean
}) {
  const [data, setData] = useState<T>()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string>()

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        let responseData: T

        if (enableCaching) {
          responseData = await apiFetchWithCache<T>(endpoint, queryParams)
        } else {
          responseData = await apiFetch<T>(endpoint, queryParams)
        }

        setData(responseData)
        setLoading(false)
      } catch (error: any) {
        setError((error as Error).message)
        setLoading(false)
      }
    }

    if (!disable) {
      fetchData()
    }
  }, [endpoint, queryParams, disable, enableCaching])

  return { data, loading, error }
}

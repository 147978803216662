import { styled } from 'styled-components'

import type { RuneDetails } from '@packages/interfaces'

import { Header } from 'src/minting/components/MintRunes/styles'
import {
  DrawerCard,
  DrawerColumn,
  DrawerRow,
  ErrorMessage,
  NumberInputWithButtons,
  Slider,
} from 'src/shared/components'
import { RunesAmountDisplay } from 'src/runes'
import { BREAKPOINTS } from 'src/shared/constants'

import { TotalAmountRow, TotalAmountHeader } from './styles'

const MAX_MINTS = 2300

export type MintRunesDrawerRepeatMintsProps = {
  runeDetails: RuneDetails | undefined
  isDisabled: boolean
  onNumberOfMintsChange: (val: number) => void
  numberOfMints: number
  numberOfMintsError: string | undefined
  totalMints: bigint | undefined
  mode?: 'read' | 'write'
}

export function MintRunesDrawerRepeatMints({
  runeDetails,
  isDisabled,
  onNumberOfMintsChange,
  numberOfMints,
  numberOfMintsError,
  totalMints,
  mode = 'write',
}: MintRunesDrawerRepeatMintsProps) {
  return (
    <DrawerCard>
      <MintsDescriptionRow>
        <MintAmountColumn>
          <Header>Repeat Mints</Header>
          {mode === 'write' && (
            <>
              {numberOfMints > 1 ? (
                <SubHeader>
                  You will be prompted to sign 1 transaction that will split your BTC. Mints will
                  take at least 2 blocks to confirm.
                </SubHeader>
              ) : (
                <SubHeader>You will be prompted to sign 1 transaction</SubHeader>
              )}
            </>
          )}
        </MintAmountColumn>
        <DrawerColumn>
          {mode === 'write' && (
            <NumberInputWithButtons
              onChange={onNumberOfMintsChange}
              value={numberOfMints}
              minValue={1}
              maxValue={MAX_MINTS}
              disabled={isDisabled}
            />
          )}
          {mode === 'read' && <TotalAmountColumn>{numberOfMints}</TotalAmountColumn>}
        </DrawerColumn>
      </MintsDescriptionRow>

      {mode === 'write' && (
        <DrawerRow>
          <Slider
            spacing={1}
            value={numberOfMints}
            onChange={onNumberOfMintsChange}
            min={1}
            max={MAX_MINTS}
            disabled={isDisabled}
          />
        </DrawerRow>
      )}

      {numberOfMintsError && (
        <DrawerRow>
          <ErrorMessageWrapper message={numberOfMintsError} />
        </DrawerRow>
      )}

      <TotalAmountRow>
        <TotalAmountHeader>Tokens Per Mint</TotalAmountHeader>
        <TotalAmountColumn>
          <RunesAmountDisplay
            rune={runeDetails}
            runesAmount={runeDetails?.runesAmountPerMint ?? 0n}
          />
        </TotalAmountColumn>
      </TotalAmountRow>

      <TotalAmountRow>
        <TotalAmountHeader>Total Minted Amount</TotalAmountHeader>
        <TotalAmountColumn>
          <RunesAmountDisplay rune={runeDetails} runesAmount={totalMints} />
        </TotalAmountColumn>
      </TotalAmountRow>
    </DrawerCard>
  )
}

const MintsDescriptionRow = styled(DrawerRow)`
  gap: 5px;
  flex-direction: row;

  @media (max-width: ${BREAKPOINTS.medium}) {
    flex-direction: column;
  }
`

const TotalAmountColumn = styled(DrawerColumn)``

const MintAmountColumn = styled(DrawerColumn)`
  flex: 1 1 auto;
  flex-direction: column;
`

const SubHeader = styled.div`
  font-weight: 300;
  font-size: 14px;
`

const ErrorMessageWrapper = styled(ErrorMessage)`
  width: 100%;
`

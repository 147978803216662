import Bottleneck from 'bottleneck'
import { Address, AddressTxsUtxo } from '@mempool/mempool.js/lib/interfaces/bitcoin/addresses'

import { satsToBtc } from '@packages/utils'

import { mempoolApiInstance } from 'src/web3'

import { AddressDetails, BtcBalances } from '../interfaces'
import { getCleanUtxos } from './cleanUtxos'

const BALANCE_THROTTLE_INTERVAL_MS = 1000 * 30

let limiter = new Bottleneck({
  maxConcurrent: 1,
  minTime: BALANCE_THROTTLE_INTERVAL_MS,
})

const {
  bitcoin: { addresses },
} = mempoolApiInstance

const wrapped = limiter.wrap(addresses.getAddress)
const wrapped2 = limiter.wrap(getCleanUtxos)

export async function getAddressBtcBalances({
  forceUpdate = false,
  address,
  setBtcBalances,
}: {
  forceUpdate?: boolean
  address: AddressDetails
  setBtcBalances: (balances: BtcBalances) => void
}) {
  try {
    let response: Address
    let responseUtxos: any
    if (forceUpdate) {
      await limiter.stop({ dropWaitingJobs: true })
      response = await addresses.getAddress({
        address: address.addrString,
      })
      responseUtxos = await getCleanUtxos(address)
      // create a new limiter
      limiter = new Bottleneck({
        maxConcurrent: 1,
        minTime: BALANCE_THROTTLE_INTERVAL_MS,
      })
    } else {
      response = await wrapped({ address: address.addrString })
      responseUtxos = await wrapped2(address)
    }

    const data: {
      address: string
      chain_stats: {
        funded_txo_count: number
        funded_txo_sum: number
        spent_txo_count: number
        spent_txo_sum: number
        tx_count: number
      }
      mempool_stats: {
        funded_txo_count: number
        funded_txo_sum: number
        spent_txo_count: number
        spent_txo_sum: number
        tx_count: number
      }
    } = await response

    const utxoData = await responseUtxos

    let availableBalance = 0n

    for (const utxo of utxoData) {
      availableBalance += utxo.witnessUtxo.amount
    }

    const mempoolBalanceSats = data.mempool_stats.funded_txo_sum - data.mempool_stats.spent_txo_sum
    // const chainBalanceSats = data.chain_stats.funded_txo_sum - data.chain_stats.spent_txo_sum

    setBtcBalances({
      mempoolBalanceSats: BigInt(mempoolBalanceSats),
      mempoolBalanceBtc: satsToBtc(mempoolBalanceSats),
      chainBalanceSats: BigInt(availableBalance),
      chainBalanceBtc: satsToBtc(availableBalance),
    })
  } catch (error) {
    console.error('Failed to fetch BTC balances:', error)
  }
}

import { useMemo, useState } from 'react'

import { RuneDetails } from '@packages/interfaces'
import { API_ENDPOINTS } from '@packages/constants'
import { normalizeRuneName } from '@packages/utils'

import { useDebounce } from 'src/shared/hooks'
import { useUpdateOnBlockPaginationApi } from 'src/api'
import { useWalletContext } from 'src/wallet'

const SEARCH_DEBOUNCE_MS = 500

export function useMintsMarketSearch({
  orderBy = 'holderCount',
  limit = 10,
}: {
  orderBy?: 'holderCount'
  limit?: number
}) {
  const { runesAddress } = useWalletContext()
  const [searchQuery, setSearchQuery] = useState('')
  const [rawSearchQuery, setRawSearchQuery] = useState('')
  const [hasSearched, setHasSearched] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)

  const queryParams = useMemo(() => {
    setIsDisabled(searchQuery.trim().length === 0)

    return {
      searchQuery: normalizeRuneName(searchQuery),
      limit,
      orderBy,
      ...(runesAddress && { address: runesAddress.addrString }),
    }
  }, [searchQuery, runesAddress?.addrString, orderBy])

  const { paginatedData, loading, fetchPage, hasNextPage, forceRefresh, resetData } =
    useUpdateOnBlockPaginationApi<RuneDetails>({
      endpoint: API_ENDPOINTS.GET.runes.markets.mintsSearch,
      disabled: isDisabled,
      otherQueryParams: queryParams,
    })

  function handleOnChange(value: string) {
    setRawSearchQuery(value)
  }

  useDebounce(
    () => {
      if (rawSearchQuery.trim().length > 0) {
        setHasSearched(true)
      } else {
        setHasSearched(false)
      }
      resetData()
      setSearchQuery(rawSearchQuery)
    },
    [rawSearchQuery],
    SEARCH_DEBOUNCE_MS
  )

  return {
    handleOnChange,
    loading,
    hasSearched,
    searchResults: paginatedData,
    searchQuery: rawSearchQuery,
    fetchPage,
    hasNextPage,
    forceRefresh,
  }
}

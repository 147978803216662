export const API_ENDPOINTS = {
  healthCheck: '/healthz',
  GET: {
    settings: {
      get: '/v1/settings',
    },
    runes: {
      mints: {
        popular: '/v1/mints/popular',
        batchByAddress: '/v1/account/:address/batch-mints',
        byAddress: '/v1/account/:address/mints',
      },
      etchings: {
        latest: '/v1/etchings/latest',
      },
      markets: {
        get: '/v1/market/:runeId',
        mintsSearch: '/v1/markets/search/mints',
        ordersSearch: '/v1/markets/search/orders',
        popular: '/v1/markets/popular',
      },
      orders: {
        byStatus: '/v1/rune/:runeName/orders/status',
        byAccount: '/v1/account/:address/orders',
        byRuneForAccount: '/v1/account/:address/orders/:runeId',
      },
      bidOrders: {
        byStatus: '/v1/rune/:runeName/bids/status',
        byAccount: '/v1/account/:address/bids',
        byRuneForAccount: '/v1/account/:address/bids/:runeId',
      },
      details: '/v1/rune/:runeName/details/',
      balances: {
        byRune: '/v1/rune/:runeId/balances',
        byAccount: '/v1/account/:address/balances',
        runeBalanceByAccount: '/v1/account/:address/balance/:runeId',
        rewardBalanceByAccount: '/v1/account/:address/reward-event/balance',
      },
      outpoints: {
        runesOutpointsForAccount: '/v1/outpoints/runes/:runeId/:address',
        cleanUtxosForAccount: '/v1/outpoints/clean/:address',
        runeAmountsForOutpoint: '/v1/outpoints/outpoint/:outpointId',
      },
      name: {
        isValidRuneName: '/v1/rune/name/is-valid',
      },
      projections: {
        get: '/v1/runes/:runeId/reward-event/projection',
      },
    },
    accounts: {
      history: '/v1/account/:address/history',
      rewardHistory: '/v1/account/:address/reward-event/history',
    },
    auth: {
      challenge: '/v1/challenge',
    },
    chain: {
      recommendedFees: '/v1/chain/recommended-fees',
      blockTip: '/v1/chain/block-tip',
      // utxos: '/v1/chain/utxos/:address',
    },
    bitcoind: {
      chainInfo: '/v1/bitcoind/chain-info',
    },
    btc: {
      price: '/v1/btc/price',
    },
    externalMarketData: {
      summary: '/v1/market-data/summary',
      // Unclear if we need these yet. It seems like CMC may not require them. Will re-add them if needed.
      // assets: '/v1/market-data/assets',
      // ticker: '/v1/market-data/ticker',
      // orderBook: '/v1/market-data/orderbook/:marketPair',
      // trades: '/v1/market-data/trades/:marketPair',
    },
    escrowWallet: {
      getAddress: '/v1/escrow/:address/wallet',
      getBalance: '/v1/escrow/:address/balance',
      getEventHistory: '/v1/escrow/:address/event-history',
    },
  },
  POST: {
    escrowWallet: {
      withdraw: '/v1/escrow/withdraw',
    },
    runes: {
      orders: {
        sell: '/v1/rune/:runeName/orders/sell',
        cancel: '/v1/rune/:runeName/orders/:orderId/cancel',
        requestBuy: '/v1/rune/:runeName/orders/request-buy',
        magicEdenRequestBuy: '/v1/rune/:runeName/magic-eden/orders/request-buy',
        submitBuy: '/v1/rune/:runeName/orders/submit-buy',
        magicEdenSubmitBuy: '/v1/rune/:runeName/magic-eden/orders/submit-buy',
      },
      bidOrders: {
        sell: '/v1/rune/:runeName/bidorders/sell',
        cancel: '/v1/rune/:runeName/bidorders/:orderId/cancel',
        requestBuy: '/v1/rune/:runeName/bidorders/:orderId/request-buy',
        submitBuy: '/v1/rune/:runeName/bidorders/:orderId/submit-buy',
      },
      mints: {
        submitMints: '/v1/rune/:runeName/mints/submit-mints',
      },
    },
    chain: {
      sendRawTransactions: '/v1/chain/send-raw-transactions',
    },
  },
}
